import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import AccountMain from '../../components/Shop/AccountMain';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import Certificates from '../../components/Shop/Certificates';

const CertificateMain = () => {
    return (
        <React.Fragment>
        <Helmet>
            <link rel="icon" href={favIcon} />
        </Helmet>
        <OffWrap />
        <Header
            parentMenu='pages'
            secondParentMenu='shop'
            headerNormalLogo={Logo}
            headerStickyLogo={Logo}
            CanvasLogo={Logo}
            CanvasClass="right_menu_togle hidden-md"
            headerClass="full-width-header header-style1 home8-style4"
            TopBar='enable'
            TopBarClass="topbar-area home8-topbar"
            emailAddress='support@website.com'
            Location='374 William S Canning Blvd, MA 2721, USA '
        />

        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
            pageTitle="Certificates"
            pageName="Certificates"
            breadcrumbsImg={bannerbg}
        />
        {/* breadcrumb-area-End */}

        <Certificates/>

        <Newsletter
            sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
            titleClass="title mb-0 white-color"
        />

        <Footer
            footerClass="rs-footer home9-style main-home"
            footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop
            scrollClassName="scrollup orange-color"
        />
        {/* scrolltop-end */}

        <SearchModal />
    </React.Fragment>
    );
};

export default CertificateMain;