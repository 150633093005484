import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BlockchainContext } from "../../../Context/BlockchainContext";
import { UserContext } from "../../../Context/UserContext";
import Swal from "sweetalert2";

const TopHeader = (props) => {
  const { topBarClass, emailAddress, phoneNumber, Location } = props;
  const { data, logout } = useContext(UserContext);
  const { user, openWalletModal, setRefetch, refetch, pathName } =
    useContext(BlockchainContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const LogOut = () => {
    logout();
    navigate("/");

    // swal({
    //     text: `Successfully Logged Out!`,
    //     icon: "success",
    //     button: "OK!",
    //     className: "modal_class_success",
    // });

    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p class='text-break text-white'>Successfully Signed Out!</p>`;

    Swal.fire({
      html: wrapper,
      icon: "success",
      customClass: "modal_class_success",
    });
  };

  useEffect(() => {
    if (user?.walletAddress && refetch && pathname != pathName) {
      navigate("/my-account");
    }
  }, [refetch]);

  return (
    <div
      className={
        topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"
      }>
      <div className="container">
        <div className="row y-middle">
          <div className="col-md-7">
            <ul className="topbar-contact">
              {emailAddress ? (
                <li>
                  <i className="flaticon-email"></i>
                  <a href={"mailto:" + "support@studyswiftly.com"}>
                    support@studyswiftly.com
                  </a>
                </li>
              ) : (
                ""
              )}
              {phoneNumber ? (
                <li>
                  <i className="flaticon-call"></i>
                  <a href={"tel:+" + phoneNumber}>{phoneNumber}</a>
                </li>
              ) : (
                ""
              )}
              {/* {Location ? 
                                <li>
                                    <i className="flaticon-location"></i>
                                    22 Sin Ming Lane #06-76 Midview City Singapore 573969
                                </li> : ""
                            } */}
            </ul>
          </div>
          <div className="col-md-5 text-end">
            {/* {user?.walletAddress ?
                            <ul className='topbar-right '>
                                <li className='login-register'>
                                    <div className='menuText loginbuttonPc'>
                                        <Link style={{ fontSize: '13px', width: "120px" }} className="headerButtonW text-white" role="button" to="/my-account">Profile</Link>
                                    </div>
                                </li>
                            </ul>
                            :
                            <ul className='topbar-right '>
                                <li className='login-register'>
                                    <div className='menuText loginbuttonPc'>
                                        <button style={{ fontSize: '13px' }} className="headerButtonW" role="button" onClick={() => openWalletModal()}>
                                            <img className='me-2' width='18px' src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg" alt="" />
                                            LOGIN WITH WALLET</button>
                                    </div>
                                </li>
                            </ul>} */}
            {/* {data.email ?
                            <ul className="topbar-right">
                                <li className="login-register">
                                    <i className="fa fa-sign-in"></i>
                                    <Link to="/my-account">PROFILE</Link>
                                </li>
                                <li className="btn-part">
                                    <Link onClick={() => LogOut()} className="apply-btn">LOGOUT</Link>
                                </li>
                            </ul>
                            :
                            <ul className="topbar-right">
                                <li className="login-register">
                                    <i className="fa fa-sign-in"></i>
                                    <Link to="/login">SIGN-IN</Link>
                                </li>
                                <li className="btn-part">
                                    <Link to="/register" className="apply-btn">SIGN-UP</Link>
                                </li>
                            </ul>
                        } */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
