import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./products.css";
// Breadcrumbs Bg

const TeamSingleMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div style={{ backgroundColor: "#171f32" }}>
        <Container className="section-pading2">
          <Box className="mt-0">
            <p
              className="text-center headerText pt-4"
              style={{ color: "#ff5421" }}
            >
              Develop your entrepreneurship skills.
            </p>

            <Box className="mt-0">
              <div className="d-flex justify-content-end">
                {/* <div className="col-6"></div> */}
                <div className="d-flex align-items-center col-6 justify-content-between pe-5 text-light all-nets-div">
                  <p className="fs-6 single-net">Testnet</p>
                  <p className="fs-6 single-net">Mainnet</p>
                  <p className="fs-6 single-net">Publicnet</p>
                </div>
              </div>
              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">Game NFT</p>
                  <p className="mb-0 text-light product-details">
                    November 2022
                  </p>
                  <a
                    href="https://celebritygames.net"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      celebritygames.net
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.celebritygames.net/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://mainnet.celebritygames.net/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick mainnet-tick"
                      style={{
                        position: "relative",
                        right: "8px",
                        width: "28px",
                        height: "28px",
                      }}
                      src="mainnetTick.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://celebritygames.net"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick publicnet-tick"
                      style={{
                        position: "relative",
                        right: "16px",
                        width: "28px",
                        height: "28px",
                      }}
                      src="publicnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">
                    Intellectual NFT
                  </p>
                  <p className="mb-0 text-light product-details">
                    December 2022
                  </p>
                  <a
                    href="https://indianfilmtitles.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      indianfilmtitles.com
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.indianfilmtitles.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://mainnet.indianfilmtitles.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick mainnet-tick"
                      style={{
                        position: "relative",
                        right: "8px",
                        width: "28px",
                        height: "28px",
                      }}
                      src="mainnetTick.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://indianfilmtitles.com"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick publicnet-tick"
                      style={{
                        position: "relative",
                        right: "16px",
                        width: "28px",
                        height: "28px",
                      }}
                      src="publicnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">
                    Educational NFT
                  </p>
                  <p className="mb-0 text-light product-details">
                    January 2023
                  </p>
                  <a
                    href="https://studyswiftly.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      Studyswiftly.com
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://studyswiftly.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">AI NFT</p>
                  <p className="mb-0 text-light product-details">
                    February 2023
                  </p>
                  <a
                    href="https://gptnft.net/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      gptnft.net
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.gptnft.net/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">
                    Promotional NFT
                  </p>
                  <p className="mb-0 text-light product-details">March 2023</p>
                  <a
                    href="https://ponniyinselvan.io/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      ponniyinselvan.io
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.ponniyinselvan.io/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">
                    Membership NFT
                  </p>
                  <p className="mb-0 text-light product-details">April 2023</p>
                  <a
                    href="https://dslcommerce.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      dslcommerce.com
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.dslcommerce.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">Video NFT</p>
                  <p className="mb-0 text-light product-details">May 2023</p>
                  <a
                    href="https://videonft.sg/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      videonft.sg
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.videonft.sg/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">
                    Identity NFT
                  </p>
                  <p className="mb-0 text-light product-details">June 2023</p>
                  <a
                    href="https://dchirp.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      dchirp.com
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.dchirp.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">
                    Virtual Pet NFT
                  </p>
                  <p className="mb-0 text-light product-details">July 2023</p>
                  <a
                    href="https://grighund.net/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      grighund.net
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.grighund.net/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="table-row-style d-flex justify-content-between mb-3">
                <div className="ps-5 text-start text-light col-6 single-product">
                  <p className="mb-0 text-light fs-4 product-name">
                    Metaverse NFT
                  </p>
                  <p className="mb-0 text-light product-details">August 2023</p>
                  <a
                    href="https://kailashian.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="product-details" style={{ color: "#64B9F7" }}>
                      kailashian.com
                    </p>
                  </a>
                </div>

                <div className="d-flex align-items-center col-6 justify-content-between pe-5 product-image-div">
                  <a
                    href="https://testnet.kailashian.com/"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="product-tick testnet-tick"
                      style={{
                        position: "relative",
                        left: "12px",

                        width: "28px",
                        height: "28px",
                      }}
                      src="testnetTick.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </Box>
            <hr style={{ borderColor: "#ececec" }} />
          </Box>

          {/* ..........................................                    
                    ............Other products................
                    ....................................... */}

          {/* <Box className="mt-4">
                    <Typography className="fw-bolder text-start">
                        Our others products
                    </Typography>
                    <table className='product_list'>
                        <thead>
                            <tr>
                                <th className=""></th>
                                <th></th>
                                <th></th>
                                <th>Testnet</th>
                                <th>Mainnet</th>
                                <th className='specialHandleTh handleReduce'>Publicnet</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ backgroundColor: "#b6b6b6", color: "black" }} className="handleTopOther">
                                <th className='ps-2'><span className='handleAlignSpecial'>Intellectual <br /><span className="breaker">World</span></span></th>
                                <td className='ps-2'>
                                </td>

                                <td className='ps-2'>
                                    <Link className='serviceLinkSite' href="https://indianfilmtitles.com" target="_blank">
                                        <span className='handleAlignSpecial text-primary '>indianfilmtitles.com</span>
                                    </Link>

                                </td>
                                <th className="text-center ps-2">
                                    <Link className='serviceLink' href="https://testnet.indianfilmtitles.com/" target="_blank">
                                        <DoneIcon className='doneIcon' />
                                    </Link>
                                </th>
                                <th className='ps-2'>
                                    <Link className='serviceLink' href="https://mainnet.indianfilmtitles.com" target="_blank">
                                        <DoneIcon className='doneIconMain' />
                                    </Link>
                                </th>
                                <th className='text-center specialHandleTh spacing'>
                                    <Link className='serviceLink' href="https://indianfilmtitles.com" target="_blank">
                                        <DoneIcon className='doneIconPublic' />
                                    </Link>
                                </th>
                            </tr>
                            <tr style={{ backgroundColor: "white", color: "black" }} className="handleTopOther">
                                <th className='ps-2'><span className='handleAlignSpecial'>Stablecoin <br /><span className="breaker">World</span></span></th>
                                <td className='ps-2'>
                                </td>
                                <td className='ps-2'>
                                    <Link className='serviceLinkSite' href="https://usdsc.net" target="_blank">


                                        <span className='handleAlignSpecial text-primary'>usdsc.net</span>
                                    </Link>
                                </td>
                                <th className="text-center ps-2">
                                    <Link className='serviceLink' href="https://testnet.usdsc.net/" target="_blank">
                                        <DoneIcon className='doneIcon' />
                                    </Link>
                                </th>
                                <th className='ps-2'></th>
                                <th className='ps-3 text-center'>
                                   
                                </th>
                            </tr>

                            <tr style={{ backgroundColor: "#b6b6b6", color: "black" }} className="handleTopOther">
                                <th className='ps-2'><span className='handleAlignSpecial'>Educational <br /><span className="breaker">World</span></span></th>
                                <td className='ps-2'>
                                </td>

                                <td className='ps-2'>
                                    <Link className='serviceLinkSite' href="https://studyswiftly.com" target="_blank">
                                        <span className='handleAlignSpecial text-primary '>Studyswiftly.com</span>
                                    </Link>

                                </td>
                                <th className="text-center ps-2">
                                    <Link className='serviceLink' href="https://studyswiftly.com/" target="_blank">
                                        <DoneIcon className='doneIcon' />
                                    </Link>
                                </th>
                                <th className='ps-2'></th>
                                <th className='ps-3 text-center'>
                                   
                                </th>
                            </tr>
                          
                        </tbody>
                    </table>
                </Box> */}
        </Container>
      </div>
    </Fragment>
  );
};

export default TeamSingleMain;
