import { ArrowBack } from '@mui/icons-material';
import { Table } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const RequestedDiplomas = () => {
    const navigate=useNavigate()
    const handleSearchDiplomas = () => {};
    return (
        <div className="adminBody">
       <p className='cursor-pointer' style={{ cursor: "pointer" }} title='Back' onClick={() => navigate(-1)}><ArrowBack /></p>
      <h5 className="text-white text-start mb-3">DIPLOMAS REQUESTED</h5>
      <div className="certificate-search-bar-container">
        <input
          className="form-control"
          placeholder="Search by name"
          type="text"
          onChange={(e) => handleSearchDiplomas(e)}
        />
      </div>
      <div className="adminCard py-2">
        {/* <div className="adminBtnDiv text-end">
          <Link to="/admin/allcertificates/">
            <button className="adminBtn mt-0 text-uppercase">
              SEE ALL CERTIFICATES
            </button>
          </Link>
        </div> */}
        <div className="tableNormal ">
          <Table className="text-white adminDataTable ">
            <thead>
              <tr>
                <th className="text-start text-nowrap">Name</th>
                <th className="text-start text-nowrap">Course Name</th>
                {/* <th className='text-start'>Email</th> */}
                {/* <th className="text-start adminHidden">Course Type</th> */}
                <th className="text-start adminHidden text-nowrap">Id</th>
                <th className="text-start adminHidden text-nowrap">Start Date</th>
                <th className="text-start text-nowrap">Action</th>
                <th className="text-start text-nowrap">Status</th>
              </tr>
            </thead>
            <tbody>
              
            </tbody>
          </Table>
          
        </div>
        {/*         
          {sliceUsers?.length && (
          <div>
            <Pagination
              lastPage={lastPage}
              page={pageNumber}
    
              pageHandle={pageHandle}
            />
          </div>
        )} */}
      </div>
    </div>
    );
};

export default RequestedDiplomas;