import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const ActionDetailsModal = (props) => {
  const { actionDetails } = props;
  //   console.log(actionDetails, "Incoming action details");

  const [details, setDetails] = useState([]);
  //   console.log(details);

  useEffect(() => {
    if (actionDetails) {
      let actDetails = actionDetails;

      // Deleting some properties
      delete actDetails._id;
      delete actDetails.__v;
      delete actDetails.createdAt;
      delete actDetails.updatedAt;

      const keys = Object.keys(actDetails);
      let detailsArr = [];
      for (const key of keys) {
        detailsArr.push({ [key]: actDetails[key] });
      }
      setDetails(detailsArr);
    } else {
      setDetails([]);
    }
  }, [actionDetails]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}
    >
      <Modal.Header closeButton className="modalHeader">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-4 text-light"
        >
          <span className="text-white">Action Details</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: "rgb(177, 185, 195)" }}
        className="handleModalBody"
      >
        <div style={{ overflowWrap: "anywhere" }} className="action-details">
          {details?.map((eachPropertyObj) => (
            <p>
              <span className="text-capitalize">
                {Object.keys(eachPropertyObj)[0]}
              </span>
              : {Object.values(eachPropertyObj)[0]}
            </p>
          ))}
        </div>

        <Modal.Footer className="mt-3 modalFooter text-center justify-content-center">
          <button
            onClick={() => props.onHide()}
            type="submit"
            className="adminBtnAdd"
          >
            OK
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ActionDetailsModal;
