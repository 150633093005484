export const voices = [
  {
    value: "en-AU-Neural2-A",
    label: "English-Australia-Female-Aurora",
    code: "f1",
  },
  {
    value: "en-AU-Neural2-B",
    label: "English-Australia-Male-Atlas",
    code: "m1",
  },
  {
    value: "en-AU-Neural2-C",
    label: "English-Australia-Female-Robonia",
    code: "f2",
  },
  {
    value: "en-AU-Neural2-D",
    label: "English-Australia-Male-Helios",
    code: "m2",
  },
  {
    value: "en-GB-Neural2-A",
    label: "English-UK-Female-Electra",
    code: "f3",
  },
  {
    value: "en-GB-Neural2-B",
    label: "English-UK-Male-Blaze",
    code: "m3",
  },
  {
    value: "en-GB-Neural2-C",
    label: "English-UK-Female-Xeferia",
    code: "f4",
  },
  {
    value: "en-GB-Neural2-D",
    label: "English-UK-Male-Odin",
    code: "m4",
  },
  {
    value: "en-US-Neural2-C",
    label: "English-US-Female-Asertina",
    code: "f5",
  },
  {
    value: "en-US-Neural2-A",
    label: "English-US-Male-Galen",
    code: "m5",
  },
  {
    value: "en-US-Neural2-E",
    label: "English-US-Female-Xylaloras",
    code: "f6",
  },
  {
    value: "en-US-Neural2-D",
    label: "English-US-Male-Zephyr",
    code: "m6",
  },
];
export const videoBgMusic = [
  {
    value: "Sample 1",
    label: "Sample 1",
    code: "Sample 1",
  },
  {
    value: "Sample 2",
    label: "Sample 2",
    code: "Sample 2",
  },
  {
    value: "Sample 3",
    label: "Sample 3",
    code: "Sample 3",
  },
];

export const myArray = [
  "Image processing in progress.",
  "Analyzing image data.",
  "Enhancing image quality.",
  "Adjusting brightness and contrast.",
  "Cropping image to desired size.",
  "Resizing image to fit desired dimensions.",
  "Adjusting color balance.",
  "Removing unwanted elements from image.",
  "Enhancing sharpness and clarity.",
  "Enhancing details in image.",
  "Adjusting levels and curves.",
  "Applying noise reduction techniques.",
  "Enhancing texture in image.",
  "Applying blur and sharpening techniques.",
  "Adjusting hue and saturation.",
  "Applying color correction techniques.",
  "Enhancing depth of field in image.",
  "Adjusting brightness and contrast.",
  "Enhancing shadows and highlights.",
  "Enhancing color and tone.",
  "Enhancing sharpness and clarity.",
  "Applying texture effects to image.",
  "Enhancing details in image.",
  "Applying depth of field effects to image.",
  "Applying color grading techniques.",
  "Enhancing color and tone.",
];

export const array = [
  "Renaissance painting of three lovers lamenting a broken glass sphere",
  "CENTERED PORTRAIT OF AN ULTRA DETAILED MECHANICAL CYBERPUNK FEMALE ANDROID, LOOKING INTO THE CAMERA, INTRICATE, ELEGANT, SUPER HIGHLY DETAILED, SMOOTH, SHARP FOCUS, NO BLUR, NO DOF, EXTREME ILLUSTRATION, UNREAL",
  "MECHANICAL DAWN, TECHNOLOGY CITY, A MECHANICAL TRANSFORMER, EPIC FANTASY ART",
  "AN ASTRONAUT RESTING ON MARS IN A BEACH CHAIR, VIBRANT LIGHTING, ELEGANT, HIGHLY DETAILED, SMOOTH, SHARP FOCUS, ILLUSTRATION, BEAUTIFUL, GEOMETRIC",
  "A BEAUTIFUL SUNSET WITH MOUNTAINS IN THE BACKGROUND AND A LAKE WITH GLACIER WATER IN THE FOREGROUND",
  "PHOTO OF CYBERPUNK FACE",
  "A CABIN SURROUNDED BY FOG. RED LIGHTS SHINING OUT OF WINDOWS. CORNFIELD",
  "PHOTO OF POND WITH LOTUS FLOWER",
  "A BABY FENNEC SNEEZING ONTO A STRAWBERRY, DETAILED, MACRO, STUDIO LIGHT, DROPLETS, BACKLIT EARS",
  "A CUP OF COFFEE ON A SAUCER WITH A SPOON, A TILT SHIFT PHOTO, ANAMORPHIC BOKEH, ITALIAN",
  "PHOTO OF SILHOUETTE OF PERSON USING THEIR PHONE OUTSIDE",
];

export const contentTypes = [
  {
    id: "01",
    text: "Quotes",
  },
  {
    id: "02",
    text: "Poems",
  },
  {
    id: "03",
    text: "History",
  },
  {
    id: "04",
    text: "News",
  },
  {
    id: "05",
    text: "Quizzes",
  },
  {
    id: "06",
    text: "Jokes",
  },
  {
    id: "07",
    text: "News",
  },
  {
    id: "08",
    text: "Articles",
  },
];

export const stepsContent = [
  {
    id: "01",
    text: "Select Category of business",
  },
  {
    id: "02",
    text: "Upload Logo",
  },
  {
    id: "03",
    text: "Connect to social media channels needed",
  },
  {
    id: "04",
    text: "Add Hashtags",
  },
  {
    id: "05",
    text: "Determine how many posts per day",
  },
  {
    id: "06",
    text: "Determine what times to post",
  },
  {
    id: "07",
    text: "Determine what type of contents to post",
  },
  {
    id: "08",
    text: "AI WILL DO THE REST",
  },
];

export const channel = [
  {
    id: "01",
    text: "Facebook Personal Profile",
  },
  {
    id: "02",
    text: "Facebook Business Profile",
  },
  {
    id: "03",
    text: "Facebook Group",
  },
  {
    id: "04",
    text: "Google Business Profile",
  },
  {
    id: "05",
    text: "Instagram",
  },
  {
    id: "06",
    text: "LinkedIn",
  },
  {
    id: "07",
    text: "Pinterest",
  },
  {
    id: "08",
    text: "Twitter",
  },
];
