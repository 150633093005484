import { CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

// CSS styles for the CardElement
const cardElementStyles = {
  base: {
    fontSize: "16px",
    color: "#32325d",
    fontFamily: "Arial, sans-serif",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

const PaymentMethodForm = ({
  handleSubmit,
  setuserDetails,
  userDetails,
  cardError,
  loader,
}) => {
  const [myCountry, setMyCountry] = useState({
    country: "",
    countryCode: "",
  });
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountryFromIp = async () => {
      const response = await axios.get(
        "https://api.ipregistry.co/?key=jswae6n7t9pc4unc"
      );
      setMyCountry({
        // country: response?.data?.country,
        // countryCode: response?.data?.countryCode,
        country: response?.data?.location?.name,
        countryCode: response?.data?.location?.code,
      });
      setuserDetails({
        ...userDetails,
        country: response?.data?.countryCode,
      });
    };
    getCountryFromIp();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v2/all");
        if (response) {
          const data = response?.data;

          const countryData = data?.map((country) => ({
            countryName: country.name,
            countryCode: country.alpha2Code,
          }));

          setCountries(countryData);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleOnChangeCountries = (e) => {
    console.log("event", e.target.value);
    setMyCountry({
      country: e.target.value,
      countryCode: e.target.value,
    });
    setuserDetails({
      ...userDetails,
      country: e.target.value,
    });
  };

  return (
    <div className="payment-card mt-2">
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-12">
          <label htmlFor="inputEmail4" className="form-label">
            Name as per card
          </label>
          <input
            required
            type="name"
            className="form-control"
            id="inputEmail4"
          />
        </div>
        <div className="col-12">
          <label htmlFor="inputEmail4" className="form-label">
            Select your country
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            value={myCountry?.countryCode}
            onChange={handleOnChangeCountries}>
            {countries?.map((country) => (
              <option key={country.countryCode} value={country.countryCode}>
                {country.countryName}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12">
          <label htmlFor="inputAddress" className="form-label">
            Address
          </label>
          <input
            required
            onChange={(e) =>
              setuserDetails({ ...userDetails, city: e.target.value })
            }
            type="text"
            className="form-control"
            id="inputAddress"
            placeholder=""
          />
        </div>
        <div className="col-12">
          <label htmlFor="inputAddress" className="form-label">
            Zip/Postal
          </label>
          <input
            required
            type="number"
            className="form-control"
            id="zipcode"
            placeholder=""
          />
        </div>
        <div className="col-12">
          <label htmlFor="inputAddress" className="form-label">
            Card Details
          </label>
          <CardElement
            options={{
              style: cardElementStyles,
            }}
          />
          {cardError && (
            <p
              style={{
                color: "rgb(231, 31, 31)",
                fontSize: "13px",
                fontWeight: "bold",
                // margin top 4px and bottom 0
                margin: "4px 0 0 0",
              }}>
              {cardError}
            </p>
          )}
        </div>

        <hr className="mb-0" />

        <div className="col-12">
          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={loader}>
            {loader ? "SAVING..." : "SAVE CARD"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentMethodForm;
