import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Admins.css";
import DashboardModalNewAdmin from "./DashboardModalNewAdmin";
import Swal from "sweetalert2";
import Pagination from "../../../components/Pagination/Pagination";
// import AdminPopUp from './AdminPopUp/AdminPopUp';
// import DeletePopUp from './DeletePopUp/DeletePopUp';

const Admins = () => {
  const [open, setOpen] = React.useState(false);
  const [allAdmin, setAllAdmin] = React.useState([]);
  console.log(allAdmin, "all admins");
  const [modalShowNewAdmin, setModalShowNewAdmin] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    axios.get("https://backend.studyswiftly.com/api/admin/").then((res) => {
      setAllAdmin(res.data);
      // console.log(res.data)
    });
  }, [open, refetch]);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceAdmins, setSliceAdmins] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(allAdmin?.length / show);
    setLastPage(lastPage);
  }, [allAdmin, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = allAdmin.slice((page - 1) * show, page * show);
      setSliceAdmins([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = allAdmin.slice(0, show);
      setSliceAdmins([...getSlicingProduct]);
    }
  }, [allAdmin, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  const handleAdminDelete = (id) => {
    // const confirmDelete = window.confirm('Are you sure, you want to delete this admin?')

    Swal.fire({
      text: "Are you sure, you want to delete this admin?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://backend.studyswiftly.com/api/admin/${id}`, {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "courseSiteAdmin"
              )}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              // alert(res.data.message);

              const wrapper = document.createElement("div");
              // wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;
              wrapper.innerHTML = `<p class='text-break text-white'>Admin Deleted.</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              // swal({
              //   title: "Success",
              //   text: res.data.message,
              //   icon: "success",
              //   button: "OK!",
              //   className: "modal_class_success",
              // });

              setAllAdmin(allAdmin.filter((admin) => admin._id !== id));
            }
          })
          .catch((error) => {
            // alert(error.response.data.message);
            // swal({
            //   title: "Attention",
            //   text: error.response.data.message,
            //   icon: "warning",
            //   button: "OK!",
            //   className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });
          });
      }
    });

    // if (confirmDelete) {
    //   axios.delete(`https://backend.studyswiftly.com/api/admin/${id}`, {
    //     headers: {
    //       'authorization': `Bearer ${localStorage.getItem('courseSiteAdmin')}`
    //     }
    //   })
    //     .then(res => {
    //       if (res.status === 200) {
    //         // alert(res.data.message);
    //         swal({
    //           title: "Success",
    //           text: res.data.message,
    //           icon: "success",
    //           button: "OK!",
    //           className: "modal_class_success",
    //         });
    //         setAllAdmin(allAdmin.filter(admin => admin._id !== id))
    //       }
    //     })
    //     .catch(error => {
    //       // alert(error.response.data.message);
    //       swal({
    //         title: "Attention",
    //         text: error.response.data.message,
    //         icon: "warning",
    //         button: "OK!",
    //         className: "modal_class_success",
    //       });
    //     })

    // }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openDelete, setOpenDelete] = React.useState(false);

  // const handleClickOpenDelete = () => {
  //   setOpenDelete(true);
  // };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  //   const Logout = () => {
  //     logOut();
  //     swal({
  //         // title: "S",
  //         text: "You have successfully logout.",
  //         icon: "success",
  //         button: "OK!",
  //         className: "modal_class_success",
  //     });
  // }

  return (
    // <div className='adminBody' style={{ minHeight: "100vh" }}>
    <div className="adminBody">
      <h5 className="text-white text-start">ADMINS</h5>
      <div className="adminCard py-2">
        <div className="">
          <button
            onClick={() => setModalShowNewAdmin(true)}
            className="adminBtn adminBtn ms-auto me-3"
          >
            ADD NEW ADMIN
          </button>
        </div>
        <div className="tableNormal">
          <Table className="text-white adminDataTable ">
            <thead>
              <tr>
                <th className="text-start">Profile</th>
                <th className="text-start">Username</th>
                <th className="text-start adminHidden">Email</th>
                <th className="text-start adminHidden">Mobile</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            <tbody>
              {sliceAdmins?.map((admin) => (
                <tr className="tableRow" key={admin._id}>
                  <td>
                    {" "}
                    <img
                      className="imgAdmin"
                      src={`${
                        admin?.avatar
                          ? `https://backend.studyswiftly.com/${admin?.avatar}`
                          : "https://backend.studyswiftly.com/assets/1676464962479.jpeg"
                      }`}
                      alt=""
                    />
                  </td>
                  <td
                    style={{ textTransform: "lowercase" }}
                    className="text-start"
                  >
                    {admin.username ? admin.username : "username"}
                  </td>
                  <td className="text-start adminHidden">{admin.email}</td>
                  <td className="text-start adminHidden">
                    {admin.phone ? admin.phone : "mobile number"}
                  </td>
                  <td className="">
                    <div className="">
                    <Link to={`/admin/adminprofile/${admin._id}`}>
                        <button className="editBtn">
                          <i className="fas fa-edit"></i>
                        </button>
                      </Link>
                      
                      <button
                        onClick={() => handleAdminDelete(admin._id)}
                        className="deleteBtn"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {sliceAdmins?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>
        {/* <AdminPopUp
          open={open}
          handleClose={handleClose}
        >
        </AdminPopUp>

        <DeletePopUp
          handleCloseDelete={handleCloseDelete}
          openDelete={openDelete}
        >

        </DeletePopUp> */}

        <DashboardModalNewAdmin
          show={modalShowNewAdmin}
          // setIsLoadingAdmin={setIsLoadingAdmin}
          // isLoadingAdmin={isLoadingAdmin}
          refetch={refetch}
          setRefetch={setRefetch}
          setModalShowNewAdmin={setModalShowNewAdmin}
          onHide={() => setModalShowNewAdmin(false)}
          // allAdmin={allAdmin}
          // setAllAdmin={setAllAdmin}
        />
      </div>
    </div>
  );
};

export default Admins;
