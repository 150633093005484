import React from 'react';
import { Link } from 'react-router-dom';

// Image
import teamImg1 from '../../../assets/img/team/1.jpg';
import teamImg2 from '../../../assets/img/team/2.jpg';

const InstructorPart = () => {

    return (
        <div className="content pt-30 pb-30 pl-30 pr-30 white-bg">
            <h3 className="instructor-title">Enroll</h3>
            <div className="row rs-team style1 orange-color transparent-bg clearfix">
                
            </div>  
        </div>
    );
}

export default InstructorPart;