import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CourseSingleTwo from "../../components/Courses/CourseSingleTwo";
import courseImg1 from "../../assets/img/courses/1.jpg";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress, Pagination } from "@mui/material";
import usePagination from "../../Pagination/UsePagination";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      backgroundColor: "#a6a6ab",
      color: "#fff",
    },
    "& .MuiPaginationItem-root:hover": {
      backgroundColor: "#a6a6ab",
      color: "#fff",
    },
  },
}));

const AllCoursesSection = (props) => {
  const [courses, setCourses] = useState([]);
  const [blockchainCourse, setBlockchainCourse] = useState([]);
  const [dappsCourses, setDappsCourses] = useState([]);
  const [playCourses, setPlayCourses] = useState([]);
  let [page, setPage] = useState(1);
  const PER_PAGE = 9;
  const classes = useStyles();
  const coursesCount = Math.ceil(courses?.length / PER_PAGE);
  const coursePageData = usePagination(courses, PER_PAGE);
  const BlockchainCount = Math.ceil(blockchainCourse?.length / PER_PAGE);
  const BlockchainPageData = usePagination(blockchainCourse, PER_PAGE);
  const DigitalCount = Math.ceil(dappsCourses?.length / PER_PAGE);
  const DigitalPageData = usePagination(dappsCourses, PER_PAGE);
  const playCount = Math.ceil(playCourses?.length / PER_PAGE);
  const playPageData = usePagination(playCourses, PER_PAGE);
  const params = new URLSearchParams(window.location.search);
  const courseParam = params.get("indexs");
  const [tabLoading, setTabLoading] = useState(false);

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/active")
        // await axios.get("courses.json")
        .then((res) => {
          setCourses(res.data.result);
        });
    };
    data();
  }, []);

  useEffect(() => {
    const blockchainCourse = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/category/Blockchain")
        .then((res) => {
          setBlockchainCourse(res.data.result?.[0]);
        });
    };
    blockchainCourse();
  }, []);

  useEffect(() => {
    const data2 = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/course/category/Digital_asset"
        )
        .then((res) => {
          setDappsCourses(res.data.result?.[0]);
        });
    };
    data2();
  }, []);

  useEffect(() => {
    const data3 = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/course/category/Play_To_Earn"
        )
        .then((res) => {
          setPlayCourses(res.data.result?.[0]);
        });
    };
    data3();
  }, []);

  const handleCoursePageChange = (e, p) => {
    setPage(p);
    coursePageData.jump(p);
  };
  const handleBlockchainPageChange = (e, p) => {
    setPage(p);
    BlockchainPageData.jump(p);
  };
  const handleDigitalPageChange = (e, p) => {
    setPage(p);
    DigitalPageData.jump(p);
  };
  const handlePlayPageChange = (e, p) => {
    setPage(p);
    playPageData.jump(p);
  };

  let tab1 = "All",
    tab2 = "Blockchain";

  const tabStyle = "gridFilter text-center mb-50  md-mb-30 row justify-content-start items-center";

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(
            res.data.Categories?.sort((a, b) => a.name.localeCompare(b.name))
          );
          if (courseParam) {
            console.log(courseParam, "ggg");
            setSelectedTab(courseParam + 1);
          }
          setTimeout(() => {
            setTabLoading(true);
          }, 1000);
          console.log(courseParam, "courseParam");
        });
    };
    data();
  }, []);
  const [selectedTab, setSelectedTab] = useState(0);
  // useEffect(() => {
  //   if (courseParam) {
  //     console.log(courseParam,"ggg");
  //     setSelectedTab(courseParam+1)
  //   }
  // },[courseParam])
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div
      id="rs-popular-course"
      className="rs-popular-courses style1 orange-style pt-100 pb-100 md-pt-70 md-pb-50">
      <div className="container">
        {tabLoading ? (
          <Tabs defaultIndex={selectedTab}>
            <TabList className={tabStyle}>
              {categories.length > 1 && (
                <Tab className="col-12 " >
                  <Button className="text-capitalize fw-semibold h-100" style={{
                    backgroundColor: activeIndex === -1 ? '#69a3ed26' : 'transparent'
                  }}
                    onClick={() => handleButtonClick(-1)} variant="text">{tab1}</Button>
                </Tab>
              )}
              {categories?.map((res, index) => (
                <Tab className="col-6 col-md-4 col-lg-3"  >
                  <Button className="text-capitalize fw-semibold text-start w-100 h-100 justify-content-start " style={{
                    backgroundColor: activeIndex === index ? '#69a3ed26' : 'transparent'
                  }}
                    onClick={() => handleButtonClick(index)} >{res?.name}</Button>
                </Tab>
              ))}
              {/* <Tab>
              <button>{tab3}</button>
            </Tab> */}
              {/* <Tab>
              <button className="mt-2">{tab4}</button>
            </Tab> */}
              {/* <Tab>
                            <button>{tab5}</button>
                        </Tab> */}
            </TabList>

            {/* tab panel 001 */}

            {categories.length > 1 && (
              <TabPanel id="tab1">
                <div className="row">
                  {courses?.length > 9
                    ? coursePageData?.currentData()?.map((course) => (
                      <div
                        className="col-lg-4 col-md-6 mb-4"
                        key={course?._id}>
                        <CourseSingleTwo
                          courseClass="courses-item mb-30"
                          courseId={course?._id}
                          courseImg={course?.image?.replace(
                            "https://backend.blockchaincert.sg/",
                            "https://backend.studyswiftly.com/"
                          )}
                          courseTitle={course?.coursename}
                          coursePrice={course?.amount}
                          courseCategory={course?.categories}
                          courseDate={`${course?.end?.slice(
                            8,
                            10
                          )}/${course?.end?.slice(5, 7)}/${course?.end?.slice(
                            0,
                            4
                          )}`}
                        />
                      </div>
                    ))
                    : courses?.map((course) => (
                      <div
                        className="col-lg-4 col-md-6 mb-4"
                        key={course?._id}>
                        <CourseSingleTwo
                          courseClass="courses-item mb-30"
                          courseId={course?._id}
                          courseImg={course?.image?.replace(
                            "https://backend.blockchaincert.sg/",
                            "https://backend.studyswiftly.com/"
                          )}
                          courseTitle={course?.coursename}
                          coursePrice={course?.amount}
                          courseCategory={course?.categories}
                          courseDate={`${course?.end?.slice(
                            8,
                            10
                          )}/${course?.end?.slice(5, 7)}/${course?.end?.slice(
                            0,
                            4
                          )}`}
                        />
                      </div>
                    ))}
                  {courses?.length > 9 && (
                    <div className="d-flex justify-content-center">
                      <Pagination
                        classes={{ ul: classes.ul }}
                        count={coursesCount}
                        size="large"
                        page={page}
                        color="primary"
                        onChange={handleCoursePageChange}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
            )}

            {/* tab panel 002 */}

            {categories?.map((res) => (
              <TabPanel>
                <div className="row ">
                  {courses?.filter((result) => result?.categories == res?.name)
                    ?.length > 9
                    ? courses
                      ?.filter((result) => result?.categories == res?.name)?.sort((a, b) => a?.sort - b?.sort)
                      ?.currentData()
                      ?.map((course1) => (
                        <div className="col-lg-4 col-md-6" key={course1?._id}>
                          <CourseSingleTwo
                            courseClass="courses-item mb-30"
                            courseImg={course1?.image?.replace(
                              "https://backend.blockchaincert.sg/",
                              "https://backend.studyswiftly.com/"
                            )}
                            courseId={course1?._id}
                            courseTitle={course1?.coursename}
                            coursePrice={course1?.amount}
                            courseCategory={course1?.categories}
                            courseDate={`${course1?.end?.slice(
                              8,
                              10
                            )}/${course1?.end?.slice(
                              5,
                              7
                            )}/${course1?.end?.slice(0, 4)}`}
                          />
                        </div>
                      ))
                    : courses
                      ?.filter((result) => result?.categories == res?.name)?.sort((a, b) => a?.sort - b?.sort)
                      ?.map((course1) => (
                        <div className="col-lg-4 col-md-6" key={course1?._id}>
                          <CourseSingleTwo
                            courseClass="courses-item mb-30"
                            courseImg={course1?.image?.replace(
                              "https://backend.blockchaincert.sg/",
                              "https://backend.studyswiftly.com/"
                            )}
                            courseId={course1?._id}
                            courseTitle={course1?.coursename}
                            coursePrice={course1?.amount}
                            courseCategory={course1?.categories}
                            courseDate={`${course1?.end?.slice(
                              8,
                              10
                            )}/${course1?.end?.slice(
                              5,
                              7
                            )}/${course1?.end?.slice(0, 4)}`}
                          />
                        </div>
                      ))}
                  {courses?.filter((result) => result?.categories == res?.name)
                    ?.length > 9 && (
                      <div className="d-flex justify-content-center">
                        <Pagination
                          classes={{ ul: classes.ul }}
                          count={BlockchainCount}
                          size="large"
                          page={page}
                          color="primary"
                          onChange={handleBlockchainPageChange}
                        />
                      </div>
                    )}
                </div>
              </TabPanel>
            ))}

            {/* tab panel 003 */}

            {/* <TabPanel>
            <div className="row ">
              {dappsCourses?.length > 6
                ? DigitalPageData?.currentData()?.map((course1) => (
                    <div className="col-lg-4 col-md-6" key={course1?._id}>
                      <CourseSingleTwo
                        courseClass="courses-item mb-30"
                        courseImg={course1?.image}
                        courseId={course1?._id}
                        courseTitle={course1?.coursename}
                        coursePrice={course1?.amount}
                        courseCategory={course1?.categories}
                        courseDate={`${course1?.end?.slice(
                          8,
                          10
                        )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                          0,
                          4
                        )}`}
                      />
                    </div>
                  ))
                : dappsCourses?.map((course1) => (
                    <div className="col-lg-4 col-md-6" key={course1?._id}>
                      <CourseSingleTwo
                        courseClass="courses-item mb-30"
                        courseImg={course1?.image}
                        courseId={course1?._id}
                        courseTitle={course1?.coursename}
                        coursePrice={course1?.amount}
                        courseCategory={course1?.categories}
                        courseDate={`${course1?.end?.slice(
                          8,
                          10
                        )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                          0,
                          4
                        )}`}
                      />
                    </div>
                  ))}
              {dappsCourses?.length > 6 && (
                <div className="d-flex justify-content-center">
                  <Pagination
                    classes={{ ul: classes.ul }}
                    count={DigitalCount}
                    size="large"
                    page={page}
                    color="primary"
                    onChange={handleDigitalPageChange}
                  />
                </div>
              )}
            </div>
          </TabPanel> */}

            {/* tab panel 004 */}

            {/* <TabPanel>
            <div className="row ">
              {playCourses?.length > 6
                ? playPageData?.currentData()?.map((course1) => (
                    <div className="col-lg-4 col-md-6" key={course1?._id}>
                      <CourseSingleTwo
                        courseClass="courses-item mb-30"
                        courseImg={course1?.image}
                        courseId={course1?._id}
                        courseTitle={course1?.coursename}
                        coursePrice={course1?.amount}
                        courseCategory={course1?.categories}
                        courseDate={`${course1?.end?.slice(
                          8,
                          10
                        )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                          0,
                          4
                        )}`}
                      />
                    </div>
                  ))
                : playCourses?.map((course1) => (
                    <div className="col-lg-4 col-md-6" key={course1?._id}>
                      <CourseSingleTwo
                        courseClass="courses-item mb-30"
                        courseImg={course1?.image}
                        courseId={course1?._id}
                        courseTitle={course1?.coursename}
                        coursePrice={course1?.amount}
                        courseCategory={course1?.categories}
                        courseDate={`${course1?.end?.slice(
                          8,
                          10
                        )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                          0,
                          4
                        )}`}
                      />
                    </div>
                  ))}
              {playCourses?.length > 6 && (
                <div className="d-flex justify-content-center">
                  <Pagination
                    classes={{ ul: classes.ul }}
                    count={playCount}
                    size="large"
                    page={page}
                    color="primary"
                    onChange={handlePlayPageChange}
                  />
                </div>
              )}
            </div>
          </TabPanel> */}
          </Tabs>
        ) : (
          <div
            style={{ height: "30vh" }}
            className="d-flex align-items-center justify-content-center">
            <CircularProgress
              color="success"
              className="all-course-loader"
              style={{
                "& .MuiCircularProgress-svg": {
                  color: "red",
                },
              }}></CircularProgress>
          </div>
        )}
        {/* 
                <div>
                    <button>More to come</button>
                </div> */}
        {/* <div className="over-view-button-container d-flex justify-content-center">
                    <button>More to come</button>
                </div> */}
      </div>
    </div>
  );
};

export default AllCoursesSection;
