import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Unstable_Grid2";
import { Table } from "react-bootstrap";

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { UserContext } from "../../Context/UserContext";
import Pagination from "../../components/Pagination/Pagination";

const Forms = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [load, setLoad] = useState(false);
  const [categories, setCategories] = useState([]);
  const [enrolled, setEnrolled] = useState([]);
  const { data: user } = useContext(UserContext);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    const data = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/v1/forms`)
        .then((res) => {
          setCertificates(res.data.result);
        });
    };
    data();
  }, []);

  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [users, setUsers] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [sliceUsers, setSliceUsers] = useState([]);

  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(certificates?.length / show);
    setLastPage(lastPage);
  }, [certificates, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = certificates.slice(
        (page - 1) * show,
        page * show
      );
      setSliceUsers([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = certificates.slice(0, show);
      setSliceUsers([...getSlicingProduct]);
    }
  }, [certificates, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="forms"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Forms"
        pageName="Forms"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}
      <div className="container" style={{ padding: "25px 0 40px 0" }}>
        <div className="tableNormal">
          <Table responsive className="text-white adminDataTable">
            <thead>
              <tr>
                <th className="text-start text-dark">S/No</th>
                <th className="text-start text-dark">Name</th>
                <th className="text-start text-dark">URL</th>
                <th className="text-start text-dark">Description</th>
              </tr>
            </thead>
            <tbody>
              {sliceUsers?.map((data, index) => (
                <tr className="tableRow" key={data._id}>
                  <td align="text-star" className="text-start text-dark">
                    {index + 1}
                  </td>
                  <td align="text-start" className="text-start text-dark">
                    {data?.name}
                  </td>
                  <td className="text-start text-dark d-none d-md-block">
                    <a target="_blank" href={data?.url}>
                      {data?.url}
                    </a>
                  </td>
                  <td className="text-start text-dark d-block d-md-none">
                    <a target="_blank" href={data?.url}>
                      View
                    </a>
                  </td>
                  <td className="text-start text-dark">{data?.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>{" "}
        {sliceUsers?.length ? (
          <div>
            <Pagination
              lastPage={lastPage}
              page={pageNumber}
              pageHandle={pageHandle}
            />
          </div>
        ): (
          <p className="text-center">The forms will be ready soon for your convenience.</p>
        )}
      </div>
      {/* <button onClick={()=>navigate("/my-account",{state:{name:1}})}>button</button> */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default Forms;
