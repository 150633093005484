import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import axios from "axios";
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

import bannerbg from '../../assets/img/breadcrumbs/inner13.jpg';


const ContactMain = () => {
    const [countryList, setCountryList] = useState([]);
    const [countryCode, setCountryCode] = useState("");
    // const [locatedCountry, setLocatedCountry] = useState({});
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [sendEmailOTP, setSendEmailOTP] = useState(false);
    const [sendMobileOTP, setSendMobileOTP] = useState(false);
    const [emailCode, setEmailCode] = useState('');
    const [mobileCode, setMobileCode] = useState('');
    const [disableAfterActivation, setDisableAfterActivation] = useState(false);
    const [disableAfterActivationMobile, setDisableAfterActivationMobile] = useState(false);
    const [phoneOtp, setPhoneOtp] = useState();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

   // Run once on component mount

    useEffect(() => {
        axios.get("https://restcountries.com/v2/all?fields=alpha2Code,callingCodes")
            .then(res => {
                setCountryList(res.data)
            })
    }, []);

    useEffect(() => {
        axios.get("https://api.ipregistry.co/?key=jswae6n7t9pc4unc")
            .then(res => {
                // setLocatedCountry(res.data);
                setCountryCode(res.data.location.country.calling_code)
            })
    }, [])

    const sendEmailVerificationCode = () => {
        setDisableAfterActivation(true);
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email || email.length === 0) {
            // swal({
            //     title: "Attention",
            //     text: "Please enter your email",
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>Please enter your email</p>`;
            
            Swal.fire(
                {
                    html: wrapper,
                    icon: "warning",
                    customClass: "modal_class_success",
                }
        )
        }
        else {
            axios.post("https://backend.studyswiftly.com/api/contact/send-email-verification-code", { email })
                .then(res => {
                    if (res.status === 200) {
                        // swal({
                        //     title: "Success",
                        //     text: res.data.message,
                        //     icon: "success",
                        //     button: "OK!",
                        //     className: "modal_class_success",
                        // });

                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;
                        
                        Swal.fire(
                            {
                                html: wrapper,
                                icon: "success",
                                customClass: "modal_class_success",
                            }
                    )
                        setSendEmailOTP(true);
                        localStorage.setItem("contactToken", res.data.token);
                    }
                })
                .catch(error => {
                    setDisableAfterActivation(false);
                    // swal({
                    //     title: "Attention",
                    //     text: error.response.data.message,
                    //     icon: "warning",
                    //     button: "OK!",
                    //     className: "modal_class_success",
                    // });

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;
                    
                    Swal.fire(
                        {
                            html: wrapper,
                            icon: "success",
                            customClass: "modal_class_success",
                        }
                )
                });
        }
    }

    const sendMobileVerificationCode = () => {
        setDisableAfterActivationMobile(true);
        if (!mobile || mobile.length === 0) {
            // swal({
            //     title: "Attention",
            //     text: "Please enter your mobile number",
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>Please enter your mobile number</p>`;
            
            Swal.fire(
                {
                    html: wrapper,
                    icon: "warning",
                    customClass: "modal_class_success",
                }
        )
        }
        else {
            axios.post("https://backend.studyswiftly.com/api/contact/send-mobile-verification-code", {
                mobile: countryCode + mobile
            }, {
                headers: { "authorization": `Bearer ${localStorage.getItem("contactToken")}` }
            })
                .then(res => {
                    if (res.status === 200) {
                        // swal({
                        //     title: "Success",
                        //     text: res?.data?.message,
                        //     icon: "success",
                        //     button: "OK!",
                        //     className: "modal_class_success",
                        // });

                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = `<p class='text-break text-white'>${res?.data?.message}</p>`;
                        
                        Swal.fire(
                            {
                                html: wrapper,
                                icon: "success",
                                customClass: "modal_class_success",
                            }
                    )

              
                        setSendMobileOTP(true)
                        setPhoneOtp(res?.data?.Otp)
                        // sendMobileVerificationCode(true);
                    }
                })
                .catch(error => {
                    setDisableAfterActivationMobile(false);
                    // swal({
                    //     title: "Attention",
                    //     text: error.response?.data?.message,
                    //     icon: "warning",
                    //     button: "OK!",
                    //     className: "modal_class_success",
                    // });

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-white'>${error.response?.data?.message}</p>`;
                    
                    Swal.fire(
                        {
                            html: wrapper,
                            icon: "warning",
                            customClass: "modal_class_success",
                        }
                )
                });
        }
    }
    // console.log(countryCode + mobile)
    const handleEmailCodeChange = event => {
        const limit = 4;
        setEmailCode(event.target.value.slice(0, limit));
    };

    const handleMobileCodeChange = event => {
        const limit = 4;
        setMobileCode(event.target.value.slice(0, limit));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const name = e.target.name.value;
        const phone = countryCode + mobile;
        const emailVerificationCode = e.target.emailVerificationCode.value;
        // const phoneVerificationCode = e.target.phoneVerificationCode.value;
        const message = e.target.message.value;
        // console.log(phoneVerificationCode)
        console.log(phoneOtp)
        // if (phoneOtp != phoneVerificationCode) {
        //     // return swal({
        //     //     title: "Attention",
        //     //     text: "Mobile verification code is invalid",
        //     //     icon: "warning",
        //     //     button: "OK!",
        //     //     className: "modal_class_success",
        //     // })

        //     const wrapper = document.createElement("div");
        //     wrapper.innerHTML = `<p class='text-break text-white'>Mobile verification code is invalid</p>`;
            
        //     Swal.fire(
        //         {
        //             html: wrapper,
        //             icon: "warning",
        //             customClass: "modal_class_success",
        //         }
        // )
        // }
        axios.post("https://backend.studyswiftly.com/api/contact/save-full-data", {
            email, name, emailVerificationCode, phoneVerificationCode:1, phone, message
        }, {
            headers: { "authorization": `Bearer ${localStorage.getItem("contactToken")}` }
        })
            .then(res => {
                if (res.status === 200) {
                    // swal({
                    //     title: "Success",
                    //     text: res.data.message,
                    //     icon: "success",
                    //     button: "OK!",
                    //     className: "modal_class_success",
                    // });

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;
                    
                    Swal.fire(
                        {
                            html: wrapper,
                            icon: "success",
                            customClass: "modal_class_success",
                        }
                )

                    e.target.reset();
                    setEmailCode("");
                    setMobileCode("");
                    setMobile("");
                    setEmail("");
                }
            })
            .catch(error => {
                // swal({
                //     title: "Attention",
                //     text: error.response.data.message,
                //     icon: "warning",
                //     button: "OK!",
                //     className: "modal_class_success",
                // });

                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;
                
                Swal.fire(
                    {
                        html: wrapper,
                        icon: "warning",
                        customClass: "modal_class_success",
                    }
            )
            });
     

    }
    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Contact"
                pageName="Contact"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div>
          
            <div className="container p-0 p-md-5 pt-5 my-5">


                <div className="row mt-5">
                    <div className="col-12 mt-2 mt-md-5">
                        <form onSubmit={handleSubmit} className="contact-form p-4 p-md-5" style={{ background: "#f5f5f5" }}>
                           

                            <input type="text" className='form-control py-3 mb-3 px-md-4' name="name" placeholder="Name" reqiured />
                            <div className='d-flex mb-3'>
                                <input type="email" className='form-control px-md-4' name="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required style={{ borderRadius: "5px 0px 0px 5px" }} />

                                <button className='text-nowrap btn btn-dark' style={{ borderRadius: "0px 5px 5px 0px" }} type="button" onClick={() => sendEmailVerificationCode()} disabled={(email.length === 0 || disableAfterActivation) ? true : false}>Send OTP</button>
                            </div>

                            <input type="number" min={0} max={9999} className='form-control py-3 px-md-4 mb-3' name="emailVerificationCode" onChange={handleEmailCodeChange} value={emailCode} placeholder="Email Verification Code" required />

                            <div className='d-flex mb-3'>
                                <select className='form-control w-auto px-md-4' style={{ borderRadius: "5px 0px 0px 5px" }} name='countryCode' value={countryCode} onChange={e => setCountryCode(e.target.value)}>

                                    {
                                        countryList.map((country, index) => <option value={country.callingCodes} key={index}>{country.alpha2Code} (+{country.callingCodes})</option>)
                                    }
                                </select>

                                <input type="text" className='form-control py-3 px-md-4' style={{ borderRadius: "5px 0px 0px 5px" }} name="number" placeholder="Mobile No" value={mobile} onChange={e => setMobile(e.target.value)} required />

                                {/* <button className='text-nowrap btn btn-dark' style={{ borderRadius: "0px 5px 5px 0px" }} type="button" onClick={() => sendMobileVerificationCode()} disabled={(email.length === 0 || disableAfterActivationMobile) ? true : false}>Send OTP</button> */}
                            </div>

                            {/* <input type="number" min={0} max={9999} className='py-3 form-control mb-3 px-md-4' name="phoneVerificationCode" onChange={handleMobileCodeChange} value={mobileCode} placeholder="Mobile No Verification Code" required /> */}


                            <textarea name="message" placeholder='Message' className='form-control mb-3' rows={4} required></textarea>

                            {/* <button className='text-nowrap btn btn-dark' type="submit" disabled={(sendEmailOTP && sendMobileOTP) ? false : true}>Submit</button> */}
                            <button className='text-nowrap btn btn-dark' type="submit" disabled={(sendEmailOTP) ? false : true}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default ContactMain;