import React from "react";
import Modal from "react-bootstrap/Modal";
import "./TemplateModal.css";
import { Table } from "react-bootstrap";

const TemplateModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}
    >
      <Modal.Header closeButton className="modelAddAdmin template-modal-header">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-5 text-light"
        >
          COURSE TEMPLATE
        </Modal.Title>
      </Modal.Header>
      <p className="template-modal-lower-title">
        Please use the template to prepare before uploading a course
      </p>
      <Modal.Body className="handleModalBody">
        <Table className="text-white adminDataTable ">
          <thead>
            <tr>
              <th className="text-start">Title</th>
              <th className="text-start">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr className="tableRow">
              <td align="start">Title</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">Image</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">What you’ll learn</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">Course Details</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">Learning Outcomes</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">Images</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">Videos</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">PDFs</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">URLs</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">Special Instructions</td>
              <td className="text-start"></td>
            </tr>
            <tr className="tableRow">
              <td align="start">MCQs</td>
              <td className="text-start"></td>
            </tr>
          </tbody>
        </Table>

        <Modal.Footer className="mt-3 template-modal-footer modalFooter">
          <button type="button" className="adminBtnAdd" onClick={props.onHide}>
            OK
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default TemplateModal;
