import { ArrowBack } from '@mui/icons-material';
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, TextField } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from "@mui/material/Button";
import axios from 'axios';
import Swal from 'sweetalert2';

const AdminFormsEdit = () => {
    const navigate=useNavigate()
    const [data, setData] = useState({});
  const [load, setLoad] = useState(false);
  const {id}=useParams()

  useEffect(() => {
    axios
    .get(`https://backend.studyswiftly.com/api/v1/forms/${id}`)
    .then((res) => {
setData(res.data.result)
    })
  },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
          .put(`https://backend.studyswiftly.com/api/v1/forms/${id}`, data)
          .then((res) => {
            const wrapper = document.createElement("div");
            // ${res.data.message}
            wrapper.innerHTML = `<p class='text-break text-white'>Form Updated.</p>`;
    
            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });
          })
          .catch((e) => {
            console.log(e);
          });
      };
      
    return (
        <div className='adminBody'>
        <p className='cursor-pointer' style={{ cursor: "pointer" }} title='Back' onClick={() => navigate(-1)}><ArrowBack /></p>
        <h5 className='text-white text-start text-uppercase'>Edit Forms</h5>
            <h5 className='text-white text-start'></h5>
            <Button onClick={()=>navigate("/admin/forms-list")} variant="contained" className='my-4'>
             All List
            </Button>
            <div className='bg-white' style={{ padding: "25px 20px 40px 20px",borderRadius:"8px" }}>
                <form onSubmit={handleSubmit} style={{ padding: "20px 5px" }}>
                    <Box >
        <Grid container spacing={2} >
        <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                      variant="outlined"
                      focused
                    onChange={(e) =>
                      setData((data) => ({ ...data, name: e.target.value }))
                    }
                    value={data?.name}
                    label="Form Name"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    value={data?.url}
              focused
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        url: e.target.value,
                      }))
                    }
                    label="Form url"
                    name="name"
                  />
                </FormControl>
              </Grid>{" "}
              <Grid md={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    multiline
                    focused
                    rows={5}
                    onChange={(e) =>
                      setData((data) => ({ ...data, description: e.target.value }))
                    }
                    value={data?.description}
                    label="Form Description"
                    name="description"
                  />
                </FormControl>
                
              </Grid>
              
              <Grid md={12} xs={12}>
            <Button disabled={load} type="submit" variant="contained">
              {load && <CircularProgress></CircularProgress>} Submit
            </Button>
            </Grid>
                </Grid>
                </Box>
                </form>
                </div>
    </div>
    );
};

export default AdminFormsEdit;