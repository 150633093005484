import React from 'react';
import SubscribeForm from '../../Elements/Subscribe/SubscribeForm';

import newsletterIcon from '../../../assets/img/newsletter.png'
import SubscribeForms from '../../Elements/Subscribe/SubscribeForm';
import { useLocation } from 'react-router-dom';

const Newsletter = (props) => {
    const { sectionClass, wrapperClass, titleClass, iconPath } = props;
    const pathname = useLocation().pathname;

    return (
        <div style={{ background: (pathname === "/certificate") && "#151515" }}>
            <div className={sectionClass ? sectionClass : 'rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70'}>
                <div className="container">
                    <div className={wrapperClass ? wrapperClass : 'newsletter-wrap'}>
                        <div className="row y-middle">
                            <div className="col-lg-6 col-md-12 md-mb-30">
                                <div className="content-part">
                                    <div className="sec-title">
                                        <div className="title-icon md-mb-14">
                                            <img src={iconPath ? iconPath : newsletterIcon} alt="icon" />
                                        </div>
                                        <h2 className={titleClass ? titleClass : 'title mb-0 white-color'}>Subscribe to Newsletter</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="">
                                    <SubscribeForms />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;