import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CourseSingleTwo from "../../components/Courses/CourseSingleTwo";
import courseImg1 from "../../assets/img/courses/1.jpg";
import axios from "axios";
import { Autocomplete, Box, Button, TextField } from "@mui/material";

const CoursePart = (props) => {
  const [courses, setCourses] = useState([]);
  const [blockchainCourse, setBlockchainCourse] = useState([]);
  const [dappsCourses, setDappsCourses] = useState([]);
  const [playCourses, setPlayCourses] = useState([]);

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/active")
        // await axios.get("courses.json")
        .then((res) => {
          setCourses(res.data.result);
        });
    };
    data();
  }, []);

  useEffect(() => {
    const blockchainCourse = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/category/Blockchain")
        .then((res) => {
          setBlockchainCourse(res.data.result?.[0]);
        });
    };
    blockchainCourse();
  }, []);

  useEffect(() => {
    const data2 = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/course/category/Digital_asset"
        )
        .then((res) => {
          setDappsCourses(res.data.result?.[0]);
        });
    };
    data2();
  }, []);

  useEffect(() => {
    const data3 = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/course/category/Play_To_Earn"
        )
        .then((res) => {
          setPlayCourses(res.data.result?.[0]);
        });
    };
    data3();
  }, []);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(
            res.data.Categories?.sort((a, b) => a.name.localeCompare(b.name))
          );
        });
    };
    data();
  }, []);

  let tab1 = "All",
    tab2 = "Blockchain";
  const colors = [
    "#402E7A",
    "#365E32",
    "#5A72A0",
    "#615EFC",
    "#FF9A00",
    "#6F4E37",
  ];
  // const tabStyle = "gridFilter text-center mb-50  md-mb-30";
  const [categoryName, setCategoryName] = useState("");
  const [tab, setTab] = useState(true);
  // console.log(categoryName);
  return (
    <div
      id="rs-popular-course"
      className="rs-popular-courses style1 orange-style pt-100 pb-100 md-pt-70 md-pb-50"
    >
      <div className="container">
        <Autocomplete
          inputValue={categoryName}
          onInputChange={(event, newInputValue) => {
            setCategoryName(newInputValue);
          }}
          className="w-100"
          style={{ paddingBottom: "30px" }}
          options={categories}
          autoHighlight
          getOptionLabel={(option) => option.category_name}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option._id}>
              {option.category_name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              onClick={() => setTab(false)}
              className="bg-white rounded"
              {...params}
              label="Search course category"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <Tabs>
          <TabList className="gridFilter text-center mb-50  md-mb-30 row ">
            <Tab
              className="col-12"
              style={{ height: "55px" }}
              onClick={() => setTab(true)}
            >
              <Button
                className="text-capitalize fw-semibold w-100 h-100 mb-5"
                variant="contained"
              >
                {tab1}
              </Button>
            </Tab>
            {categories?.slice(0, 6)?.map((res, index) => (
              <Tab
                className="col-6 col-md-4 my-2"
                style={{ height: "55px" }}
                onClick={() => setTab(true)}
              >
                <Button
                  variant="contained"
                  className="text-capitalize fw-semibold h-100 w-100 text-start justify-content-start"
                  style={{ backgroundColor: colors[index] }}
                >
                  {res?.name}
                </Button>
              </Tab>
            ))}
          </TabList>

          {tab === true && (
            <>
              <TabPanel>
                <div>
                  <h3 className="text-center fw-bold">All</h3>
                </div>
                <div className="row ">
                  {courses?.slice(0, 3)?.map((course) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={course?._id}>
                      <CourseSingleTwo
                        courseClass="courses-item mb-30"
                        courseId={course?._id}
                        courseImg={course?.image?.replace(
                          "https://backend.blockchaincert.sg/",
                          "https://backend.studyswiftly.com/"
                        )}
                        courseTitle={course?.coursename}
                        coursePrice={course?.amount}
                        courseCategory={course?.categories}
                        courseDate={`${course?.end?.slice(
                          8,
                          10
                        )}/${course?.end?.slice(5, 7)}/${course?.end?.slice(
                          0,
                          4
                        )}`}
                      />
                    </div>
                  ))}
                </div>
                <div className="over-view-button-container d-flex justify-content-end">
                  <Link to="/allCourses">
                    <button>See All</button>
                  </Link>
                </div>
              </TabPanel>

              {categories?.slice(0, 6).map((res, ind) => (
                <TabPanel>
                  <div>
                    <h3 className="text-center fw-bold">{res?.name}</h3>
                  </div>
                  <div className="row ">
                    {courses
                      ?.filter((result) => result?.categories == res?.name)
                      ?.sort((a, b) => a?.sort - b?.sort)
                      ?.slice(0, 3)
                      ?.map((course1, index) => (
                        <div className="col-lg-4 col-md-6" key={course1?._id}>
                          <CourseSingleTwo
                            courseClass="courses-item mb-30"
                            courseImg={course1?.image?.replace(
                              "https://backend.blockchaincert.sg/",
                              "https://backend.studyswiftly.com/"
                            )}
                            courseId={course1?._id}
                            courseTitle={course1?.coursename}
                            coursePrice={course1?.amount}
                            courseCategory={course1?.categories}
                            courseDate={`${course1?.end?.slice(
                              8,
                              10
                            )}/${course1?.end?.slice(
                              5,
                              7
                            )}/${course1?.end?.slice(0, 4)}`}
                          />
                        </div>
                      ))}
                  </div>
                  {courses?.filter((result) => result?.categories == res?.name)
                    ?.length > 3 && (
                    <div className="over-view-button-container d-flex justify-content-end">
                      <Link
                        to={`/allCourses?course=${res?.name}&indexs=${ind}`}
                      >
                        <button>See All</button>
                      </Link>
                    </div>
                  )}
                </TabPanel>
              ))}

              {/* {categories?.map((res, ind) => (
            <TabPanel>
              <div>
                <h3 className="text-center fw-bold">{categoryName}</h3>
              </div>
              <div className="row">
                {courses
                  ?.filter((result) => result?.categories == res?.name)
                  ?.sort((a, b) => a?.sort - b?.sort)
                  ?.slice(0, 3)
                  ?.map((course1, index) => (
                    <div className="col-lg-4 col-md-6" key={course1?._id}>
                      <CourseSingleTwo
                        courseClass="courses-item mb-30"
                        courseImg={course1?.image?.replace(
                          "https://backend.blockchaincert.sg/",
                          "https://backend.studyswiftly.com/"
                        )}
                        courseId={course1?._id}
                        courseTitle={course1?.coursename}
                        coursePrice={course1?.amount}
                        courseCategory={course1?.categories}
                        courseDate={`${course1?.end?.slice(
                          8,
                          10
                        )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                          0,
                          4
                        )}`}
                      />
                    </div>
                  ))}
              </div>
              {courses?.filter((result) => result?.categories == res?.name)
                ?.length > 3 && (
                <div className="over-view-button-container d-flex justify-content-end">
                  <Link to={`/allCourses?course=${res?.name}&indexs=${ind}`}>
                    <button>See All</button>
                  </Link>
                </div>
              )}
            </TabPanel>
          ))} */}
            </>
          )}

          {/* {
            categoryName == "" && (
              <div>

                <div>
              <h3 className="text-center fw-bold">All</h3>
            </div>
            <div className="row ">
              {courses?.slice(0, 3)?.map((course) => (
                <div className="col-lg-4 col-md-6 mb-4" key={course?._id}>
                  <CourseSingleTwo
                    courseClass="courses-item mb-30"
                    courseId={course?._id}
                    courseImg={course?.image?.replace(
                      "https://backend.blockchaincert.sg/",
                      "https://backend.studyswiftly.com/"
                    )}
                    courseTitle={course?.coursename}
                    coursePrice={course?.amount}
                    courseCategory={course?.categories}
                    courseDate={`${course?.end?.slice(
                      8,
                      10
                    )}/${course?.end?.slice(5, 7)}/${course?.end?.slice(0, 4)}`}
                  />
                </div>
              ))}
            </div>
            <div className="over-view-button-container d-flex justify-content-end">
              <Link to="/allCourses">
                <button>See All</button>
              </Link>
            </div>
              </div>
            )
          } */}

          {/* <TabPanel>
            <div className="row ">
              {dappsCourses?.map((course1) => (
                <div className="col-lg-4 col-md-6" key={course1?._id}>
                  <CourseSingleTwo
                    courseClass="courses-item mb-30"
                    courseImg={course1?.image}
                    courseTitle={course1?.coursename}
                    coursePrice={course1?.amount}
                    courseCategory={course1?.categories}
                    courseDate={`${course1?.end?.slice(
                      8,
                      10
                    )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                      0,
                      4
                    )}`}
                  />
                </div>
              ))}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="row ">
              {playCourses?.map((course1) => (
                <div className="col-lg-4 col-md-6" key={course1?._id}>
                  <CourseSingleTwo
                    courseClass="courses-item mb-30"
                    courseImg={course1?.image}
                    courseTitle={course1?.coursename}
                    coursePrice={course1?.amount}
                    courseCategory={course1?.categories}
                    courseDate={`${course1?.end?.slice(
                      8,
                      10
                    )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                      0,
                      4
                    )}`}
                  />
                </div>
              ))}
            </div>
          </TabPanel> */}
        </Tabs>

        {tab === false && (
          <div>
            <div>
              <h3 className="text-center fw-bold">{categoryName}</h3>
            </div>
            <div className="row">
              {courses
                ?.filter((result) => result?.categories == { categoryName })
                ?.sort((a, b) => a?.sort - b?.sort)
                ?.slice(0, 3)
                ?.map((course1, index) => (
                  <div className="col-lg-4 col-md-6" key={course1?._id}>
                    <CourseSingleTwo
                      courseClass="courses-item mb-30"
                      courseImg={course1?.image?.replace(
                        "https://backend.blockchaincert.sg/",
                        "https://backend.studyswiftly.com/"
                      )}
                      courseId={course1?._id}
                      courseTitle={course1?.coursename}
                      coursePrice={course1?.amount}
                      courseCategory={course1?.categories}
                      courseDate={`${course1?.end?.slice(
                        8,
                        10
                      )}/${course1?.end?.slice(5, 7)}/${course1?.end?.slice(
                        0,
                        4
                      )}`}
                    />
                  </div>
                ))}
            </div>
            {courses?.filter((result) => result?.categories == { categoryName })
              ?.length > 3 && (
              <div className="over-view-button-container d-flex justify-content-end">
                {/* <Link to={`/allCourses?course=${res?.name}&indexs=${ind}`}>
                    <button>See All</button>
                  </Link> */}
              </div>
            )}
          </div>
        )}

        {/* 
                <div>
                    <button>More to come</button>
                </div> */}

        <div className="over-view-button-container d-flex justify-content-center mt-">
          <Link to="/allCourses">
            <button className="btn btn-info" style={{ background: "#25cff2" }}>
              SEE ALL FROM ALL CATEGORIES
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CoursePart;
