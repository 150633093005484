export const title_data = [
    "Start studying early to avoid last-minute stress.",
    "Prioritize understanding over memorizing.",
    "Take regular breaks to stay focused.",
    "Organize your study space to minimize distractions.",
    "Set specific, achievable goals for each study session.",
    "Practice active listening in class.",
    "Use a planner to keep track of assignments and deadlines.",
    "Seek help when you don’t understand something.",
    "Form study groups to enhance learning.",
    "Stay curious and ask questions.",
    "Review notes daily to reinforce learning.",
    "Practice past exams to prepare effectively.",
    "Stay hydrated and eat healthy snacks.",
    "Sleep well to improve concentration.",
    "Limit social media usage during study time.",
    "Reward yourself for meeting study goals.",
    "Use mnemonic devices to aid memory.",
    "Break large tasks into smaller, manageable parts.",
    "Stay positive and motivated.",
    "Use online resources for additional learning.",
    "Don’t be afraid to make mistakes; they’re part of learning.",
    "Discuss difficult topics with peers or teachers.",
    "Set up a regular study schedule.",
    "Stay organized with your notes and materials.",
    "Balance study time with hobbies and relaxation.",
    "Practice mindfulness to reduce stress.",
    "Always have a backup of important assignments.",
    "Participate actively in class discussions.",
    "Use color-coded notes for better retention.",
    "Stay engaged by connecting subjects to real-life situations.",
    "Avoid cramming the night before exams.",
    "Use flashcards for quick revision.",
    "Keep a growth mindset; effort leads to improvement.",
    "Take time to reflect on what you've learned.",
    "Study in a quiet and comfortable environment.",
    "Use apps to help with time management.",
    "Find a study buddy to keep each other accountable.",
    "Take care of your mental health.",
    "Read widely beyond your syllabus.",
    "Use a variety of study materials to keep things interesting.",
    "Stay updated with current events related to your subjects.",
    "Use diagrams and charts to visualize information.",
    "Practice teaching what you’ve learned to someone else.",
    "Stay away from distractions during study time.",
    "Use positive affirmations to boost confidence.",
    "Join academic clubs or societies.",
    "Keep a study journal to track progress.",
    "Prepare questions before going to class.",
    "Review feedback from assignments to improve.",
    "Allocate more time to challenging subjects.",
    "Stay calm and focused during exams.",
    "Use online forums to discuss difficult topics.",
    "Study at your most productive time of day.",
    "Keep your study space tidy and organized.",
    "Use online courses to supplement learning.",
    "Stay consistent with your study routine.",
    "Avoid multitasking to improve focus.",
    "Stay informed about your academic requirements.",
    "Develop critical thinking skills.",
    "Maintain a balanced lifestyle.",
    "Practice good posture while studying.",
    "Engage in physical activity to boost brain function.",
    "Stay curious and explore different perspectives.",
    "Use breaks to recharge, not procrastinate.",
    "Prepare summaries of each chapter or topic.",
    "Study in a well-lit environment.",
    "Test yourself regularly to gauge understanding.",
    "Practice gratitude to stay positive.",
    "Learn from your peers’ strengths.",
    "Set long-term academic goals.",
    "Keep your electronic devices away while studying.",
    "Utilize campus resources like libraries and tutoring centers.",
    "Stay adaptable to different learning methods.",
    "Take notes during lectures.",
    "Use audiobooks and podcasts for learning on the go.",
    "Keep track of your grades and performance.",
    "Study a little every day rather than all at once.",
    "Use mind maps to organize thoughts and ideas.",
    "Seek internships or practical experiences.",
    "Stay disciplined with your study schedule.",
    "Engage in discussions to deepen understanding.",
    "Reflect on your learning style and adapt.",
    "Stay patient and persistent.",
    "Use the Pomodoro technique for effective studying.",
    "Get involved in extracurricular activities.",
    "Keep a positive attitude towards challenging subjects.",
    "Avoid overloading yourself with commitments.",
    "Celebrate small victories in your academic journey.",
    "Ask for feedback to improve.",
    "Stay engaged during online classes.",
    "Take care of your physical health.",
    "Use study apps to enhance productivity.",
    "Form connections with your professors.",
    "Stay open to new ideas and approaches.",
    "Practice ethical academic behavior.",
    "Keep your study goals realistic and attainable.",
    "Use study guides and textbooks effectively.",
    "Maintain a steady and calm study pace.",
    "Stay inspired by setting personal challenges.",
    "Remember that learning is a lifelong process."
]
