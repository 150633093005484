import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import CourseSidebar from "../CourseSidebarSection";
import "./Course.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const OverviewPart = () => {
  const { id } = useParams();
  const [singleCourse, setSingleCourse] = useState([]);
  const [singleCourseLoader, setSingleCourseLoader] = useState(false);

  useEffect(() => {
    const data1 = async () => {
      setSingleCourseLoader(true);
      await axios
        .get(`https://backend.studyswiftly.com/api/course/${id}`)
        .then((res) => {
          setSingleCourse(res.data.result);
          console.log(res.data.result);
          setSingleCourseLoader(false);
        });
    };
    data1();
  }, [id]);

  console.log(singleCourse);

  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          {singleCourseLoader ? (
            <div className="mt-5 text-center">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <div>
              <div className="mb-4 videoDiv">
                <img
                  className="detailsVideo"
                  src={singleCourse.image?.replace(
                    "https://backend.blockchaincert.sg/",
                    "https://backend.studyswiftly.com/"
                  )}
                  alt="Video Image"
                />
              </div>
              <h3 className="" style={{ color: "#ff5421" }}>
                {singleCourse.coursename}
              </h3>
              {/* <h4>Course Details</h4> */}
              <h4>Course One-liner</h4>

              <div
                className="pb-1 "
                dangerouslySetInnerHTML={{ __html: singleCourse.description }}
              ></div>

              {/* <h3>What you’ll learn?</h3> */}
              {singleCourse?.learnPdf?.length > 0 && (
                <>
                  {singleCourse?.learnPdf?.map((pdf) => (
                    <embed
                      // src={URL.createObjectURL(pdf)}
                      src={pdf}
                      type="application/pdf"
                      frameBorder="0"
                      className="overview-pdf"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                    ></embed>
                  ))}
                </>
              )}
              {/* <div
                className="pb-1 "
                dangerouslySetInnerHTML={{ __html: singleCourse.youlearn }}
              ></div> */}

              <h3>Course Overview</h3>

              <div
                className="pb-1 "
                dangerouslySetInnerHTML={{ __html: singleCourse.howlearn }}
              ></div>

              <h3>How you can get the certificate?</h3>

              {/* <div
                className="pb-1 "
                // dangerouslySetInnerHTML={{
                //   __html: singleCourse.howGetCertificate,
                // }}
                dangerouslySetInnerHTML={{
                  __html:
                    "Our AI evaluates a student before awarding certification through online quizzes. These quizzes are designed to test the student's knowledge, critical thinking skills, problem-solving abilities, and understanding of the subject matter. Our AI algorithms analyzes the student's performance based on predefined criteria and provides feedback or determine whether the student meets the requirements for certification.",
                }}
              ></div> */}
              <div className="pb-1 ">
                <p>
                  Our AI evaluates a student before awarding certification
                  through online quizzes. These quizzes are designed to test the
                  student's knowledge, critical thinking skills, problem-solving
                  abilities, and understanding of the subject matter.
                </p>{" "}
                <p>
                  Our AI algorithms analyzes the student's performance based on
                  predefined criteria and provides feedback or determine whether
                  the student meets the requirements for certification.
                </p>
              </div>

              {/* <h3>Learning Materials</h3> */}

              {/* <div className="pb-1 ">
                {singleCourse?.imageData?.length > 0 && (
                  <p>
                    {singleCourse?.imageData?.length} Images
                  </p>
                )}
                {singleCourse?.videoData?.length > 0 && (
                  <p>
                    {singleCourse?.videoData?.length} Video
                  </p>
                )}
                {singleCourse?.pdfData?.length > 0 && (
                  <p>
                    {singleCourse?.pdfData?.length} PDF files
                  </p>
                )}
              </div> */}
            </div>
          )}

          {/* <div className="course-features-info">
                        <ul>
                            <li className="lectures-feature">
                               <h4  style={{fontSize: "18px"}}> Course Duration: {singleCourse.start}</h4> 
                            </li>

                            <li className="duration-feature">
                            <h4  style={{fontSize: "18px"}}> Course Price: {singleCourse.amount}</h4> 
                            </li>

                        </ul>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default OverviewPart;
