import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//Custom elements

import Home from "../pages/home";
import HomeTwo from "../pages/home-2";
import HomeThree from "../pages/home-3";
import HomeFour from "../pages/home-4";

import About from "../pages/about";
import AboutTwo from "../pages/about-2";
import CourseOne from "../pages/course";
import CourseTwo from "../pages/course-2";
import CourseThree from "../pages/course-3";
import CourseFour from "../pages/course-4";
import CourseFive from "../pages/course-5";
import CourseSix from "../pages/course-6";
import CourseSingle from "../pages/course/course-single";
import CourseCategoryPage from "../pages/course-categories";
import Team from "../pages/team";
import TeamTwo from "../pages/team-2";
import TeamSingle from "../pages/team/team-single";
import Event from "../pages/event";
import EventTwo from "../pages/event-2";
import EventThree from "../pages/event-3";
import Gallery from "../pages/gallery";
import GalleryTwo from "../pages/gallery-2";
import GalleryThree from "../pages/gallery-3";
import Shop from "../pages/shop";
import ShopSingle from "../pages/shop/shop-single";
import Cart from "../pages/shop/cart";
import Checkout from "../pages/shop/checkout";
import MyAccount from "../pages/shop/my-account";
import Faq from "../pages/faq";
import Register from "../pages/register";
import Blog from "../pages/blog";
import BlogLeft from "../pages/blog/BlogLeft";
import BlogRight from "../pages/blog/BlogRight";
import SinglePostLeftSidebar from "../pages/blog/single-post-left-sidebar";
import SinglePostRightSidebar from "../pages/blog/single-post-right-sidebar";
import SinglePostFullWidth from "../pages/blog/single-post-full-width";
import Contact from "../pages/contact";

import Error from "../pages/404";
// import LoadTop from '../Components/Common/ScrollTop/LoadTop'
import News from "../pages/News/News";
import LoginUser from "../pages/login";
import Forgetpassword from "../pages/login/Forgetpassword";
import Otp from "../pages/login/Otp";
import ResetPassword from "../pages/login/ResetPassword";
import AdminRoutes from "../components/AdminRoutes/AdminRoutes";
import Dashboard from "../pages/Dashboard/Dashboard";
import AdminDashboard from "../pages/Dashboard/AdminDashboard/AdminDashboard";
import DashboardAdminEditProfile from "../pages/Dashboard/Admins/DashboardAdminEditProfile";
import Login from "../pages/login/Login";
import Admins from "../pages/Dashboard/Admins/Admins";
import Courses from "../pages/Dashboard/Courses/Courses";
import Users from "../pages/Dashboard/Users/Users";
import AddCourse from "../pages/Dashboard/Courses/AddCourse";
import Subscribers from "../pages/Dashboard/Subscribers/Subscribers";
import SoldCourses from "../pages/Dashboard/SoldCourses/SoldCourses";
import EditCourse from "../pages/Dashboard/Courses/EditCourse";
import "./App.css";
import Affiliate from "../pages/Dashboard/Affiliate/Affiliate";
import Affiliat from "../pages/shop/Affiliat";
import BuyCourse from "../pages/course/course-single/BuyCourse";
import CertificateMain from "../pages/shop/CertificateMain";
import Certificates from "../pages/Dashboard/Certificates/Certificates";
import AddCertificate from "../pages/Dashboard/Certificates/AddCertificate";
import LoginForgetPassword from "../pages/LoginForgetPassword";
import LoginResetPassword from "../pages/LoginResetPassword";
import EditCertificate from "../pages/Dashboard/Certificates/EditCertificate";
import JoinUs from "../pages/join-us/JoinUs";
import Registered from "../pages/Dashboard/Registered/Registered";
import EnrolledCourseDetails from "../components/Shop/EnrolledCourseDetails";
import AddCourseToRegistered from "../pages/Dashboard/Users/AddCourse/AddCourse";
import AddedCourse from "../pages/Dashboard/Users/AddCourse/AddedCourse";
import EditContent from "../pages/Dashboard/Users/AddCourse/EditContent";
import AllCertificates from "../pages/Dashboard/Certificates/AllCertificates";
import Payments from "../pages/Dashboard/Payments/Payments";
import AllCourseMain from "../pages/AllCourses/AllCourseMain";
import AllCourses from "../pages/AllCourses";
import { Pagination } from "react-bootstrap";
import Transactions from "../pages/Dashboard/Transactions/Transactions";
import Register2 from "../pages/register2";
import Login2 from "../pages/login2";
import PaymentSuccessful from "../pages/Dashboard/Payments/PaymentSuccessful";
import PaymentFailed from "../pages/Dashboard/Payments/PaymentFailed";
import PaymentStripe from "../pages/Dashboard/Payments/PaymentStripe";
import AddCategory from "../pages/Dashboard/Courses/AddCategory";
import AdminContactForm from "../pages/Dashboard/Courses/AdminContactForm";
import MCQ from "../pages/mcq/MCQ";
import Scholarship from "../pages/Scholarship/Scholarship";
import ScholarshipAdmin from "../pages/Dashboard/ScholarshipAdmin/ScholarshipAdmin";
import Forms from "../pages/Forms/Forms";
import AwardedCertificate from "../pages/Dashboard/Certificates/AwardedCertificate";
import RejectedCertificate from "../pages/Dashboard/Certificates/RejectedCertificate";
import Diploma from "../pages/Dashboard/Diploma/RequestedDiplomas";
import Degree from "../pages/Dashboard/Degree/Degree";
import AdminForms from "../pages/Dashboard/Forms/AdminForms";
import AdminFormsList from "../pages/Dashboard/Forms/AdminFormsList";
import AdminFormsEdit from "../pages/Dashboard/Forms/AdminFormsEdit";
import CourseCreatorAI from "../pages/Dashboard/CourseCreatorAI/CourseCreatorAI";
import RequestedDiplomas from "../pages/Dashboard/Diploma/RequestedDiplomas";
import AwardedDiplomas from "../pages/Dashboard/Diploma/AwardedDiplomas";
import RejectedDiplomas from "../pages/Dashboard/Diploma/RejectedDiplomas";
import RequestedDegrees from "../pages/Dashboard/Degree/RequestedDegrees";
import AwardedDegrees from "../pages/Dashboard/Degree/AwardedDegrees";
import RejectedDegrees from "../pages/Dashboard/Degree/RejectedDegrees";
const App = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);

  return (
    <div className="App">
      {/* <LoadTop /> */}

      

      <Routes>
        <Route path="/" exact element={<Home></Home>} />
        <Route path="/:affiliate" exact element={<Home></Home>} />
        <Route path="/home" element={<Home></Home>} />
        <Route path="/home-2" element={<HomeTwo></HomeTwo>} />
        <Route path="/home-3" element={<HomeThree></HomeThree>} />
        <Route path="/home-4" element={<HomeFour></HomeFour>} />

        <Route path="/scholarship" element={<Scholarship />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/about" element={<About />} />
        <Route path="/join-us" element={<JoinUs />} />
        <Route path="/about-2" element={<AboutTwo />} />
        <Route path="/course" exact element={<CourseOne />} />
        <Route path="/courses" element={<CourseTwo />} />
        <Route path="/allCourses" element={<AllCourses />} />
        <Route path="/course-3" element={<CourseThree />} />
        <Route path="/course-4" element={<CourseFour />} />
        <Route path="/course-5" element={<CourseFive />} />
        <Route path="/course-6" element={<CourseSix />} />
        <Route path="/courses/:id/:name" element={<CourseSingle />} />
        {/* <Route path="/course/:id" element={<CourseSingle />} /> */}
        <Route path="/enrol/:id" element={<BuyCourse />} />
        <Route path="/test/:id/:enrolId" element={<MCQ />} />
        <Route path="/ourdapps" exact element={<Team />} />
        <Route path="/team-2" element={<TeamTwo />} />
        <Route path="/team/team-single" element={<TeamSingle />} />
        <Route path="/event" element={<Event />} />
        <Route path="/event-2" element={<EventTwo />} />
        <Route path="/event-3" element={<EventThree />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery-2" element={<GalleryTwo />} />
        <Route path="/gallery-3" element={<GalleryThree />} />
        <Route path="/shop" exact element={<Shop />} />
        <Route path="/shop/shop-single" element={<ShopSingle />} />
        <Route path="/shop/cart" element={<Cart />} />
        <Route path="/shop/checkout" element={<Checkout />} />
        <Route path="/shop/paymentsuccessful" element={<PaymentSuccessful />} />
        <Route path="/shop/paymentfailed" element={<PaymentFailed />} />
        <Route path="/shop/payment" element={<PaymentStripe />} />
        <Route
          path="/my-account"
          element={<MyAccount expiryTimestamp={time} />}
        />
        <Route
          path="/certificate/:coursename/:from/:to/:serial/:certificateno"
          element={<CertificateMain />}
        />
        <Route path="/affiliate" element={<Affiliat />} />
        <Route path="/enrollDetails/:id" element={<EnrolledCourseDetails />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/login2" element={<LoginUser />} />
        <Route path="/sign-in" element={<Login2 />} />
        <Route path="/register2" element={<Register />} />
        <Route path="/sign-up" element={<Register2 />} />
        <Route path="/forgotpassword" element={<LoginForgetPassword />} />
        <Route path="/reset-password/:token" element={<LoginResetPassword />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/blog/blog-left-sidebar" element={<BlogLeft />} />
        <Route path="/blog/blog-right-sidebar" element={<BlogRight />} />
        <Route
          path="/blog/single-post-left-sidebar"
          element={<SinglePostLeftSidebar />}
        />
        <Route
          path="/blog/single-post-right-sidebar"
          element={<SinglePostRightSidebar />}
        />
        <Route
          path="/blog/single-post-full-width"
          element={<SinglePostFullWidth />}
        />
        <Route path="/news" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<Error />} />

        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgetpassword" element={<Forgetpassword />} />

        <Route
          path="/admin/otp/:token/:email"
          element={<Otp expiryTimestamp={time} />}
        />
        <Route
          path="/admin/reset-password/:token"
          element={<ResetPassword />}
        />

        <Route
          path="/admin"
          element={
            // <AdminRoutes>
            <Dashboard />
            //  </AdminRoutes>
          }
        >
          <Route index element={<AdminRoutes>
            <AdminDashboard />
          </AdminRoutes>} />
          <Route path="dashboard" element={<AdminRoutes><AdminDashboard /></AdminRoutes>} />
          <Route path="/admin/admins" element={<AdminRoutes><Admins /></AdminRoutes>} />
          <Route
            path="/admin/adminprofile/:id"
            element={<DashboardAdminEditProfile />}
          />
          <Route path="/admin/courses" element={<AdminRoutes><Courses /></AdminRoutes>} />
          <Route path="/admin/scholarships" element={<AdminRoutes><ScholarshipAdmin /></AdminRoutes>} />
          <Route path="/admin/students" element={<AdminRoutes><Users /></AdminRoutes>} />
          <Route path="/admin/course-enrollments" element={<AdminRoutes><Registered /></AdminRoutes>} />
          <Route path="/admin/addCourses" element={<AdminRoutes><AddedCourse /></AdminRoutes>} />
          <Route path="/admin/categories" element={<AdminRoutes><AddCategory /></AdminRoutes>} />
          <Route path="/admin/enquiries" element={<AdminRoutes><AdminContactForm /></AdminRoutes>} />
          <Route path="/admin/affiliate" element={<AdminRoutes><Affiliate /></AdminRoutes>} />
          <Route path="/admin/forms" element={<AdminRoutes><AdminForms /></AdminRoutes>} />
          <Route path="/admin/forms-list" element={<AdminRoutes><AdminFormsList /></AdminRoutes>} />
          <Route path="/admin/forms/:id" element={<AdminRoutes><AdminFormsEdit /></AdminRoutes>} />
          <Route path="/admin/transactions" element={<AdminRoutes><Transactions /></AdminRoutes>} />
          <Route path="/admin/allcertificates" element={<AdminRoutes><AllCertificates /></AdminRoutes>} />
          <Route path="/admin/diploma" element={<AdminRoutes><Diploma /></AdminRoutes>} />
          <Route path="/admin/degree" element={<AdminRoutes><Degree /></AdminRoutes>} />
          <Route path="/admin/payments-received" element={<AdminRoutes><Payments /></AdminRoutes>} />
          <Route path="/admin/course-creator-ai" element={<AdminRoutes><CourseCreatorAI /></AdminRoutes>} />

          {/* Certificates ********************* */}
          <Route path="/admin/certificates-requested" element={<AdminRoutes><Certificates /></AdminRoutes>} />
          <Route path="/admin/certificates-awarded" element={<AdminRoutes><AwardedCertificate /></AdminRoutes>} />
          <Route path="/admin/certificates-rejected" element={<AdminRoutes><RejectedCertificate /></AdminRoutes>} />
          <Route
            path="/admin/add-certificate/:id"
            element={<AdminRoutes><AddCertificate /></AdminRoutes>}
          />
          <Route
            path="/admin/edit-certificate/:id"
            element={<AdminRoutes><EditCertificate /></AdminRoutes>}
          />

           {/* Diplomas ********************* */}
           <Route path="/admin/diplomas-requested" element={<AdminRoutes><RequestedDiplomas /></AdminRoutes>} />
          <Route path="/admin/diplomas-awarded" element={<AdminRoutes><AwardedDiplomas /></AdminRoutes>} />
          <Route path="/admin/diplomas-rejected" element={<AdminRoutes><RejectedDiplomas /></AdminRoutes>} />
           
           {/* Diplomas ********************* */}
           <Route path="/admin/degrees-requested" element={<AdminRoutes><RequestedDegrees /></AdminRoutes>} />
          <Route path="/admin/degrees-awarded" element={<AdminRoutes><AwardedDegrees /></AdminRoutes>} />
          <Route path="/admin/degrees-rejected" element={<AdminRoutes><RejectedDegrees /></AdminRoutes>} />

          <Route path="/admin/addcourse" element={<AdminRoutes><AddCourse /></AdminRoutes>} />
          <Route path="/admin/editcourse/:id" element={<AdminRoutes><EditCourse /></AdminRoutes>} />
          <Route path="/admin/editcontent/:id" element={<AdminRoutes><EditContent /></AdminRoutes>} />
          <Route
            path="/admin/addcourse/:id"
            element={<AdminRoutes><AddCourseToRegistered /></AdminRoutes>}
          />
          <Route path="/admin/email-subscribers" element={<AdminRoutes><Subscribers /></AdminRoutes>} />
          <Route path="/admin/soldcourses" element={<AdminRoutes><SoldCourses /></AdminRoutes>} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
