import { getAuth } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import initializeFirebase from "../Firebase/Firebase.init";
import axios from "axios";
import Swal from "sweetalert2";
import { useContext } from "react";
import { UserContext } from "../UserContext";

initializeFirebase();

const useFirebase = () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const { refetch, setRefetch, setError } = useContext(UserContext);
  provider.setCustomParameters({ prompt: 'select_account' });

  const signUpGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log("google sign in");
        axios
          .post(
            "https://backend.studyswiftly.com/api/v1/user/signup/google",
            {
              name: user?.displayName,
              email: user?.email,
              image: user?.photoURL,
            },
            {}
          )
          .then((res) => {
            if (res.status === 200) {
              setRefetch(!refetch);
              localStorage.setItem("tokenBlockchian", res.data.token);
              console.log(res.data.token);
              // swal({
              //     title: "Success",
              //     text: `${res.data.message}`,
              //     icon: "success",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });
            } else if (res.status === 400) {
              // swal({
              //     title: "Attention",
              //     text: `${res.data.message}`,
              //     icon: "warning",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "warning",
                customClass: "modal_class_success",
              });
            }
          })
          .catch((error) => {
            // swal({
            //     title: "Attention",
            //     text: `${error.response.data.message}`,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "warning",
              customClass: "modal_class_success",
            });
          });
        // const destination = location?.state?.from || "/";
        // history.replace(destination);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };
  const signInGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        axios
          .post(
            "https://backend.studyswiftly.com/api/v1/user/signin/google",
            {
              email: user?.email,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log("google google");
              setRefetch(!refetch);
              localStorage.setItem("tokenBlockchian", res.data.token);
              console.log(res.data.token);
              // swal({
              //     title: "Success",
              //     text: `${res.data.message}`,
              //     icon: "success",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });
            } else if (res.status === 400) {
              // swal({
              //     title: "Attention",
              //     text: `${res.data.message}`,
              //     icon: "warning",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "warning",
                customClass: "modal_class_success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            // swal({
            //     title: "Attention",
            //     text: `${error.response.data.message}`,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "warning",
              customClass: "modal_class_success",
            });
          });
        // const destination = location?.state?.from || "/";
        // history.replace(destination);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return {
    signInGoogle,
    signUpGoogle,
  };
};
export default useFirebase;
