import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const ScholarshipAdminViewModal = (props) => {
  const { modalData } = props;
  // console.log('dataaaaaaaaaaaaaa', modalData);
  const [data, setData] = useState({});
  useEffect(() => {
    setData(modalData);
  }, [modalData]);

  const editStudentInfo = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="scholarship-modal"
      style={{ zIndex: 9999 }}>
      <Modal.Header closeButton className="modalHeader">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-4 text-light">
          <span className="text-white">Student Details</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: "rgb(177, 185, 195)" }}
        className="handleModalBody">
        <div
          className="container"
          style={{
            padding: "25px 0 40px 0",
            background: "white",
            borderRadius: "8px",
          }}>
          {" "}
          <form>
            {/* <Card> */}
            <Box sx={{ padding: "20px 5px" }}>
              <Grid container spacing={2}>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">Personal Information</h5>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      disabled
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          fullName: e.target.value,
                        }))
                      }
                      value={data?.fullName}
                      label="Full Name"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      type="date"
                      disabled
                      value={data?.dateOfBirth}
                      focused
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          dateOfBirth: e.target.value,
                        }))
                      }
                      label="Date of Birth"
                      name="name"
                    />
                  </FormControl>
                </Grid>{" "}
                <Grid lg={12} md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      label="Gender"
                      name="Gender"
                      disabled
                      variant="outlined"
                      onChange={(e) => {
                        setData((data) => ({
                          ...data,
                          gender: e.target.value,
                        }));
                      }}
                      value={data?.gender}>
                      <MenuItem key="male" value="male">
                        Male
                      </MenuItem>
                      <MenuItem key="female" value="female">
                        Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({ ...data, phone: e.target.value }))
                      }
                      value={data?.phone}
                      label="Phone"
                      disabled
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({ ...data, email: e.target.value }))
                      }
                      value={data?.email}
                      label="Email"
                      disabled
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          mailing: e.target.value,
                        }))
                      }
                      disabled
                      value={data?.mailing}
                      label="Mailing Address"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">Educational Background</h5>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      disabled
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          currentSchool: e.target.value,
                        }))
                      }
                      value={data?.currentSchool}
                      label="Current School/College/University"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      disabled
                      onChange={(e) =>
                        setData((data) => ({ ...data, grade: e.target.value }))
                      }
                      value={data?.grade}
                      label="Grade/Year Level"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      disabled
                      onChange={(e) =>
                        setData((data) => ({ ...data, gpa: e.target.value }))
                      }
                      value={data?.gpa}
                      label="GPA/Percentage"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      disabled
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          extraCurricular: e.target.value,
                        }))
                      }
                      value={data?.extraCurricular}
                      label="Extracurricular Activities"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">Scholarship Details</h5>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      disabled
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          nameOfScholarship: e.target.value,
                        }))
                      }
                      value={data?.nameOfScholarship}
                      label="Name of Scholarship"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      value={data?.whyApply}
                      multiline
                      disabled
                      rows={5}
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          whyApply: e.target.value,
                        }))
                      }
                      label="Why are you applying for this scholarship? (Max 200 words)"
                      name="description"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      value={data?.utlilizeThis}
                      multiline
                      disabled
                      rows={5}
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          utlilizeThis: e.target.value,
                        }))
                      }
                      label="How do you plan to utilize this scholarship to further your academic or career goals? (Max 200 words)"
                      name="description"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">
                    Interest and Achievements:
                  </h5>
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      value={data?.interest}
                      multiline
                      disabled
                      rows={5}
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          interest: e.target.value,
                        }))
                      }
                      label="Briefly describe your academic interests and any achievements related to your field of interest. (Max 200 words)"
                      name="description"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">Financial Information</h5>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      disabled
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          annualIncome: e.target.value,
                        }))
                      }
                      value={data?.annualIncome}
                      label="Annual Household Income"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      disabled
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          numberHouseHold: e.target.value,
                        }))
                      }
                      value={data?.numberHouseHold}
                      label="Number of Dependents in Household"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      disabled
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          financialConsideration: e.target.value,
                        }))
                      }
                      value={data?.financialConsideration}
                      label="Any additional financial considerations"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">References</h5>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      disabled
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          referenceAcademic: e.target.value,
                        }))
                      }
                      value={data?.referenceAcademic}
                      label="Name and Contact Information of Academic Reference (Teacher/Professor)"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      disabled
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          referencePersonal: e.target.value,
                        }))
                      }
                      value={data?.referencePersonal}
                      label="Name and Contact Information of Personal Reference (Non-family member)"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">Essay</h5>
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      multiline
                      disabled
                      rows={5}
                      onChange={(e) =>
                        setData((data) => ({ ...data, essay: e.target.value }))
                      }
                      value={data?.essay}
                      label="Please attach an essay (500-700 words) discussing a topic related to your field of interest or a significant personal experience that has influenced your academic journey."
                      name="description"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">Declaration</h5>
                </Grid>{" "}
                <Grid md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiCheckbox-root": {
                            backgroundColor: "#ff5421",
                          },
                        }}
                        checked={data?.dec1}
                        disabled
                        onChange={(e) =>
                          setData((data) => ({
                            ...data,
                            dec1: e.target.checked,
                          }))
                        }
                        name="inActive"
                      />
                    }
                    label="I certify that all information provided in this application is accurate and complete to the best of my knowledge.
                    "
                  />
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.dec2}
                        disabled
                        onChange={(e) =>
                          setData((data) => ({
                            ...data,
                            dec2: e.target.checked,
                          }))
                        }
                        name="inAct"
                      />
                    }
                    label="I understand that any false information may result in disqualification from consideration for the scholarship."
                  />
                </Grid>
                <Grid md={12} xs={12}>
                  <h5 className="section_heading">Signature</h5>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      disabled
                      onChange={(e) =>
                        setData((data) => ({ ...data, sign: e.target.value }))
                      }
                      value={data?.sign}
                      label="Signature"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      variant="outlined"
                      type="date"
                      disabled
                      focused
                      onChange={(e) =>
                        setData((data) => ({ ...data, date: e.target.value }))
                      }
                      value={data?.date}
                      label="Date"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            {/* </Card> */}
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScholarshipAdminViewModal;
