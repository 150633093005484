import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { Divider } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  AdminPanelSettings,
  FormatAlignLeft,
  List,
  Money,
  QuestionAnswer,
} from "@mui/icons-material";
import SchoolIcon from "@mui/icons-material/School";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { AdminContext } from "../../Context/AdminContext";
import WalletModal from "../../components/WalletModal/WalletModal";

const menuLinkStyles = ({ isActive }) => {
  return {
    backgroundColor: isActive ? "#1A1C33" : "",
  };
};
const drawerWidth = 280;

function Dashboard(props) {
  const { admin, logout } = React.useContext(AdminContext);
  const navigate = useNavigate();

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [anchorEl5, setAnchorEl5] = useState(null);


 
  const handleOpenSubmenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleOpenSubmenu3 = (event) => {
    setAnchorEl3(event.currentTarget);
    setAnchorEl1(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
  };
  const handleOpenCertificatesSubmenu = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleOpenDiplomasSubmenu = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleOpenDegreesSubmenu = (event) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleCloseAllMenus = () => {
    setAnchorEl1(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
  };
  // Event handler to close submenu
  // const handleCloseSubmenu = () => {
  //   setAnchorEl1(null);
  // };
  const handleCloseSubmenu2 = () => {
    setAnchorEl2(null);
  };
  const handleCloseSubmenu3 = () => {
    setAnchorEl3(null);
  };

  // useEffect(() => {
  //   if (admin?.role !== "admin") {
  //     navigate("/");
  //   }
  // }, [admin, navigate])

  // const { logOut } = React.useContext(GrighundContext);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [switchAccountAnchorEl, setSwitchAccountAnchorEl] =
    React.useState(null);
  const switchAccountPopOverOpen = Boolean(switchAccountAnchorEl);

  const handleOpenSwitchAccountPopOver = (e) => {
    setSwitchAccountAnchorEl(e.currentTarget);
  };
  const handleCloseSwitchAccountPopOver = (e) => {
    setSwitchAccountAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClose = () => {
    setMobileOpen(false);
  };

  // const handleLogout = () => {
  //   logout();
  //   navigate("/");
  // }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [studentsAnchorEl, setStudentsAnchorEl] = useState(null);
  const studentsMenuOpen = Boolean(studentsAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openStudentsMenu = (event) => {
    setStudentsAnchorEl(event.currentTarget);
  };
  const closeStudentsMenu = () => {
    setStudentsAnchorEl(null);
  };

  const drawer = (
    <div className="sideBar">
      <WalletModal></WalletModal>
      <Toolbar />
      <Link to="/">
        <img className="dashLogo mx-auto" src="/logo192.png" alt="" />
      </Link>
      <Divider />
      <div className="menuDiv">
        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="dashboard"
        >
          <span className="navIconAdmin">
            {/* <i className="fas fa-th-large"></i> */}
            <DashboardIcon />
          </span>
          DASHBOARD
        </NavLink>
        <br />
        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="admins"
        >
          {" "}
          <span className="navIconAdmin">
            {/* <i class="fas fa-users"></i> */}
            <AdminPanelSettings />
          </span>
          ADMINS
        </NavLink>
        <br />
        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="students"
        >
          {" "}
          <span className="navIconAdmin">
            {/* <i class="fas fa-users"></i> */}
            <SupervisedUserCircleIcon />
          </span>
          STUDENTS
        </NavLink>
        <br />
        <>
          {/* Button to trigger submenu */}
          <h6
            onClick={handleOpenSubmenu2}
            style={{ cursor: "pointer" }}
            className="submenu-trigger cursor-pointer dashboardMenu"
          >
            <span className="navIconAdmin">
              <SchoolIcon />
            </span>
            COURSES
            <IoMdArrowDropdown className="fs-4" style={{ color: "#afafba" }} />
          </h6>

          {/* Submenu */}
          <Menu
            id="submenu"
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={handleCloseSubmenu2}
          >
            {/* Submenu items */}
            <MenuItem onClick={handleCloseSubmenu2}>
              <NavLink to="categories" onClick={handleClose}>CATEGORIES</NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseSubmenu2}>
              <NavLink to="courses" onClick={handleClose}>COURSES</NavLink>
            </MenuItem>
           
            <MenuItem onClick={handleCloseSubmenu2}>
              <NavLink to="course-enrollments" onClick={handleClose}>COURSE ENROLLMENTS</NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseSubmenu2}>
              <NavLink to="course-creator-ai" onClick={handleClose}>COURSE CREATOR AI</NavLink>
            </MenuItem>
          </Menu>
        </>
        <br />
        <h6
          onClick={handleOpenSubmenu3}
          style={{ cursor: "pointer" }}
          className="submenu-trigger cursor-pointer dashboardMenu"
        >
          <span className="navIconAdmin">
            <CardMembershipIcon />
          </span>
          QUALIFICATIONS
          <IoMdArrowDropdown className="fs-4" style={{ color: "#afafba" }} />
        </h6>
        <Menu
          id="submenu"
          anchorEl={anchorEl3}
          open={Boolean(anchorEl3)}
          onClose={handleCloseAllMenus}
        >
          <MenuItem
            style={{ color: "#21a7d0" }}
            onClick={handleOpenCertificatesSubmenu}
          >
            CERTIFICATES
            <IoMdArrowDropdown className="fs-4" style={{ color: "#afafba" }} />
            <Menu
              id="submenu-certificates"
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={handleCloseAllMenus}
            >
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="certificates-requested"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Requested
                </NavLink>
              </MenuItem>
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="certificates-awarded"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Awarded
                </NavLink>
              </MenuItem>
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="certificates-rejected"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Rejected
                </NavLink>
              </MenuItem>
            </Menu>
          </MenuItem>
          <MenuItem
            style={{ color: "#21a7d0" }}
            onClick={handleOpenDiplomasSubmenu}
          >
            DIPLOMAS
            <IoMdArrowDropdown className="fs-4" style={{ color: "#afafba" }} />
            <Menu
              id="submenu-deplomas"
              anchorEl={anchorEl4}
              open={Boolean(anchorEl4)}
              onClose={handleCloseAllMenus}
            >
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="diplomas-requested"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Requested
                </NavLink>
              </MenuItem>
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="diplomas-awarded"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Awarded
                </NavLink>
              </MenuItem>
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="diplomas-rejected"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Rejected
                </NavLink>
              </MenuItem>
            </Menu>
          </MenuItem>
          <MenuItem
            style={{ color: "#21a7d0" }}
            onClick={handleOpenDegreesSubmenu}
          >
            DEGREES
            <IoMdArrowDropdown className="fs-4" style={{ color: "#afafba" }} />
            <Menu
              id="submenu-degrees"
              anchorEl={anchorEl5}
              open={Boolean(anchorEl5)}
              onClose={handleCloseAllMenus}
            >
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="degrees-requested"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Requested
                </NavLink>
              </MenuItem>
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="degrees-awarded"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Awarded
                </NavLink>
              </MenuItem>
              <MenuItem
                onClick={handleCloseAllMenus}
                style={{ backgroundColor: "#373A40" }}
              >
                <NavLink
                  to="degrees-rejected"
                  style={{ color: "whitesmoke" }}
                  onClick={handleClose}
                >
                  Rejected
                </NavLink>
              </MenuItem>
            </Menu>
          </MenuItem>
          {/* <MenuItem onClick={handleCloseSubmenu3}>
            <NavLink to="diploma" onClick={handleClose}>DIPLOMAS</NavLink>
          </MenuItem> */}
          {/* <MenuItem onClick={handleCloseSubmenu3}>
            <NavLink to="degree" onClick={handleClose}>DEGREES</NavLink>
          </MenuItem> */}
        </Menu>
        <>
          {/* Button to trigger submenu */}
          {/* <h6
            onClick={handleOpenSubmenu}
            style={{ cursor: "pointer" }}
            className="submenu-trigger cursor-pointer dashboardMenu">
            <span className="navIconAdmin">
              <CardMembershipIcon />
            </span>
            CERTIFICATES
          </h6> */}

          {/* Submenu */}
          {/* <Menu
            id="submenu"
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={handleCloseSubmenu}>
            <MenuItem onClick={handleCloseSubmenu}>
              <NavLink to="certificates">Requested</NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseSubmenu}>
              <NavLink to="awarded-certificates">Awarded</NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseSubmenu}>
              <NavLink to="rejected-certificates">Rejected</NavLink>
            </MenuItem>
          </Menu> */}
        </>
        <br />
        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="scholarships"
        >
          {" "}
          <span className="navIconAdmin">
            {/* <i class="fas fa-users"></i> */}
            <Money />
          </span>
          SCHOLARSHIPS
        </NavLink>
        <br />
        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="forms"
        >
          {" "}
          <span className="navIconAdmin">
            {/* <i class="fas fa-users"></i> */}
            <List />
          </span>
          FORMS
        </NavLink>
        <br />

        <NavLink
          className="dashboardMenu  text-uppercase"
          style={menuLinkStyles}
          onClick={handleClose}
          to="email-subscribers"
        >
          {" "}
          <span className="navIconAdmin">
            {/* <i class="fas fa-users"></i> */}
            <BeenhereIcon />
          </span>
          Email Subscribers
        </NavLink>
        <br />
        {/* <NavLink
          className="dashboardMenu  text-uppercase d-none d-lg-block"
          style={menuLinkStyles}
          onClick={handleClose}
          to="enquiries"
        >
          {" "}
          <span className="navIconAdmin">
            <QuestionAnswer />
          </span>
          ENQUIRIES
        </NavLink> */}
        {/* <br className="dashboardMenu d-none d-lg-block"/> */}
        {/* <NavLink
          className="dashboardMenu d-none d-lg-block"
          style={menuLinkStyles}
          onClick={handleClose}
          to="payments-received">
          {" "}
          <span className="navIconAdmin">
            <PaymentIcon />
          </span>
          PAYMENTS RECEIVED
        </NavLink> */}
        {/* <br className="dashboardMenu d-none d-lg-block" /> */}
        {/* <NavLink
          className="dashboardMenu d-none d-lg-block"
          style={menuLinkStyles}
          onClick={handleClose}
          to="affiliate">
          {" "}
          <span className="navIconAdmin">
            <SensorsIcon />
          </span>
          AFFILIATE PAYMENTS
        </NavLink> */}
        {/* <br className="dashboardMenu d-none d-lg-block"/> */}
        <NavLink
          className="dashboardMenu d-none d-lg-block"
          style={menuLinkStyles}
          onClick={handleClose}
          to="transactions"
        >
          {" "}
          <span className="navIconAdmin ">
            {/* <i class="fas fa-users"></i> */}
            <ReceiptLongIcon />
          </span>
          TRANSACTIONS
        </NavLink>

        {/* <div
          style={{ cursor: "pointer" }}
          className="dashboardMenu cursor-pointer"
          onClick={openStudentsMenu}
        >
        
          <span className="navIconAdmin">
            <SupervisedUserCircleIcon />
          </span>
          STUDENTS <ArrowDropDownIcon />
        </div> */}
        <br className="dashboardMenu d-none d-lg-block" />
        {/* <NavLink
          onClick={handleClose}
          className="dashboardMenu" style={menuLinkStyles} to="registered"> <span className='navIconAdmin'><i class="fas fa-users"></i></span>REGISTERED</NavLink><br /> */}
        {/* <NavLink
          onClick={handleClose}
          className="dashboardMenu" style={menuLinkStyles} to="addCourses"> <span className='navIconAdmin'><i class="fas fa-users"></i></span>ADDED COURSES</NavLink><br /> */}
        {/* <NavLink className="dashboardMenu" style={menuLinkStyles} onClick={handleClose} to="certificates"> <span className='navIconAdmin'><i class="fas fa-users"></i></span>CERTIFICATES</NavLink><br /> */}
        {/* <NavLink

          

          className="dashboardMenu" 
          style={menuLinkStyles} onClick={() => { handleClose(); handleClick() }} to="courses"> <span className='navIconAdmin'><i class="fas fa-users"></i></span>COURSES</NavLink><br /> */}
        {/* Course menu */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div
            style={{ backgroundColor: "#171f32" }}
            className="text-light text-uppercase"
          >
            <div className="pt-5">
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  handleClose();
                  navigate("/admin/courses");
                }}
                className=" text-uppercase "
              >
                All Courses
              </MenuItem>
            </div>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                handleClose();
                navigate("/admin/addCourses");
              }}
              className="text-uppercase"
            >
              Course Content
            </MenuItem>
          </div>
        </Menu>
        {/* Students menu */}
        <Menu
          id="basic-menu"
          anchorEl={studentsAnchorEl}
          open={studentsMenuOpen}
          onClose={closeStudentsMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div
            style={{ backgroundColor: "#171f32" }}
            className="text-light text-uppercase"
          >
            <div className="pt-5">
              <MenuItem
                onClick={() => {
                  closeStudentsMenu();
                  handleClose();
                  navigate("/admin/students");
                }}
                className="text-uppercase "
              >
                All Students
              </MenuItem>
            </div>
            {/* <MenuItem
              onClick={() => {
                closeStudentsMenu();
                handleClose();
                navigate("/admin/course-enrollments");
              }}
              className="text-uppercase ">
              Latest Registration
            </MenuItem> */}
          </div>
        </Menu>
        {/* <Link to="/"> */}
        <button onClick={() => logout(navigate)} className="LogoutButton">
          {" "}
          Logout
        </button>
        {/* </Link> */}
        {/* <NavLink className="dashboardMenu  text-uppercase" style={menuLinkStyles} onClick={handleClose} to="soldcourses"> <span className='navIconAdmin'><i class="fas fa-users"></i></span>Sold Courses</NavLink><br /> */}
        {/* <NavLink className="dashboardMenu" style={menuLinkStyles} onClick={handleClose} to="titles_not_eligible"> <span className='navIconAdmins'><i class="fas fa-dot-circle"></i></span><span className='mintMenu'>TITLES NOT ELIGIBLE</span></NavLink><br />

        <NavLink className="dashboardMenu" style={menuLinkStyles} onClick={handleClose} to="titles_registered"> <span className='navIconAdmins'><i class="fas fa-dot-circle"></i></span><span className='mintMenu'>TITLES MINTED</span></NavLink><br />
        <NavLink className="dashboardMenu" style={menuLinkStyles} onClick={handleClose} to="titles_suggest"> <span className='navIconAdmins'><i class="fas fa-dot-circle"></i></span><span className='mintMenu'>SUGGESTED TITLES</span></NavLink><br /> */}
        {/* <Button variant="danger" onClick={() => handleLogout()} className="mt-3 text-uppercase ms-2 pt-3 pb-3" size="sm">Log Out</Button> */}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const menuToggle = () => {
    const toggleMenu = document.querySelector(".adminProfile");
    toggleMenu.classList.toggle("active");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          // width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          paddingRight: "0px !important",
        }}
      >
        <Toolbar className="dashboardNav">
          <IconButton
            color="inherit"
            className="ms-3"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block" } }}
          >
            <FormatAlignLeft />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="dashboardTopBar"
          >
            <h4 className="dashboardTitlehidden">Dashboard</h4>
            {/* <div className="profile">
              <div className="imgDashDiv" onClick={menuToggle}>
                <img src={currentAdmin.avatar} alt="" />
              </div>
            </div> */}
          </Typography>
        </Toolbar>
        {/* <div className="adminProfile" onClick={menuToggle}>
          <p className='text-start'>{currentAdmin?.name}</p>
          <hr />
          <Link to="admin/profile" className='mb-3 d-flex' ><i className="fas fa-user me-2"></i>Profile</Link>
          <p className='text-start logoutBtn' onClick={handleLogout}><i className="fas fa-sign-out-alt"></i> Log Out</p>
        </div> */}
      </AppBar>

      <Box
        component="nav"
        
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#272D47 !important",
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#272D47 !important",
            },
          }}
          open
        >
          {drawer}
        </Drawer> */}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        className="dashboardDiv"
      >
        <Toolbar />
        <div className="contentAllDiv">
          <div style={{ minHeight: "100vh" }}>
            <Outlet />
          </div>
          <div>
            <div className="copyrightAdmin mt-4 ">
              <p className="my-2">
                Copyright © {new Date().getFullYear()} - DS Legends Pte. Ltd.
              </p>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}

Dashboard.propTypes = {
  window: PropTypes.func,
};

export default Dashboard;
