import { forwardRef, useContext, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CompanionAi from './CompanionAi';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)`
  width: 600px;
  max-width: 1000px !important;
  //   background:linear-gradient(to right bottom, #4e4c4c, #4e4c4c);
  color: white;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
  }
`;

const SpeechModal = ({ open, setOpen,pdfs}) => {
  const [selectAvatarThemeIcon, setSelectAvatarThemeIcon] = useState(false);
  const [selectAvatarTheme, setSelectAvatarTheme] = useState(false);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      PaperComponent={StyledPaper}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="border-dark border-bottom bg-green text-dark d-flex align-items-center justify-content-between">
       <div className="d-flex align-items-center gap-2"> <h5 className='m-0' style={{color:"#ff5421",fontSize:"22px"}}>LECTURER AI</h5>  {selectAvatarThemeIcon &&  <i class="fas fa-cog" onClick={() => setSelectAvatarTheme(res=>!res)}></i>}</div>
        <button
          className="rounded-circle cross-btn"
          onClick={() => {
            setOpen(false);
            // window.location.reload()
          }}
        >
          X
        </button>
      </DialogTitle>
      <DialogContent className='text-dark p-0'>
{open&&<CompanionAi setSelectAvatarThemeIcon={setSelectAvatarThemeIcon} selectAvatarThemeIcon={selectAvatarThemeIcon} selectAvatarTheme={selectAvatarTheme} setSelectAvatarTheme={setSelectAvatarTheme} pdf={pdfs}></CompanionAi>}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default SpeechModal;
