import { ArrowBack } from '@mui/icons-material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import './AddCertificate.css'

const AddCertificate = () => {
  const { id } = useParams()
  const [details, setDetails] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [refetch, setrefetch] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const getCertificateDetails = async () => {
      await axios.get(`https://backend.studyswiftly.com/api/mint/${id}`)
        .then(res => {

          setDetails(res?.data?.result)
        })
    }
    getCertificateDetails();
  }, [id]);



  useEffect(() => {
    const data = async () => {
      await axios.get(`https://backend.studyswiftly.com/api/certificate/`)
        .then(res => {
          setCertificates(res.data.result)
        })
    }
    data();
  }, [refetch])



  const dateObj = new Date();
  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();
  const shortDate = `${day}-${month}-${year}`;



  const handleAddCertificate = async e => {
    e.preventDefault()
    const certificateNo = e.target.certificateNo.value;
    const name = e.target.name.value;
    const description = e.target.description.value;
    // const date = e.target.date.value;

    const data = {
      coursename: details?.coursename,
      CertificateNo: certificateNo,
      name: name,
      description: description,
      date: shortDate,
      email: details?.email,
      Serial: `0${(certificates?.length == 0 ? 1 : Number(certificates?.[certificates?.length - 1]?.Serial) + 1)}`,
      memberId: details?.memberId
    }



    await axios.post(`https://backend.studyswiftly.com/api/certificate/`, data)
      .then(res => {
        if (res.status === 200) {
          setrefetch(refetch + 1)
          swal({
            title: "Success",
            text: `Certificate added successfully !`,
            icon: "success",
            button: "OK!",
            className: "modal_class_success",
          });
          e.target.reset();
          navigate("/admin/certificates-requested")
        }
      })
      .catch(error => {
        swal({
          title: "Attention",
          text: `Something went wrong.Try again !`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });

  }


  // let newDate = new Date();
  // let dd = String(newDate.getDate()).padStart(2, '0');
  // let mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  // let yyyy = newDate.getFullYear();
  // let hh = newDate.getHours();
  // let min = newDate.getMinutes();
  // let ss = newDate.getSeconds();
  // newDate = dd + '/' + mm + '/' + yyyy;

  // console.log(newDate)



  // declare all characters
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789203040506066656564';

  // function generateString(length) {
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  // return result;
  // }
  const upperCased = result.toUpperCase()



  return (
    <div>
      <p className='cursor-pointer' style={{ cursor: "pointer" }} title='Back' onClick={() => navigate(-1)}><ArrowBack /></p>
      <div className='container certificateDiv'>
        <form
          onSubmit={handleAddCertificate}
        >
          <div className="backgroundImag1">
            <div className="abs_wrapper">

              <input
                defaultValue={upperCased}
                className='certificateInput ms-3 border-0 transparent-input'
                placeholder='No'
                type="text"
                required
                name='certificateNo'
              />

              <input
                defaultValue={details?.name}
                className='StudentNameInput ps-1 border-0 transparent-input'
                placeholder="Name"
                type="text"
                required
                name='name'
              />

              <div className='certificateDesInput ps-0 ' >
                <textarea
                  type="text"
                  className='transparent-input'
                  placeholder="For Completing The"
                  required
                  name='description'
                />
              </div>

              <input
                defaultValue={shortDate}
                id="myDate"
                className='dateInput transparent-input'
                placeholder="DD/MM/YY"
                // data-date-format="DD MMMM YYYY"
                type="text"
                name='date'
              />

            </div>

          </div>
          <button className='profileEnrolBtnMint mb-5'>Save</button>
        </form>
      </div>
    </div>
  )
}

export default AddCertificate