import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import './WalletModal.css';
import { BlockchainContext } from '../../Context/BlockchainContext';
import { UserContext } from '../../Context/UserContext';
import { useNavigate } from 'react-router-dom';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});

const WalletModal = () => {
    const { connectToMetamask, walletModal, closeWalletModal, user } = useContext(BlockchainContext);
    const { data } = useContext(UserContext);
    const navigate = useNavigate();
    const [onGoing, setOngoing] = useState(false);


    return (
        <div className='dialogDiv'>
            <Dialog
                open={walletModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeWalletModal}
                aria-describedby="alert-dialog-slide-description"
                className='dialog'
            >
                <div className="dialogWallet pt-4">


                    <DialogContent className='alertWalletDiv'>


                        <DialogContentText id="alert-dialog-slide-description">


                            <Row xs={1} md={1} className="g-2">
                                <Col>
                                    <Card className='walletDiv walletModal_icon' onClick={() => connectToMetamask()} >
                                        <Card.Img variant="top" src="https://i.ibb.co/vVf533V/1.png" className="imgWallet" />
                                        <div className='font-style'>
                                            <h2 className='walletName mb-0'>Metamask</h2>
                                            <button className='modal-login-btn my-3' type='button' >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <img className='me-2' width='26px' src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg" alt="logo" style={{ marginTop: "-3px" }} />
                                                Click here to login</button>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="contentDiv">
                                <p className='contents pt-2'>You can use Binance Chain to connect.</p>
                                <p className='contents'>Add Binance Chain in your Metamask as follows.</p>
                                <p className='contents px-2'><a className='contents1' style={{ color: '#8484f8' }} href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_any" >https://academy.binance.com/en/articles
                                    /connecting-metamask-to-binance-smart-chain</a></p>
                            </div>
                            <p className='text-center mt-4'>
                                <Button onClick={closeWalletModal} className="text-white fs-6 bg-danger">Cancel</Button>
                            </p>
                            {/* <div className='d-flex align-items-center justify-content-center mb-3 cursor-pointer' style={{cursor:"pointer"}}>
                                <img width="28" height="28" className='me-2' src="https://img.icons8.com/color/28/google-logo.png" alt="google-logo" />
                                <p className='text-white m-0'>Login With Google</p>
                            </div> */}

                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
};

export default WalletModal;