import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Link, Typography } from "@mui/material";
import axios from "axios";
import htmlToDraft from "html-to-draftjs";
import swal from "sweetalert";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./Courses.css";
import { ArrowBack, Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import MCQModal from "./MCQModal/MCQModal";
import { AdminContext } from "../../../Context/AdminContext";

const AddCourse = () => {
  const { admin, setAdmin } = useContext(AdminContext);
  console.log(admin, "Admin datails");
  const [img, setImg] = useState(null);
  const [amount, setAmount] = useState(() => EditorState.createEmpty());
  const [startTimeInput, setStartTimeInput] = useState(new Date());
  const [endTimeInput, setEndTimeInput] = useState("");
  const [oneDayBefore, setOneDayBefore] = useState();
  const [charLimit, setCharLimit] = useState(0);
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [mcqArr, setMcqArr] = useState([]);
  const [showMCQModal, setShowMCQModal] = useState(false);
  console.log(mcqArr, "MCQ ARRAY");
  const [answer, setAnswer] = useState("");
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState(1);
  console.log(answer, "Answere");
  const [selectedImage, setSelectedImage] = useState();
  const [image, setImage] = useState();

  const [videoValueArr, setVideoValueArr] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videoData, setVideoData] = useState();
  const [videoLoader, setvideoLoader] = useState(false);

  const [imageArr, setImageArr] = useState([]);
  const [imgData, setImgData] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageLoader, setimageLoader] = useState(false);

  const [pdfLoader, setpdfLoader] = useState(false);
  const [selectedPDFs, setSelectedPDFs] = useState([]);
  const [PdfValueArr, setPdfValueArr] = useState([]);
  const [PdfData, setPdfData] = useState();

  const [instructions, setInstructions] = useState(() =>
    EditorState.createEmpty()
  );
  const instructionsData = draftToHtml(
    convertToRaw(instructions.getCurrentContent())
  );

  const [urls, setUrls] = useState(() => EditorState.createEmpty());
  const urlsData = draftToHtml(convertToRaw(urls.getCurrentContent()));

  const [questionary, setQuestionary] = useState(() =>
    EditorState.createEmpty()
  );
  const questionaryData = draftToHtml(
    convertToRaw(questionary.getCurrentContent())
  );

  const [youlearn, setYoulearn] = useState(() => EditorState.createEmpty());
  const stepOne = draftToHtml(convertToRaw(youlearn.getCurrentContent()));

  const [howYoulearn, sethowYoulearn] = useState(() =>
    EditorState.createEmpty()
  );
  const howYoulearnHtml = draftToHtml(
    convertToRaw(howYoulearn.getCurrentContent())
  );

  const [howGetCertificate, setHowGetCertificate] = useState(() =>
    EditorState.createEmpty()
  );
  const howGetCertificateHtml = draftToHtml(
    convertToRaw(howGetCertificate.getCurrentContent())
  );

  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );
  const descriptiondraft = draftToHtml(
    convertToRaw(description.getCurrentContent())
  );

  // console.log(saveAsDraft);
  const navigate = useNavigate();
  var newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();

  if (min < 10) {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + 0 + min + ":" + ss;
  } else {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  }

  // useEffect(() => {
  //     console.log(amount);
  //     let dateu = parseInt(amount);
  //     setOneDayBefore(dateu - 1);
  // }, [amount])
  // console.log(oneDayBefore);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(res.data.Categories);
        });
    };
    data();
  }, []);

  const handleTimeChange = (e) => {
    const startTimeChange = parseInt(e.target.value);
    const endTimeChange = startTimeChange + 3;
    setEndTimeInput(endTimeChange);
  };

  const handleAddMcq = () => {
    document.getElementById("mcqsQuestion").value = "";

    const mcqObj = {
      question: question,
      option1: answer,
      option2: answer1,
      option3: answer2,
      option4: answer3,
      ans: answer4,
    };

    if (question) {
      setMcqArr((prevArr) => [...prevArr, mcqObj]);
    }
    setQuestion("");
    setAnswer("");
    setAnswer1("");
    setAnswer2("");
    setAnswer3("");
    setAnswer4(1);
  };

  const subCourse = async (e) => {
    e.preventDefault();
    console.log(descriptiondraft);
    console.log(stepOne);
    const coursename = e.target.coursename.value;
    const description = descriptiondraft;
    const youlearn = stepOne;
    // const start = e.target.start.value;
    // const end = e.target.end.value;
    const amount = e.target.amount.value;
    const image = e.target.image.files?.[0];
    const categories = e.target.categories.value;
    const start_date = e.target.start_date.value;
    const end_date = e.target.end_date.value;
    const sort = e.target.sort.value;

    const formData = new FormData();
    formData.append("coursename", coursename);
    formData.append("youlearn", youlearn);
    formData.append("description", description);
    formData.append("howlearn", howYoulearnHtml);
    formData.append("howGetCertificate", howGetCertificateHtml);
    // formData.append("start", start);
    // formData.append("end", end);
    formData.append("amount", amount);
    formData.append("image", selectedImage);
    formData.append("categories", categories);
    formData.append("McqData", JSON.stringify(mcqArr));
    formData.append("allMcq", JSON.stringify(mcqArr));
    formData.append("pdfData", JSON.stringify(PdfData));
    formData.append("videoData", JSON.stringify(videoData));
    formData.append("imageData", JSON.stringify(imgData));

    formData.append("urls", urlsData);
    formData.append("start", new Date());
    formData.append("end", end_date);
    formData.append("sort", sort);
    formData.append("instructions", instructionsData);

    // formData.append("description2", linksAndDetailsData);
    // formData.append("tutoring", onlineTutorialData);
    // formData.append("questionaries", questionaryData);

    await axios
      .post("https://backend.studyswiftly.com/api/course/add", formData)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          // alert(res.data.message);
          // document.getElementById("pdfInput2").value = "";
          // swal({
          //     title: "Success",
          //     text: `${res.data.message}`,
          //     icon: "success",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          // Keeping action records
          (async () => {
            await axios
              .post(
                `https://backend.studyswiftly.com/api/data/admin/course_add`,
                {
                  name: admin.name,
                  email: admin.email,
                  coursename: coursename,
                  courseprice: amount,
                  coursecatagory: categories,
                  date: newDate,
                }
              )
              .then((res) => console.log(res.data, "Admin add course action"));
          })();

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });
        }

        navigate(-1);
      })
      .catch((error) => {
        console.log("erooorrrrrrrr: " + error);
        // alert(error.response.data.message);
        // swal({
        //     title: "Attention",
        //     text: `${error.response.data.message}`,
        //     icon: "warning",
        //     button: "OK!",
        //     className: "modal_class_success",
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        });
      });
  };

  useEffect(() => {
    if (!selectedImage) {
      setImage(
        "https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
      );
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const imageChange = (e) => {
    const selected = e.target.files[0];

    if (selected) {
      let reader = new FileReader();
      reader.onload = () => {
        setImg(reader?.result);
      };
      reader.readAsDataURL(selected);
    }
  };

  const loadFile = (amount) => {
    setImg(amount.target.files[0]);
    let output = document.getElementById("output");
    output.src = URL.createObjectURL(amount.target.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };
  };

  const changePhoto = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedImage(e.target.files[0]);
  };

  function removeVideoFile(index) {
    var attachments = document.getElementById("videoInput").files;
    var fileBuffer = new DataTransfer();

    for (let i = 0; i < attachments.length; i++) {
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    document.getElementById("videoInput").files = fileBuffer.files;
  }

  const handleVideoSelection = (e) => {
    if (e.target.files[0] !== 0) {
      const newVideos = Object.values(e.target.files);
      const Videos = [...selectedVideos, ...newVideos];

      const formData = new FormData();
      for (const singleVideo of Videos) {
        formData.append("videos", singleVideo);
      }

      setvideoLoader(true);

      axios
        .post(`https://backend.studyswiftly.com/api/mint/videos`, formData)
        .then((res) => {
          setSelectedVideos(res?.data?.videos);
          setvideoLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setvideoLoader(true);
        });
    }
  };

  const handleRemoveVideo = (Video, index) => {
    const filterdVideos = selectedVideos.filter((vdo) => vdo !== Video);

    const filteredData = videoData?.filter((value) => value.video != Video);
    setVideoData(filteredData);
    setSelectedVideos(filterdVideos);
    removeVideoFile(index);
  };

  const handleVideoInputValues = (e) => {
    let mainArr = [];
    let array = videoValueArr;
    array[e.target.name] = e.target.value;
    // console.log(selectedImages, array)

    selectedVideos?.map((video, index1) => {
      array?.map((value, index2) => {
        if (index1 == index2) {
          const dataObj = {
            video: video,
            value: value,
          };

          mainArr.push(dataObj);
          // console.log(dataObj, mainArr)
          setVideoData(mainArr);
          // console.log(mainArr)
        }
      });
    });
  };

  const handleImageSelection = (e) => {
    if (e.target.files[0] !== 0) {
      const newImages = Object.values(e.target.files);
      const images = [...selectedImages, ...newImages];

      const formData = new FormData();
      for (const image of images) {
        formData.append("images", image);
      }

      setimageLoader(true);

      axios
        .post(`https://backend.studyswiftly.com/api/mint/img`, formData)
        .then((res) => {
          // console.log("image form data", res.data)
          // setImg(res?.data?.images);
          setSelectedImages(res?.data?.images);
          setimageLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setimageLoader(false);
        });
    }
  };

  const handleImageInputValues = (e) => {
    let mainArr = [];
    let array = imageArr;
    array[e.target.name] = e.target.value;
    // console.log(selectedImages, array)

    selectedImages?.map((img, index1) => {
      array?.map((value, index2) => {
        if (index1 == index2) {
          const dataObj = {
            image: img,
            value: value,
          };

          mainArr.push(dataObj);
          // console.log(dataObj, mainArr)
          setImgData(mainArr);
        }
      });
    });
  };

  // please dont change any code if you dont understand---->Jahid
  function removeImageFile(index) {
    var attachments = document.getElementById("imgInput").files; // <-- reference of file input here
    var fileBuffer = new DataTransfer();

    // append the file list to an array iteratively
    for (let i = 0; i < attachments.length; i++) {
      // Exclude file in specified index
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    // Assign buffer to file input
    document.getElementById("imgInput").files = fileBuffer.files; // <-- according to your file input reference
  }

  const handleRemoveImage = (image, index) => {
    const filterdImages = selectedImages.filter((img) => img !== image);

    const filteredData = imgData?.filter((value) => value.image != image);
    setImgData(filteredData);
    setSelectedImages(filterdImages);

    removeImageFile(index);
  };

  const handlePDFSelection = (e) => {
    if (e.target.files[0] !== 0) {
      const newPDFs = Object.values(e.target.files);
      const PDFs = [...selectedPDFs, ...newPDFs];

      const formData = new FormData();
      for (const doc of PDFs) {
        formData.append("doc", doc);
      }
      // console.log("file form data", ...formData)

      setpdfLoader(true);
      axios
        .post(`https://backend.studyswiftly.com/api/mint/doc`, formData)
        .then((res) => {
          setSelectedPDFs(res?.data?.document);
          setpdfLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setpdfLoader(false);
        });
    }
  };

  const handlePDFInputValues = (e) => {
    let mainArr = [];
    let array = PdfValueArr;
    array[e.target.name] = e.target.value;
    // console.log(selectedImages, array)

    selectedPDFs?.map((pdf, index1) => {
      array?.map((value, index2) => {
        if (index1 == index2) {
          const dataObj = {
            pdf: pdf,
            value: value,
          };

          mainArr.push(dataObj);
          // console.log(dataObj, mainArr)
          setPdfData(mainArr);
          // console.log(mainArr)
        }
      });
    });
  };

  function removePDFFile(index) {
    var attachments = document.getElementById("pdfInput").files;
    var fileBuffer = new DataTransfer();

    for (let i = 0; i < attachments.length; i++) {
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    document.getElementById("pdfInput").files = fileBuffer.files;
  }

  const handleRemovePDF = (PDF, index) => {
    const filterdPDFs = selectedPDFs.filter((pdf) => pdf !== PDF);

    const filteredData = PdfData?.filter((value) => value.pdf != PDF);

    setSelectedPDFs(filterdPDFs);
    setPdfData(filteredData);
    removePDFFile(index);
  };

  return (
    <div>
      <p
        className="cursor-pointer"
        style={{ cursor: "pointer" }}
        title="Back"
        onClick={() => navigate(-1)}>
        <ArrowBack />
      </p>
      <h5 className="text-white text-uppercase">Add Course</h5>
      <div
        style={{ backgroundColor: "#272d47", color: "white" }}
        className="mx-auto forRespoMarginReduce">
        <h5 className="text-warning text-start text-uppercase pt-30 ps-2">
          Course Details
        </h5>
        {/* <h4 className='pt-3 ps-3 container text-light'>Add Courses</h4> */}
        <form onSubmit={subCourse}>
          <div className="container pb-5 pt-0">
            <label className="mb-1">Course Image</label>
            <input
              type="file"
              accept="image/*, video/*"
              name="image"
              onChange={changePhoto}
              className="border w-100 rounded mb-3"
              style={{ backgroundColor: "#272d47", color: "white" }}
              required
            />
            <div className="imageDivNft" style={{ width: "200px" }}>
              <img
                src={image}
                width={200}
                height={200}
                className="d-flex justify-content-center"
                alt=""
              />
            </div>

            <label className="mb-1 mt-3">Course name</label>
            <div className="course-name-input-container">
              <input
                maxLength={55}
                type="text"
                name="coursename"
                className="border w-100 rounded mb-3 p-2"
                onChange={(e) => setCharLimit(e.target.value)}
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                required
              />
              {charLimit?.length > 0 && (
                <span
                  className={`char-limit-position ${
                    charLimit.length == 55 && "text-danger"
                  }`}>
                  {charLimit.length}/55
                </span>
              )}
            </div>
            <label className="mb-1">Course Categories</label>
            <InputGroup
              className="mb-3"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <Form.Select
                aria-label="Default select example"
                required
                onChange={(e) => setCategory(e.target.value)}
                name="categories"
                className=""
                style={{ backgroundColor: "#272d47", color: "white" }}>
                {/* <option>Type Of NFT</option> */}
                {categories?.map((res) => (
                  <option value={res?.name}>{res?.name}</option>
                ))}
                {/* <option value="Blockchain">Blockchain</option>
                <option value="Digital_asset">Digital Asset Management</option>
                <option value="Play_To_Earn">Play To Earn</option> */}
              </Form.Select>
            </InputGroup>
            <label className="mb-1 mt-2">Course Start Date</label>
            <div className="course-name-input-container">
              <input
                type="date"
                name="start_date"
           disabled
                value={new Date(startTimeInput).toISOString().split('T')[0]}
                onChange={(e) => { setStartTimeInput(e.target.value); console.log(e.target.value);}}
                className="border w-100 rounded mb-3 p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
              />
            </div>
            <label className="mb-1 mt-2">Course End Date</label>
            <div className="course-name-input-container">
              <input
                type="date"
                name="end_date"
                className="border w-100 rounded mb-3 p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
         
              />
            </div>
            <label className="mb-1 mt-2">Sort Number</label>
            <div className="course-name-input-container">
              <input
              defaultValue={0}
                type="number"
                name="sort"
                className="border w-100 rounded mb-3 p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                required
              />
            </div>

            <label className="mb-1">Details of Course</label>
            <Editor
              editorState={description}
              required={true}
              onEditorStateChange={setDescription}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class border mt-2 p-2 bg-white text-black"
              toolbarClassName="toolbar-class text-black"
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                  fontSize: {
                    options: [
                      8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  fontFamily: {
                    options: [
                      "Arial",
                      "sans-serif",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                },
              }}
            />

            <label className="mb-1 mt-3">What You will learn?</label>
            <Editor
              editorState={youlearn}
              onEditorStateChange={setYoulearn}
              required={true}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class border mt-2 p-2 bg-white text-black"
              toolbarClassName="toolbar-class text-black"
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                  fontSize: {
                    options: [
                      8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  fontFamily: {
                    options: [
                      "Arial",
                      "sans-serif",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                },
              }}
            />
            <label className="mb-1 mt-3">Learning outcomes</label>
            <Editor
              editorState={howYoulearn}
              onEditorStateChange={sethowYoulearn}
              required={true}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class border mt-2 p-2 bg-white text-black"
              toolbarClassName="toolbar-class text-black"
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                  fontSize: {
                    options: [
                      8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  fontFamily: {
                    options: [
                      "Arial",
                      "sans-serif",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                },
              }}
            />
            <label className="mb-1 mt-3">
              How you can get the certificate?
            </label>
            <Editor
              editorState={howGetCertificate}
              onEditorStateChange={setHowGetCertificate}
              required={true}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class border mt-2 p-2 bg-white text-black"
              toolbarClassName="toolbar-class text-black"
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                  fontSize: {
                    options: [
                      8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  fontFamily: {
                    options: [
                      "Arial",
                      "sans-serif",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                },
              }}
            />

            <label className="mb-1">Course Price(SGD)</label>
            <InputGroup className="mb-3" style={{ textAlign: "left" }}>
              <Form.Control
                style={{ backgroundColor: "#272d47", color: "white" }}
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                step="0.01"
                min={0.0}
                defaultValue={0.0}
                name="amount"
                required
                aria-label="Amount (to the nearest dollar)"
              />
            </InputGroup>

            {/* <label className="mb-1">Course Duration</label>

            <InputGroup className="mb-3">
              <Form.Control
                style={{ backgroundColor: "#272d47", color: "white" }}
                type="text"
                name="start"
                aria-label="Amount (to the nearest dollar)"
              />
            </InputGroup>

            <label className="mb-1">Closing Date</label>

            <InputGroup className="mb-3 text-light">
              <Form.Control
                className="text-light"
                style={{ backgroundColor: "#272d47", color: "white" }}
                type="date"
                name="end"
                aria-label="Amount (to the nearest dollar)"
              />
            </InputGroup> */}
          </div>

          {/* course content start */}
          <h5 className="text-warning text-start text-uppercase pt-5 ps-2">
            Course Materials
          </h5>
          <div className="container pb-5 pt-0">

            <label className="mb-1">URLs</label>
            <Editor
              editorState={urls}
              required={true}
              onEditorStateChange={setUrls}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class border mt-2 p-2 bg-white text-black"
              toolbarClassName="toolbar-class text-black"
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                  fontSize: {
                    options: [
                      8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  fontFamily: {
                    options: [
                      "Arial",
                      "sans-serif",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                },
              }}
            />
            <label className="mb-1 mt-3 pb-5">Special Instructions</label>
            <Editor
              editorState={instructions}
              required={true}
              onEditorStateChange={setInstructions}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class border mt-2 p-2 bg-white text-black"
              toolbarClassName="toolbar-class text-black"
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                  fontSize: {
                    options: [
                      8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  fontFamily: {
                    options: [
                      "Arial",
                      "sans-serif",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                },
              }}
            />
                        <>
              {selectedImages.length > 0 && (
                <div className="selected-video-container mt-10">
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      className="each-selected-video-for-priview">
                      <div className="each-selected-video-container">
                        <img
                          className="each-selected-image"
                          // src={URL.createObjectURL(image)}
                          src={image}
                          alt=""
                        />
                        <Close
                          className="selected-image-remove-button"
                          fontSize="small"
                          onClick={() => handleRemoveImage(image, index)}
                        />
                      </div>
                      <div className="">
                        <p className="mb-1">Add title</p>
                        <input
                          maxLength={55}
                          type="text"
                          defaultValue="Course title"
                          name={index}
                          className="border  h- rounded  p-"
                          // onChange={(e) => setImgTitle(e.target.value)}
                          onChange={(e) => handleImageInputValues(e)}
                          style={{
                            backgroundColor: "#272d47",
                            color: "white",
                            width: "100%",
                            height: "35px",
                          }}
                          required
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
            <label className="mb-1 mt-10">
              {imageLoader ? "Uploading Images..." : "Upload Images"}
            </label>
            <input
              id="imgInput"
              type="file"
              accept=".png,.jpeg,.jpg"
              multiple
              name="images"
              // onChange={onChangePicture}
              onChange={(e) => handleImageSelection(e)}
              className="border w-100 rounded mb-3"
              style={{ backgroundColor: "#272d47", color: "white" }}
              // required
            />
            <>
              {selectedVideos.length > 0 && (
                <div className="selected-video-container">
                  {selectedVideos.map((video, index) => (
                    <div
                      key={index}
                      className="each-selected-video-for-priview">
                      <div className="each-selected-video-container">
                        <video
                          controls
                          // src={URL.createObjectURL(video)}
                          src={video}
                          className="each-selected-vidoe">
                          <source
                            // src={URL.createObjectURL(video)}
                            src={video}
                          />
                        </video>
                        <Close
                          className="selected-image-remove-button"
                          fontSize="small"
                          onClick={() => handleRemoveVideo(video, index)}
                        />
                      </div>
                      <div className="">
                        <p className="mb-1">Add title</p>
                        <input
                          maxLength={55}
                          type="text"
                          defaultValue="Video title"
                          name={index}
                          className="border  h- rounded mb-3 p-"
                          // onChange={(e) => setImgTitle(e.target.value)}
                          onChange={(e) => handleVideoInputValues(e)}
                          style={{
                            backgroundColor: "#272d47",
                            color: "white",
                            width: "100%",
                            height: "35px",
                          }}
                          required
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
            <label className="mb-1">
              {videoLoader ? "Uploading Videos..." : "Upload Videos"}
            </label>
            <input
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              multiple
              id="videoInput"
              // name="image"
              // onChange={onChangeVideo}
              onChange={(e) => handleVideoSelection(e)}
              className="border w-100 rounded mb-3"
              style={{ backgroundColor: "#272d47", color: "white" }}
              // required
            />
            <>
              {selectedPDFs.length > 0 && (
                <div className="selected-video-container">
                  {selectedPDFs.map((pdf, index) => (
                    <div
                      key={index}
                      className="each-selected-video-for-priview">
                      <div className="each-selected-video-container">
                        <embed
                          // src={URL.createObjectURL(pdf)}
                          src={pdf}
                          type="application/pdf"
                          frameBorder="0"
                          className="each-selected-image"
                          scrolling="auto"
                          // height="100%"
                          // width="100%"
                        ></embed>

                        <Close
                          className="selected-image-remove-button"
                          fontSize="small"
                          onClick={() => handleRemovePDF(pdf, index)}
                        />
                      </div>
                      <div className="">
                        <p className="mb-1">Add title</p>
                        <input
                          maxLength={55}
                          type="text"
                          defaultValue="File title"
                          name={index}
                          className="border  h- rounded mb-3 p-"
                          // onChange={(e) => setImgTitle(e.target.value)}
                          onChange={(e) => handlePDFInputValues(e)}
                          style={{
                            backgroundColor: "#272d47",
                            color: "white",
                            width: "100%",
                            height: "35px",
                          }}
                          required
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
            <label className="mb-1">
              {pdfLoader ? "Uploading Pdf..." : "Upload Pdfs"}
            </label>
            <input
              id="pdfInput"
              type="file"
              // accept="application/pdf,application/vnd.ms-excel"
              // accept="application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf,image/*"
              accept="application/pdf,
      application/vnd.ms-powerpoint,
      application/msword,
      application/vnd.openxmlformats-officedocument.presentationml.slideshow,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.openxmlformats-officedocument.presentationml.presentation,
      .pps"
              multiple
              // name="image"
              // onChange={onChangePDF}
              onChange={(e) => handlePDFSelection(e)}
              className="border w-100 rounded mb-3"
              style={{ backgroundColor: "#272d47", color: "white" }}
              // required
            />
            {/* Course Certification START */}
            <h5 className="text-warning text-start text-uppercase pt-30 mb-2 ps-0">
              Course Certification
            </h5>
            <label className="mb-1 mt-3">MCQs</label>
            <div className="course-name-input-container">
              <textarea
                // maxLength={55}
                type="text"
                id="mcqsQuestion"
                name="mcqsQuestion"
                className="border w-100 rounded p-2"
                onChange={(e) => setQuestion(e.target.value)}
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
              />
              {/* {charLimit?.length > 0 &&
                    < span className={`char-limit-position ${charLimit.length == 55 && "text-danger"}`}>{charLimit.length}/55</span>} */}
            </div>
            <div className="d-flex justify-content-end">
              <p className="text-end mb-0">
                Added : {mcqArr?.length > 0 ? mcqArr?.length : "0"}
              </p>
            </div>
            {/* <label className="mb-1">Response type</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <Form.Select
                aria-label="Default select example"
                required
                name="answer"
                className=""
                style={{ backgroundColor: "#272d47", color: "white" }}>
                <option>Yes / No</option>
                {/* <option value="Yes">Yes</option>
                    <option value="No">No</option> 
              </Form.Select>
            </InputGroup> */}
            <label className="mb-1">Ideal answer 1</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer}
                onChange={(e) => {
                  setAnswer(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>
            <label className="mb-1">Ideal answer 2</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer1}
                onChange={(e) => {
                  setAnswer1(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>{" "}
            <label className="mb-1">Ideal answer 3</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer2}
                onChange={(e) => {
                  setAnswer2(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>{" "}
            <label className="mb-1">Ideal answer 4</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer3}
                onChange={(e) => {
                  setAnswer3(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>
            <label className="mb-1">Correct Ans</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <Form.Select
                aria-label="Default select example"
                required
                onChange={(e) => setAnswer4(e.target.value)}
                value={answer4}
                name="answer"
                className=""
                style={{ backgroundColor: "#272d47", color: "white" }}>
                <option value={1}>Ideal answer 1</option>
                <option value={2}>Ideal answer 2</option>
                <option value={3}>Ideal answer 3</option>
                <option value={4}>Ideal answer 4</option>
              </Form.Select>
            </InputGroup>
            <button
              className="btn btn-primary btn-sm mt-2"
              type="button"
              style={{ padding: "5px 5px", fontSize: "10px" }}
              onClick={() => {
                setShowMCQModal(true);
              }}>
              <i class="fas fa-eye me-1"></i>
              View
            </button>
            <div className="d-flex justify-content-center text-center pt-4">
              <p
                className="btn btn-success btn-sm bg-success"
                style={{ padding: "5px 25px" }}
                onClick={handleAddMcq}>
                Add
              </p>
            </div>
            <div className="mx-auto text-center my-3">
              <Button
                type="button"
                style={{
                  backgroundColor: "#dc3545",
                  width: "125px",
                  fontSize: "13px",
                }}
                className="border-0 text-uppercase modal-btn ms-3 pt-3 text-center pb-3  me-3 extraCare"
                onClick={() => navigate(-1)}>
                CANCEL
              </Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: "blueviolet",
                  width: "125px",
                  fontSize: "13px",
                }}
                className="border-0 pt-3 pb-3  text-uppercase modal-btn ms-3 me-3 extraCare">
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>

      {/* MCQ MODAL */}
      <MCQModal
        show={showMCQModal}
        mcqArr={mcqArr}
        setMcqArr={setMcqArr}
        setShowMCQModal={setShowMCQModal}
        onHide={() => setShowMCQModal(false)}
      />
    </div>
  );
};

export default AddCourse;
