import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import Newsletter from "../components/Common/Newsletter";
import ScrollToTop from "../components/Common/ScrollTop";
import OffWrap from "../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../components/Common/Breadcumb";
import SearchModal from "../components/Layout/Header/SearchModal";

// Image
import favIcon from "../assets/img/fav-orange.png";
import Logo from "../assets/img/logo/dark-logo.png";
import footerLogo from "../assets/img/logo/lite-logo.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import bannerbg from "../assets/img/breadcrumbs/inner7.jpg";

import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { UserContext } from "../Context/UserContext";

const LoginResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);
  const changeIcon = showPassword === true ? false : true;
  const [password, setpassword] = useState("");
  console.log("tokennnns : " + token);
  const resetPassword = (e) => {
    e.preventDefault();
    const newPassword = password;

    // axios.post("https://backend.studyswiftly.com/api/user/reset-password", { newPassword }, {
    axios
      .post(
        "https://backend.studyswiftly.com/api/v1/user/reset-password",
        { newPassword },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // alert(res.data.message);
          // swal({
          //     title: "Success",
          //     text: `${res.data.message}`,
          //     icon: "success",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });

          navigate("/sign-in");
        }
      })
      .catch((err) => {
        // alert(err.response.data.message);
        // swal({
        //     title: "Attention",
        //     text: `${err.response.data.message}`,
        //     icon: "warning",
        //     button: "OK!",
        //     className: "modal_class_success",
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="others"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Reset-Password"
        pageName="Reset-Password"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-End */}

      {/* Login Part Start */}
      <div className="rs-login pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
          <div className="noticed">
            <div className="main-part">
              <div className="method-account">
                <h2 className="title mb-30">Reset-Password</h2>
                <form onSubmit={resetPassword}>
                  <div className="d-flex">
                    <input
                      onChange={(e) => setpassword(e.target.value)}
                      type={showPassword ? "password" : "text"}
                      name="text"
                      placeholder="Password"
                      required
                    />
                    <span
                      style={{
                        cursor: "pointer",
                        marginLeft: "-40px",
                        zIndex: "10",
                        marginTop: "8px",
                      }}
                      onClick={() => {
                        setShowPassword(changeIcon);
                      }}>
                      {changeIcon ? (
                        <VisibilityIcon className="text-dark" />
                      ) : (
                        <VisibilityOffIcon className="text-dark" />
                      )}
                    </span>
                  </div>

                  <button
                    // style={{textShadow: '2px 2px #FF0000'}}
                    type="submit"
                    className="readon submit-btn">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login Part End */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default LoginResetPassword;
