export const dummyText=[
    "Invest in your future: pay for knowledge, reap the rewards.",
"	Unlock your potential: pay for education, unlock opportunities.",
	"Commit to excellence: pay for learning, excel in life.",
	"Seize the day: pay for wisdom, seize the opportunities.",
"Transform your tomorrow: pay for education, transform your future.",
	"Invest in yourself: pay for knowledge, invest in your success.",
	"Empower your journey: pay for education, empower yourself.",
"	Shape your destiny: pay for learning, shape your future.",
	'Secure your success: pay for knowledge, secure your future.',
	"Elevate your life: pay for education, elevate your prospects.",
	'Forge your path: pay for learning, forge your own success.',
	'Craft your future: pay for knowledge, craft your destiny.',
	'Build your dreams: pay for education, build your future.',
	'Sow the seeds of success: pay for learning, reap the rewards.',
	'Invest in your aspirations: pay for knowledge, realize your dreams.',
	'Ignite your potential: pay for education, ignite your future.',
	'Fuel your ambition: pay for learning, fuel your success.',
	'Shape your narrative: pay for knowledge, shape your story.',
	'Unlock your brilliance: pay for education, unlock your potential.',
	'Foster your growth: pay for learning, foster your success.',
	'Harness your talents: pay for knowledge, harness your potential.',
	'Illuminate your path: pay for education, illuminate your future.',
	'Pave the way to success: pay for learning, pave your future.',
	'Invest in your journey: pay for knowledge, invest in your future.',
	'Enrich your life: pay for education, enrich your future.',
	'Design your destiny: pay for learning, design your future.',
	'Forge ahead with knowledge: pay for knowledge, forge your success.',
	'Empower your potential: pay for education, empower your future.',
	'Shape your story: pay for learning, shape your success.',
	'Fuel your ambition: pay for knowledge, fuel your success.',
	'Invest in your brilliance: pay for education, invest in your future.',
	'Cultivate your success: pay for learning, cultivate your future.',
	'Discover your greatness: pay for knowledge, discover your future.',
	'Expand your horizons: pay for education, expand your future.',
'Build your empire: pay for learning, build your success.',
	'Champion your dreams: pay for knowledge, champion your future.',
	'Master your destiny: pay for education, master your future.',
	'Invest in your potential: pay for learning, invest in your success.',
	'Elevate your journey: pay for knowledge, elevate your future.',
	'Shape your legacy: pay for education, shape your future.',
	'Embrace your journey: pay for learning, embrace your success.',
	'Foster your brilliance: pay for knowledge, foster your future.',
	'Sculpt your path: pay for education, sculpt your future.',
	'Empower your potential: pay for learning, empower your success.',
	'Forge your legacy: pay for knowledge, forge your future.',
	'Invest in your journey: pay for education, invest in your success.',
	'Craft your success story: pay for learning, craft your future.',
'Fuel your aspirations: pay for knowledge, fuel your future.',
	'Empower your journey: pay for education, empower your success.',
	'Shape your destiny: pay for learning, shape your future.',
	'Invest in your aspirations: pay for knowledge, invest in your future.',
	'Ignite your potential: pay for education, ignite your future.',
	'Fuel your ambition: pay for learning, fuel your success.',
	'Shape your narrative: pay for knowledge, shape your story.',
	'Unlock your brilliance: pay for education, unlock your potential.',
	'Forge ahead with knowledge: pay for learning, forge your success.',
'Empower your potential: pay for knowledge, empower your future.',
	'Shape your story: pay for education, shape your success.',
	'Fuel your ambition: pay for learning, fuel your success.',
	'Invest in your brilliance: pay for knowledge, invest in your future.',
	'Cultivate your success: pay for education, cultivate your future.',
	'Discover your greatness: pay for learning, discover your future.',
	'Expand your horizons: pay for knowledge, expand your future.',
	'Build your empire: pay for education, build your success.',
	'Champion your dreams: pay for learning, champion your future.',
	'Master your destiny: pay for knowledge, master your future.',
	'Invest in your potential: pay for education, invest in your success.',
	'Elevate your journey: pay for learning, elevate your future.',
'	Shape your legacy: pay for knowledge, shape your future.',
	'Embrace your journey: pay for education, embrace your success.',
	'Foster your brilliance: pay for learning, foster your future.',
	'Sculpt your path: pay for knowledge, sculpt your future.',
	'Empower your potential: pay for education, empower your success.',
	'Forge your legacy: pay for learning, forge your future.',
	'Invest in your journey: pay for knowledge, invest in your success.',
	'Craft your success story: pay for education, craft your future.',
	'Fuel your aspirations: pay for learning, fuel your future.',
	'Empower your journey: pay for knowledge, empower your success.',
	'Shape your destiny: pay for education, shape your future.',
	'Invest in your aspirations: pay for learning, invest in your future.',
	'Ignite your potential: pay for knowledge, ignite your future.',
	'Fuel your ambition: pay for education, fuel your success.',
	'Shape your narrative: pay for learning, shape your story.',
	'Unlock your brilliance: pay for knowledge, unlock your potential.',
'	Forge ahead with knowledge: pay for education, forge your success.',
	'Empower your potential: pay for learning, empower your future.',
	'Shape your story: pay for knowledge, shape your success.',
	'Fuel your ambition: pay for education, fuel your success.',
	'Invest in your brilliance: pay for learning, invest in your future.',
	'Cultivate your success: pay for education, cultivate your future.',
	'Discover your greatness: pay for learning, discover your future.',
	'Expand your horizons: pay for knowledge, expand your future.',
	'Build your empire: pay for education, build your success.',
	'Champion your dreams: pay for learning, champion your future.',
	'Master your destiny: pay for knowledge, master your future.',
	'Invest in your potential: pay for education, invest in your success.',
	'Elevate your journey: pay for learning, elevate your future.'
]