import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RiAdminFill } from "react-icons/ri";

import axios from "axios";
import swal from "sweetalert";
import { MdClose } from "react-icons/md";
import { Add } from "@mui/icons-material";

const AddCategoryModal = (props) => {
  const { setIsLoadingAdmin, setModalShowNewAdmin, refetch, setRefetch } =
    props;
  const [value, setValue] = useState();
  // if (isLoadingAdmin) {
  //     return <Loader></Loader>
  // }
  const subNewAdmin = async (event) => {
    event.preventDefault();

    setIsLoadingAdmin(true);
    const name = event.target.name.value;
    console.log(name, "name");

    const formDataAddAdmin = new FormData();
    formDataAddAdmin.append("name", name);
    formDataAddAdmin.append("category_name", name);

    await axios
      .post("https://backend.studyswiftly.com/api/v1/category/category", {
        name,
        category_name: name,
      })
      .then((res) => {
        if (res.status === 200) {
          // setAllAdmin(res.data.newAdmin);
          setIsLoadingAdmin(false);
          setModalShowNewAdmin(false);
          setRefetch(!refetch);
          event.target.reset();
          swal({
            title: "Success",
            text: `Category Added`,
            icon: "success",
            button: "OK!",
            className: "modal_class_success",
          });
          // alert(res.data.message);
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        // console.log(error);
        setIsLoadingAdmin(false);
        swal({
          title: "Attention",
          text: `${error.response.data.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
    // setIsLoadingAdmin(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header style={{ backgroundColor: "#272d47", color: "white" }}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-5 text-light">
          <Add className="fs-4"></Add> Add Category
        </Modal.Title>
        <MdClose
          onClick={props.onHide}
          color="#fff"
          size={30}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#272d47", color: "white" }}>
        <div>
          <div>
            <form onSubmit={subNewAdmin}>
              <div className="row addAdminDiv">
                <div className="col-lg-12">
                  <p className="mb-1">Enter Category Name</p>
                  <input
                    className="form-control"
                    placeholder="Enter Category Name"
                    type="text"
                    name="name"
                    required
                  />
                </div>
                <Modal.Footer className="mt-5">
                  <button
                    type="button"
                    className="adminBtnAdd11 text-uppercase"
                    onClick={props.onHide}>
                    Cancel
                  </button>
                  <button type="submit" className="adminBtnAdd text-uppercase">
                    Add
                  </button>
                </Modal.Footer>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategoryModal;
