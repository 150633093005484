import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const ViewMessage = (props) => {
  const { modalData } = props;
  // console.log('dataaaaaaaaaaaaaa', modalData);
  const [selectedImage, setSelectedImage] = useState();
  const [studentProfileUrl, setStudentProfileUrl] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentId, setStudentId] = useState("");
  const [studentWalletAddress, setStudentWalletAddress] = useState("");

  useEffect(() => {
    if (!selectedImage) {
      setStudentProfileUrl(
        modalData?.profilePicture
          ? modalData?.profilePicture
          : "https://i.ibb.co/JtVwzXs/default.jpg"
      );
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setStudentProfileUrl(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage, modalData]);

  const changePhoto = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    console.log("inside image");
    setSelectedImage(e.target.files[0]);
  };

  const editStudentInfo = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}>
      <Modal.Header closeButton className="modalHeader">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-4 text-light">
          <span className="text-white">Message Details</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: "rgb(177, 185, 195)" }}
        className="handleModalBody">
        <div>
          <div className="d-flex align-items-center">
            <h6 className="text-white me-2">Name: </h6>
            <p> {modalData?.name}</p>
          </div>
          <div className="d-flex align-items-center">
            <h6 className="text-white me-2">Email: </h6>
            <p> {modalData?.email}</p>
          </div>
          <div className="d-flex align-items-center">
            <h6 className="text-white me-2">Phone: </h6>
            <p> {modalData?.phone}</p>
          </div>
          <div className="d-flex align-items-baseline">
            <h6 className="text-white me-2">Message: </h6>
            <p> {modalData?.message}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewMessage;
