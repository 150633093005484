import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [allAdmin, setAllAdmin] = useState([]);
  const [students, setStudents] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [affiliate, setAffiliate] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [payments, setPayments] = useState();
  const [enquiries, setEnquiries] = useState();
  const [scholarship, setScholarship] = useState([]);
  console.log(registrations, "REGISTRATIONS DATA");
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("https://backend.studyswiftly.com/api/admin/").then((res) => {
      setAllAdmin(res.data);
      console.log(res.data);
    });
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.studyswiftly.com/api/certificate")
      .then((res) => {
        setCertificate(res.data?.result);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`https://backend.studyswiftly.com/api/scholarship`)
      .then((res) => {
        setScholarship(res.data.result);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.studyswiftly.com/api/contact/getdata")
      .then((res) => {
        setEnquiries(res.data?.data);
      });
  }, []);
  useEffect(() => {
    axios.get("https://backend.studyswiftly.com/api/wallet/all").then((res) => {
      setStudents(res.data);
      console.log(res.data.result);
    });
  }, []);
  useEffect(() => {
    axios.get("https://backend.studyswiftly.com/api/subscribe").then((res) => {
      setSubscribers(res.data.result);
      console.log(res.data.result);
    });
  }, []);
  // useEffect(() => {
  //   axios.get('https://backend.studyswiftly.com/api/course/active')
  //     .then(res => {
  //       setAffiliate(res.data.result);
  //       console.log(res.data.result)
  //     })
  // }, [])
  useEffect(() => {
    axios
      .get("https://backend.studyswiftly.com/api/v1/affilate")
      .then((res) => {
        setAffiliate(res.data.Categories);
        console.log(res.data.Categories, "affiliate");
      });
  }, []);

  const handleClickOpenAdmin = () => {
    navigate("/admin/admins");
  };
  const handleClickTitlenot = () => {
    navigate("/admin/students");
  };
  const handleClicktitleSubscribers = () => {
    navigate("/admin/email-subscribers");
  };
  const handleClicktitleAffiliate = () => {
    navigate("/admin/affiliate");
  };
  const handleClicktitlePayments = () => {
    navigate("/admin/payments-received");
  };

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/mint/get/pay-busd")
        .then((res) => {
          setPaymentData(res.data.result);
        });
    };
    data();
  }, []);

  useEffect(() => {
    axios
      .get("https://backend.studyswiftly.com/api/course/active")
      .then((res) => {
        setCourses(res.data.result);
      });
  }, []);

  useEffect(() => {
    axios.get("https://backend.studyswiftly.com/api/mint").then((res) => {
      setRegistrations(res.data);
    });
  }, []);

  useEffect(() => {
    axios
      // .get("https://backend.studyswiftly.com/api/mint/get/pay-busd")
      .get("https://backend.studyswiftly.com/api/v1/affilate")
      .then((res) => {
        setPayments(res.data.Categories);
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get("https://backend.studyswiftly.com/api/mint")
  //     .then((res) => {
  //       setRegistrations(res.data.result);
  //     });
  // }, []);

  // Total
  let arr = [];
  paymentData.forEach((d) => {
    const amount = d?.amount;
    arr.push(Number(amount));
    console.log(amount);
  });

  let result = 0;
  for (let i = 0; i < arr.length; i++) {
    result += arr[i];
  }

  // Current exchange rate as of February 21, 2023
  const exchangeRate = 0.7426;

  // Amount to convert from SGD to USD

  // Calculate the equivalent amount in USD
  const usdAmount = result * exchangeRate;

  return (
    <div>
      <div className="container titlesregistered">
        <h5 className="text-white text-start">DASHBOARD</h5>
        <Row xs={1} md={2} lg={3} xl={4} className="g-4">
          <Col onClick={handleClickOpenAdmin}>
            <Card
              className="cardDash text-light"
              style={{ backgroundColor: "#a169e1" }}
            >
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p>Admins</p>
                  <h2 className="text-start text-light">{allAdmin?.length}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={handleClickTitlenot}>
            <Card className="cardDash " style={{ backgroundColor: "#ff5b5b" }}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Students</p>
                  <h2 className="text-start text-white">{students?.length}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={() => navigate("/admin/courses")}>
            <Card className="cardDash " style={{ backgroundColor: "#74512D" }}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Courses</p>
                  <h2 className="text-start text-white">{courses?.length}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col onClick={() => navigate("/admin/course-enrollments")}>
            <Card className="cardDash " style={{backgroundColor:"#6c8a8f"}}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Course Enrollments</p>
                  <h2 className="text-start text-white">
                    {registrations?.length}
                  </h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col onClick={() => navigate("/admin/certificates-requested")}>
            <Card className="cardDash" style={{backgroundColor:"#bb3eb1"}}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Certificates</p>
                  <h2 className="text-start text-white">{certificate?.length}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}

          {/* <Col onClick={() => navigate("/admin/scholarships")}>
            <Card className="cardDash " style={{backgroundColor:"#543310"}}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Scholarships</p>
                  <h2 className="text-start text-white">
                    {scholarship?.length}
                  </h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
          <Col onClick={handleClicktitleSubscribers}>
            <Card className="cardDash " style={{ backgroundColor: "#977e60" }}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Email Subscribers</p>
                  <h2 className="text-start text-white">
                    {subscribers?.length}
                  </h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col onClick={() => navigate("/admin/enquiries")}>
            <Card className="cardDash " style={{backgroundColor:"#059212"}}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Enquiries</p>
                  <h2 className="text-start text-white">{enquiries?.length}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col onClick={() => navigate("/admin/payments-received")}>
            <Card className="cardDash " style={{backgroundColor:"#609765"}}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Payments Received</p>
                  <h2 className="text-start text-white">{payments?.length}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col onClick={handleClicktitleAffiliate}>
            <Card className="cardDash " style={{backgroundColor:"#606697"}}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className="dashboardTxt">
                  <p className="text-white">Affiliates Payments</p>
                  <h2 className="text-start text-white">{affiliate?.length}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>

        {/* <Row  xs={1} md={2} lg={3} xl={4} className="g-4 mt-2">
        <Col onClick={handleClicktitlePayments}>
            <Card className='cardDash text-light'>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <Card.Text className='dashboardTxt'>
                  <p>Payments</p>
                  <h2 className='text-start text-light'>{0}</h2>
                </Card.Text>
                <div className="iconDas"> */}
        {/* <p className='text-white coinsIcon'>
                    <i class="fas fa-users"></i>
                  </p> */}
        {/* <PaymentIcon className="das-icons" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default AdminDashboard;
