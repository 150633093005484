import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import Newsletter from "../components/Common/Newsletter";
import ScrollToTop from "../components/Common/ScrollTop";
import OffWrap from "../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../components/Common/Breadcumb";
import SearchModal from "../components/Layout/Header/SearchModal";

// Image
import favIcon from "../assets/img/fav-orange.png";
import Logo from "../assets/img/logo/dark-logo.png";
import footerLogo from "../assets/img/logo/lite-logo.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import bannerbg from "../assets/img/breadcrumbs/2.jpg";
import axios from "axios";
import swal from "sweetalert";
import { useTimer } from "react-timer-hook";
import EmailVerificationModal from "./EmailVerificationModal";
import { UserContext } from "../Context/UserContext";
import { BlockchainContext } from "../Context/BlockchainContext";
import Swal from "sweetalert2";
const Register = () => {
  const [openEmail, setOpenEmail] = useState(false);
  const [otpVerify, setOtpVerify] = useState();
  const [isError, setError] = useState(false);
  const [disableAfterActivation, setDisableAfterActivation] = useState(false);
  const [email, setEmail] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const navigate = useNavigate();
  const { handleRegister, data } = useContext(UserContext);
  const [forEnable, setForEnable] = useState(false);

  const [showPassword, setShowPassword] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const changeIcon = showPassword === true ? false : true;
  const changeIcon2 = showPassword2 === true ? false : true;
  const { user, openWalletModal } = useContext(BlockchainContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log("dataa" + data.email);

  useEffect(() => {
    if (data.email) {
      navigate(-1, { replace: true });
    }
  }, [data, navigate]);

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 180);

  // Re-send OTP functionality
  const { seconds, minutes, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const handleVerifyEmail = async (e) => {
    // check if email is valid
    e.preventDefault();
    setDisableAfterActivation(true);
    if (email.length > 0 && email.includes("@" && ".")) {
      // setLoading(true);
      setEmailVerify(true);
      console.log(email);
      await axios
        .post("https://backend.studyswiftly.com/api/emailverify", { email })
        .then((res) => {
          console.log(res, "--data results gotten~");
          if (res.status === 200) {
            console.log(res);
            restarting(180);
            // swal({
            //     text: res.data.message,
            //     icon: "success",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });

            console.log(res);
            setOtpVerify(res.data.otp);

            setTimeout(() => {
              setDisableAfterActivation(false);
            }, 120000);
          }
          setOpenEmail(true);
        })
        .catch((err) => {
          // alert(err.response.data.message);
          console.log(err);
          setEmailVerify(false);
          // swal({
          //     title: "Attention",
          //     text: err.response.data.message,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      // swal({
      //     title: "Attention",
      //     text: "Please enter a valid email address",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Please enter a valid email address</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const handleRegisterUser = (event) => {
    event.preventDefault();

    const password = event.target.password.value;
    const Cpassword = event.target.Cpassword.value;
    if (!forEnable) {
      // return swal({
      //     title: "Attention",
      //     text: "Please verify your email!",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Please verify your email!</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
    if (password == Cpassword) {
      const data = {
        email: email,
        password: password,
      };
      handleRegister(data);
    } else {
      // return swal({
      //     title: "Attention",
      //     text: "Confirm Password not match!",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Confirm Password not match!</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="others"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Sign-Up"
        pageName="Sign-Up"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-End */}

      {/* Register Start */}
      <div className="register-section pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
          {!user?.walletAddress && (
            <div className=" d-flex align-items-center justify-content-center">
              <div className="menuText loginbuttonmobile">
                <button
                  style={{ fontSize: "13px" }}
                  className="headerButtonW"
                  role="button"
                  onClick={openWalletModal}>
                  <img
                    className="me-2"
                    width="18px"
                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                    alt=""
                  />
                  LOGIN WITH WALLET
                </button>
              </div>
            </div>
          )}
          <div className="register-box">
            <div className="sec-title text-center mb-30">
              <h2 className="title mb-10">Sign-Up</h2>
            </div>
            <div className="styled-form">
              <div id="form-messages"></div>
              <form onSubmit={handleRegisterUser}>
                <div className="row clearfix">
                  <div className="form-group col-lg-12">
                    <div className="d-flex">
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailVerify(false);
                        }}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Address "
                        required
                      />
                      <button
                        onClick={handleVerifyEmail}
                        disabled={
                          email.length === 0 || disableAfterActivation
                            ? true
                            : false
                        }
                        className="border-0 px-4"
                        style={{
                          color: "#ffffff",
                          backgroundColor: "#FF5421",
                        }}>
                        Verify
                      </button>
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="d-flex align-items-center">
                      <input
                        type={showPassword ? "password" : "text"}
                        id="puser"
                        name="password"
                        placeholder="Password"
                        required
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          marginLeft: "-40px",
                          zIndex: "10",
                        }}
                        onClick={() => {
                          setShowPassword(changeIcon);
                        }}>
                        {changeIcon ? (
                          <VisibilityIcon className="text-dark" />
                        ) : (
                          <VisibilityOffIcon className="text-dark" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="d-flex align-items-center">
                      <input
                        type={showPassword2 ? "password" : "text"}
                        id="Confirm"
                        name="Cpassword"
                        placeholder="Confirm Password"
                        required
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          marginLeft: "-40px",
                          zIndex: "10",
                        }}
                        onClick={() => {
                          setShowPassword2(changeIcon2);
                        }}>
                        {changeIcon2 ? (
                          <VisibilityIcon className="text-dark" />
                        ) : (
                          <VisibilityOffIcon className="text-dark" />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className=" col-lg-12 col-md-12 col-sm-12 text-center">
                    <button type="submit" className="readon register-btn">
                      <span className="txt">Sign Up</span>
                    </button>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-sm-12 mt-2">
                    <div className="users">
                      Already have an account?{" "}
                      <Link to="/sign-in">Sign In</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <div className="menuText loginbuttonmobile">
              <button
                style={{ fontSize: "13px" }}
                className="headerButtonWGoogle"
                role="button"
                onClick={openWalletModal}>
                <img
                  className="me-2"
                  width="18px"
                  src="https://i.ibb.co/yVb01xv/Google-G-Logo-svg.webp"
                  alt=""
                />
                SIGN-UP WITH GOOGLE
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Register End */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />

      <EmailVerificationModal
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        seconds={seconds}
        open={openEmail}
        setOpenEmail={setOpenEmail}
        otpVerify={otpVerify}
        setForEnable={setForEnable}
        setError={setError}
      />
    </React.Fragment>
  );
};

export default Register;
