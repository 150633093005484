// import MailchimpSubscribe from "react-mailchimp-subscribe"

// const url = "https://rstheme.us16.list-manage.com/subscribe?u=b07284c0016b6ff3084de6551&id=292fe5312b";

// // simplest form (only email)
// const SubscribeForm = () => <MailchimpSubscribe url={url} />

// export default SubscribeForm;


import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

const SubscribeForms = () => {
    const [inputEmail, setInputEmail] = useState("");


    const newDate = new Date()
        .toISOString()
        .replace(/T.*/, "")
        .split("-")
        .reverse()
        .join("-");

    console.log(newDate)


    const handleSubscribeNewsLetter = async () => {



        if (inputEmail != "") {
            await axios
                .post(`https://backend.studyswiftly.com/api/subscribe`, {
                    email: inputEmail,
                    date: newDate
                })
                .then((res) => {
                    if (res.status === 200) {
                        console.log(res)
                        document.getElementById("input-field").value = "";

                        // swal({
                        //     text: res.data.message,
                        //     icon: "success",
                        //     button: "OK!",
                        //     className: "modal_class_success",
                        // });

                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;
                        
                        Swal.fire(
                            {
                                html: wrapper,
                                icon: "success",
                                customClass: "modal_class_success",
                            }
                        )



                        //   setOpenMobileModal(true);

                    }
                })
                .catch((err) => {
                    console.log(err)
                    // alert(err.response.data.message);
                    // swal({
                    //     title: "Attention",
                    //     text: `${err}`,
                    //     icon: "warning",
                    //     button: "OK!",
                    //     className: "modal_class_success",
                    // });

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-white'>${err}</p>`;
                    
                    Swal.fire(
                        {
                            html: wrapper,
                            icon: "warning",
                            customClass: "modal_class_success",
                        }
                )
            
                });
        } else {
            console.log("enter email")
        }
    };



    return (
        <div>
            <InputGroup className="mb-3">
                <Form.Control
                style={inputEmail ? { textTransform: 'lowercase' } : { textTransform: 'capitalize'}}
                    id='input-field'
                    onChange={(e) => setInputEmail(e.target.value)}
                    placeholder="Your Email"
                    aria-label="Your Email"
                    aria-describedby="basic-addon2"
                    required={true}
                />
                <Button type='submit' variant="outline-secondary" id="button-addon2" className='otp-bg-color text-light' onClick={handleSubscribeNewsLetter}>
                    SUBMIT
                </Button>
            </InputGroup>
        </div>
    );
};

export default SubscribeForms;