import React, { useEffect, useState } from "react";
import Team from "./TeamSection";
import Blog from "./BlogSection";
import AboutVideo from "./VideoSection";
import AboutCounter from "./CounterSection";
import Testimonial from "./TestimonialSection";
import ScrollToTop from "../../components/Common/ScrollTop";
import axios from "axios";
import { Link } from "react-router-dom";

const AboutMain = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://backend.dsl.sg/api/v1/page/about")
      .then((res) => {
        setData(res.data.page.content);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <div className="container my-5">
        {/* <h2 className='mt-5'>About</h2> */}
        <div>
          <p>
            <span style={{ color: "#ff5421" }}>Studyswiftly.com</span> is a
            one-stop e-learning portal. <br />
            {/* <br /> */}
            {/* <span style={{ color: "#ff5421" }}>Studyswiftly.com</span> creates
            multiple opportunities in the Blockchain World. We provide best
            learning experience.{" "} */}
          </p>

       
          <p>
            Students completing our courses will be awarded Certificate NFTs.
          </p>
          <p>
          Our AI evaluates a student before awarding certification through online quizzes. These quizzes are designed to test the student's knowledge, critical thinking skills, problem-solving abilities, and understanding of the subject matter. 
          </p>
          <p>
          Our AI algorithms analyzes the student's performance based on predefined criteria and provides feedback or determine whether the student meets the requirements for certification.
          </p>
          <p>
            <span style={{ color: "#ff5421" }}>Studyswiftly.com</span> was
            developed by DS legend Pte. Ltd.
          </p>

          {/* <p>
            DS Legends Pte. Ltd. is a member of Singapore Fintech Association. 
            and Certified Fintech Company.
          </p> */}
          <p className="fw-bold text-decoration-underline">
            Get your international diploma in 10 weeks!{" "}
            {/* <Link className="text-primary" to="/login">
              joining us now.
            </Link> */}
          </p>
          <p className="fw-bold text-decoration-underline">
            Get your international bachelor degree in 52 weeks!
          </p>
          {/* <p>The <span style={{ color: "#ff5421" }}>Studyswiftly.com</span> website was developed by DS legend and Findexx in partnership to provide the latest education in Blockchain and Web3 technology. </p>
          <p>As a member of the Singapore Fintech Association & with the support of experienced blockchain developers, we uphold the highest standard in all our courses.</p>
          <p>Be part of the leading blockchain community by <Link className='text-primary' to="/join-us">joining us now.</Link></p> */}
        </div>
      </div>

      {/* dangerouslySetInnerHTML={{ __html: data }}
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    axios
      .get("https://backend.dsl.sg/api/v1/page/about")
      .then((res) => {
        setData(res.data.page.content);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <div className="container my-5">
        <h2 className='mt-5'>About</h2>
        <div>
          <p>Welcome to <span style={{ color: "#ff5421" }}>Studyswiftly.com</span> a one stop e-learning portal for all your need to knows in the Blockchain world. By blending leading-edge technology and support with nationally recognized bodies, <span style={{ color: "#ff5421" }}>Studyswiftly.com</span> offers the best possible experience. The <span style={{ color: "#ff5421" }}>Studyswiftly.com</span> website was developed by Ds legend and Findexx in partnership to provide the latest education in Blockchain and Web3 technology. </p>
          <p>As a member of the Singapore Fintech Association & with the support of experienced blockchain developers, we uphold the highest standard in all our courses.</p>
          <p>Be part of the leading blockchain community by <Link className='text-primary' onClick={() => {window.scrollTo(0, 0)}} to="/join-us">joining us now.</Link></p>
        </div>
      </div>

      {/* dangerouslySetInnerHTML={{ __html: data }}
            className="text-dark text_left click_here about_content" */}
      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default AboutMain;
