import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import DeleteIcon from '@mui/icons-material/Delete';

const Certificate = ({ index, detail, handleDelete,mintCerti,handleUpdate,mintCertificate,openWalletModal,user  }) => {
  console.log(detail);
  const [checkDevice, setCheckDevice] = useState("");
  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);
  return (
    <tr className="tableRow" key={index}>
      <td className="text-start text-nowrap">{detail?.name}</td>
      <td className="text-start text-nowrap">{detail?.coursename}</td>
      {/* <td className='text-start text-lowercase'>{detail?.email}</td> */}
      {/* <td className='text-start adminHidden'>{detail?.method}</td> */}
      <td className="text-start adminHidden text-nowrap">{detail?.memberId}</td>
      <td className="text-start adminHidden text-nowrap">{detail?.date}</td>
      <td className="">
        <div className="d-flex">
          {/* <Link to={`/admin/add-certificate/${detail?._id}`}><button className="editBtn"><i className="fas fa-plus"></i></button></Link> */}

          <Link to={`/admin/edit-certificate/${detail?._id}`}>
            <button className="editBtn text-nowrap">
              <i className="fas fa-eye"></i>
            </button>
          </Link>
          {/* <Link to={`/admin/edit-certificate/${detail?._id}`}><button className="editBtn"><i className="fas fa-edit"></i></button></Link> */}

          <button
            onClick={() => handleDelete(detail?._id)}
            className="deleteBtn"
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
      </td>
      <td className="">
        <div className="d-flex">
          {!user?.walletAddress ?
           <div>
            {checkDevice === "mobileWithoutApp" && (
              <a
                href="https://metamask.app.link/dapp/http://studyswiftly.com"
                target={"_blank"}
                className="text-decoration-none">
                <button
                 style={{ fontSize: '12px' }}
                  className="headerButtonW m-0 text-nowrap">
                  MINT
                </button>
              </a>
            )}
            {checkDevice === "mobileWithApp" && (
              <button
              style={{ fontSize: '12px' }}
                className="headerButtonW m-0 text-nowrap"
                onClick={() => openWalletModal()}>
                MINT
              </button>
            )}
            {checkDevice === "pcWithExtention" && (
              <button
             
                className="headerButtonW m-0 text-nowrap" style={{ fontSize: '12px' }}
                onClick={() => openWalletModal()}>
                MINT
              </button>
            )}
            {checkDevice === "pcWithoutExtention" && (
              <a
                href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                target={"_blank"}
                className="text-decoration-none">
                <button
             
                  className="headerButtonW m-0 text-nowrap" style={{ fontSize: '12px' }}>
                  MINT
                </button>
              </a>
            )}
          </div>
            // <button style={{ fontSize: '12px' }} className="headerButtonW m-0 text-nowrap" role="button" onClick={() => openWalletModal()}>LOGIN WITH WALLET</button>
            :
        <button
              onClick={() => { mintCertificate(detail);}}
              className="headerButtonW m-0 text-nowrap">
              Mint
            </button>}
          {/* {<button onClick={() => handleUpdate(detail?._id,"approve")} className='ms-1 editBtn'>Approve</button>} */}
                                                <button onClick={() => handleUpdate(detail?._id,"cancel")} className='ms-1 studentBtnDlt text-white'><DeleteIcon /></button>
        </div>
      </td>
    </tr>
  );
};

export default Certificate;
