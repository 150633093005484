import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Certificate from "./Certificate";
import Swal from "sweetalert2";
import Pagination from "../../../components/Pagination/Pagination";
import { BlockchainContext } from "../../../Context/BlockchainContext";
import { v4 as uuidv4 } from "uuid";
import { ethers } from "ethers";
import { ArrowBack } from "@mui/icons-material";

const Certificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [allCertificate, setAllCertificate] = useState([]);
  const [courses, setCourses] = useState([]);
  const [mintCerti, setmintCerti] = useState(false);
  const {
    user,
    getBalanceTestnet,
    signBuyFunction,
    mintCertificateMainFunction,
    openWalletModal
  } = useContext(BlockchainContext);

  const navigate=useNavigate()

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/active")
        // await axios.get("courses.json")
        .then((res) => {
          setCourses(res.data.result);
        });
    };
    data();
  }, []);

  const mintCertificate = async (single) => {
setmintCerti(false)
    const singleCourse = courses?.find(res => single?.coursename == res?.coursename)
    
    const data = new FormData();
    
    data.append("Id", singleCourse._id);
    data.append("amount", 0);
    data.append("tokenAddress", "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d");
    data.append("refAddress", "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d");
    data.append("refAddress2","0x4c17f3c856d1d059bdd597ad64d0f4375e52944d");
    data.append("refAddress3", "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d");
    data.append(
          "refAddress4",
          "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d"
        );
    data.append("refAmount", 0);
    data.append("refAmount2", 0);
    data.append("refAmount3", 0);
    data.append("refAmount4", 0);
    data.append("image", singleCourse?.image);
    data.append("email", user?.email);
    data.append("nonce", uuidv4());
    // getBalanceTestnet();
    await axios
      .post(
        "https://backend.studyswiftly.com/api/mint/uri-json-nft",
        data,
        {}
      )
      .then(async (res) => {
        let Obj = {};

        if (res.status === 200) {
          const data1=res.data
          //   = await signBuyFunction(
          //   singleCourse._id,
          //   ethers.utils.parseEther(0),
          //   "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
          //   "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
          //   "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
          //   "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
          //   "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
          //   ethers.utils.parseEther(0),
          //   ethers.utils.parseEther(0),
          //   ethers.utils.parseEther(0),

          //   ethers.utils.parseEther(0),
          //   res.data.uri
          // );
          mintCertificateMainFunction(data1).then((res) => { setmintCerti(res); console.log(res, "mint value"); handleUpdate(singleCourse._id,"approve")})
        }
      })
    // const data={}
    //! api data here, data which contain's uri
   
    console.log("click");

  };

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/certificate/")
        // .get("https://backend.studyswiftly.com/api/mint")
        .then((res) => {
          setAllCertificate(res.data.result?.filter(res=>res.status=="requested"));
          setCertificates(res.data.result?.filter(res=>res.status=="requested"));
        });
    };
    data();
  }, []);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceCertificates, setSliceCertificates] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(certificates?.length / show);
    setLastPage(lastPage);
  }, [certificates, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = certificates.slice(
        (page - 1) * show,
        page * show
      );
      setSliceCertificates([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = certificates.slice(0, show);
      setSliceCertificates([...getSlicingProduct]);
    }
  }, [certificates, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this certificate?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://backend.studyswiftly.com/api/certificate/${id}`
          )
          .then((res) => {
            if (res.status === 200) {
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              setAllCertificate(
                allCertificate.filter((certificate) => certificate._id !== id)
              );
            }
          })
          .catch((error) => {
            Swal.fire({
              text: "Something went wrong",
              icon: "success",
              customClass: "modal_class_success",
            });
          });
      }
    });
  };

  const handleUpdate = (id,value) => {
    Swal.fire({
      text: "Are you sure, you want to procced?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `https://backend.studyswiftly.com/api/certificate/status/${id}`,{status:value}
          )
          .then((res) => {
            if (res.status === 200) {
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              setAllCertificate(
                allCertificate.filter((certificate) => certificate._id !== id)
              );
            }
          })
          .catch((error) => {
            Swal.fire({
              text: "Something went wrong",
              icon: "success",
              customClass: "modal_class_success",
            });
          });
      }
    });
  };

  const handleSearchCertificate = (e) => {
    const keyWord = e?.target?.value;

    if (keyWord) {
      const searchedCertificates = allCertificate.filter(
        (eachCertificate) =>
          eachCertificate.name
            .toLocaleLowerCase()
            .includes(keyWord.toLocaleLowerCase()) === true
      );

      console.log(searchedCertificates, keyWord);
      setCertificates(searchedCertificates);
    } else {
      setCertificates(allCertificate);
    }
  };

  return (
    // <div className='adminBody' style={{ height: "100vh" }}>
    <div className="adminBody">
       <p className='cursor-pointer' style={{ cursor: "pointer" }} title='Back' onClick={() => navigate(-1)}><ArrowBack /></p>
      <h5 className="text-white text-start mb-3">CERTIFICATES REQUESTED</h5>
      <div className="certificate-search-bar-container">
        <input
          className="form-control"
          placeholder="Search by name"
          type="text"
          onChange={(e) => handleSearchCertificate(e)}
        />
      </div>
      <div className="adminCard py-2">
        {/* <div className="adminBtnDiv text-end">
          <Link to="/admin/allcertificates/">
            <button className="adminBtn mt-0 text-uppercase">
              SEE ALL CERTIFICATES
            </button>
          </Link>
        </div> */}
        <div className="tableNormal ">
          <Table className="text-white adminDataTable ">
            <thead>
              <tr>
                <th className="text-start text-nowrap">Name</th>
                <th className="text-start text-nowrap">Course Name</th>
                {/* <th className='text-start'>Email</th> */}
                {/* <th className="text-start adminHidden">Course Type</th> */}
                <th className="text-start adminHidden text-nowrap">Id</th>
                <th className="text-start adminHidden text-nowrap">Start Date</th>
                <th className="text-start text-nowrap">Action</th>
                <th className="text-start text-nowrap">Status</th>
              </tr>
            </thead>
            <tbody>
              {sliceCertificates?.map((detail, index) => (
                <Certificate
                  index={index}
                  detail={detail}
                  handleDelete={handleDelete}
                  handleUpdate={handleUpdate}
                  mintCertificate={mintCertificate}
                  openWalletModal={openWalletModal}
                  user={user}
                  mintCerti={mintCerti}
                />
              ))}
            </tbody>
          </Table>
          {sliceCertificates?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>
        {/*         
          {sliceUsers?.length && (
          <div>
            <Pagination
              lastPage={lastPage}
              page={pageNumber}
    
              pageHandle={pageHandle}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Certificates;
