import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BlockchainContext } from "../../../Context/BlockchainContext";
import { UserContext } from "../../../Context/UserContext";
import "./Header.css";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";

import { Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import axios from "axios";

const RSMobileMenu = ({
  setMenuOpen,
  menuOpen,
  parentMenu,
  secondParentMenu,
  headerFullWidth,
}) => {
  const { data, logout } = useContext(UserContext);
  const { user, openWalletModal, pathName } = useContext(BlockchainContext);
  const navigate = useNavigate();
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [course, setCourse] = useState(false);
  const [pages, setPages] = useState(false);
  const [team, setTeam] = useState(false);
  const [news, setNews] = useState(false);
  const [event, setEvent] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [shop, setShop] = useState(false);
  const [otherPages, setOtherPages] = useState(false);
  const [blog, setBlog] = useState(false);
  const [blogSidebar, setBlogSidebar] = useState(false);
  const [blogSingle, setBlogSingle] = useState(false);
  const [contact, setContact] = useState(false);
  const [forms, setForms] = useState(false);
  const { pathname } = useLocation();
  const [checkDevice, setCheckDevice] = useState("");

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);

  const walletLogin = () => {
    openWalletModal();
    if (user?.walletAddress && pathname != pathName) {
      navigate("/my-account");
    }
  };

  const LogOut = () => {
    logout();
    navigate("/");
    // swal({
    // 	text: `Successfully Logged Out!`,
    // 	icon: "success",
    // 	button: "OK!",
    // 	className: "modal_class_success",
    // });

    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p class='text-break text-white'>Successfully Signed Out!</p>`;

    Swal.fire({
      html: wrapper,
      icon: "success",
      customClass: "modal_class_success",
    });
  };

  useEffect(() => {
    console.log("dataa" + data.email);
  }, []);
  const location = useLocation();

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "about") {
      setHome(false);
      setAbout(!about);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "course") {
      setHome(false);
      setAbout(false);
      setCourse(!course);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "pages") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(!pages);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "team") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(!team);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "forms") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setForms(true);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "news") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setNews(!news);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "gallery") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setEvent(false);
      setGallery(!gallery);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "shop") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(!shop);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "otherPages") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(true);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(!otherPages);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "blog") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(!blog);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "blogSidebar") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(true);
      setBlogSidebar(!blogSidebar);
      setBlogSingle(false);
      setContact(false);
    } else if (menu === "blogSingle") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(true);
      setBlogSidebar(false);
      setBlogSingle(!blogSingle);
      setContact(false);
    } else if (menu === "contact") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setPages(false);
      setTeam(false);
      setNews(false);
      setEvent(false);
      setGallery(false);
      setShop(false);
      setOtherPages(false);
      setBlog(false);
      setBlogSidebar(false);
      setBlogSingle(false);
      setContact(!contact);
    }
  };

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(
            res.data.Categories?.sort((a, b) => a.name.localeCompare(b.name))
          );
        });
    };
    data();
  }, []);

  return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }>
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu pb-2">
          <ul className="nav-menu">
            <li
              className={
                home
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }>
              <Link
                to="/home"
                onClick={() => {
                  openMobileMenu("home");
                }}
                className={parentMenu === "home" ? "active-menu" : ""}>
                Home
              </Link>
            </li>
            <li
              className={
                about
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }>
              <Link
                to="/about"
                onClick={() => {
                  openMobileMenu("about");
                }}
                className={parentMenu === "about" ? "active-menu" : ""}>
                About
              </Link>
            </li>
            <li
              className={
                course
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }>
              <Link
                to="/courses"
                onClick={() => {
                  openMobileMenu("course");
                }}
                className={parentMenu === "course" ? "active-menu" : ""}>
                Courses
              </Link>
            </li>
            {/* <li
              className={
                pages
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="/ourdapps"
                onClick={() => {
                  openMobileMenu("pages");
                }}
                className={parentMenu === "pages" ? "active-menu" : ""}
              >
                Our Dapps
              </Link>
            </li> */}
            <li
              className={
                news
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }>
              <Link
                to="/news"
                href="#"
                onClick={() => {
                  openMobileMenu("news");
                }}
                className={parentMenu === "news" ? "active-menu" : ""}>
                News
              </Link>
            </li>
            <li
              className={
                forms
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }>
              <Link
                to="/forms"
                onClick={() => {
                  openMobileMenu("forms");
                }}
                className={parentMenu === "forms" ? "active-menu" : ""}>
                Forms
              </Link>
            </li>
            {/* <li
              className={
                contact
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }>
              <Link
                to="/contact"
                onClick={() => {
                  openMobileMenu("contact");
                }}
                className={parentMenu === "contact" ? "active-menu" : ""}>
                Contact
              </Link>
            </li> */}
          </ul>

          {/* <ul className="topbar-right "> */}

          {/* <li className="login-register"> */}
          <div className="menuText loginbuttonmobile">
            <div className="mb-3">
              {!data.email ? (
                <Link className="header-btn" to="/sign-in">
                  <img
                    className="me-1"
                    width="22px"
                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                    alt=""
                  />
                  GET STARTED
                </Link>
              ) : (
                // <button
                //   className=" bannerButtonW text-white"
                //   onClick={() => navigate("/login")}
                //   style={{ fontSize: "11px" }}>
                //   <img
                //     className="me-1"
                //     width="22px"
                //     src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                //     alt=""
                //   />
                //   GET STARTED
                // </button>
                <Link className="header-btn" to="/my-account">
                  <img
                    className="me-1"
                    width="22px"
                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                    alt=""
                  />
                  PROFILE
                </Link>
                // <Link
                //   style={{ fontSize: "13px", width: "120px" }}
                //   className=" bannerButtonW text-white "
                //   role="button"
                //   to="/my-account">
                //   Profile
                // </Link>
              )}
            </div>
            {/* {checkDevice === "mobileWithoutApp" && (
                  <a
                    href="https://metamask.app.link/dapp/http://studyswiftly.com"
                    target={"_blank"}
                    className="text-decoration-none bannerButtonW"
                  >
                    <button
                      className=" text-white "
                      style={{ background: "transparent", border: "none" }}
                    >
                      <img
                        className="me-1"
                        width="22px"
                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                        alt=""
                      />
                      GET STARTED
                    </button>
                  </a>
                )}
                {checkDevice === "mobileWithApp" && (
                  <>
                    {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                      <button
                        className=" bannerButtonW text-white"
                        onClick={() => openWalletModal()}
                        style={{ fontSize: "11px" }}
                      >
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        GET STARTED
                      </button>
                    ) : (
                      <Link
                        style={{ fontSize: "13px", width: "120px" }}
                        className=" bannerButtonW text-white "
                        role="button"
                        to="/my-account"
                      >
                        Profile
                      </Link>
                    )}
                  </>
                )}
                {checkDevice === "pcWithExtention" && (
                  <>
                    {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                      <button
                        className="bannerButtonW text-white"
                        onClick={() => openWalletModal()}
                        style={{ fontSize: "11px" }}
                      >
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        GET STARTED
                      </button>
                    ) : (
                      <Link
                        style={{ fontSize: "13px", width: "120px" }}
                        className=" bannerButtonW text-white "
                        role="button"
                        to="/my-account"
                      >
                        Profile
                      </Link>

                    )}
                  </>
                )}
                {checkDevice === "pcWithoutExtention" && (
                  <a
                    href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                    target={"_blank"}
                    className="text-decoration-none bannerButtonW"
                  >
                    <button
                      className=" text-white "
                      style={{ background: "transparent", border: "none" }}
                    >
                      <img
                        className="me-1"
                        width="22px"
                        src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                        alt=""
                      />
                      GET STARTED
                    </button>
                  </a>
                )} */}
          </div>
          {/* </li> */}
          {/* </ul> */}
          <ul>
            {/* {!data?.email && (
              <li
                style={{ marginLeft: "-28px" }}
                className="nav-bar-info-button">
                <a href="https://studyswiftly.com/join-us" target="_blank">
                  <button>
                    <InfoIcon sx={{ color: "#cb8c00 !important" }} />
                  </button>
                </a>
              </li>
            )} */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
