import React from "react";
import SingleTeam from "../../components/Team/SingleTeam";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

// Team Images
import teamImg1 from "../../assets/img/team/1.jpg";
import teamImg2 from "../../assets/img/team/2.jpg";
import teamImg3 from "../../assets/img/team/3.jpg";
import teamImg4 from "../../assets/img/team/4.jpg";
import teamImg5 from "../../assets/img/team/5.jpg";
import teamImg6 from "../../assets/img/team/6.jpg";
import TeamSingleMain from "./TeamSingleMain";

const Team = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="team"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Our Dapps"
        pageName="Our Dapps"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-End */}
      <div className="my-5 mt-0">
        <TeamSingleMain />
      </div>

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Team;
