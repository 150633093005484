import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import FeatureStyle1 from "../Feature/FeatureStyle1";
// import { BlockchainContext } from "../../Context/BlockchainContext";
import thumbnail from "./blockchain-thumbnail.jpeg";
import { title_data } from "../../assets/datas/Title_data";
// import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import "./SliderDefault.css"
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
const SliderDefault = () => {
  const sliderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  // const { user, openWalletModal } = useContext(BlockchainContext);

  const [popupOpen, setPopupOpen] = useState(false);
  const [checkDevice, setCheckDevice] = useState("");
  const navigate = useNavigate();

  const [openCertificate, setOpenCertificate] = React.useState(false);
  const handleClickOpen = () => {
    setOpenCertificate(true);
  };

  const handleClose = () => {
    setOpenCertificate(false);
  };

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);

  // useEffect(() => {
  //   if (user?.walletAddress) {
  //     setPopupOpen(false);
  //   } else {
  //     setPopupOpen(true);
  //   }
  // }, [user?.walletAddress]);
  // if(user?.walletAddress){
  //     setPopupOpen(false)
  // }
  const sliderSetting = {
    // arrows: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "0px",
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1,
        },
      },
    ],
  };

  const [randomTitle, setRandomTitle] = useState("");

  useEffect(() => {
    const changeTitle = () => {
        const randomIndex = Math.floor(Math.random() * title_data.length);
        setRandomTitle(title_data[randomIndex]);
    };

    changeTitle();
    const intervalId = setInterval(changeTitle, 5000);

    return () => clearInterval(intervalId);
}, []);

  return (
    <React.Fragment>
      <div className="rs-slider main-home">
        <div className="slider-content slide1">
          <div className="container pb-md-5">
            <div className="content-part position-relative">
              <div
                style={{ color: "rgb(194, 116, 0)", marginBottom: "6px" }}
                className="sl-sub-title"
              >
                Get Certified Now!
              </div>
              <Tooltip title="Tips" style={{marginBottom: "6px", padding: "2px"}}>
                <IconButton>
                  <TipsAndUpdatesIcon className="tips_icon" />
                </IconButton>
              </Tooltip>
              <h1 className="sl-sub-title text-white text-capitalize">
                {randomTitle}
                {/* <span style={{ color: "#ff5421" }}>EXPERTS</span> */}
              </h1>
              <p className="text-center text-white">
                <span style={{ color: "#ff5421" }}>Studyswiftly.com</span>{" "}
                offers certification based on NFTs.
              </p>
              <Slider {...sliderSetting}>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img className="img-fluid " src={thumbnail} alt="" />
                  {/* banner-image */}
                </div>{" "}
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img className="img-fluid " src="/s4.jpeg" alt="" />
                </div>{" "}
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img className="img-fluid " src="/s2.jpeg" alt="" />
                </div>{" "}
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img className="img-fluid " src="/s3.jpeg" alt="" />
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img className="img-fluid " src="/s5.jpeg" alt="" />
                </div>
              </Slider>
              {/* <div className="d-flex justify-content-center align-items-center mt-3">
                <img
                  className="img-fluid banner-image"
                  src={thumbnail}
                  alt=""
                />
              </div> */}

              <div className="sl-btn d-flex justify-content-center gap-3 mt-4 banner-button-container">
                <Link
                  className="explore-course-button-in-banner"
                  to="/scholarship"
                >
                  APPLY FOR FULL SCHOLARSHIPS NOW!
                </Link>
                {/* <div
                  className="connect-meta-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  {checkDevice === "mobileWithoutApp" && (
                    <a
                      href="https://metamask.app.link/dapp/http://studyswiftly.com"
                      target={"_blank"}
                      className="text-decoration-none">
                      <button
                        className="text-uppercase bannerWalletLoginButton  "
                        style={{}}>
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        Open in metamask
                      </button>
                    </a>
                  )}
                  {checkDevice === "mobileWithApp" && (
                    <>
                      {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase bannerWalletLoginButton"
                          onClick={() => openWalletModal()}
                          style={{}}>
                          <img
                            className="me-1"
                            width="22px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          />
                          Connect to metamask
                        </button>
                      ) : (
                        <Link
                          style={{ fontSize: "13px", width: "120px" }}
                          className="text-uppercase bannerWalletLoginButton "
                          role="button"
                          to="/my-account">
                          Profile
                        </Link>
                        // <Link
                        //   style={{ width: "120px" }}
                        //   className="text-uppercase bannerButtonW "
                        //   role="button"
                        //   to="/my-account"
                        // >
                        //   Profile
                        // </Link>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithExtention" && (
                    <>
                      {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase bannerWalletLoginButton"
                          onClick={() => openWalletModal()}
                          style={{ fontSize: "12px" }}>
                          <img
                            className="me-1"
                            width="22px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          />
                          Connect to metamask
                        </button>
                      ) : (
                        <Link
                          style={{ fontSize: "13px", width: "120px" }}
                          className="text-uppercase bannerWalletLoginButton "
                          role="button"
                          to="/my-account">
                          Profile
                        </Link>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithoutExtention" && (
                    <a
                      href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                      target={"_blank"}
                      className="text-decoration-none">
                      <button
                        className="text-uppercase bannerWalletLoginButton "
                        style={{ fontSize: "12px" }}>
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        INSTALL METAMASK
                      </button>
                    </a>
                  )}
                </div> */}
              </div>
              {/* <div className="d-flex justify-content-center align-items-center mt-1 banner-info-btn">
                {!user.walletAddress && (
                  <a
                    className="nav-bar-info-button info-icon-button-in-banner"
                    href="https://studyswiftly.com/join-us"
                    target="_blank"
                    style={{ marginRight: "-37px !important" }}>
                    <button>
                      <InfoIcon sx={{ color: "#cb8c00 !important" }} />
                    </button>
                  </a>
                )}
              </div> */}
              {/* <div className="d-flex justify-content-center align-items-center">
                <div
                  className="position-absolute mx-auto"
                  style={{ bottom: "30px" }}>
                  <button
                    onClick={handleClickOpen}
                    className="explore-course-button-in-banner2 border-0">
                    FinTech Certification
                  </button>
                  <div>
                    <Dialog
                      open={openCertificate}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description">
                      <img
                        src="https://i.ibb.co/G0bxDBj/Whats-App-Image-2022-07-06-at-9-23-46-PM.jpg"
                        alt=""
                      />
                    </Dialog>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* <Slider {...sliderSettings}>
          <div className="slider-content slide1">
            <div className="container">
              <div className="content-part">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="mb-3"
                >
                  {!user.walletAddress && (
                    <li
                      style={{ marginLeft: "-52px" }}
                      className="nav-bar-info-button"
                    >
                      <a
                        href="https://studyswiftly.com/join-us"
                        target="_blank"
                      >
                        <button>
                          <InfoIcon sx={{ color: "#cb8c00 !important" }} />
                        </button>
                      </a>
                    </li>
                  )}

                  {checkDevice === "mobileWithoutApp" && (
                    <a
                      href="https://metamask.app.link/dapp/http://studyswiftly.com"
                      target={"_blank"}
                      className="text-decoration-none"
                    >
                      <button
                        className="text-uppercase bannerButtonW  "
                        style={{ fontSize: "12px" }}
                      >
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        Open in metamask
                      </button>
                    </a>
                  )}
                  {checkDevice === "mobileWithApp" && (
                    <>
                      {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase bannerButtonW"
                          onClick={() => openWalletModal()}
                          style={{ fontSize: "12px" }}
                        >
                          <img
                            className="me-1"
                            width="22px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          />
                          Connect to metamask
                        </button>
                      ) : (
                        <Link
                          style={{ fontSize: "13px", width: "120px" }}
                          className="text-uppercase bannerButtonW "
                          role="button"
                          to="/my-account"
                        >
                          Profile
                        </Link>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithExtention" && (
                    <>
                      {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase bannerButtonW"
                          onClick={() => openWalletModal()}
                          style={{ fontSize: "12px" }}
                        >
                          <img
                            className="me-1"
                            width="22px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          />
                          Connect to metamask
                        </button>
                      ) : (
                        <Link
                          style={{ fontSize: "13px", width: "120px" }}
                          className="text-uppercase bannerButtonW "
                          role="button"
                          to="/my-account"
                        >
                          Profile
                        </Link>
                        
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithoutExtention" && (
                    <a
                      href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                      target={"_blank"}
                      className="text-decoration-none"
                    >
                      <button
                        className="text-uppercase bannerButtonW "
                        style={{ fontSize: "12px" }}
                      >
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        INSTALL METAMASK
                      </button>
                    </a>
                  )}
                </div>

                <div className="sl-sub-title">Get Certified Now!</div>
                <h1 className="sl-title">Courses From Industry Experts</h1>
                <div className="sl-btn">
                  <Link className="readon orange-btn main-home" to="/courses">
                    EXPLORE COURSES
                  </Link>
                </div>
               
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img
                    className="img-fluid banner-image"
                    src={thumbnail}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>




          <div className="slider-content slide2">
            <div className="container">
              <div className="content-part">
                
                <div className="sl-sub-title">Start to learning today</div>
                <h1 className="sl-title">
                  Explore Interests and Career With Courses
                </h1>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img
                    className="img-fluid"
                    src={thumbnail}
                    alt=""
                    style={{ width: "50%" }}
                  />
                </div>




                <div className="d-flex justify-content-center align-align-items-center">
                <div className="sl-btn">
                  <Link className="readon orange-btn main-home" to="/courses">
                    EXPLORE COURSES
                  </Link>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="mb-3"
                >
                  {!user.walletAddress && (
                    <li
                      style={{ marginLeft: "-52px" }}
                      className="nav-bar-info-button"
                    >
                      <a
                        href="https://studyswiftly.com/join-us"
                        target="_blank"
                      >
                        <button>
                          <InfoIcon sx={{ color: "#cb8c00 !important" }} />
                        </button>
                      </a>
                    </li>
                  )}

                  {checkDevice === "mobileWithoutApp" && (
                    <a
                      href="https://metamask.app.link/dapp/http://dsl.sg/superdapps"
                      target={"_blank"}
                      className="text-decoration-none"
                    >
                      <button
                        className="text-uppercase bannerButtonW  "
                        style={{ fontSize: "12px" }}
                      >
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        Open in metamask
                      </button>
                    </a>
                  )}
                  {checkDevice === "mobileWithApp" && (
                    <>
                      {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase bannerButtonW"
                          onClick={() => openWalletModal()}
                          style={{ fontSize: "12px" }}
                        >
                          <img
                            className="me-1"
                            width="22px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          />
                          Connect to metamask
                        </button>
                      ) : (
                        <Link
                          style={{ fontSize: "13px", width: "120px" }}
                          className="text-uppercase bannerButtonW "
                          role="button"
                          to="/my-account"
                        >
                          Profile
                        </Link>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithExtention" && (
                    <>
                      {!user.walletAddress ||
                      user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase bannerButtonW"
                          onClick={() => openWalletModal()}
                          style={{ fontSize: "12px" }}
                        >
                          <img
                            className="me-1"
                            width="22px"
                            src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                            alt=""
                          />
                          Connect to metamask
                        </button>
                      ) : (
                        <Link
                          style={{ fontSize: "13px", width: "120px" }}
                          className="text-uppercase bannerButtonW "
                          role="button"
                          to="/my-account"
                        >
                          Profile
                        </Link>
                   
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithoutExtention" && (
                    <a
                      href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                      target={"_blank"}
                      className="text-decoration-none"
                    >
                      <button
                        className="text-uppercase bannerButtonW "
                        style={{ fontSize: "12px" }}
                      >
                        <img
                          className="me-1"
                          width="22px"
                          src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                          alt=""
                        />
                        INSTALL METAMASK
                      </button>
                    </a>
                  )}
                </div>
                </div>



              </div>
            </div>
          </div>
        </Slider> */}
        <FeatureStyle1 />
      </div>
    </React.Fragment>
  );
};

export default SliderDefault;
