import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";
import AffiliateModal from "../Affiliate/AffiliateModal";

const Total = ({ date, month, year }) => {
  const [affiliateData, setAffiliateData] = useState([]);
  const [allAffiliateData, setAllAffiliateData] = useState([]);
  const [affiliateModal, setAffiliateModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [modalData, setModalData] = useState("");

  useEffect(() => {
    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/mint/get/pay-busd"
        )
        .then((res) => {
          setAllAffiliateData(res.data.result);
        });
    };
    data();
  }, [refetch]);

  useEffect(() => {
    if (date !== "date" && month !== "month" && year !== "year") {
      const filteredByFullDate = allAffiliateData.filter((eachPayment) => {
        return eachPayment?.date === `${date + "/" + month + "/" + year}`;
      });
      setAffiliateData(filteredByFullDate);
    } else if (month !== "month" && year !== "year") {
      console.log("Month, Year");
      const filteredByMonthAndYear = allAffiliateData.filter((eachPayment) => {
        return eachPayment?.date?.slice(3, 10) === `${month + "/" + year}`;
      });
      setAffiliateData(filteredByMonthAndYear);
    } else if (year !== "year") {
      const filteredByYear = allAffiliateData.filter((eachPayment) => {
        return eachPayment?.date?.slice(6, 10) === year;
      });
      setAffiliateData(filteredByYear);
    } else {
      setAffiliateData(allAffiliateData);
    }
  }, [date, month, year]);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceAffiliateData, setSliceAffiliateData] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(affiliateData?.length / show);
    setLastPage(lastPage);
  }, [affiliateData, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = affiliateData.slice(
        (page - 1) * show,
        page * show
      );
      setSliceAffiliateData([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = affiliateData.slice(0, show);
      setSliceAffiliateData([...getSlicingProduct]);
    }
  }, [affiliateData, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  const handleModal = (data) => {
    // console.log(data)
    setModalData(data);
  };

  return (
    // <div className='adminBody' style={{ height: "100vh" }}>
    <div className="adminBody">
      <div className="adminCard py-2">
        <div className="tableNormal pt-5">
          <Table className="text-white adminDataTable">
            <thead>
              <tr>
                <th className="text-start ">Date</th>
                <th className="text-start">Email</th>
                <th className="text-start ">Total Amount</th>
                <th className="text-start ">BSC URL</th>
              </tr>
            </thead>
            <tbody>
              {affiliateData?.map((data) => (
                <tr className="tableRow" key={data._id}>
                  <td align="text-start">{data?.date}</td>
                  <td className="text-start">{data?.email}</td>
                  <td className="text-start ">
                    <span style={{ color: "orange" }}>{data?.amount}</span> SGD
                  </td>
                  <td className="action ">
                    <a
                      className="bscUrlText"
                      target="_blank"
                      href={data?.bscUrl1}
                    >
                      <p>Click Here</p>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {sliceAffiliateData?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>
      </div>
      <AffiliateModal
        // affiliateModal={affiliateModal}
        // setAffiliateModal={setAffiliateModal}
        // refetch={refetch}
        // setRefetch={setRefetch}
        // modalData={modalData}
        // setModalData={setModalData}
        show={affiliateModal}
        refetch={refetch}
        setRefetch={setRefetch}
        setAffiliateModal={setAffiliateModal}
        onHide={() => setAffiliateModal(false)}
        modalData={modalData}
      />
    </div>
  );
};

export default Total;
