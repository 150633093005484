import React from "react";
import { Helmet } from "react-helmet";

// Image
import favIcon from "../../../assets/img/fav-orange.png";
import Logo from "../../../assets/img/logo/dark-logo.png";
import footerLogo from "../../../assets/img/logo/lite-logo.png";

// Breadcrumbs Background Image
import bannerbg from "../../../assets/img/breadcrumbs/2.jpg";
import Header from "../../../components/Layout/Header/Header";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import Newsletter from "../../../components/Common/Newsletter";
import Footer from "../../../components/Layout/Footer/Footer";
import ScrollToTop from "../../../components/Common/ScrollTop";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";

const PaymentSuccessful = () => {
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <link rel="icon" href={favIcon} />
        </Helmet>
        <OffWrap />
        <Header
          parentMenu="pages"
          secondParentMenu="shop"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="support@website.com"
          Location="374 William S Canning Blvd, MA 2721, USA "
        />
        {/* 
        <SiteBreadcrumb
          pageTitle="Payment Successful"
          pageName="Payment"
          breadcrumbsImg={bannerbg}
        /> */}

        {/* CheckoutMain Start */}
        <div className="container">
          {/* <div className="printer-top"></div> */}

          <div className="paper-container">
            {/* <div className="printer-bottom"></div> */}

            <div className="paper">
              <div className="main-contents">
                <div className="success-icon">&#10004;</div>
                <div className="success-title">Payment Complete</div>
                <div className="success-description">
                  Thank you for completing the payment! You will shortly receive
                  an email of your payment.
                </div>
                <div className="order-details">
                  <div className="order-number-label">Transaction ID</div>
                  <div className="order-number">123456789</div>
                  <div className="complement">Thank You!</div>
                </div>
              </div>
              <div className="jagged-edge"></div>
            </div>
          </div>
        </div>
        {/* CheckoutMain End */}

        <Newsletter
          sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
          titleClass="title mb-0 white-color"
        />

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}

        <SearchModal />
      </React.Fragment>
    </div>
  );
};

export default PaymentSuccessful;
