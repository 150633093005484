import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Users.css";
import ViewStudent from "./ViewStudent";
import Swal from "sweetalert2";
import Pagination from "../../../components/Pagination/Pagination";

const Users = () => {
  const [users, setUsers] = useState([]);
  console.log(users);
  const [allUsers, setAllUsers] = useState([]);
  const [studentModal, setStudentModal] = useState(false);
  const [addCourseModal, setAddCourseModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [addCourseData, setAddCourseData] = useState(null);
  const [deleteRefetch, setDeleteRefetch] = useState(false);

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/v1/user")
        .then((res) => {
          setAllUsers(res.data?.result);
          setUsers(res.data?.result);
        });
    };
    data();
  }, [deleteRefetch]);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceUsers, setSliceUsers] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(users?.length / show);
    setLastPage(lastPage);
  }, [users, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = users.slice((page - 1) * show, page * show);
      setSliceUsers([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = users.slice(0, show);
      setSliceUsers([...getSlicingProduct]);
    }
  }, [users, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  const handleDelete = (id) => {
    // console.log(id);
    // const confirmDelete = window.confirm('Are you sure, you want to delete this student?')

    Swal.fire({
      text: "Are you sure, you want to delete this student?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://backend.studyswiftly.com/api/v1/user/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              // swal({
              //     title: "Success",
              //     text: res.data.message,
              //     // text: "Student delete success .",
              //     icon: "success",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              setUsers(users.filter((user) => user._id !== id));
              setDeleteRefetch(true);
            }
          })
          .catch((error) => {
            // alert(error.response.data.message);
            // swal({
            //     title: "Attention",
            //     text: error.response.data.message,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "warning",
              customClass: "modal_class_success",
            });
          });
      }
    });

    // swal({
    //     title: "Are you sure, you want to delete this student?",
    //     // text: "You will not be able to recover this post again!",
    //     icon: "warning",
    //     buttons: ["No", "Yes"],
    //     dangerMode: true,
    // }).then(function (isConfirm) {

    //     if (isConfirm) {
    //         axios.delete(`https://backend.studyswiftly.com/api/wallet/${id}`)
    //             .then(res => {
    //                 if (res.status === 200) {

    //                     swal({
    //                         title: "Success",
    //                         text: res.data.message,
    //                         // text: "Student delete success .",
    //                         icon: "success",
    //                         button: "OK!",
    //                         className: "modal_class_success",
    //                     });
    //                     setUsers(users.filter(user => user._id !== id));
    //                     setDeleteRefetch(true);
    //                 }
    //             })
    //             .catch(error => {
    //                 // alert(error.response.data.message);
    //                 swal({
    //                     title: "Attention",
    //                     text: error.response.data.message,
    //                     icon: "warning",
    //                     button: "OK!",
    //                     className: "modal_class_success",
    //                 });
    //             })

    //     }
    // });
  };

  const handleModal = (data) => {
    // console.log(data)
    setModalData(data);
  };

  const handleAddModal = (data) => {
    setAddCourseData(data);
  };

  const handleSearchUser = (e) => {
    const keyWord = e?.target?.value;

    console.log(allUsers, "all users in search function");

    if (keyWord) {
      const searchedUsers = allUsers.filter(
        (eachUser) =>
          eachUser?.name
            ?.toLocaleLowerCase()
            .includes(keyWord?.toLocaleLowerCase()) === true
      );

      setUsers(searchedUsers);
    } else {
      setUsers(allUsers);
    }
  };

  console.log(users);

  console.log(sliceUsers);
  console.log("sliceUsers");

  return (
    // <div className='adminBody' style={{ height: "100vh" }}>
    <div className="adminBody">
      <h5 className="text-white text-start mb-3">STUDENTS</h5>
      <div className="certificate-search-bar-container">
        <input
          className="form-control"
          placeholder="Search by name"
          type="text"
          onChange={(e) => handleSearchUser(e)}
        />
      </div>
      <div className="adminCard py-2">
        <div className="tableNormal pt-5">
          <Table className="text-white adminDataTable">
            <thead>
              <tr>
                <th className="text-start ">Name</th>
                <th className="text-start adminHidden">Email</th>
                <th className="text-start text-nowrap">Student Id</th>
                {/* <th className='text-start adminHidden'>Username</th> */}
                {/* <th className='text-start adminHidden'>Date</th> */}
                {/* <th className='text-start adminHidden'>Mobile</th> */}
                <th className="text-start ">Action</th>
              </tr>
            </thead>
            <tbody>
              {sliceUsers?.map((data) => (
                <tr className="tableRow" key={data._id}>
                  <td align="text-start">{data?.name || "Username"}</td>
                  <td className="text-start adminHidden">
                    {data?.email || "Email"}
                  </td>
                  <td className="text-start">
                    {data?.memeberId || "Membership-Id"}
                  </td>
                  <td className="action ">
                    <div className="d-flex">
                      <button
                        className="studentView"
                        onClick={() => {
                          setStudentModal(true);
                          handleModal(data);
                        }}
                      >
                        <i class="fas fa-eye"></i>
                      </button>

                      <button
                        onClick={() => handleDelete(data?._id)}
                        className="studentBtnDlt"
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {sliceUsers?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>

        {/* Pagination  */}

        {/* <AddCourseModal
                    show={addCourseModal}
                    onHide={() => setAddCourseModal(false)}
                    addCourseData={addCourseData}
                /> */}

        <ViewStudent
          show={studentModal}
          onHide={() => setStudentModal(false)}
          modalData={modalData}
        />
      </div>
    </div>
  );
};

export default Users;
