import mint from "./MintNFT.json";
import mint2 from "./MintNFT2.json";
import mint3 from "./MintNFT3.json";
// import token from "./BEP20.json";
// import dsltestnet from "./DSLTestnet.json";
// import dslmainnet from "./DSLMainnet.json";
// import usdsc from "./USDSC.json";
import S39 from "./S39.json";
import busd from "./BUSDTestnet.json";
import exportBuyCourseMintAbi from "./buyCourseMintAbi.json";
import exportBUSDTokenAbi from "./BUSDTokenAbi.json";
// import Quest from "./Quest.json";

export const mintAddressTestnet = "0xfE7E8add581487b6e53F8B8d1fA76779491767ac"; //busd+s39
export const mintABITestnet = mint.abi;

export const mintAddressTestnet2 = "0x53Ed4A02e234B8709340B18264Ebb8a809cC5A54"; //S39minting
export const mintABITestnet2 = mint2.abi;

export const mintAddressTestnet3 = "0x2995E3bd041E4C737537f033ca608713414E0e3e"; //certificatemint
export const mintABITestnet3 = mint3.abi;

export const RPC = "https://data-seed-prebsc-1-s1.binance.org:8545";

export const chainId = "97";

//config
export const buyCourseMintContractAddress =
  "0xC4DC2416098Ca52031Aa34272A89861397B8BD04";
export const buyCourseMintAbi = exportBuyCourseMintAbi;

export const BUSDTokenContractAddress =
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const BUSDTokenAbi = exportBUSDTokenAbi;

// export const USDSCtokenAddressTestnet =
//   "0x8d671b650248B4BfaF411CB36Cc1C8bD8429F2A3";
// export const USDSCtokenABITestnet = token.abi;

// export const DSLtokenAddressTestnet =
//   "0x203507762cF4B63bFEF530a87497c7F5FDad7b0B";
// export const DSLtokenABITestnet = dsltestnet.abi;

// export const USDSCtokenAddressMainnet =
//   "0x13b852e276f10281C72ccF33EdF81d81DD198Aae";
// export const USDSCtokenABIMainnet = usdsc.abi;

// export const DSLtokenAddressMainnet =
//   "0x4A1530Fb85BdB9250Db2bE251584874179eB8Dc5";
// export const DSLtokenABIMainnet = dslmainnet.abi;

export const S39tokenAddressTestnet =
  "0x80aAeA2450F81a67fcEB2dEDABCbC57ebF346Ee7";
export const S39tokenABITestnet = S39.abi;

// export const QuesttokenAddressTestnet =
//   "0x5C6e946b94b2c79563b1fB2B73bD60bB7bd0Cf5B";
// export const QuesttokenABITestnet = Quest.abi;

export const BUSDtokenAddressTestnet =
  "0xEB35Fc2712C2fD4Fe4B484f815564880774153E6";
export const BUSDtokenABITestnet = busd.abi;
