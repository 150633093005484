import React from "react";
import { Table, Modal } from "react-bootstrap";

const MCQModal = (props) => {
  const { mcqArr, setMcqArr } = props;
  console.log(mcqArr, "MCQ Array in modal");

  const removeMcqArray = (eachMCQ) => {
    console.log("HELLO");
    let mcqs = mcqArr.filter((mcq) => mcq !== eachMCQ);
    setMcqArr(mcqs);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}>
      <Modal.Header closeButton className="modelAddAdmin">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-5 text-light">
          MCQs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="handleModalBody">
        <div>
          {/* MCQ Table */}
          <Table style={{ color: "white" }} bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {mcqArr?.map((eachMCQ, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{eachMCQ?.question}</td>
                  <td>
                    <p className="mb-2">1. {eachMCQ?.option1}</p>
                    <p className="mb-2"> 2. {eachMCQ?.option2}</p>
                    <p className="mb-2">3. {eachMCQ?.option3}</p>
                    <p className="mb-2">4. {eachMCQ?.option4}</p>
                    <p className="mb-2">Correct Ans: {eachMCQ?.ans}</p></td>
                  <td>
                    <button
                      onClick={() => removeMcqArray(eachMCQ)}
                      className="btn btn-danger btn-sm"
                      type="button"
                      style={{
                        padding: "2px 7px",
                        fontSize: "11px",
                        backgroundColor: "#dc3545",
                      }}>
                      <i class="fas fa-trash"></i>
                    </button>
                    {/* <button
                      // onClick={() => removeMcqArray(eachMCQ)}
                      className="btn btn-danger btn-sm ms-2"
                      type="button"
                      style={{
                        padding: "2px 7px",
                        fontSize: "11px",
                        backgroundColor: "green",
                      }}>
                      <i className="fas fa-edit"></i>
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* FOOTER */}
          <Modal.Footer className="mt-3 modalFooter justify-content-center">
            <button
              type="submit"
              className="adminBtnAdd"
              onClick={props.onHide}>
              OK
            </button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MCQModal;
