import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AffiliateModal from "./AffiliateModal";
import Pagination from "../../../components/Pagination/Pagination";

const Affiliate = () => {
  const [affiliateData, setAffiliateData] = useState([]);
  const [affiliateModal, setAffiliateModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [modalData, setModalData] = useState("");

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/v1/affilate")
        .then((res) => {
          setAffiliateData(res.data.Categories);
        });
    };
    data();
  }, [refetch]);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceAffiliateData, setSliceAffiliateData] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(affiliateData?.length / show);
    setLastPage(lastPage);
  }, [affiliateData, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = affiliateData?.slice(
        (page - 1) * show,
        page * show
      );
      setSliceAffiliateData([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = affiliateData?.slice(0, show);
      setSliceAffiliateData([...getSlicingProduct]);
    }
  }, [affiliateData, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  const handleModal = (data) => {
    // console.log(data)
    setModalData(data);
  };

  return (
    // <div className='adminBody' style={{ height: "100vh" }}>
    <div className="adminBody">
      <h5 className="text-white text-start">AFFILIATE PAYMENTS</h5>
      <div className="adminCard py-2">
        <div className="tableNormal pt-5">
          <Table className="text-white adminDataTable">
            <thead>
              <tr>
                <th className="text-start ">Date</th>
                <th className="text-start">Buyer Email</th>
                <th className="text-start">Receiver Email</th>
                <th className="text-start ">Amount</th>
                {/* <th className="text-start ">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {sliceAffiliateData?.map((data) => (
                <tr className="tableRow" key={data._id}>
                  <td align="text-start">
                    {new Date(data.createdAt)?.toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </td>
                  <td className="text-start">{data?.fromEmail}</td>
                  <td className="text-start">{data?.toEmail}</td>
                  <td className="text-start ">{data.amount} SGD</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {sliceAffiliateData?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>
      </div>
      <AffiliateModal
        // affiliateModal={affiliateModal}
        // setAffiliateModal={setAffiliateModal}
        // refetch={refetch}
        // setRefetch={setRefetch}
        // modalData={modalData}
        // setModalData={setModalData}
        show={affiliateModal}
        refetch={refetch}
        setRefetch={setRefetch}
        setAffiliateModal={setAffiliateModal}
        onHide={() => setAffiliateModal(false)}
        modalData={modalData}
      />
    </div>
  );
};

export default Affiliate;
