import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AdminContext = createContext();

export default function AdminProvider({ children }) {
  const [admin, setAdmin] = useState(null);
  const [loader,setLoader]=useState(false)

  var newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();

  if (min < 10) {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + 0 + min + ":" + ss;
  } else {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  }

  // console.log(localStorage)
  useEffect(() => {
    axios
      .get(
        "https://backend.studyswiftly.com/api/admin/admin",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("courseSiteAdmin")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAdmin(res.data.admin);
          setLoader(true)
        }
      })
      .catch((err) => {
        setAdmin(null);
        setLoader(true)
      });
  }, []);

  const logout = async (navigate) => {
    // Keeping the logout record
    await axios
      .post(
        `https://backend.studyswiftly.com/api/data/admin/logout`,
        {
          name: admin.name,
          email: admin.email,
          username: admin.username,
          mobile: admin.mobile,
          date: newDate,
        }
      )
      .then((res) => console.log(res.data, "Admin logout action"));

    setAdmin(null);
    localStorage.removeItem("courseSiteAdmin");
    navigate("/");
  };

  return (
    <AdminContext.Provider
      value={{
        admin,
        setAdmin,
        logout,
        loader
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}
