import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import "./home.css";

const FaqSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-faq-part style1 orange-style pt-100 faqPart md-pt-40 pb-4">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="LrKXdVA3HJ8"
        onClose={() => {
          openModal();
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 padding-0">
            <div className="main-part bg-transparent">
              <div className="title mb-40 md-mb-14">
                <h2 className="text-part">Frequently Asked Questions</h2>
              </div>
              <div className="faq-content">
                <Accordion className="accordion-style1" preExpanded={"a"}>
                  {/* <AccordionItem className="accordion-item" uuid="a">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Who is Findexx ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Findexx LLC is a Digital Asset Management Platform which
                      offers it's users crypto trading through spot trade,
                      bundle buy and arbitrage via Findexx.net
                    </AccordionItemPanel>
                  </AccordionItem> */}
                  <AccordionItem className="accordion-item" uuid="b">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Who is offering the courses?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      DS Legends Pte. Ltd. is providing multiple industry based
                      courses.
                      {/* DS Legends Pte. Ltd. has
                      multiple tie-ups with Findexx LLC. */}
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="c">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        What kind of courses are offered?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      We offer a wide array of courses spanning various
                      disciplines to cater to diverse career paths and
                      interests.
                      {/* Courses are categorized as Blockchain, Dapps, Play To Earn
                      and Digital Asset Management. We want to create industry
                      players through our courses. */}
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="d">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Why Studyswiftly.com?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Upon completion, you'll receive NFT certification, opening
                      doors to lucrative business and employment prospects.
                      We're constantly seeking exceptional individuals like you
                      to join our team.
                      {/* Upon completion we provide NFT certification. You can
                      receive business opportunities and employment
                      opportunities very effectively. We are continuously in the
                      look out for great people like you to join us too. */}
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-6 padding-0 mb-5 d-lg-flex align-items-center">
            <div className="home-video">
              <video
                poster="https://i.ibb.co/31q4cnS/Screenshot-1562.png"
                preload="auto"
                controls
                loop=""
                style={{ width: "100%", height: "100%" }}>
                <source src="./grigVid.mp4" type="video/mp4" />
              </video>
              <h6 className="text-center orange-color ">
                Learn how to develop PLAY TO EARN Blockchain games.
              </h6>
              <p className="text-center">
                <button className="soonPlay">Coming Soon</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
