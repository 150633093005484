import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { Link, Typography } from "@mui/material";
import axios from 'axios';
import htmlToDraft from 'html-to-draftjs';
import swal from 'sweetalert';
import { MdClose } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import Swal from 'sweetalert2';



const EditContent = () => {

    const [img, setImg] = useState();
    const [video, setVideo] = useState();
    const [pdf, setPdf] = useState();
    const [imgTitle, setImgTitle] = useState("");
    const [videoTitle, setVideoTitle] = useState("");
    const [pdfTitle, setPdfTitle] = useState("");
    const [singleCourse, setSingleCourse] = useState({});

    const [videoLoader, setvideoLoader] = useState(false);
    const [imageLoader, setimageLoader] = useState(false);
    const [pdfLoader, setpdfLoader] = useState(false);

    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [selectedPDFs, setSelectedPDFs] = useState([]);
    const [inputValues, setinputValues] = useState("");


    const [charLimit, setCharLimit] = useState(0);
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const { id } = useParams();

    const [description, setDescription] = useState(() => EditorState.createEmpty());
    const descriptiondraft = draftToHtml(convertToRaw(description.getCurrentContent()));

    // console.log(saveAsDraft);
    const navigate = useNavigate();
    var newDate = new Date();
    let dd = String(newDate.getDate()).padStart(2, '0');
    let mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = newDate.getFullYear();
    // let hh = newDate.getHours();
    // let min = newDate.getMinutes();
    // let ss = newDate.getSeconds();


    newDate = dd + '/' + mm + '/' + yyyy




    useEffect(() => {
        const data = async () => {
            await axios.get(`https://backend.studyswiftly.com/api/learn/${id}`)
                .then(res => {
                    setSingleCourse(res?.data?.result)

                    const description = res.data.result.description;
                    const blocksFromHtml = htmlToDraft(description);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

                    setDescription(EditorState.createWithContent(contentState));

                })
        }
        data();
    }, [id])

    console.log(singleCourse)





    const [imageArr, setImageArr] = useState([]);
    const [imgData, setImgData] = useState();

    const [videoValueArr, setVideoValueArr] = useState([]);
    const [videoData, setVideoData] = useState();

    const [PdfValueArr, setPdfValueArr] = useState([]);
    const [PdfData, setPdfData] = useState();





    const handleImageSelection = (e) => {
        if (e.target.files[0] !== 0) {
            const newImages = Object.values(e.target.files);
            const images = [...selectedImages, ...newImages];



            const formData = new FormData();
            for (const image of images) {
                formData.append("images", image);
            }

            setimageLoader(true);

            axios
                .post(`https://backend.studyswiftly.com/api/mint/img`, formData)
                .then((res) => {
                    // console.log("image form data", res.data)
                    // setImg(res?.data?.images);
                    setSelectedImages(res?.data?.images);
                    setimageLoader(false);

                })
                .catch((err) => {
                    console.error(err)
                    setimageLoader(false);
                }
                );

        }
    };


    const handleVideoSelection = (e) => {
        if (e.target.files[0] !== 0) {
            const newVideos = Object.values(e.target.files);
            const Videos = [...selectedVideos, ...newVideos];

            const formData = new FormData();
            for (const singleVideo of Videos) {
                formData.append("videos", singleVideo);
            }

            setvideoLoader(true);

            axios
                .post(
                    `https://backend.studyswiftly.com/api/mint/videos`,
                    formData
                )
                .then((res) => {

                    setSelectedVideos(res?.data?.videos);
                    setvideoLoader(false);
                })
                .catch((err) => { console.error(err); setvideoLoader(true); });

        }
    };





    const handlePDFSelection = (e) => {
        if (e.target.files[0] !== 0) {
            const newPDFs = Object.values(e.target.files);
            const PDFs = [...selectedPDFs, ...newPDFs];



            const formData = new FormData();
            for (const doc of PDFs) {
                formData.append("doc", doc);
            }
            // console.log("file form data", ...formData)

            setpdfLoader(true);
            axios
                .post(`https://backend.studyswiftly.com/api/mint/doc`, formData)
                .then((res) => {

                    setSelectedPDFs(res?.data?.document);
                    setpdfLoader(false);
                })
                .catch((err) => {
                    console.error(err)
                    setpdfLoader(false);
                });

        }
    };


    const handleImageInputValues = (e) => {
        let mainArr = [];
        let array = imageArr;
        array[e.target.name] = e.target.value;
        // console.log(selectedImages, array)

        selectedImages?.map((img, index1) => {
            array?.map((value, index2) => {
                if (index1 == index2) {
                    const dataObj = {
                        image: img,
                        value: value
                    }

                    mainArr.push(dataObj);
                    // console.log(dataObj, mainArr)
                    setImgData(mainArr);

                }

            })

        })
    };

    const handleVideoInputValues = (e) => {
        let mainArr = [];
        let array = videoValueArr;
        array[e.target.name] = e.target.value;
        // console.log(selectedImages, array)

        selectedVideos?.map((video, index1) => {
            array?.map((value, index2) => {
                if (index1 == index2) {
                    const dataObj = {
                        video: video,
                        value: value
                    }

                    mainArr.push(dataObj);
                    // console.log(dataObj, mainArr)
                    setVideoData(mainArr);
                    // console.log(mainArr)
                }

            })

        })
    };

    const handlePDFInputValues = (e) => {
        let mainArr = [];
        let array = PdfValueArr;
        array[e.target.name] = e.target.value;
        // console.log(selectedImages, array)

        selectedPDFs?.map((pdf, index1) => {
            array?.map((value, index2) => {
                if (index1 == index2) {
                    const dataObj = {
                        pdf: pdf,
                        value: value
                    }

                    mainArr.push(dataObj);
                    // console.log(dataObj, mainArr)
                    setPdfData(mainArr);
                    // console.log(mainArr)
                }

            })

        })
    };




    // please dont change any code if you dont understand---->Jahid
    function removeImageFile(index) {
        var attachments = document.getElementById("imgInput").files; // <-- reference of file input here
        var fileBuffer = new DataTransfer();

        // append the file list to an array iteratively
        for (let i = 0; i < attachments.length; i++) {
            // Exclude file in specified index
            if (index !== i)
                fileBuffer.items.add(attachments[i]);
        }

        // Assign buffer to file input
        document.getElementById("imgInput").files = fileBuffer.files; // <-- according to your file input reference
    }


    function removeVideoFile(index) {
        var attachments = document.getElementById("videoInput").files;
        var fileBuffer = new DataTransfer();

        for (let i = 0; i < attachments.length; i++) {
            if (index !== i)
                fileBuffer.items.add(attachments[i]);
        }

        document.getElementById("videoInput").files = fileBuffer.files;
    }

    function removePDFFile(index) {
        var attachments = document.getElementById("pdfInput").files;
        var fileBuffer = new DataTransfer();

        for (let i = 0; i < attachments.length; i++) {
            if (index !== i)
                fileBuffer.items.add(attachments[i]);
        }

        document.getElementById("pdfInput").files = fileBuffer.files;
    }




    const handleRemoveImage = (image, index) => {
        const filterdImages = selectedImages.filter((img) => img !== image);

        const filteredData = imgData?.filter(value => value.image != image);
        setImgData(filteredData);
        setSelectedImages(filterdImages);

        removeImageFile(index);
    };


    const handleRemoveVideo = (Video, index) => {
        const filterdVideos = selectedVideos.filter((vdo) => vdo !== Video);

        const filteredData = videoData?.filter(value => value.video != Video);
        setVideoData(filteredData);
        setSelectedVideos(filterdVideos);
        removeVideoFile(index);
    };


    const handleRemovePDF = (PDF, index) => {
        const filterdPDFs = selectedPDFs.filter((pdf) => pdf !== PDF);

        const filteredData = PdfData?.filter(value => value.pdf != PDF);

        setSelectedPDFs(filterdPDFs);
        setPdfData(filteredData);
        removePDFFile(index);
    };



    console.log(imgData, videoData, PdfData)







    // console.log(name)

    const handleEditCourse = async () => {


        const formData = new FormData()
        formData.append('name', name || singleCourse?.name)
        formData.append('description', descriptiondraft)
        formData.append('amount', singleCourse?.method == "0.00" ? "FREE" : singleCourse?.amount)
        formData.append('filetitle', pdfTitle)
        formData.append('imgtitle', imgTitle)
        formData.append('videotitle', videoTitle)
        formData.append('pdfData', PdfData)
        formData.append('videoData', videoData)
        formData.append('imageData', imgData)
        formData.append('date', newDate)



        const datas = {
            name: name || singleCourse?.name,
            description: descriptiondraft,
            amount: price || (singleCourse?.method == "0.00" ? "FREE" : singleCourse?.amount),
            date: newDate,
            imageData: imgData,
            videoData: videoData,
            videotitle: videoTitle,
            imgtitle: imgTitle,
            filetitle: pdfTitle,
            pdfData: PdfData
        }


        await axios.put(`https://backend.studyswiftly.com/api/learn/${id}`, datas)
            .then(res => {
                if (res.status === 200) {
                    // alert(res.data.message);
                    console.log(res.data)
                    // swal({
                    //   title: "Success",
                    //   text: `${res.data.message}`,
                    //   icon: "success",
                    //   button: "OK!",
                    //   className: "modal_class_success",
                    // });

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

                    Swal.fire(
                        {
                            html: wrapper,
                            icon: "success",
                            customClass: "modal_class_success",
                        }
                    )


                }

                navigate("/admin/addCourses");
            })
            .catch(error => {
                console.dir(error)
                // alert(error.response.data.message);
                // swal({
                //   title: "Attention",
                //   text: `${error.response.data.message}`,
                //   icon: "warning",
                //   button: "OK!",
                //   className: "modal_class_success",
                // });

                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

                Swal.fire(
                    {
                        html: wrapper,
                        icon: "warning",
                        customClass: "modal_class_success",
                    }
                )


            });

    };





    return (
        <div>
            <div style={{ backgroundColor: "#272d47", color: 'white' }} className='mx-auto forRespoMarginReduce'>
                <h4 className='pt-3 ps-3 container text-light'>Edit Content</h4>
                <div className='container pb-5 pt-0'>




                    <label className='mb-1 mt-3'>Course title</label>
                    <div className="course-name-input-container">

                        <input
                            defaultValue={singleCourse?.name}

                            maxLength={55}
                            type="text"
                            name="coursename"
                            className='border w-100 rounded  p-2'
                            onChange={(e) => { setCharLimit(e.target.value); setName(e.target.value) }}
                            style={{ backgroundColor: "#272d47", color: 'white', position: "relative" }}
                            required
                        />
                        {charLimit?.length > 0 &&
                            < span className={`char-limit-position ${charLimit.length == 55 && "text-danger"}`}>{charLimit.length}/55</span>}
                    </div>
                    <label className='mb-1 mt-3'>Course price</label>
                    <div className="course-name-input-container">
                        <input
                            defaultValue={singleCourse?.method == "0.00" ? "FREE" : singleCourse?.amount}
                            maxLength={55}
                            type="text"
                            name="coursename"
                            className='border w-100 rounded mb-3 p-2'
                            onChange={(e) => setPrice(e.target.value)}
                            style={{ backgroundColor: "#272d47", color: 'white', position: "relative" }}
                            required
                        />

                    </div>





                    <>
                        {selectedVideos?.length > 0 && (
                            <div className="selected-video-container">
                                {selectedVideos?.map((video, index) => (
                                    <div key={index} className='each-selected-video-for-priview'>
                                        <div className="each-selected-video-container">
                                            <video controls
                                                // src={URL.createObjectURL(video)}
                                                src={video}
                                                className="each-selected-vidoe">
                                                <source
                                                    // src={URL.createObjectURL(video)}
                                                    src={video}
                                                />
                                            </video>
                                            <Close
                                                className="selected-image-remove-button"
                                                fontSize="small"
                                                onClick={() => handleRemoveVideo(video, index)}
                                            />
                                        </div>
                                        <div className=''>
                                            <p className='mb-1'>Add title</p>
                                            <input
                                                maxLength={55}
                                                type="text"
                                                name={index}
                                                className='border  h- rounded mb-3 p-'
                                                // onChange={(e) => setImgTitle(e.target.value)}
                                                onChange={(e) => handleVideoInputValues(e)}

                                                style={{ backgroundColor: "#272d47", color: 'white', width: "100%", height: "35px" }}
                                                required
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>




                    <label className='mb-1'>{videoLoader ? "Uploading Videos..." : "Upload Videos"}</label>
                    <input
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        multiple
                        id='videoInput'
                        name="image"
                        // onChange={onChangeVideo}
                        onChange={(e) => handleVideoSelection(e)}
                        className='border w-100 rounded mb-3'
                        style={{ backgroundColor: "#272d47", color: 'white' }}
                        required
                    />





                    <>

                        {selectedImages?.length > 0 && (
                            <div className="selected-video-container">
                                {selectedImages?.map((image, index) => (
                                    <div key={index} className='each-selected-video-for-priview'>
                                        <div className="each-selected-video-container">
                                            <img
                                                className="each-selected-image"
                                                // src={URL.createObjectURL(image)}
                                                src={image}
                                                alt=""
                                            />
                                            <Close
                                                className="selected-image-remove-button"
                                                fontSize="small"
                                                onClick={() => handleRemoveImage(image, index)}
                                            />
                                        </div>
                                        <div className=''>
                                            <p className='mb-1'>Add title</p>
                                            <input
                                                maxLength={55}
                                                type="text"
                                                name={index}
                                                className='border  h- rounded mb-3 p-'
                                                // onChange={(e) => setImgTitle(e.target.value)}
                                                onChange={(e) => handleImageInputValues(e)}

                                                style={{ backgroundColor: "#272d47", color: 'white', width: "100%", height: "35px" }}
                                                required
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>




                    <label className='mb-1'>{imageLoader ? "Uploading Images..." : "Upload Images"}</label>
                    <input
                        id='imgInput'
                        type="file"
                        accept=".png,.jpeg,.jpg"
                        multiple
                        name="image"
                        // onChange={onChangePicture}
                        onChange={(e) => handleImageSelection(e)}
                        className='border w-100 rounded mb-3'
                        style={{ backgroundColor: "#272d47", color: 'white' }}
                        required
                    />



                    <>


                        {selectedPDFs?.length > 0 && (
                            <div className="selected-video-container">
                                {selectedPDFs?.map((pdf, index) => (
                                    <div key={index} className='each-selected-video-for-priview'>
                                        <div className="each-selected-video-container">
                                            <embed
                                                // src={URL.createObjectURL(pdf)}
                                                src={pdf}
                                                type="application/pdf"
                                                frameBorder="0"
                                                className="each-selected-image"
                                                scrolling="auto"
                                            // height="100%"
                                            // width="100%"
                                            ></embed>

                                            <Close
                                                className="selected-image-remove-button"
                                                fontSize="small"
                                                onClick={() => handleRemovePDF(pdf, index)}
                                            />
                                        </div>
                                        <div className=''>
                                            <p className='mb-1'>Add title</p>
                                            <input
                                                maxLength={55}
                                                type="text"
                                                name={index}
                                                className='border  h- rounded mb-3 p-'
                                                // onChange={(e) => setImgTitle(e.target.value)}
                                                onChange={(e) => handlePDFInputValues(e)}

                                                style={{ backgroundColor: "#272d47", color: 'white', width: "100%", height: "35px" }}
                                                required
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>





                    <label className='mb-1'>{pdfLoader ? "Uploading Pdf..." : "Upload Pdf"}</label>
                    <input
                        id='pdfInput'
                        type="file"
                        // accept="application/pdf,application/vnd.ms-excel"
                        // accept="application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf,image/*"
                        accept="application/pdf,
      application/vnd.ms-powerpoint,
      application/msword,
      application/vnd.openxmlformats-officedocument.presentationml.slideshow,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.openxmlformats-officedocument.presentationml.presentation,
      .pps"
                        multiple
                        name="image"
                        // onChange={onChangePDF}
                        onChange={(e) => handlePDFSelection(e)}
                        className='border w-100 rounded mb-3'
                        style={{ backgroundColor: "#272d47", color: 'white' }}
                        required
                    />




                    <label className='mb-1'>Course links and details</label>
                    <Editor
                        editorState={description}
                        required={true}
                        onEditorStateChange={setDescription}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                        toolbarClassName="toolbar-class text-black"
                        toolbar={{
                            image: {
                                urlEnabled: true,
                                uploadEnabled: true,
                                alignmentEnabled: true,
                                uploadCallback: undefined,
                                previewImage: true,
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                alt: { present: false, mandatory: false },
                                defaultSize: {
                                    height: 'auto',
                                    width: 'auto',
                                },
                                fontSize: {
                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                fontFamily: {
                                    options: ['Arial', 'sans-serif', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                            },
                        }}
                    />



                    <div className='mx-auto text-center my-3'>
                        <Button type='button' style={{ backgroundColor: '#dc3545', width: '125px', fontSize: "13px" }} className='border-0 text-uppercase modal-btn ms-3 pt-3 text-center pb-3  me-3 extraCare'>CANCEL</Button>
                        <Button
                            type='submit'
                            onClick={handleEditCourse}
                            style={{ backgroundColor: 'blueviolet', width: '125px', fontSize: "13px" }} className='border-0 pt-3 pb-3  text-uppercase modal-btn ms-3 me-3 extraCare'>Add</Button>
                    </div>

                </div>
            </div>
        </div >
    );
};

export default EditContent;