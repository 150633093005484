import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AdminContext } from '../../Context/AdminContext';
import { CircularProgress } from '@mui/material';
const AdminRoutes = ({ children }) => {
    const { admin,loader } = useContext(AdminContext);
    let location = useLocation();
    if (!loader) {
        return <div className="w-100 vh-100 d-flex align-items-center justify-content-center">
        <CircularProgress></CircularProgress>
        </div>
    }
    if (admin?.role === "admin") {
        return children;
    }
    return <Navigate to="/admin/login" state={{ from: location }} />;

};

export default AdminRoutes;