import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";
import { FaTiktok } from "react-icons/fa";

import footerLogo1 from "../../../assets/img/logo/logo.png";
import postImg1 from "../../../assets/img/blog/post1.jpg";
import postImg2 from "../../../assets/img/blog/post2.jpg";
import footer1 from "../../../assets/img/footer/footer2.jpeg";
import footer2 from "../../../assets/img/footer/footer1.jpeg";
import axios from "axios";

const Footer = (props) => {
  const [allMedia, setAllMedia] = useState({});
  useEffect(() => {
    axios.get(`https://backend.dsl.sg/api/v1/social-link`).then((res) => {
      setAllMedia(res.data);
    });
  }, []);
  const { footerLogo, footerClass, footerTopClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className={`footer-top ${footerTopClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
              <div className="footer-logo">
                <Link to="/" as="/">
                  <p className="logo-text footer-logos">STUDYSWIFTLY.COM</p>
                  {/* <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /> */}
                </Link>
              </div>
              <div className="textwidget pr-60 md-pr-14">
                <p>DS Legends Pte Ltd (DSL) is Web3/AI company.</p>
              </div>
              <ul className="footer_social">
                {/* <li>
                                    <a href="https://www.facebook.com/dslsingapore" target="_blank">
                                        <i className="fa fa-facebook-f"></i>
                                    </a>
                                </li> */}
                                   <li>
                  <a href={allMedia?.instagram} target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href={allMedia?.twitter} target="_blank">
                    {/* <i className="fa fa-twitter"></i> */}
                    <i class="fa-brands fa-x-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href={allMedia?.tiktok} target="_blank">
                    <FaTiktok />
                  </a>
                </li>
                <li>
                  <a href={allMedia?.linkedin} target="_blank">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href={allMedia?.pinterest} target="_blank">
                    <i className="fa fa-pinterest"></i>
                  </a>
                </li>
                {/* <li>
                                    <a href="https://dsl.sg" target="_blank">
                                        <i className="fa fa-google-plus"></i>
                                    </a>
                                </li> */}
             
                {/* <li>
                  <a href="https://t.me/dslsg" target="_blank">
                    <i className="fa fa-telegram"></i>
                  </a>
                </li> */}
               
                <li>
                  <a href={allMedia?.medium} target="_blank">
                    <i className="fa fa-medium"></i>
                  </a>
                </li>
               
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title">Address</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  {/* <div className="desc">22 Sin Ming Lane #06-76 Midview City Singapore 573969</div> */}
                  <div className="desc">
                    60 Paya Lebar Road <br />
                    #07-54 Paya Lebar Square <br />
                    Singapore 409051 <br />
                    (Registered Office Address)
                  </div>
                </li>

                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <a href="mailto:support@studyswiftly.com">
                      support@studyswiftly.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-3 col-md-12 col-sm-12 pl-50 md-pl-14 footer-widget md-mb-50">
                            <h3 className="widget-title">Courses</h3>
                            <ul className="site-map">
                                <li><Link to="/course">Courses</Link></li>
                                <li><Link to="/courses">Course Two</Link></li>
                                <li><Link to="/course/course-single">Single Course</Link></li>
                                <li><Link to="/shop/my-account">Profile</Link></li>
                                <li><Link to="/login">Login</Link>/<Link to="/register">Register</Link></li>
                            </ul>
                        </div> */}
            <div className="col-lg-4 col-md-12 col-sm-12 footer-widget">
              <h3 className="widget-title">Recent News</h3>
              <div className="recent-post mb-20">
                <div className="">
                  <img
                    style={{ width: "205px", borderRadius: "4px" }}
                    src={footer1}
                    alt="blog image"
                  />
                </div>
                <div className="post-item ps-3">
                  <div className="post-desc">
                    <Link to="/news">
                      The Decentralized Future of the Internet with Web3
                    </Link>
                  </div>
                </div>
              </div>
              <div className="recent-post mb-20">
                <div className="">
                  <img
                    style={{
                      width: "175px",
                      height: "94px",
                      borderRadius: "4px",
                    }}
                    src={footer2}
                    alt="blog image"
                  />
                </div>
                <div className="post-item">
                  <div className="post-desc ps-3">
                    <Link to="/news">
                      Exploring the Benefits of Cloud Computing
                    </Link>
                  </div>
                </div>
              </div>
              <Link to="/news">Read More </Link>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
