import React, { useEffect } from 'react'
import joinHero from './img/join-hero-dark.webp'
import walletIllo from './img/wallet-illo.svg'
import BrowseIllo from './img/Browse-illo.svg'
import ExploreIllo from './img/Explore-illo.svg'
import chrome from './img/Chrome_Logo.svg'
import { BsChevronDown } from 'react-icons/bs';
import dog from './img/dog.png'
import './JoinUsMain.css'

const JoinUsMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <div className='container'>
        {/************************** Banner  **************/}
        <div className='row'>
          <div className='col-lg-6 order-2 order-md-1 d-flex flex-column  justify-content-center'>
            <h2 className='font-weight-bold customH2' >A crypto wallet & <br /> gateway to <br /> decentralized apps</h2>
            <p className='customP'>Start exploring decentralized applications in seconds. Trusted by over 30 million users worldwide.</p>
            <div className='btnC'>
              <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" rel="noopener noreferrer">
                <button className='btn btn-primary text-capitalize  px-5 py-3' style={{ borderRadius: '999px' }}>Download for <img className='ps-2' src={chrome} style={{ width: '37px' }} alt="chrome" /></button>
              </a>

            </div>
          </div>
          <div className='col order-1 order-md-2'>
            <img src={joinHero} alt="join-hero" />
          </div>
        </div>

        {/************************** Learn More  **************/}
        <div className='text-center py-5 py-md-2'>
          <p className='text-uppercase mb-0'>Learn More</p>
          <BsChevronDown className='text-black p-0 font-weight-bold' style={{ fontSize: '26px' }} />

        </div>
        {/************************** Wallet Illo  **************/}
        <div className='row gap-5'>
          <div className='col-lg-6 order-2 order-md-1 d-flex flex-column  justify-content-center'>
            <h2 className='customH' >Buy, store, send <br className='customBr' /> and swap tokens</h2>
            <p className='customP'>Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure login, token wallet, and token exchange—everything you need to manage your digital assets.</p>
            <div>
            </div>
          </div>
          <div className='col order-1 order-md-2'>
            <img src={walletIllo} alt="join-hero" />
          </div>
        </div>

        {/************************** Explore-illo  **************/}

        <div className='row gap-5'>
          <div className='col '>
            <img src={ExploreIllo} alt="ExploreIllo" />
          </div>
          <div className='col-lg-6  d-flex flex-column  justify-content-center'>
            <h2 className='customH' >Explore decentralized apps</h2>
            <p className='customP'>MetaMask provides the simplest yet most secure way to connect to decentralized-based applications. You are always in control when interacting on the new decentralized web.</p>
            <div>
            </div>
          </div>
        </div>

        {/************************** Browse Illo  **************/}
        <div className='row'>
          <div className='col-lg-6 order-2 order-md-1 d-flex flex-column  justify-content-center'>
            <h2 className='customH' >Own your data</h2>
            <p className='customP'>MetaMask generates passwords and keys on your device, so only you have access to your accounts and data. You always choose what to share and what to keep private.</p>
            <div>
            </div>
          </div>
          <div className='col order-1 order-md-2'>
            <img src={BrowseIllo} alt="join-hero" />
          </div>
        </div>
      </div>

      {/************************************* Get Started ***************************** */}

      <div className='d-flex flex-column py-5 mt-5 justify-content-center align-items-center' style={{ background: '#393E46' }}>
        <img src={dog} style={{ width: '150px' }} alt="dog" />
        <h2 className='pt-4 pb-5 text-white'>Get started</h2>
        <div className='d-flex flex-column flex-md-row mb-5 '>

          <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" rel="noopener noreferrer">
            <button className='btn btn-primary text-capitalize mb-3 mb-md-0' style={{ borderRadius: '999px' }}>Install for Chrome</button>
          </a>

          <a href="https://apps.apple.com/us/app/metamask/id1438144202?_branch_match_id=964610233522654516&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz00tScxNLM7WSywo0MvJzMvWL8529DB2SnSztAQA5G46IyQAAAA%3D" target="_blank" rel="noopener noreferrer">
            <button className='btn btn-primary mb-3 mb-md-0 mx-md-5' style={{ borderRadius: '999px', textTransform: "none" }}>Install for iPhone</button>
          </a>

          <a href="https://play.google.com/store/apps/details?id=io.metamask&hl=en_US&ref=producthunt&_branch_match_id=964610233522654516&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz00tScxNLM7WSywo0MvJzMvWT6ooz3a1yHeztAQA%2FOIqTSQAAAA%3D">
            <button className='btn btn-primary text-capitalize mb-3 mb-md-0' style={{ borderRadius: '999px' }}>Install for Android</button>
          </a>

        </div>
      </div>

      {/************************************* Video ***************************** */}
      <div className='my-5 pb-lg-5'>
        <div className="container">
          <h3 className='text-center'>What is MetaMask?</h3>
          <div className='d-flex justify-content-center'>
            <div class="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/YVgfHZMFFFQ" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>

      </div>


    </div>
  )
}

export default JoinUsMain