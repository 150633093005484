import { ArrowBack } from '@mui/icons-material';
import { Table } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const RejectedDegrees = () => {
    const navigate=useNavigate()
    return (
        <div className="adminBody">
          <p
            className="cursor-pointer"
            style={{ cursor: "pointer" }}
            title="Back"
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </p>
          <h5 className="text-white text-start text-uppercase">
          degrees Rejected
          </h5>
          <div className="adminCard py-2">
            <div className="tableNormal pt-5">
              <Table className="text-white adminDataTable">
                <thead>
                  <tr>
                    <th className="text-start ">Name</th>
                    <th className="text-start adminHidden">Certificate No</th>
                    <th className="text-start">Date</th>
    
                    <th className="text-start">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {certificates?.map((data) => (
                    <tr className="tableRow" key={data._id}>
                      <td align="text-start">{data?.name}</td>
                      <td className="text-start adminHidden">
                        {data?.CertificateNo}
                      </td>
                      <td className="text-start">{data?.date}</td>
    
                      <td className="action">
                        <div className="d-flex">
                          <Link to={`/admin/edit-certificate/${data?._id}`}>
                            <button className="editBtn">
                              <i className="fas fa-edit"></i>
                            </button>
                          </Link>
    
                          <button
                            onClick={() => handleDelete(data?._id)}
                            className="ms-1 studentBtnDlt"
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))} */}
                </tbody>
              </Table>
    
              
            </div>
          </div>
        </div>
      );
};

export default RejectedDegrees;