import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "react-phone-number-input/style.css";
import "./ViewStudent.css";

const ViewStudent = (props) => {
  const { modalData } = props;
  console.log('dataaaaaaaaaaaaaa', modalData);
  const [selectedImage, setSelectedImage] = useState();
  const [studentProfileUrl, setStudentProfileUrl] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentId, setStudentId] = useState("");
  const [studentWalletAddress, setStudentWalletAddress] = useState("");

  useEffect(() => {
    setStudentName(modalData?.name ? modalData?.name : "");
    setStudentEmail(modalData?.email ? modalData?.email : "");
    setStudentWalletAddress(modalData?.walletAddress);
    setStudentId(modalData?.memeberId);
  }, [modalData]);

  useEffect(() => {
    if (!selectedImage) {
      setStudentProfileUrl(
        modalData?.profilePicture
          ? modalData?.profilePicture
          : "https://i.ibb.co/JtVwzXs/default.jpg"
      );
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setStudentProfileUrl(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage, modalData]);

  const changePhoto = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    console.log("inside image");
    setSelectedImage(e.target.files[0]);
  };

  const editStudentInfo = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}
    >
      <Modal.Header closeButton className="modalHeader">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-4 text-light"
        >
          <span className="text-white">Student Details</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: "rgb(177, 185, 195)" }}
        className="handleModalBody"
      >
         <p className="mb-1">Image</p>
        <img src={studentProfileUrl} style={{ width: "250px" }} alt="img" />
        <form onSubmit={editStudentInfo}>
          {/* <p className="mb-1">Image</p> */}
          {/* <input
            className="form-control"
            type="file"
            accept="image/*"
            name="image"
            onChange={changePhoto}
            disabled
          /> */}
          <p className="mb-1">Name</p>
          <input
            className="form-control"
            placeholder="Enter name"
            type="text"
            name="name"
            onChange={(e) => setStudentName(e.target.value)}
            value={studentName}
            disabled
            required
          />

          <p className="mb-1">Email</p>
          <input
            className="form-control"
            placeholder="Enter Email"
            type="email"
            name="email"
            onChange={(e) => setStudentEmail(e.target.value)}
            value={studentEmail}
            disabled
            required
          />
          {/* <p className="mb-1">Wallet Address</p>
          <input
            className="form-control"
            placeholder="Wallet Address"
            type="text"
            name="walletAddress"
            value={studentWalletAddress}
            readOnly
            required
          /> */}
          <p className="mb-1">Student Id</p>
          <input
            className="form-control"
            placeholder="Student Id"
            type="text"
            name="studentId"
            onChange={(e) => setStudentId(e.target.value)}
            value={studentId}
            readOnly
            disabled
            required
          />
          {/* <p className="mb-1">Credit</p>
          <input
            className="form-control"
            type="text"
            name="studentId"
            value={modalData.credit}
            readOnly
            disabled
            required
          />
          <p className="mb-1">Earnings</p>
          <input
            className="form-control"
            type="text"
            name="studentId"
            value={modalData.earning}
            readOnly
            disabled
            required
          />
          <p className="mb-1">Your Affiliate Code</p>
          <input
            className="form-control"
            type="text"
            name="studentId"
            value={modalData.myReferralCode}
            readOnly
            disabled
            required
          />
          <p className="mb-1">Your Affiliate Code</p>
          <input
            className="form-control"
            type="text"
            name="studentId"
            value={modalData.myReferralCode}
            readOnly
            disabled
            required
          /> */}
          <Modal.Footer className="mt-3 modalFooter text-center justify-content-center">
            {/* <button type="submit" className="adminBtnAdd">
              Save
            </button> */}
          </Modal.Footer>
        </form>

        {/* <div className="text-white"> */}
        {/* <p>
            <img
              src={modalData?.profilePicture || "/default-profile.jpg"}
              style={{ width: "250px" }}
              alt="img"
            />
          </p>
          <p>Name : {modalData?.name}</p>
          <p>
            Wallet Address :{" "}
            <span id="walletFont">{modalData?.walletAddress}</span>
          </p>
          <p>Email : {modalData?.email}</p>
          <p>Student Id : {modalData?.memberId}</p> */}
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default ViewStudent;
