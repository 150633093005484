import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
// import { BlockchainContext } from "../../Context/BlockchainContext";
import "./home.css";
import { UserContext } from "../../Context/UserContext";
const Cta = () => {
  // const { user, openWalletModal } = useContext(BlockchainContext);
  const {data:user}=useContext(UserContext)
  const [checkDevice, setCheckDevice] = useState("");
  // console.log(pathname)

  // useEffect(() => {
  //   const detecting = async () => {
  //     if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
  //       console.log("mobile");
  //       setCheckDevice(() => "mobileWithoutApp");
  //     } else if (
  //       window.innerWidth < 1000 &&
  //       typeof window.ethereum !== "undefined"
  //     ) {
  //       setCheckDevice(() => "mobileWithApp");
  //     } else if (
  //       window.innerWidth > 1000 &&
  //       typeof window.ethereum !== "undefined"
  //     ) {
  //       console.log("pc");
  //       setCheckDevice(() => "pcWithExtention");
  //     } else if (
  //       window.innerWidth > 1000 &&
  //       typeof window.ethereum === "undefined"
  //     ) {
  //       setCheckDevice(() => "pcWithoutExtention");
  //     }
  //   };

  //   detecting();
  // }, []);

  const navigate = useNavigate();

  return (
    <div className="rs-cta main-home">
      <div className="partition-bg-wrap">
        <div className="container">
          <div className="row">
            <div className="offset-lg-6 col-lg-6 pl-70 homeMiddleSection md-pl-14">
              <SectionTitle
                sectionClass="sec-title3 mb-20"
                titleClass="title white-color mb-16"
                title="20% Off! Join now!"
                descClass="desc white-color pr-100 md-pr-0"
              />
              <div className="btn-part">
                {
                  user.email ? (
                    <Link to="/my-account">
                      <button className="readon orange-btn transparent">
                        PROFILE
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="readon orange-btn transparent"
                      // onClick={() => openWalletModal()}
                      onClick={() => navigate("/sign-up")}>
                      SIGN UP NOW TO ENJOY 20% OFF!
                    </button>
                  )
                  // <>
                  //     {checkDevice === "mobileWithoutApp" && (
                  //         <a
                  //             href="https://metamask.app.link/dapp/http://studyswiftly.com"
                  //             target={"_blank"}
                  //             className="text-decoration-none"
                  //         >
                  //             <button className="readon orange-btn transparent">

                  //                 Open in metamask
                  //             </button>
                  //         </a>
                  //     )}
                  //     {checkDevice === "mobileWithApp" && (
                  //         <button
                  //             className="readon orange-btn transparent"
                  //             onClick={() => openWalletModal()}
                  //         >

                  //             SIGN UP TO ENJOY 20% OFF
                  //         </button>
                  //     )
                  //     }
                  //     {checkDevice === "pcWithExtention" && (
                  //         <button
                  //             className="readon orange-btn transparent"
                  //             // onClick={() => openWalletModal()}
                  //             onClick={() => navigate("/login")}
                  //         >
                  //             SIGN UP TO ENJOY 20% OFF
                  //         </button>
                  //     )}
                  //     {checkDevice === "pcWithoutExtention" && (
                  //         <a
                  //             href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                  //             target={"_blank"}
                  //             className="text-decoration-none"
                  //         >
                  //             <button className="readon orange-btn transparent">
                  //                 INSTALL METAMASK
                  //             </button>
                  //         </a>
                  //     )}
                  // </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
