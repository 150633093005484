import { Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import IosShareIcon from "@mui/icons-material/IosShare";
import { CSVLink } from "react-csv";
import Pagination from "../../../components/Pagination/Pagination";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Subscribers = () => {
  const [open, setOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [allEmail, setALlEmail] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");

  const handleClickOpen = (email) => {
    console.log(email);
    setEmail(email);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    axios.get("https://backend.studyswiftly.com/api/subscribe").then((res) => {
      console.log(res.data.result);
      setALlEmail(res.data.result);
    });
  }, [refetch]);

  //pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [users, setUsers] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [sliceUsers, setSliceUsers] = React.useState([]);
  console.log(sliceUsers, "subscriber");

  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(allEmail?.length / show);
    setLastPage(lastPage);
  }, [allEmail, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = allEmail.slice((page - 1) * show, page * show);
      setSliceUsers([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = allEmail.slice(0, show);
      setSliceUsers([...getSlicingProduct]);
    }
  }, [allEmail, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  //****************************** Delete Subscriber******************************/
  const handleDelete = (email) => {
    // console.log(email);
    Swal.fire({
      text: "Are you sure, you want to delete this subscriber?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("https://backend.studyswiftly.com/api/subscribe/" + email)
          .then((res) => {
            if (res.status === 200) {
              // alert(res.data.message);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              setALlEmail(allEmail.filter((email) => email.email !== email));
              setRefetch(true);
            }
          })
          .catch((error) => {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });
          });
      }
    });
  };

  const handleSendMessage = async () => {
    const messageData = {
      email: email,
      message: inputMessage,
    };

    await axios
      .post("https://backend.studyswiftly.com/api/subscribe/email", messageData)
      .then((res) => {
        if (res.status === 200) {
          handleClose();
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Attention",
          text: error.response.data.message,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };
  console.log("slice");
  console.log(sliceUsers);
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center pb-2">
        <h5 className="text-white text-uppercase pt-4">email subscribers</h5>
        <CSVLink data={allEmail} className="">
          <button className="btn btn-primary px-3 py-2 d-flex align-items-center">
            <IosShareIcon className="me-2" />
            Export
          </button>
        </CSVLink>
      </div>

      <div className="py-2 adminCard">
        <div className="tableNormal">
          <Table className="text-white">
            <thead>
              <tr>
                <th className="text-left">No</th>
                <th className="text-left">Email</th>
                <th className="text-left ">Send mail</th>
                <th className="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {sliceUsers?.map((email, index) => (
                <tr className="tableRow" key={index}>
                  <td className="text-left text-capitalize">{index + 1}</td>
                  <td
                    className="text-left "
                    style={{ textTransform: "lowercase" }}
                  >
                    {email.email}
                  </td>

                  <td className="text-left text-capitalize ">
                    <button
                      onClick={() => handleClickOpen(email?.email)}
                      className="btn px-2 py-1 text-white btn-primary"
                    >
                      Send Email
                    </button>
                  </td>
                  <td className="action">
                    <div className="actionDiv text-left">
                      <button
                        onClick={() => handleDelete(email?.email)}
                        className="deleteBtn text-white"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

              <div>
                <Dialog
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ background: "#1a1c33", color: "#fff" }}
                  >
                    {"Send Message"}
                  </DialogTitle>
                  <DialogContent
                    style={{ background: "#1a1c33", color: "#fff" }}
                  >
                    <DialogContentText id="alert-dialog-description">
                      <div className=" text-white">
                        <label htmlFor="">To: </label>
                        <input
                          type="email"
                          onChange={(e) =>
                            setEmail(e.target.value.toLocaleLowerCase())
                          }
                          value={email}
                          className="p-1 ms-2 rounded mt-2 border-1 bolder w-75"
                          style={{
                            background: "#1a1c33",
                            color: "#fff",
                            border: "2px solid #fff",
                          }}
                        />

                        <div className="mt-3">
                          <label htmlFor="">Message: </label>
                          <textarea
                            onChange={(e) => setInputMessage(e.target.value)}
                            className=""
                            name=""
                            id=""
                            cols="30"
                            rows="6"
                            placeholder="Write Message"
                          ></textarea>
                        </div>
                        <button
                          onClick={handleSendMessage}
                          className="btn px-3 py-2 text-white btn-primary"
                        >
                          Send
                        </button>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{ background: "#1a1c33", color: "#fff" }}
                  >
                    <Button className="text-danger" onClick={handleClose}>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </tbody>
          </Table>

          {sliceUsers?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscribers;
