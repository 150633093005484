import { ArrowBack, Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import axios from "axios";
import Swal from "sweetalert2";
import AdminFormsList from "./AdminFormsList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AdminForms = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [load, setLoad] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const handleCloseForm = () => setOpenForm(false);
  const handleOpenForm = () => setOpenForm(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.studyswiftly.com/api/v1/forms", data)
      .then((res) => {
        const wrapper = document.createElement("div");
        // ${res.data.message}
        wrapper.innerHTML = `<p class='text-break text-white'>Form Created.</p>`;

        Swal.fire({
          html: wrapper,
          icon: "success",
          customClass: "modal_class_success",
        });
        navigate("/admin/forms-list");
        handleCloseForm();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="adminBody">
      <p
        className="cursor-pointer"
        style={{ cursor: "pointer" }}
        title="Back"
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </p>
      {/* <h5 className="text-white text-start text-uppercase">FORMS</h5>
        <h5 className="text-white text-start"></h5> */}
      {/* <Button onClick={()=>navigate("/admin/forms-list")} variant="contained" className='my-4'>
          All List
          </Button> */}
      <Modal
        open={openForm}
        onClose={handleCloseForm}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ padding: "1rem" }}
      >
        <Box style={{ ...style }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6">FORMS</Typography>
            <IconButton onClick={handleCloseForm}>
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit} style={{ padding: "20px 5px" }}>
            <Box>
              <Grid container spacing={2}>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required style={{ width: "100%" }}>
                    <TextField
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({ ...data, name: e.target.value }))
                      }
                      value={data?.name}
                      label="Form Name"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      value={data?.url}
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          url: e.target.value,
                        }))
                      }
                      label="Form URL"
                      name="name"
                    />
                  </FormControl>
                </Grid>{" "}
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          description: e.target.value,
                        }))
                      }
                      value={data?.description}
                      label="Form Description"
                      name="description"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <Button disabled={load} type="submit" variant="contained">
                    {load && <CircularProgress></CircularProgress>} Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* {openForm ? (
        <div
          className="bg-white"
          style={{ padding: "25px 20px 40px 20px", borderRadius: "8px" }}
        >
          <form onSubmit={handleSubmit} style={{ padding: "20px 5px" }}>
            <Box>
              <Grid container spacing={2}>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required style={{ width: "100%" }}>
                    <TextField
                      variant="outlined"
                      onChange={(e) =>
                        setData((data) => ({ ...data, name: e.target.value }))
                      }
                      value={data?.name}
                      label="Form Name"
                      name="Alias"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      value={data?.url}
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          url: e.target.value,
                        }))
                      }
                      label="Form URL"
                      name="name"
                    />
                  </FormControl>
                </Grid>{" "}
                <Grid md={12} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setData((data) => ({
                          ...data,
                          description: e.target.value,
                        }))
                      }
                      value={data?.description}
                      label="Form Description"
                      name="description"
                    />
                  </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                  <Button disabled={load} type="submit" variant="contained">
                    {load && <CircularProgress></CircularProgress>} Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </div>
      ) : null} */}
      <div style={{ marginTop: "40px" }}>
        <AdminFormsList handleOpenForm={handleOpenForm} />
      </div>
    </div>
  );
};

export default AdminForms;
