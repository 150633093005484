import { ArrowBack } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";

const EditCertificate = () => {
  const { id } = useParams();
  const [singleCertificate, setSingleCertificate] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const data = async () => {
      await axios
        .get(
          `https://backend.studyswiftly.com/api/certificate/${id}`
        )
        .then((res) => {
          setSingleCertificate(res.data.result);
        });
    };
    data();
  }, []);

  const handleEditCertificate = async (e) => {
    e.preventDefault();
    const certificateNo = e.target.certificateNo.value;
    const name = e.target.name.value;
    const description = e.target.description.value;

    // const date = e.target.date.value;

    const data = {
      CertificateNo: certificateNo,
      name: name,
      description: description,
      date: singleCertificate?.date,
    };

    await axios
      .put(
        `https://backend.studyswiftly.com/api/certificate/${id}`,
        data
      )
      .then((res) => {
        if (res.status === 200) {
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>Certificate updated successfully.</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });
          e.target.reset();
          navigate("/admin/allcertificates");
        }
      })
      .catch((error) => {
        console.dir(error);
        swal({
          title: "Attention",
          text: `Something went wrong.Try again !`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  return (
    <div>
      <p
        className="cursor-pointer"
        style={{ cursor: "pointer" }}
        title="Back"
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </p>
      <div className="container certificateDiv">
        <form className="wi-100" onSubmit={handleEditCertificate}>
          <div className="backgroundImag1">
            <div className="abs_wrapper">
              <input
                className="certificateInput ms-3 border-0 transparent-input"
                type="text"
                name="certificateNo"
                defaultValue={singleCertificate?.CertificateNo}
                required
              />

              <input
                className="StudentNameInput ps-1 border-0 transparent-input"
                type="text"
                name="name"
                defaultValue={singleCertificate?.name}
                required
              />

              <div className="certificateDesInput ps-0 ">
                <textarea
                  type="text"
                  name="description"
                  className="transparent-input"
                  defaultValue={singleCertificate?.coursename}
                  required
                />
              </div>

              <input
                className="dateInput transparent-input"
                type="text"
                name="date"
                defaultValue={singleCertificate?.date}
              />
            </div>
          </div>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="profileEnrolBtnMint mb-5"
          >
            BACK
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditCertificate;
