import React from 'react';
import Slider from "react-slick";
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';


import quote from '../../assets/img/testimonial/main-home/test-2.png';
import author1 from '../../assets/img/testimonial/style5/1.png';
import author2 from '../../assets/img/testimonial/style5/2.png';
import author3 from '../../assets/img/testimonial/style5/3.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: true,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-testimonial main-home pt-100 pb-100 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title3 mb-50 md-mb-30 text-center"
                        subtitleClass="sub-title primary"
                        subtitle="Testimonies"
                        titleClass="title white-color"
                        title="What Students Are Saying"
                        effectClass="heading-line"
                    />
                    <Slider {...testimonialSettings}>
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="John"
                            Designation="Web Developer"
                            Description="I recently completed a course in blockchain from Studyswiftly.com and I was very impressed with the quality of the course. The content was very comprehensive. I highly recommend the site to anyone who wants to learn about blockchain technology."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Safiya"
                            Designation="Web Developer"
                            Description="I've taken several courses on Studyswiftly.com and have always been impressed with the quality of instruction. The courses are well-designed and the instructors are experts in their fields."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Fazli Rabbi"
                            Designation="Web Developer"
                            Description="The certificate I received after completing the course has already opened doors for me in the job market. I'm grateful for the opportunity to learn from such a reputable institution."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Aaron James"
                            Designation="Web Developer"
                            Description="This course is entirely different. I am really happy to be a part of this course because it makes me stronger, a hard worker, and more committed. By enrolling in this course, I believe I have made the best decision of my life. I've learned enough to be a junior developer thus far."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Dhiren Aarush"
                            Designation="Web Developer"
                            Description="This course is entirely different. I am really happy to be a part of this course because it makes me stronger, a hard worker, and more committed. By enrolling in this course, I believe I have made the best decision of my life. I've learned enough to be a junior developer thus far."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author2}
                            Title="Daksh Sharma"
                            Designation="App Developer"
                            Description="This course has helped me identify my passion, develop time management skills, learn how to stop procrastinating, work as a team, and meet some amazing mentors and classmates. The Blockchaincer.sg team has my sincere gratitude. Thank you to the Blockchaincer.sg staff."


                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author3}
                            Title="Sarah Ali"
                            Designation="Web Designer"
                            Description="After pressing the complete button, I'm feeling far too sentimental. I doubt that this is the final official class I will ever watch. Overall, we did manage to lay the groundwork for my career. You will be in my mind throughout my entire career. Overall, I think the adventure was wonderful.
                           "                        />
                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial