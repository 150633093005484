import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

const DashboardExpired = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [selectedTab, setSelectedTab] = useState("courses");

    // Date
    const todayDate = new Date();

    useEffect(() => {
        const data = async () => {
            await axios.get("https://backend.studyswiftly.com/api/course")
                .then(res => {
                    // const filtering = res.data.result.filter(items => new Date(`${items?.end.slice(5, 7)}/${items?.end.slice(8, 10)}/${items?.end.slice(0, 4)}`) < todayDate);
                    const filtering=res.data.result.filter(items =>items?.isExpired==true)
                    setCourses(filtering);
                })
        }
        data();
    }, [])
    // , {
    //     headers: {
    //         'authorization': `Bearer ${localStorage.getItem('courseSiteAdmin')}`
    //     }
    // }
    const handleCourseDelete = (id) => {
        // const confirmDelete = window.confirm('Are you sure, you want to delete this course?')

        Swal.fire({
            text: "Are you sure, you want to delete this course?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
          }).then((result) => {
            if(result.isConfirmed){
                axios.delete(`https://backend.studyswiftly.com/api/course/${id}`)
                .then(res => {
                    if (res.status === 200) {
                        // alert(res.data.message);
                        // swal({
                        //     title: "Success",
                        //     text: res.data.message,
                        //     icon: "success",
                        //     button: "OK!",
                        //     className: "modal_class_success",
                        // });

                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;
                        
                        Swal.fire(
                            {
                                html: wrapper,
                                icon: "success",
                                customClass: "modal_class_success",
                            }
                    )
                

                        setCourses(courses.filter(course => course._id !== id))
                    }
                })
                .catch(error => {
                    // alert(error.response.data.message);
                    // swal({
                    //     title: "Attention",
                    //     text: error.response.data.message,
                    //     icon: "warning",
                    //     button: "OK!",
                    //     className: "modal_class_success",
                    // });

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;
                    
                    Swal.fire(
                        {
                            html: wrapper,
                            icon: "warning",
                            customClass: "modal_class_success",
                        }
                )
            

                })
            }
          })

        // if (confirmDelete) {
        //     axios.delete(`https://backend.studyswiftly.com/api/course/${id}`)
        //         .then(res => {
        //             if (res.status === 200) {
        //                 // alert(res.data.message);
        //                 swal({
        //                     title: "Success",
        //                     text: res.data.message,
        //                     icon: "success",
        //                     button: "OK!",
        //                     className: "modal_class_success",
        //                 });
        //                 setCourses(courses.filter(course => course._id !== id))
        //             }
        //         })
        //         .catch(error => {
        //             // alert(error.response.data.message);
        //             swal({
        //                 title: "Attention",
        //                 text: error.response.data.message,
        //                 icon: "warning",
        //                 button: "OK!",
        //                 className: "modal_class_success",
        //             });
        //         })

        // }
    }
    return (
        <div className="tableNormal ">

                    <Table className='text-white adminDataTable '>


                        <thead>
                            <tr>
                                <th className='text-center'>Image</th>
                                <th className='text-start'>Course</th>
                                <th className='text-start adminHidden'>Amount</th>
                                <th className='text-start adminHidden'>End date</th>
                                <th className='text-start'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                courses?.map(course => (
                                    <tr className='tableRow' key={course?._id}>
                                        <td align='center'> <img className='imgAdmin' src={course?.image} alt="" /></td>
                                        <td className='text-start'>{course?.coursename}</td>
                                        <td className='text-start adminHidden'>{course?.amount>0?course?.amount:"Free"}</td>
                                        <td className='text-start adminHidden'>{`${course?.end?.slice(8, 10)}/${course?.end?.slice(5, 7)}/${course?.end?.slice(0, 4)}`}</td>
                                        <td className='action'>
                                            <div className="actionDiv text-start">
                                                <Link to={`/admin/editcourse/${course?._id}`}><button className="editBtn"><i className="fas fa-edit"></i></button></Link>
                                                <button onClick={() => handleCourseDelete(course?._id)} className="saveBtn"><i className="fa fa-clone"></i></button>
                                                <button onClick={() => handleCourseDelete(course?._id)} className="deleteBtn"><i className="fas fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }


                        </tbody>
                    </Table>
                </div>
    );
};

export default DashboardExpired;