const firebaseConfig = {
  apiKey: "AIzaSyC-zsG00x2Azhwfa92dmkt2cjYf6aGxHIo",
  authDomain: "blockchainart-4f251.firebaseapp.com",
  projectId: "blockchainart-4f251",
  storageBucket: "blockchainart-4f251.appspot.com",
  messagingSenderId: "717083032823",
  appId: "1:717083032823:web:f9cb936ac4ace5839e67f2",
};

export default firebaseConfig;
