import React, { useContext, useEffect, useState } from "react";
import "./CompanionAi.css";
import { Modal, Button, Tooltip, Form, Select, Input, Alert } from "antd";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CompanionVoiceSelect from "./CompanionVoiceSelect";
import { voices } from "../../utils/api";
import { Howl } from "howler";
import { useRef } from "react";
import { GoEye, GoEyeClosed, GoMute, GoUnmute } from "react-icons/go";
import ReactMarkdown from "react-markdown";
import Avatar from "./Avatar";
import { ProgressBar } from "@react-pdf-viewer/core";
import { handleAiChatCompletionsAssistant } from "../../utils/AiApiFunctions";
import { UserContext } from "../../Context/UserContext";
// import pdfjs from 'pdfjs-dist';
import pdfToText from "react-pdftotext";
import PdfViewer from "./PdfViewer";

import { Viewer, Worker as PdfWorker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const loadingMessages = [
  "Hang tight while we load the awesomeness!",
  "Loading the magic, please wait…",
  "Gathering pixels and data, almost there…",
  "Loading progress bar: Patience is a virtue!",
  "Fetching bits and bytes, just a moment…",
  "Loading brilliance, it’s worth the wait!",
  "One step closer to greatness, loading…",
  "Loading… Grab a snack, we’ll be quick!",
  "Loading the future, stay tuned…",
  "Loading… Meanwhile, enjoy the anticipation!",
  "We’re on it! Loading in progress…",
  "Loading the good stuff, hang in there!",
  "Loading the next big thing, just for you!",
  "Loading… Because good things take time!",
  "Building the excitement, loading…",
  "Loading… Let’s make patience a habit!",
  "Loading… The journey to excellence begins!",
  "Loading… Slow and steady wins the race!",
  "Brace yourself, loading brilliance ahead!",
  "Loading… Your patience is highly appreciated!",
];

const questionsAsk = [
  "What social media platforms should my business be on?",
  "How can I increase engagement on my social media posts?",
  "What types of content perform best on each platform?",
  "How often should I post on social media?",
  "Should I use paid advertising on social media?",
  "How can I grow my following on social media?",
  "What are the best practices for creating visually appealing posts?",
  "How can I leverage user-generated content on social media?",
  "What metrics should I track to measure the success of my social media efforts?",
  "How can I use social media to drive traffic to my website?",
  "What are some effective ways to engage with my audience on social media?",
  "How can I use storytelling to connect with my followers on social media?",
  "What are the latest trends in social media marketing?",
  "How can I use hashtags effectively on social media?",
  "What are some common mistakes businesses make on social media?",
  "How can I handle negative feedback or comments on social media?",
  "Should I use influencers to promote my brand on social media?",
  "How can I create a cohesive brand identity across all my social media channels?",
  "What are some creative ways to showcase my products or services on social media?",
  "How can I use social media to generate leads for my business?",
  "What are the benefits of using video content on social media?",
  "How can I use social media to stay connected with my existing customers?",
  "What are some effective strategies for running social media contests or giveaways?",
  "How can I optimize my social media profiles for better visibility?",
  "What are the best times to post on social media for maximum engagement?",
  "How can I use social media to build relationships with other businesses or influencers?",
  "What are some ways to repurpose content for different social media platforms?",
  "How can I use social listening to understand my audience better?",
  "Should I invest more in organic reach or paid advertising on social media?",
  "What are some tools or apps that can help me manage my social media accounts more efficiently?",
  "How can I use social media to showcase the human side of my brand?",
  "What are some strategies for creating shareable content on social media?",
  "How can I measure the ROI of my social media marketing efforts?",
  "What are the differences between B2B and B2C social media marketing?",
  "How can I use Facebook Insights to analyze the performance of my page?",
  "What are some ways to integrate social media into my overall marketing strategy?",
  "How can I use LinkedIn to connect with potential clients or partners?",
  "What are some emerging social media platforms that businesses should keep an eye on?",
  "How can I use Instagram Stories to engage with my audience?",
  "What are some strategies for building a loyal community on social media?",
  "How can I use Pinterest to drive traffic to my website or blog?",
  "What are some ways to encourage user-generated content on social media?",
  "How can I use Twitter to participate in relevant conversations in my industry?",
  "What are some strategies for creating effective Facebook ads?",
  "How can I use Instagram Reels to showcase my products or services?",
  "What are some best practices for responding to messages or comments on social media?",
  "How can I use social media to promote events or special promotions?",
  "What are some strategies for increasing reach and engagement on LinkedIn?",
  "How can I use social media to establish myself or my business as a thought leader in my industry?",
  "What are some ways to use LinkedIn Groups to connect with other professionals?",
  "How can I use social media to conduct market research or gather customer feedback?",
  "What are some strategies for leveraging user-generated content in my marketing campaigns?",
  "How can I use Instagram Insights to understand my audience better?",
  "What are some ways to create interactive content on social media?",
  "How can I use social media to drive sales for my ecommerce business?",
  "What are some strategies for building brand loyalty through social media?",
  "How can I use Facebook Groups to engage with my audience?",
  "What are some common myths or misconceptions about social media marketing?",
  "How can I use Instagram Live to connect with my audience in real-time?",
  "What are some strategies for maximizing the reach of my social media posts?",
  "How can I use social media to recruit top talent for my company?",
  "What are some strategies for running successful influencer marketing campaigns?",
  "How can I use Facebook Messenger to provide customer support?",
  "What are some ways to use social media to showcase behind-the-scenes content?",
  "How can I use social media to build trust and credibility with my audience?",
  "What are some strategies for creating compelling visual content on social media?",
  "How can I use LinkedIn Ads to target specific demographics or industries?",
  "What are some ways to measure the effectiveness of my Instagram Stories?",
  "How can I use social media to promote my blog or content marketing efforts?",
  "What are some strategies for leveraging user testimonials or reviews on social media?",
  "How can I use social media to amplify the reach of my email marketing campaigns?",
  "What are some strategies for creating viral content on social media?",
  "How can I use Facebook Pixel to track conversions from my ads?",
  "What are some ways to use social media to educate my audience about my products or services?",
  "How can I use LinkedIn Publishing to share thought leadership content?",
  "What are some strategies for optimizing my LinkedIn profile for better visibility?",
  "How can I use Instagram Ads to reach new audiences?",
  "What are some ways to use social media to support my PR efforts?",
  "How can I use social media to build partnerships or collaborations with other businesses?",
  "What are some strategies for leveraging user-generated content in my advertising campaigns?",
  "How can I use social media to drive foot traffic to my brick-and-mortar store?",
  "What are some ways to use Instagram Shopping to sell products directly through the platform?",
  "How can I use social media to recruit brand ambassadors or advocates?",
  "What are some strategies for creating engaging Instagram carousels?",
  "How can I use social media to showcase customer success stories or case studies?",
  "What are some ways to use LinkedIn Events to promote my company's events?",
  "How can I use social media to support my crisis management efforts?",
  "What are some strategies for creating effective LinkedIn Sponsored Content?",
  "How can I use Instagram Guides to curate and share helpful content with my audience?",
  "What are some ways to use social media to build anticipation for product launches or announcements?",
  "How can I use social media to target different demographics or personas within my audience?",
  "What are some strategies for creating engaging Instagram polls or quizzes?",
  "How can I use social media to foster community among my customers or followers?",
  "What are some ways to use LinkedIn Analytics to track the performance of my content?",
  "How can I use social media to highlight my company's corporate social responsibility efforts?",
  "What are some strategies for running successful Twitter chats or Q&A sessions?",
  "How can I use social media to support my employee advocacy program?",
  "What are some ways to use Instagram Insights to inform my content strategy?",
];

const CompanionAi = ({
  pdf,
  selectAvatarThemeIcon,
  setSelectAvatarThemeIcon,
  selectAvatarTheme,
  setSelectAvatarTheme,
}) => {
  const {
    data: AIuser,
    refetch: userRefetch,
    setRefetch: setUserRefetch,
  } = useContext(UserContext);
  const [generatingtext, setGeneratingText] = useState("");
  const [text, setText] = useState("");
  const [questions, setQuestions] = useState(
    "Which lecturer would you like to select?"
  );
  const [userName, setUserName] = useState(`${AIuser?.name}`);
  const [chatbotName, setChatbotName] = useState("");
  const [gender, setGender] = useState("Male");
  const [percent, setPercent] = useState(0);
  const [nameSubmitted, setnameSubmitted] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [voice, setVoice] = useState(defaultValue);
  const [usingChat, setUsingChat] = useState(false);
  const [selectedAvater, setselectedAvater] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isRestarted, setIsRestarted] = useState(false);
  const [visibleAudio, setVisibleAudio] = useState(false);
  const [audioDuration, setAudioDuration] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  const [isShort, setIsShort] = useState(true);
  const [isHaveViewMessage, setIsHaveViewMessage] = useState(false);
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * questionsAsk?.length)
  );
  const [messages, setMessages] = useState([
    { author: chatbotName, body: `Hi` },
  ]);
  // console.log('cahtbotname =====================: ', chatbotName)
  const [inputText, setInputText] = useState("");

  const [isGenerating, setIsGenerating] = useState(false);
  const [nextQues, setNextQues] = useState("1st");

  const inputRef = useRef(null);
  const divRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [voiceCount, setVoiceCount] = useState(1);
  const [finalSubmit, setfinalSubmit] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showAvatar, setshowAvatar] = useState(false);
  const [arrivalText, setarrivalText] = useState(false);
  const [words, setWords] = useState(0);
  const [audiocontent, setAudioContent] = useState(0);
  const [sound, setSound] = useState(null);
  const [viewMessages, setViewMessages] = useState([]);
  const inputRef2 = useRef(null);
  const wordRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [transactionId, setTransactionId] = useState("");

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    // generate a random 8 character alpha numeric string

    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567891011121314156655";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    setTransactionId(result);
  }, []);

  // show the user name in the first question of the chatbot
  const copyMsgs = messages;
  copyMsgs[0].body = `Hi`;

  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      setIsScrolling(false);
    } else if (event.deltaY < 0) {
      setIsScrolling(true);
    }
  };

  useEffect(() => {
    // if the text is generating then run the below code
    if (isGenerating === true) {
      const handleScroll = () => {
        const scrollTop = inputRef?.current?.scrollTop;
        setIsScrollingUp(scrollTop < inputRef?.current?._prevScrollTop);
        inputRef.current._prevScrollTop = scrollTop;
      };

      inputRef.current._prevScrollTop = 0;
      inputRef.current.addEventListener("scroll", handleScroll);
      const ref = inputRef.current;

      return () => {
        if (ref) {
          ref.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [generatingtext, isGenerating]);

  useEffect(() => {
    if (isScrolling === false && isScrollingUp === false) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
    console.log(
      isGenerating,
      text,
      isScrolling,
      isScrollingUp,
      generatingtext,
      "all the text",
      text == generatingtext
    );
  }, [text, isScrolling, isScrollingUp]);

  useEffect(() => {
    const worker = new Worker("/worker.js");

    worker.addEventListener("message", (event) => {
      const { text } = event.data;

      // setIsDoneGenerating(isDone);
      if (text !== "Processing...") {
        setText(text);
      }

      setMessages([...messages, { author: chatbotName, body: text }]);
      setInputText("");
      inputRef.current.focus();
    });

    worker.postMessage({ generatingtext, words });
    return () => {
      worker.terminate();
      // setarrivalText(false)
    };
  }, [generatingtext, isGenerating]);

  // const handleSubmitCredits = async (e) => {
  //   const deductedCredits = AIuser?.credits - 1;
  //   // console.log("question", question, conversation);

  //   await axios
  //     .put(
  //       "https://backend.whalesai.com/api/dashboard/user_signup-signin/update-credits",
  //       { email: AIuser?.email, credits: deductedCredits },
  //       {
  //         headers: {
  //           authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setUserRefetch(!userRefetch);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsHaveViewMessage(false);
  //   // setarrivalText(true)

  //   // if (divRef.current) {
  //   //   divRef.current.scrollIntoView({ behavior: "smooth" });
  //   // }
  //   localStorage.setItem("inputText", inputText);

  //   // if (AIuser.credits > 0) {
  //     setIsGenerating(true);
  //     setGeneratingText(() => "");
  //     setText("");
  //     setIsScrolling(false);
  //     setIsScrollingUp(false);
  //     setMessages([...messages, { author: userName, body: inputText }]);

  //     const prompt = messages.map((message) => message.body).join("\n");
  //     let question = `${prompt}\n${userName}: ${inputText}\n${chatbotName}:`;

  //     const response = await handleAiChatCompletionsAssistant(
  //       question,
  //       inputText
  //     );

  //     if (response) {
  //       // handleSubmitCredits();
  //       // const transactionRes = await axios.post(
  //       //   "https://backend.whalesai.com/api/v1/credits-transaction/create",
  //       //   {
  //       //     aiType: "Consultant Ai",
  //       //     transactionId: transactionId,
  //       //     requestQues: inputText,
  //       //     response: response,
  //       //     credit: 1,
  //       //     userId: AIuser?._id,
  //       //     userName: userName,
  //       //     companionName: chatbotName,
  //       //     companionAiVoice: voice,
  //       //     companionAiGender: gender,
  //       //     // userId: "6468c8ab7f567716c118ad6c",
  //       //   }
  //       // );
  //       setGeneratingText(response);
  //       handleSynthesize(response, voice);
  //       setIsGenerating(false);
  //     } else {
  //       setGeneratingText("Invalid prompt!");
  //     }

  //     // await axios
  //     //   .post(
  //     //     "https://backend.dsl.sg/api/v1/chatgpt/gpt4",
  //     //     { question: question, preset: "CHAT" },
  //     //     {
  //     //       headers: {
  //     //         authorization: `Bearer dsltoken`,
  //     //       },
  //     //     }
  //     //   )
  //     //   .then((res) => {
  //     //     if (res.status === 200 && res.data?.outputMessage) {
  //     //       handleSubmitCredits();
  //     //       setGeneratingText(res.data?.outputMessage);
  //     //       handleSynthesize(res.data?.outputMessage, voice);
  //     //       setIsGenerating(false);
  //     //     } else {
  //     //       setGeneratingText("Invalid prompt!");
  //     //     }
  //     //   })
  //     //   .catch((err) => {
  //     //     console.log(err);
  //     //   })
  //     //   .finally(() => { });
  //   // } else {
  //   //   setShowAlert(true);
  //   // }
  // };

  // const handleClearInput = () => {
  //   document.getElementById("inputText").value = "";
  //   setInputText("");
  // };

  // const handleKeyDown = (event) => {
  //   if (
  //     event.key === "Enter" &&
  //     inputText !== "" &&
  //     !event.shiftKey &&
  //     window.innerWidth > 1000
  //   ) {
  //     if (divRef.current) {
  //       divRef.current.scrollIntoView({ behavior: "smooth" });
  //     }
  //     event.preventDefault();
  //     event.stopPropagation();
  //     handleSubmit(event);
  //   }

  //   if (event.key === "Enter" && event.shiftKey) {
  //     event.preventDefault();
  //     setInputText(inputText + "\n");
  //   }

  //   if (window.innerWidth < 1000 && event.key === "Enter") {
  //     event.preventDefault();
  //     setInputText(inputText + "\n");
  //   }
  // };

  const handleFinishConsultant = async (pdfs) => {
    setPercent(100);
    // handleSynthesize("Hi " + userName + ", how can I help you?", voice);
    setselectedAvater(true);
    setIsShort(false);
    setnameSubmitted(true);
    setUsingChat(true);
    setMessages([{ author: chatbotName, body: `Hi` }]);
    setIsGenerating(true);
    setGeneratingText(() => "");
    setText("");
    setIsScrolling(false);
    setIsScrollingUp(false);

    // setMessages([])

    // Load the PDF document

    const file = await fetch(pdfs)
      .then((res) => res.blob())
      .catch((error) => console.error(error));

    // const pdf = await getDocument(pdfs).promise;

    // const pdfDoc = await PDFDocument.load(pdf);

    //   // Extract text from each page
    //   let extractedText = '';
    //   const numPages = pdfDoc.getPageCount();

    //   for (let i = 0; i < numPages; i++) {
    //     const page = pdfDoc.getPage(i);
    //     const textContent = await page.getTextContent();
    //     const pageText = textContent.items.map(item => item.str).join(' ');
    //     extractedText += pageText + '\n';
    //   }

    //   // Set the extracted text
    // console.log(extractedText,"new pdf file");

    pdfToText(file)
      .then((response) => {
        console.log(response, "responseresponse");
        setGeneratingText(response);
        handleSynthesize(response, voice);
        setIsGenerating(false);
      })
      .catch((error) => console.error("Failed to extract text from pdf"));
    // const response=handlePdf(pdf)
  };

  // const onFinish = (values) => {
  //   // if (userName && chatbotName && finalSubmit) {
  //   if (userName && chatbotName) {
  //
  //     setUsingChat(true);

  //     handleSynthesize("Hi " + userName + ", how can I help you?", voice);

  //     // if (divRef.current) {
  //     //   divRef.current.scrollIntoView({ behavior: "smooth" });
  //     // }
  //     setIsShort(false);
  //   }
  // };

  // const handlePercent = (value) => {
  //   if (value == "1st") {
  //     setPercent(35);
  //   } else if (value == "2nd") {
  //     setPercent(70);
  //   } else {
  //     setPercent(100);
  //     // make showAvatar true after 3seconds
  //     setTimeout(() => {
  //       // setShowAvatar(true);
  //     }, 3000);
  //   }
  // };

  // const onChange = (value) => {
  //   setGender(value);
  // };

  const onChangeVoices = (value) => {
    const selectedVoices = voices?.find(
      (voice) => voice.label === value.target.value,
      setChatbotName(value.target.value)
    );
    setVoice(selectedVoices.value);
    // console.log(selectedVoices.value);

    setDefaultValue(selectedVoices);
    const latestInput = localStorage.getItem("latestInput");
    handleSynthesize(latestInput, selectedVoices.value);
    // setGender(value);
  };

  useEffect(() => {
    return () => {
      if (sound) {
        sound.stop();
      }
    };
  }, [sound]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (sound) {
      sound.mute(!isMuted);
    }
  };

  const handleSynthesize = async (input, voiceParam) => {
    try {
      localStorage.setItem("latestInput", input);
      // console.log("voiceParam", voiceParam);
      const splittedVoice = voiceParam?.split("-");

      // setVoice(voices?.[randomIndex]?.value);

      const randomVoice = defaultValue?.value;
      const splittedRandomVoice = randomVoice?.split("-");

      const response = await fetch(
        "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyBnaoRoWAnWm6poRcPrETHcYF0LZryXzw8",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: {
              text: input,
            },

            voice: {
              languageCode: voiceParam
                ? `${splittedVoice?.[0]}-${splittedVoice?.[1]}`
                : `${splittedRandomVoice?.[0]}-${splittedRandomVoice?.[1]}`,
              name: voiceParam ? voiceParam : randomVoice,
            },

            audioConfig: {
              audioEncoding: "MP3",
            },
          }),
        }
      );

      const data = await response.json();
      const audioContent = data.audioContent;

      setAudioContent(audioContent);

      // create a new Howler.js sound object
      const sound = new Howl({
        src: [`data:audio/mp3;base64,${audioContent}`],
        mute: isMuted,
        onload: function () {
          const duration = sound.duration();
          setAudioDuration(duration);
        },
        onplay: function () {
          setIsPlaying(true);
          setVisibleAudio(true);
        },
        onend: function () {
          setIsPlaying(false);
        },
      });

      // play the sound automatically
      sound.play();
      setSound(sound);
      setIsPlaying(true); // set playing state to true initially
    } catch (err) {
      console.log(err);
    }
  };

  // Handle visibility change
  const handleVisibilityChange = () => {
    if (document.hidden) {
      sound.pause();
    } else {
      sound.play();
    }
  };

  // Add visibility change event listener

  // Cleanup event listener on component unmount
  useEffect(() => {
    isPlaying &&
      document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPlaying]);

  const handlePlayPause = () => {
    if (sound) {
      if (isPlaying) {
        sound.pause();
      } else {
        sound.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleRewind = () => {
    if (sound) {
      const newTime = Math.max(sound.seek() - 10, 0);
      sound.seek(newTime);
    }
  };

  const handleForward = () => {
    if (sound) {
      const newTime = Math.min(sound.seek() + 10, sound.duration());
      sound.seek(newTime);
    }
  };

  const randomIndex = Math.floor(Math.random() * voices?.length);

  useEffect(() => {
    // scroll to top on initial render
    window.scrollTo(0, 0);
    setDefaultValue(voices?.[randomIndex]);
    setChatbotName(voices?.[randomIndex]?.value);
    setVoice(voices?.[randomIndex]?.value);
  }, []);

  useEffect(() => {
    let i = 0;
    let textQ;
    if (nextQues == "1st") {
      if (!loaded) {
        textQ =
          loadingMessages[Math.floor(Math.random() * loadingMessages?.length)];
      } else {
        // textQ = "What is your name?";
        textQ = "Which lecturer would you like to select?";
      }
      if (isRestarted === false) {
        if (loaded) {
          handleSynthesize("Which lecturer would you like to select?", voice);
        }
      }
    }
    // else if (nextQues == "2nd") {
    //   textQ = "Which consultant would you like to chat with?";
    //   if (isRestarted === false) {
    //     handleSynthesize("Which consultant would you like to chat with?", voice);
    //   }
    // } else {
    //   textQ = "What is your gender?";
    //   if (isRestarted === false) {
    //     handleSynthesize("What is your gender?", voice);
    //   }
    // }
    // const interval = setInterval(() => {
    //   setQuestions(textQ.slice(0, i));
    //   i++;
    //   if (i > textQ.length) {
    //     clearInterval(interval);
    //   }
    // }, 80);

    // return () => clearInterval(interval);
  }, [nextQues, loaded, voiceCount, voice]);

  // const creditTolcalString = AIuser?.credits
  //   ? parseInt(AIuser?.credits).toLocaleString()
  //   : "0";

  // console.log("messages", messages);

  useEffect(() => {
    if (location?.state !== null) {
      setIsHaveViewMessage(true);
      setUsingChat(location.state.usingChat);
      setVoice(location.state.companionAiVoice);
      setGender(location.state.companionAiGender);
      setViewMessages(location.state.messages);
      const selectedVoices = voices?.find(
        (voice) => voice?.value === location?.state?.companionAiVoice
      );
      setDefaultValue(selectedVoices);
    }
  }, [location.state]);

  // console.log('locationnnnnnnnnnnnnnnnnnnn : ' + location.pathname)

  // if (location.pathname == "/consultant-ai-app") {
  //   console.log("its from mobile application..........");
  // } else if (location.pathname == "/consultant-ai") {
  //   console.log("its from web application...........");
  // }

  // Function to scroll textarea to bottom

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    divRef.current.scrollTop = divRef.current.scrollHeight;
  }, [messages]);

  const handlePdf = async (pdfs) => {
    const file = await fetch(pdfs)
      .then((res) => res.blob())
      .catch((error) => console.error(error));

    pdfToText(file)
      .then((text) => {
        console.log(text, "the pdf");
        return text;
      })
      .catch((error) => console.error("Failed to extract text from pdf"));
    // pdfjs.getDocument(pdfs).then(pdf => {
    //     const totalPages = pdf.numPages;

    //     // Extract text from each page
    //     for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
    //       pdf.getPage(pageNumber).then(page => {
    //         page.getTextContent().then(textContent => {
    //           const pageText = textContent.items.map(item => item.str).join(' ');
    //           console.log(`Page ${pageNumber} text:`, pageText);
    //         });
    //       });
    //     }
    //   }).catch(error => {
    //     console.error('Error loading PDF:', error);
    //   });
  };

  return (
    <div className="h-100 companion-ai-container">
      <div className="container companion-ai-main">
        {selectAvatarTheme && (
          <div className="text-uppercase custom-select">
            <Form.Item
              className="mb-4"
              name="chatbotName"
              rules={[
                {
                  required: true,
                  message: "Please enter your chatbot Name!",
                },
              ]}
              required
            >
              <CompanionVoiceSelect
                onChangeVoices={onChangeVoices}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
                setVoice={setVoice}
                voices={voices}
              />
            </Form.Item>
          </div>
        )}
        <div className="selectDiv mt-4 d-flex align-items-center justify-content-between">
          {/* <CompanionVoiceSelect
            onChangeVoices={onChangeVoices}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            setVoice={setVoice}
            voices={voices}
          /> */}
          <div>
            {showAvatar ? (
              <Tooltip placement="top" title={"Show"}>
                <GoEye
                  className="chatai-icon-styles text-dark"
                  style={{
                    marginLeft: "5px",
                    fontSize: "28px",

                    cursor: "pointer",
                  }}
                  onClick={() => setshowAvatar(false)}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={"Hide"}>
                <GoEyeClosed
                  className="chatai-icon-styles text-dark"
                  style={{
                    marginLeft: "5px",
                    fontSize: "28px",

                    cursor: "pointer",
                  }}
                  onClick={() => setshowAvatar(true)}
                />
              </Tooltip>
            )}
          </div>
          <div
            className={`${
              selectedAvater == false && setVisibleAudio == true
                ? "d-none"
                : "d-flex gap-3 align-items-end justify-content-center"
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="text-dark m-0" style={{ fontSize: "12px" }}>
                10 seconds
              </p>
              <button
                style={{ height: "30px", width: "30px" }}
                className={`bg-info border-0 text-white rounded-circle ${
                  selectedAvater == false && setVisibleAudio == true
                    ? "d-none"
                    : "d-flex align-items-center justify-content-center"
                }`}
                onClick={handleRewind}
              >
                <i class="fas fa-backward"></i>
              </button>
            </div>

            <button
              style={{ height: "30px", width: "30px" }}
              className={`${
                isPlaying ? "bg-danger" : "bg-success"
              } border-0 text-white rounded-circle ${
                selectedAvater == false && setVisibleAudio == true
                  ? "d-none"
                  : "d-flex align-items-center justify-content-center"
              }`}
              onClick={handlePlayPause}
            >
              {isPlaying ? (
                <i class="fas fa-pause"></i>
              ) : (
                <i class="fas fa-play"></i>
              )}
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="text-dark m-0" style={{ fontSize: "12px" }}>
                10 seconds
              </p>
              <button
                style={{ height: "30px", width: "30px" }}
                className={`bg-info border-0 text-white rounded-circle ${
                  selectedAvater == false && setVisibleAudio == true
                    ? "d-none"
                    : "d-flex align-items-center justify-content-center"
                }`}
                onClick={handleForward}
              >
                <i class="fas fa-forward"></i>
              </button>
            </div>
          </div>
          {isMuted ? (
            <Tooltip placement="top" title={"Mute"}>
              <GoMute
                className="chatai-icon-styles text-dark"
                style={{
                  marginLeft: "5px",
                  fontSize: "28px",

                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={"Unmute"}>
              <GoUnmute
                className="chatai-icon-styles text-dark"
                style={{
                  marginLeft: "5px",

                  fontSize: "28px",
                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            </Tooltip>
          )}
          {/* <img
            className="mute-icon"
            src={muteIcon}
            alt=""
            // onClick={handlePlayButton}
          /> */}
        </div>
        {
          <div className={`${showAvatar == false ? "d-none" : "d-block"}`}>
            <Avatar
              string={gender}
              generatingtext={generatingtext}
              defaultValue={defaultValue}
              voice={voice}
              voices={voices}
              isPlaying={isPlaying}
              loaded={loaded}
              setLoaded={setLoaded}
              isShort={isShort}
            ></Avatar>
          </div>
        }
        <div
          className="first-field-container"
          // ref={inputRef}
          // // onScroll={handleScroll2}
          // onWheel={handleScroll}
        >
          {/* <PdfViewer pdfUrl={pdf} /> */}

          {/* <iframe 
         
                        src={pdf}
                        title="PDF Viewer"
                        scrolling="auto"
                        height="100%"
                        width="100%"
                      /> */}
        { selectedAvater && <PdfWorker
            className={`${
              selectedAvater == true ? "d-none" : "d-block"
            }`}
            workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js"
          >
            <div
              style={{
                height: "550px",
                maxWidth: "900px",
                marginLeft: "auto",
                marginRight: "auto",
                color: "black",
              }}
            >
              <Viewer
                fileUrl={pdf}
                theme="dark"
                renderLoader={(percentages) => (
                  <div style={{ width: "240px" }}>
                    <ProgressBar progress={Math.round(percentages)} />
                  </div>
                )}
                plugins={[defaultLayoutPluginInstance]}
              />
            </div>
          </PdfWorker>}
          {
            <div
              className={`${
                selectedAvater == true ? "d-none" : "d-block"
              } companion-res-area p-3`}
              ref={divRef}
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <div
                className=""
                ref={inputRef}
                // onScroll={handleScroll2}
                onWheel={handleScroll}
              >
                <div class="media mb-3">
                  <>
                    <div class="media-body ml-3">
                      <div
                        className="text-light rounded  text-start ask-ai-fonts copy-contents"
                        ref={wordRef}
                      >
                        {showAlert == false ? (
                          <>
                            {usingChat ? (
                              <>
                                {isHaveViewMessage ? (
                                  <>
                                    {viewMessages?.map((message, i) => {
                                      return (
                                        console.log("viewMessages", message),
                                        (
                                          <div className="mb-4" key={i}>
                                            <div
                                              style={{
                                                color: "black",
                                              }}
                                            >
                                              <span
                                                className="chat-author text-uppercase"
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "#a500b7",
                                                }}
                                              >
                                                {message?.userName}:
                                              </span>{" "}
                                              {message.userQues}
                                            </div>
                                            <div
                                              className="mb-4"
                                              style={{
                                                color: "black",
                                                whiteSpace: "pre-line",
                                              }}
                                            >
                                              <span
                                                className="chat-author text-uppercase"
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "#003991",
                                                }}
                                              >
                                                {message?.companionName}:
                                              </span>{" "}
                                              <p>{message.companionAns}</p>
                                            </div>
                                          </div>
                                        )
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {messages.length > 0 &&
                                      messages?.map((message, i) => {
                                        const isHuman =
                                          message.author === userName;
                                        return (
                                          <div key={i}>
                                            {isHuman ? (
                                              <div
                                                className="mb-4"
                                                style={{
                                                  color: "black",
                                                }}
                                              >
                                                <span
                                                  className="chat-author text-uppercase"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#a500b7",
                                                  }}
                                                >
                                                  {userName}:
                                                </span>{" "}
                                                {message.body}
                                                {isGenerating && (
                                                  <span
                                                    className="mb-4 d-block typewriter"
                                                    style={{ color: "red" }}
                                                  >
                                                    Your lecturer is thinking ….
                                                  </span>
                                                )}
                                              </div>
                                            ) : (
                                              <>
                                                {message.body && (
                                                  <div
                                                    className="mb-4"
                                                    style={{
                                                      color: "black",
                                                      whiteSpace: "pre-line",
                                                    }}
                                                  >
                                                    <span
                                                      className="chat-author text-uppercase textContainer"
                                                      style={{
                                                        color: "#003991",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {chatbotName}:
                                                    </span>{" "}
                                                    {message.body}
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <p class="mb-0 text-dark">
                                  {/* {questions} */}
                                  <ReactMarkdown>{questions}</ReactMarkdown>
                                  {/* What is your name?
                              <br />
                              Whom you want to chat with? */}
                                </p>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Alert
                              message="You don't enough credits! Please buy credits to continue."
                              type="error"
                              showIcon
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {/* ) : (
                  <div className="text-center mx-auto">
                    {" "}
                    <PropagateLoader
                      color="white"
                      loading
                      size={window.innerWidth <= 700 ? 10 : 15}
                    />
                  </div>
                )} */}
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          }
        </div>
        {nameSubmitted ? (
          <>
            {/* <div className="second-field-container position-relative">
              <form className="text-ai-rep-form" onSubmit={handleSubmit}>
                <textarea
                  className="second-text-area"
                  type="text"
                  lang="en"
                  placeholder=""
                  value={inputText}
    //               onFocus={() => {
    // setarrivalText(true)              
    //               }}
                  id="inputText"
                  name="inputBox"
                  onKeyDown={handleKeyDown}
                  ref={inputRef2}
                  onChange={(e) => {
                    setInputText(e.target.value);
                    setUsingChat(true);
                    setText("");
                  }}></textarea>
                <button
                  className="arrow-button h-70"
                  onClick={handleSubmit}
                  disabled={inputText.length === 0}>
                  <img src='/arrow-icon.png' alt="" />
                </button>
              </form>
            </div> */}
            {/* <div>
              <small style={{ cursor: "pointer" }} onClick={() => {
                if (text==generatingtext || text=="") {
                if (!isGenerating ) {
                // if (!isGenerating) {
              setInputText(questionsAsk[randomNumber]); setUsingChat(true);
              setText("");
              let number =Math.floor(Math.random() * questionsAsk?.length)
              if (number == randomNumber) {
                number=Math.floor(Math.random() * questionsAsk?.length)
              }
            setRandomNumber(number)
            //  }
             }
             }
              }} className="text-white">
                <span className="text-warning">You can ask: </span>{questionsAsk[randomNumber]}
              </small>
            </div> */}
            {/* <div
              style={{ marginBottom: "60px", marginTop: "10px" }}
              className="ai-clearbtn">
             <button className="credits-button mt-2">
                EVERY REQUEST IS 10 CREDITS
              </button> 
              {inputText.length > 0 && (
                <button
                  className="comp-clear-button "
                  onClick={handleClearInput}>
                  CLEAR
                </button>
              )} 
            </div> */}
          </>
        ) : (
          <div>
            <>
              <div className="text-uppercase custom-select">
                <Form.Item
                  className="mb-4"
                  name="chatbotName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your chatbot Name!",
                    },
                  ]}
                  required
                >
                  <CompanionVoiceSelect
                    onChangeVoices={onChangeVoices}
                    defaultValue={defaultValue}
                    setDefaultValue={setDefaultValue}
                    setVoice={setVoice}
                    voices={voices}
                  />
                </Form.Item>
              </div>
            </>
          </div>
          // <div className="d-flex w-100 first-field-container">
          //   <Form
          //     name="normal_login"
          //     className="login-form w-100"
          //     initialValues={{
          //       remember: true,
          //     }}
          //     layout="vertical"
          //     // onFinish={onFinish}
          //   >
          //     {nextQues == "1st" ? (
          //       <>
          //         <Form.Item
          //           className="mb-2"
          //           name="userName"
          //           rules={[
          //             {
          //               required: true,
          //               message: "Please enter your Name!",
          //             },
          //           ]}
          //           // label="What is your name?"
          //           required
          //           // tooltip="This is a required field"
          //         >
          //           <Input
          //             disabled={!loaded}
          //             className="selector-second h-70"
          //             style={{ borderRadius: "4px 0 0 4px" }}
          //             placeholder=""
          //             defaultValue={AIuser?.name}
          //             onChange={(e) => setUserName(e.target.value)}
          //           />
          //         </Form.Item>
          //       </>
          //     ) : nextQues == "2nd" ? (
          //       <Form.Item
          //         className="mb-2"
          //         name="chatbotName"
          //         rules={[
          //           {
          //             required: true,
          //             message: "Please enter your chatbot Name!",
          //           },
          //         ]}
          //         // label="Whom you want to chat with?"
          //         required
          //         // tooltip="This is a required field"
          //       >
          //         {/* <Input
          //           placeholder=""
          //           onChange={(e) => setChatbotName(e.target.value)}
          //         /> */}

          //         <CompanionVoiceSelect
          //           onChangeVoices={onChangeVoices}
          //           // onChange={setChatbotName(onChangeVoices.target.value)}
          //           defaultValue={defaultValue}
          //           setDefaultValue={setDefaultValue}
          //           setVoice={setVoice}
          //           voices={voices}
          //         />
          //       </Form.Item>
          //     ) : nextQues == "3rd" ? (
          //       <select
          //         className="selector-second mb-2 h-70"
          //         style={{ borderRadius: "4px 0 0 4px" }}
          //         name=""
          //         id=""
          //         onChange={onChange}>
          //         <option value="male">Male</option>
          //         <option value="female">Female</option>
          //       </select>
          //     ) : null}
          //     {nextQues == "1st" ? (
          //       <div className="d-flex justify-content-between text-uppercase">
          //         <Form.Item>
          //           <Button
          //             type="warning"
          //             // htmlType="submit"
          //             className="login-form-button me-2 bg-warning text-uppercase"
          //             variant="contained"
          //             onClick={() => {
          //               setNextQues("1st");
          //               setVoiceCount(voiceCount + 1);
          //               setPercent(0);
          //             }}>
          //             Back
          //           </Button>
          //         </Form.Item>

          //         <Form.Item>
          //           {/* <Button
          //             type="primary"
          //             htmlType="submit"
          //             className="login-form-button text-uppercase"
          //             variant="contained"
          //             onClick={() => {
          //               if (userName) {
          //                 handlePercent("1st");
          //                 setNextQues("2nd");
          //                 setVoiceCount(voiceCount + 1);
          //               }
          //             }}>
          //             Next
          //           </Button> */}
          //         </Form.Item>
          //       </div>
          //     ) : nextQues == "2nd" ? (
          //       <>
          //         <div className="d-flex justify-content-between text-uppercase">
          //           <Form.Item>
          //             <Button
          //               type="warning"
          //               // htmlType="submit"
          //               className="login-form-button me-2 bg-warning text-uppercase"
          //               variant="contained"
          //               onClick={() => {
          //                 setNextQues("1st");
          //                 setVoiceCount(voiceCount + 1);
          //                 setPercent(0);
          //               }}>
          //               Back
          //             </Button>
          //           </Form.Item>
          //           <Form.Item>
          //             {/* <Button
          //               type="primary"
          //               htmlType={"submit"}
          //               className="login-form-button text-uppercase"
          //               onClick={() => {
          //                 if (chatbotName) {
          //                   handlePercent("2nd");
          //                   setNextQues("3rd");
          //                   setVoiceCount(voiceCount + 1);
          //                 }
          //               }}>
          //               Next
          //             </Button> */}
          //           </Form.Item>
          //         </div>
          //       </>
          //     ) : nextQues == "3rd" ? (
          //       <>
          //         <div className="d-flex justify-content-between text-uppercase">
          //           <Form.Item>
          //             <Button
          //               type="warning"
          //               // htmlType="submit"
          //               className="login-form-button me-2 bg-warning text-uppercase"
          //               onClick={() => {
          //                 setNextQues("2nd");
          //                 setVoiceCount(voiceCount + 1);
          //                 setPercent(35);
          //               }}>
          //               Back
          //             </Button>
          //           </Form.Item>
          //           <Form.Item>
          //             {/* <Button
          //               type="primary"
          //               // disabled={gender == ""}
          //               variant="contained"
          //               htmlType={"submit"}
          //               className="login-form-button text-uppercase"
          //               onClick={() => {
          //                 setfinalSubmit(true);
          //               }}>
          //               Submit
          //             </Button> */}
          //           </Form.Item>
          //         </div>
          //       </>
          //     ) : null}
          //   </Form>
          //   {
          //     <button
          //       // style={{ height: "32px" }}
          //       className="arrow-button"
          //       onClick={() => {
          //         console.log(nextQues, userName, chatbotName);
          //         if (nextQues == "1st") {
          //           if (userName) {
          //             handlePercent("1st");
          //             setNextQues("2nd");
          //             setVoiceCount(voiceCount + 1);
          //           }
          //         }
          //         if (nextQues == "2nd") {
          //           console.log("work");
          //           if (chatbotName) {
          //             console.log("work1");
          //             handlePercent("2nd");
          //             setNextQues("3rd");
          //             setVoiceCount(voiceCount + 1);
          //           }
          //         }
          //         if (nextQues == "3rd") {
          //           setfinalSubmit(true);
          //           onFinish();
          //         }
          //       }}>
          //       <img style={{ maxWidth: "21px" }} src="/arrow-icon.png" alt="" />
          //     </button>
          //   }
          // </div>
        )}
      </div>
      {/* <div class="d-flex justify-content-center ">
        <Button
          type="primary"
          htmlType={"submit"}
          className="login-form-button text-uppercase mb-5"
          onClick={() => window.location.reload()}>
          RE-START
        </Button>
      </div> */}

      {percent == 0 && (
        <Button
          type="primary"
          className="login-form-button text-uppercase ms-3"
          onClick={() => {
            // if (chatbotName) {
            // handlePercent("2nd");
            setNextQues("3rd");
            setVoiceCount(voiceCount + 1);
            handleFinishConsultant(pdf);
            setSelectAvatarThemeIcon(true);
            // }
          }}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default CompanionAi;
