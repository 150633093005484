import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Newsletter from "../Common/Newsletter";
import Footer from "../Layout/Footer/Footer";
import SearchModal from "../Layout/Header/SearchModal";
import Header from "../Layout/Header/Header";
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import OffWrap from "../Layout/Header/OffWrap";
import { BlockchainContext } from "../../Context/BlockchainContext";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import poster from "../../assets/img/poster.jpeg";
import SiteBreadcrumb from "../Common/Breadcumb";
import "./Shop.css";
import fileDownload from "js-file-download";
import { Download } from "@mui/icons-material";
import Mcq from "./Mcq";
import SpeechModal from "../CompanionAi/SpeechModal";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function EnrolledCourseDetails() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState();
  const [pdfs,setPdfs]=useState()
  const [open1, setOpen1] = useState(false);
  const [courseDetails, setcourseDetails] = useState(null);
  const [latestCourseDetails, setLatestCourseDetails] = useState({});
  console.log(latestCourseDetails, "Course details in details page");
  const [item, setItem] = useState(null);
  console.log(item, "files item");
  const [news, setNews] = useState({});
  const [amountDetails, setamountDetails] = useState();
  const { id } = useParams();
  const { user, openWalletModal, getBalanceTestnet, logOut } =
    useContext(BlockchainContext);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const title = params.get("news");
  const [typeSelectionOpen, setTypeSelectionOpen] = useState(false);

  useEffect(() => {
    const dataOfCourse = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/mint/${id}`)
        // await axios.get(`https://backend.studyswiftly.com/api/course/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setDetails(res.data.result);

            const secondFetch = async () => {
              // await axios.get(`https://backend.studyswiftly.com/api/learn/get/${res.data.result.amount}`)
              await axios
                .get(`https://backend.studyswiftly.com/api/mint/${id}`)
                .then((res) => {
                  if (res.status === 200) {
                    if (res.data.result) {
                      setcourseDetails(() => res.data.result);
                    } else {
                      setcourseDetails(() => null);
                    }
                  }
                });
            };
            secondFetch();
          }
        });
    };
    dataOfCourse();
  }, [id]);

  useEffect(() => {
    const getSingleCouse = async () => {
      await axios
        .get(
          `https://backend.studyswiftly.com/api/course/${courseDetails?.courseId}`
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.result) {
              console.log(res.data);

              setLatestCourseDetails(() => res.data.result);
            }
          }
        });
    };
    getSingleCouse();
  }, [courseDetails?.courseId]);

  const navigate = useNavigate();

  // Determine which options have data available
  const availableOptions = [];
  if (latestCourseDetails?.imageData?.length > 0) {
    availableOptions.push("IMAGES");
  }
  if (latestCourseDetails?.videoData?.length > 0) {
    availableOptions.push("VIDEOS");
  }
  if (latestCourseDetails?.pdfData?.length > 0) {
    availableOptions.push("PDFs");
  }
  // if (latestCourseDetails?.urls?.length > 8) {
  //   availableOptions.push("URLs");
  // }
  // if (latestCourseDetails?.instructions?.length > 8) {
  //   availableOptions.push("INSTRUCTIONS");
  // }
  // if (latestCourseDetails?.allMcq?.length > 0) {
  //   availableOptions.push("TEST");
  // }

  // Set the default option to the first available option, or null if none are available
  useEffect(() => {
    if (availableOptions.length > 0 && !availableOptions.includes(item)) {
      setItem(availableOptions[0]);
    }
  }, [availableOptions, item]);

  const paramsAmount = details?.amount;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const linkData = 1;
  const classData = 1;
  const Questionnairedata = 1;
  const totalData = parseFloat(
    latestCourseDetails?.imageData?.length +
      latestCourseDetails?.videoData?.length +
      latestCourseDetails?.pdfData?.length +
      linkData +
      classData +
      Questionnairedata
  );

  console.log("latestCourseDetails", latestCourseDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(location?.state?.name || 0);

  const tabStyle = "gridFilter-learn-page text-start mb-20 md-mb-30";

  // course tab items starts

  let content = "";
  if (item === "IMAGES") {
    content = (
      <>
        <div className="row">
          {latestCourseDetails?.imageData?.map((data, index) => (
            <div className="col-12 col-lg-12 pb-4">
              <img className="w-100 h-100" src={data?.image} alt="" />
              <p>{data?.value}</p>
              {/* <span className='mb-5'>Have you viewed the image? <a href="#">Yes</a> or <a href="#">No</a></span> */}
            </div>
          ))}
        </div>
      </>
    );
  } else if (item === "VIDEOS") {
    content = (
      <>
        <div className="row">
          {latestCourseDetails?.videoData?.map((data, index) => (
            <div className="col-12 col-lg-12">
              <video
                controls
                poster={poster}
                autoplay
                className="w-100"
                id="video"
                src={data?.video}
                controlsList="nodownload">
                <source src={data?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <p>{data?.value}</p>
              {/* <p className='mb-5'>Have you viewed the video? <a href="#">Yes</a> or <a href="#">No</a></p> */}
            </div>
          ))}
        </div>
      </>
    );
    // } else if (item === "FILES") {
  } else if (item === "PDFs") {
    content = (
      <>
        <div className="row">
          <h5>
            <span
              style={{
                color: "rgb(17,17,17)",
                backgroundColor: "rgb(255,255,255)",
                fontSize: "font-size: 18px",
                fontFamily: "Nunito, sans-serif",
              }}>
              {latestCourseDetails?.filetitle}
            </span>
          </h5>
          <h6>PDFs</h6>
          {latestCourseDetails?.pdfData?.map((pdf, index) => (
            <>
              <p style={{fontWeight:"bold",textDecoration:"underline",color:"#272d47"}}>
                {index + 1}. {pdf.value}
              </p>
              <div className="d-flex align-items-center justify-content-between mb-3">
              <i class="fas fa-volume-up fs-5" onClick={()=>{setPdfs(pdf?.pdf);setOpen1(true)}} height={60} width={60} style={{cursor:"pointer"}}></i>
                {/* <img onClick={()=>{setPdfs(pdf?.pdf);setOpen1(true)}} height={60} width={60} style={{cursor:"pointer"}} src="/sw1.jpeg" alt="img"/> */}
              <small className="text-end">You can download to study</small>
              </div>
              <p>
              <div className="pdf-container">
{/* <iframe
  src={pdf?.pdf}
  title="PDF Viewer"
  className="overview-pdf"
  scrolling="auto"
  height="100%"
  width="100%"
/> */}
                </div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      <div
        style={{
          height: "750px",
          maxWidth: "900px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Viewer
          fileUrl={pdf?.pdf}
          theme="dark"
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </Worker>

                {/* <embed
                  src={pdf?.pdf}
                  type="application/pdf"
                  frameBorder="0"
                  className="overview-pdf"
                  scrolling="auto"
                  height="100%"
                  width="100%"></embed> */}
                {/* {index + 1}.
                <span>
                  Please{" "}
                  <a href={pdf?.pdf} target="_blank" rel="noopener noreferrer">
                    Click Here
                  </a>{" "}
                  to see the PDF.
                </span> */}
              </p>
              {/* <p>Have you Download the file? <a href="#">Yes</a> or <a href="#">No</a></p> */}
            </>
          ))}
        </div>
      </>
    );
  } else if (item === "URLs") {
    content = (
      <>
        <div
          dangerouslySetInnerHTML={{ __html: latestCourseDetails?.urls }}></div>
        {/* <p>Have you visited the links? <a href="#">Yes</a> or <a href="#">No</a></p> */}
      </>
    );
  } else if (item === "INSTRUCTIONS") {
    content = (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: latestCourseDetails?.instructions,
          }}></div>
        {/* <p>Have you completed the classes? <a href="#">Yes</a> or <a href="#">No</a></p> */}
      </>
    );
  } else if (item === "ONLINE QUESTIONNARIE") {
    content = (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: latestCourseDetails?.questionaries,
          }}></div>
        {/* <p>Have you visited the links? <a href="#">Yes</a> or <a href="#">No</a></p> */}
      </>
    );
  }
  // else if (item === "TEST") {
  //   content = (
  //     <>
  //       <Mcq
  //         className="text-danger"
  //         latestCourseDetails={latestCourseDetails}
  //       />
  //     </>
  //   );
  // }

  // course tab items close

  {
    console.log("dataa", courseDetails?.imageData);
  }

  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      <SiteBreadcrumb
        pageTitle="Learn"
        pageName="Learn"
        breadcrumbsImg={bannerbg}
      />

      <div className="learnTop">
        <div className="news-wrapper">
          <div>
            <Container className="section-pading2">
              <p className="text-size" >
                Welcome{" "}
                <span style={{ color: "#ff5421" }}>{details?.name}</span>{" "}
                {window?.innerWidth < 500 && <br />} All the best for <br />
                <span style={{ color: "#ff5421" }}>
                  {details?.coursename}
                </span>{" "}
                !
              </p>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="imageDivLearn mb-4">
                    <img
                      src={details?.image}
                      alt=""
                      className=" w-100 mx-auto"
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-6 text-start">
                  {/* <div className="text-start pb-3">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#ff5421",
                        marginTop: "5px",
                      }}
                    >
                      {details?.coursename}
                    </Typography>
                  </div> */}
                  {/* <p>Course duration: {details?.duration} </p> */}
                  <div className="d-flex align-items-center gap-2 flex-wrap">
                    <>
                      {latestCourseDetails !== null ? (
                        <div className="course-content-type-selection-container">
                          {" "}
                          <Button
                            sx={{
                              background: "#095E68",
                              padding: "10px 20px",
                              borderRadius: "0px",
                              color: "#ffffff",
                              // marginBottom: "20px",
                              textTransform: "initial",
                              "&:hover": { background: "#095E68" },
                            }}
                            id="basic-button"
                            // aria-controls={open ? "basic-menu" : undefined}
                            // aria-haspopup="true"
                            // aria-expanded={open ? "true" : undefined}
                            // onClick={handleClick}
                            onClick={() =>
                              availableOptions?.length > 1 &&
                              setTypeSelectionOpen(!typeSelectionOpen)
                            }>
                            {item}
                            {availableOptions?.length > 1 && (
                              <KeyboardArrowDownIcon />
                            )}
                          </Button>{" "}
                          <div>
                            <div
                              style={{ zIndex: 99 }}
                              className={`course-content-items z-99 ${
                                !typeSelectionOpen ? "d-none" : "d-block"
                              }`}>
                              {availableOptions?.map((option, index) => (
                                <li
                                  key={option}
                                  onClick={() => {
                                    if (option == "TEST") {
                                      navigate(
                                        `/test/${courseDetails?.courseId}/${courseDetails?._id}`
                                      );
                                    }
                                    setItem(option);
                                    setTypeSelectionOpen(false);
                                  }}>
                                  {option}
                                </li>
                              ))}
                            </div>
                          </div>
                          {/* <Menu
                            sx={{ minWidth: "200" }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {availableOptions?.map((option, index) => (
                              <MenuItem
                                key={option}
                                sx={{ paddingRight: "42px" }}
                                onClick={() => {
                                  setItem(option);
                                  handleClose();
                                }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Menu> */}{" "}
                        </div>
                      ) : (
                        <p>No items found, we will update soon!</p>
                      )}
                    </>
                    <button
                      onClick={() =>
                        navigate(
                          `/test/${courseDetails?.courseId}/${courseDetails?._id}`
                        )
                      }
                      className="btn btn-warning"
                      style={{
                        background: "#ff5421",
                        color: "white",
                        borderRadius: 0,
                        padding: "10px 20px",
                      }}>
                      GET YOUR CERTIFICATE
                    </button>
                    {/* <img height={50} width={50} style={{cursor:"pointer"}} src="/sw2.jpeg" alt="img"/> */}
                    {/* {item === "Test" && ( */}
                    {/* <p className="text-start fw-bold ps-2 m-0">
                      Please pass the TEST to get your NFT Certificate
                    </p> */}
                    {/* )} */}
                  </div>
                  <div>{content}</div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <Newsletter
          sectionClass="rs-newsletter style1 gray-bg orange-color mb--90 sm-mb-0 sm-pb-70"
          titleClass="title mb-0 white-color"
        />
      </div>
      <SpeechModal open={open1} setOpen={setOpen1} pdfs={pdfs}></SpeechModal>
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
}

export default EnrolledCourseDetails;
