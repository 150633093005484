import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import Newsletter from "../components/Common/Newsletter";
import ScrollToTop from "../components/Common/ScrollTop";
import OffWrap from "../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../components/Common/Breadcumb";
import SearchModal from "../components/Layout/Header/SearchModal";

// Image
import favIcon from "../assets/img/fav-orange.png";
import Logo from "../assets/img/logo/dark-logo.png";
import footerLogo from "../assets/img/logo/lite-logo.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import bannerbg from "../assets/img/breadcrumbs/2.jpg";
import axios from "axios";
import swal from "sweetalert";
import { useTimer } from "react-timer-hook";
import EmailVerificationModal from "./EmailVerificationModal";
import { UserContext } from "../Context/UserContext";
import { BlockchainContext } from "../Context/BlockchainContext";
import Swal from "sweetalert2";
import useFirebase from "../Context/hook/useFirebase";
import RegisterModal from "./RegisterModal";
import { Check } from "@mui/icons-material";
const Register2 = () => {
  const [openEmail, setOpenEmail] = useState(false);
  const [otpVerify, setOtpVerify] = useState();
  const [open, setOpen] = useState(false);
  const [affiliateCheck, setAffiliateCheck] = useState(false);
  const [name, setName] = useState("");
  const [affiliate, setAffiliate] = useState("");
  const [allAffiliate, setAllAffiliate] = useState("");
  const [isError, setError] = useState(false);
  const [newPassword, setnewPassword] = useState(false);
  const [disableAfterActivation, setDisableAfterActivation] = useState(false);
  const [email, setEmail] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const navigate = useNavigate();
  const { data, refetch, setRefetch } = useContext(UserContext);
  const [forEnable, setForEnable] = useState(false);

  const [password, setpassword] = useState("");
  const [password1, setpassword1] = useState("");

  const [showPassword, setShowPassword] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const changeIcon = showPassword === true ? false : true;
  const changeIcon2 = showPassword2 === true ? false : true;
  const { user, openWalletModal } = useContext(BlockchainContext);

  const { signUpGoogle } = useFirebase();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);

  console.log("dataa" + data.email);

  useEffect(() => {
    if (data.email) {
      navigate(-1, { replace: true });
    }
  }, [data, navigate]);

  useEffect(() => {
    axios.get(`https://backend.studyswiftly.com/api/v1/user`).then((res) => {
      const datas = res?.data?.result?.map((res) => res?.myReferralCode);
      setAllAffiliate(datas);
    });
    console.log("callingTime?", data);
  }, []);

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 180);

  // Re-send OTP functionality
  const { seconds, minutes, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const handleVerifyEmail = async (e) => {
    // check if email is valid
    e.preventDefault();
    setDisableAfterActivation(true);
    if (email.length > 0 && email.includes("@" && ".")) {
      // setLoading(true);
      setEmailVerify(true);
      console.log(email);
      // await axios.post('https://backend.studyswiftly.com/api/emailverify', { email })
      await axios
        .post("https://backend.studyswiftly.com/api/v1/user/email-verify", {
          email,
        })
        .then((res) => {
          console.log(res, "--data results gotten~");
          if (res.status === 200) {
            console.log(res);
            restarting(180);
            // swal({
            //     text: res.data.message,
            //     icon: "success",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            }).then((confiremd) => {
              if (confiremd) {
                setOpenEmail(true);
              }
            });

            console.log(res);
            setOtpVerify(res.data.otp);

            setTimeout(() => {
              setDisableAfterActivation(false);
            }, 120000);
          }
        })
        .catch((err) => {
          // alert(err.response.data.message);
          console.log(err);
          setEmailVerify(false);
          // swal({
          //     title: "Attention",
          //     text: err.response.data.message,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      // swal({
      //     title: "Attention",
      //     text: "Please enter a valid email address",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Please enter a valid email address</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const handleRegisterUser = (event) => {
    event.preventDefault();

    const password = event.target.password.value;
    const Cpassword = event.target.Cpassword.value;
    if (!forEnable) {
      // return swal({
      //     title: "Attention",
      //     text: "Please verify your email!",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Please verify your email!</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
      return
    }
    if (password == Cpassword) {
      let data = {};
      if (affiliateCheck) {
        data = {
          email: email,
          password: password,
          name: name,
          otp: otpVerify,
          affiliate: affiliate,
        };
      } else {
        data = {
          email: email,
          password: password,
          name: name,
          otp: otpVerify,
        };
      }

      handleRegister(data);
    } else {
      // return swal({
      //     title: "Attention",
      //     text: "Confirm Password not match!",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Confirm Password not match!</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const handleRegister = (data) => {
    // axios.post("https://backend.studyswiftly.com/api/user/register", data, {
    axios
      .post("https://backend.studyswiftly.com/api/v1/user/signup", data, {})
      .then((res) => {
        if (res.status === 200) {
          setRefetch(!refetch);
          localStorage.setItem("tokenBlockchian", res.data.token);
          console.log(res.data.token);

          // swal({
          //     title: "Success",
          //     text: `${res.data.message}`,
          //     icon: "success",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });

          navigate("/my-account");
        }
      })
      .catch((error) => {
        // swal({
        //     title: "Attention",
        //     text: `${error.response.data.message}`,
        //     icon: "warning",
        //     button: "OK!",
        //     className: "modal_class_success",
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        })
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="others"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Sign-Up"
        pageName="Sign-Up"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-End */}

      {/* Register Start */}
      <div className="register-section pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
          {/* {!user?.walletAddress && <div className=' d-flex align-items-center justify-content-center'>
						<div className='menuText loginbuttonmobile'>
							<button style={{ fontSize: '13px' }} className="headerButtonW" role="button">
							<img className='me-2' width='18px' src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg" alt="" /> 
							LOGIN WITH WALLET</button> 
						</div>
						</div>
					
					} */}
          <div className="register-box">
            <div className="sec-title text-center mb-30">
              <h2 className="title mb-10">Sign-Up</h2>
            </div>
            <div className="styled-form">
              <div id="form-messages"></div>
              <form onSubmit={handleRegisterUser}>
                <div className="row clearfix">
                  <div className="form-group col-lg-12">
                    <div className="d-flex">
                      <input
                        onChange={(e) => {
                          setAffiliate(e.target.value);
                          if (allAffiliate.includes(e.target.value))
                            setAffiliateCheck(true);
                          else setAffiliateCheck(false);
                        }}
                        type="text"
                        id="affiliate"
                        name="affiliate"
                        placeholder="Affiliate Code "
                      />
                      <button
                        disabled={affiliateCheck}
                        className="border-0 px-4"
                        style={{
                          color: "#ffffff",
                          backgroundColor: affiliateCheck ? "green" : "red",
                        }}>
                        {affiliateCheck ? <Check></Check> : "X"}
                      </button>
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="d-flex">
                      <input
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="d-flex">
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailVerify(false);
                          setForEnable(false)
                          setDisableAfterActivation(false)
                        }}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Address "
                        required
                      />
                      <button
                        onClick={handleVerifyEmail}
                        disabled={
                          email.length === 0 || disableAfterActivation
                            ? true
                            : false
                        }
                        className="border-0 px-4"
                        style={{
                          color: "#ffffff",
                          backgroundColor:`${forEnable?"green":"#FF5421"}`,
                        }}>
                      {forEnable?"Verified":disableAfterActivation?"Processing":"Verify"}
                      </button>
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="d-flex align-items-center">
                      <input
                        type={showPassword ? "password" : "text"}
                        id="puser"
                        name="password"
                        onChange={(e) => setpassword(e.target.value)}
                        placeholder="Password"
                        required
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          marginLeft: "-40px",
                          zIndex: "10",
                        }}
                        onClick={() => {
                          setShowPassword(changeIcon);
                        }}>
                        {changeIcon ? (
                          <VisibilityIcon className="text-dark" />
                        ) : (
                          <VisibilityOffIcon className="text-dark" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="d-flex align-items-center">
                      <input
                        type={showPassword2 ? "password" : "text"}
                        id="Confirm"
                        name="Cpassword"
                        onChange={(e) => {
                          setpassword1(e.target.value);
                          // setnewPassword(true);
                        }}
                        placeholder="Confirm Password"
                        required
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          marginLeft: "-40px",
                          zIndex: "10",
                        }}
                        onClick={() => {
                          setShowPassword2(changeIcon2);
                        }}>
                        {changeIcon2 ? (
                          <VisibilityIcon className="text-dark" />
                        ) : (
                          <VisibilityOffIcon className="text-dark" />
                        )}
                      </span>
                    </div>
                  </div>
                  {!(password == password1) && password1?.length > 0 && (
                    <p className="mt-2 text-danger">
                      Your password does not match. Please check.
                    </p>
                  )}

                  <div className=" col-lg-12 col-md-12 col-sm-12 text-center">
                    <button type="submit" className="readon register-btn">
                      <span className="txt">Sign Up</span>
                    </button>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-sm-12 mt-10">
                    <div className="users">
                      Already have an account?{" "}
                      <Link to="/sign-in">Sign In</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="mt-3 d-flex align-items-center justify-content-center">
            <div className="menuText loginbuttonmobile">
              <button
                onClick={signUpGoogle}
                style={{ fontSize: "13px", backgroundColor: "black" }}
                className="headerButtonWGoogle"
                role="button">
                <img
                  className="me-2"
                  width="18px"
                  src="https://i.ibb.co/yVb01xv/Google-G-Logo-svg.webp"
                  alt=""
                />
                GOOGLE SIGN UP
              </button>
            </div>
          </div> */}
        </div>
      </div>
      {/* Register End */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
      <RegisterModal open={open} setOpen={setOpen}></RegisterModal>

      <EmailVerificationModal
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        seconds={seconds}
        open={openEmail}
        setOpenEmail={setOpenEmail}
        otpVerify={otpVerify}
        setForEnable={setForEnable}
        setError={setError}
      />
    </React.Fragment>
  );
};

export default Register2;
