import React from "react";
import { Link } from "react-router-dom";
import "./Courses.css";

const CourseSingleTwo = (props) => {
  const {
    courseClass,
    courseImg,
    courseTitle,
    catLink,
    coursePrice,
    courseCategory,
    courseDate,
    courseId,
  } = props;

  console.log(courseImg, "COURSE IMG");

  return (
    <div className={courseClass ? courseClass : "courses-item"}>
      <Link to={`/courses/${courseId}/${courseTitle?.replace(/\s+/g, "-")}`}>
        <div className="img-part">
          <img src={courseImg} alt={courseTitle} />
        </div>
        <div className="content-part">
          <ul className="meta-part">
            {coursePrice === "0.00" ? (
              <li>
                <span style={{ backgroundColor: "red" }} className="price">
                  {" "}
                  FREE{" "}
                </span>
              </li>
            ) : (
              <li>
                <span className="price bg-primary"> {coursePrice} SGD</span>
              </li>
            )}
          </ul>
          <h4
            className="title "
            style={{
              textTransform: "uppercase",
              height: "90px",
              margin: "0px",
            }}>
            <Link
              to={`/courses/${courseId}/${courseTitle?.replace(/\s+/g, "-")}`}>
              {courseTitle
                ? courseTitle
                : "Become a PHP Master and Make Money Fast"}
            </Link>
          </h4>
          <div className="bottom-part">
            <div className="info-meta">
              <ul>
                <li>
                  <button className="mordetailsBtn">
                    CLICK HERE FOR MORE DETAILS
                  </button>
                </li>
              </ul>
            </div>
            <div className="btn-part">
              <Link to={`/courses/${courseId}/${courseTitle?.replace(/\s+/g, "-")}`}>
                {props.btnText}
                <i className="flaticon-right-arrow"></i>
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CourseSingleTwo;
