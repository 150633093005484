import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import "./EditCourse.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import Swal from "sweetalert2";
import { ArrowBack } from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import MCQModal from "./MCQModal/MCQModal";
import { AdminContext } from "../../../Context/AdminContext";

const EditCourse = () => {
  const { admin, setAdmin } = useContext(AdminContext);
  const [saveAsDraft, setSaveAsDraft] = useState();
  const [category, setCategory] = useState("");
  const [Nfts, setNfts] = React.useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState("");
  const [mcqArr, setMcqArr] = useState([]);
  const [showMCQModal, setShowMCQModal] = useState(false);
  const [answer, setAnswer] = useState("");

  const [firstValue, setFirstValue] = useState(() => EditorState.createEmpty());
  const stepOne = draftToHtml(convertToRaw(firstValue.getCurrentContent()));

  const [videoValueArr, setVideoValueArr] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videoData, setVideoData] = useState();
  const [videoLoader, setvideoLoader] = useState(false);

  const [imageArr, setImageArr] = useState([]);
  const [imgData, setImgData] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageLoader, setimageLoader] = useState(false);

  const [pdfLoader, setpdfLoader] = useState(false);
  const [selectedPDFs, setSelectedPDFs] = useState([]);
  const [PdfValueArr, setPdfValueArr] = useState([]);
  const [PdfData, setPdfData] = useState();
 
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState(1);

  const [instructions, setInstructions] = useState(() =>
    EditorState.createEmpty()
  );
  const instructionsData = draftToHtml(
    convertToRaw(instructions.getCurrentContent())
  );

  const [urls, setUrls] = useState(() => EditorState.createEmpty());
  const urlsData = draftToHtml(convertToRaw(urls.getCurrentContent()));

  const [linksAndDetails, setLinksAndDetails] = useState(() =>
    EditorState.createEmpty()
  );
  const linksAndDetailsData = draftToHtml(
    convertToRaw(linksAndDetails.getCurrentContent())
  );

  const [onlineTutorial, setOnlineTutorial] = useState(() =>
    EditorState.createEmpty()
  );
  const onlineTutorialData = draftToHtml(
    convertToRaw(onlineTutorial.getCurrentContent())
  );

  const [questionary, setQuestionary] = useState(() =>
    EditorState.createEmpty()
  );
  const questionaryData = draftToHtml(
    convertToRaw(questionary.getCurrentContent())
  );

  const [refetch, setRefetch] = useState(false);

  const [secondValue, setSecondValue] = useState(() =>
    EditorState.createEmpty()
  );

  const [charLimit, setCharLimit] = useState(0);

  const [perkNfts, setPerkNfts] = useState(() => EditorState.createEmpty());
  const stepPerkNft = draftToHtml(convertToRaw(perkNfts.getCurrentContent()));

  const [howLearn, setHowLearn] = useState(() => EditorState.createEmpty());
  const howYoulearnDesc = draftToHtml(
    convertToRaw(howLearn.getCurrentContent())
  );

  const [howGetCertificate, setHowGetCertificate] = useState(() =>
    EditorState.createEmpty()
  );
  const howGetCertificateDesc = draftToHtml(
    convertToRaw(howGetCertificate.getCurrentContent())
  );

  var newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();

  if (min < 10) {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + 0 + min + ":" + ss;
  } else {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  }

  useEffect(() => {
    axios
      .get(`https://backend.studyswiftly.com/api/course/${id}`)
      .then((res) => {
        setNfts(res.data.result);
        console.log(res.data.result);

        const description = res.data.result.description;
        const howlearn = res.data.result.howlearn;
        const howGetCertificate = res?.data?.result?.howGetCertificate;
        const urlsData = res?.data?.result?.urls;
        const instructions = res?.data?.result?.instructions;
        const perkNft = res.data.result.youlearn;
        const briefDetails = res.data.result.youlearn;
        const description2 = res.data.result.description2 || "<p></p>";
        const tutoring = res.data.result.tutoring || "<p></p>";
        const questionaries = res.data.result.questionaries || "<p></p>";
        setCategory(res.data.result.categories)
        console.log(res.data.result,"res.data.result.allMcq");
        setMcqArr(res.data.result.allMcq)

        const blocksFromHtmlPerk = htmlToDraft(perkNft);
        const blocksFromHtml = htmlToDraft(description);
        const blocksFromHtmlTwo = htmlToDraft(briefDetails);
        const howlearnFromHtml = htmlToDraft(howlearn);
        const howGetCertificateFromHtml = htmlToDraft(howGetCertificate);
        const urlsFromHtml = htmlToDraft(urlsData);
        const instructionsFromHtml = htmlToDraft(instructions);
        const blocksFromDescription2 = htmlToDraft(description2);
        const blocksFromTutoring = htmlToDraft(tutoring);
        const blocksFromQuestionaries = htmlToDraft(questionaries);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const content = blocksFromHtmlTwo.contentBlocks;
        const entri = blocksFromHtmlTwo.entityMap;
        const content2 = blocksFromHtmlPerk.contentBlocks;
        const entri2 = blocksFromHtmlPerk.entityMap;
        const howLearnContent2 = howlearnFromHtml.contentBlocks;
        const howLearnEntri2 = howlearnFromHtml.entityMap;
        const howGetCertificateContent2 =
          howGetCertificateFromHtml?.contentBlocks;
        const howGetCertificateEntri2 = howGetCertificateFromHtml?.entityMap;
        const urlsContent2 = urlsFromHtml?.contentBlocks;
        const urlsEntri2 = urlsFromHtml?.entityMap;
        const instructionsContent2 = instructionsFromHtml?.contentBlocks;
        const instructionsEntri2 = instructionsFromHtml?.entityMap;
        const description2Content = blocksFromDescription2.contentBlocks;
        const description2Entity = blocksFromDescription2.entityMap;
        const tutoringContent = blocksFromTutoring.contentBlocks;
        const tutoringEntity = blocksFromTutoring.entityMap;
        const questionariesContent = blocksFromQuestionaries.contentBlocks;
        const questionariesEntity = blocksFromQuestionaries.entityMap;

        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const contentStateTwo = ContentState.createFromBlockArray(
          content,
          entri
        );
        const contentStatePerk = ContentState.createFromBlockArray(
          content2,
          entri2
        );
        const contentStateHowLearn = ContentState.createFromBlockArray(
          howLearnContent2,
          howLearnEntri2
        );

        const contentStateHowGetCertificate = ContentState.createFromBlockArray(
          howGetCertificateContent2,
          howGetCertificateEntri2
        );
        const contentStateUrls = ContentState.createFromBlockArray(
          urlsContent2,
          urlsEntri2
        );
        const contentStateInstructions = ContentState.createFromBlockArray(
          instructionsContent2,
          instructionsEntri2
        );
        const contentStateDescription2 = ContentState.createFromBlockArray(
          description2Content,
          description2Entity
        );
        const contentStateTutoring = ContentState.createFromBlockArray(
          tutoringContent,
          tutoringEntity
        );
        const contentStateQuestionaries = ContentState.createFromBlockArray(
          questionariesContent,
          questionariesEntity
        );
        setFirstValue(EditorState.createWithContent(contentState));
        setSecondValue(EditorState.createWithContent(contentStateTwo));
        setPerkNfts(EditorState.createWithContent(contentStatePerk));
        setHowLearn(EditorState.createWithContent(contentStateHowLearn));
        setHowGetCertificate(
          EditorState.createWithContent(contentStateHowGetCertificate)
        );
        setInstructions(
          EditorState.createWithContent(contentStateInstructions)
        );
        setUrls(EditorState.createWithContent(contentStateUrls));
        setLinksAndDetails(
          EditorState.createWithContent(contentStateDescription2)
        );
        setOnlineTutorial(EditorState.createWithContent(contentStateTutoring));
        setQuestionary(
          EditorState.createWithContent(contentStateQuestionaries)
        );
      });
  }, [id]);

  const handleAddMcq = () => {
    document.getElementById("mcqsQuestion").value = "";
    const mcqObj = {
      question: question,
      option1: answer,
      option2: answer1,
      option3: answer2,
      option4: answer3,
      ans: answer4,
    };

    if (question) {
      setMcqArr((prevArr) => [...prevArr, mcqObj]);
    }
    setQuestion("");
    setAnswer("");
    setAnswer1("");
    setAnswer2("");
    setAnswer3("");
    setAnswer4(1);
  };

  // form submit funtion
  const onSubForm = (e) => {
    e.preventDefault();

    const coursename = e?.target?.coursename?.value;
    const description = stepOne;
    const youlearn = stepPerkNft;
    const howlearn = howYoulearnDesc;
    const categories = e?.target?.categories?.value;
    // const start = e?.target?.startDate?.value;
    const start = Nfts.start;
    const end = e?.target?.end_date?.value;
    const sort = e?.target?.sort?.value;
    const amount = e?.target?.amount?.value;
    const image = e?.target?.image?.files[0];

    console.log("mcqArr", mcqArr);

    const formData = new FormData();
    formData.append("coursename", coursename);
    formData.append("categories", category);
    formData.append("description", description);
    formData.append("youlearn", youlearn);
    formData.append("howlearn", howlearn);
    formData.append("sort", sort);
    formData.append("howGetCertificate", howGetCertificateDesc);
    formData.append("start", start);
    formData.append("end", end);
    formData.append("amount", amount);
    formData.append("date", newDate);
    // formData.append('isDraft', isDraft)
    formData.append("image", image);
    formData.append(
      "McqData",
      JSON.stringify(mcqArr) || JSON.stringify(Nfts?.McqData)
    );
    // formData.append("filetitle", pdfTitle);
    // formData.append("imgtitle", imgTitle);
    // formData.append("videotitle", videoTitle);
    formData.append(
      "pdfData",
      JSON.stringify(PdfData) || JSON.stringify(Nfts?.pdfData)
    );
    formData.append(
      "videoData",
      JSON.stringify(videoData) || JSON.stringify(Nfts?.videoData)
    );
    formData.append(
      "imageData",
      JSON.stringify(imgData) || JSON.stringify(Nfts?.imageData)
    );

    formData.append("urls", urlsData);
    formData.append("instructions", instructionsData);

    // formData.append("description2", linksAndDetailsData);
    // formData.append("tutoring", onlineTutorialData);
    // formData.append("questionaries", questionaryData);

    axios
      .put(`https://backend.studyswiftly.com/api/course/${Nfts._id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          console.log("response after edit", res);
          // alert(res.data.message);
          // swal({
          //   title: "Success",
          //   text: `${res.data.message}`,
          //   icon: "success",
          //   button: "OK!",
          //   className: "modal_class_success",
          // });

          (async () => {
            await axios
              .post(
                `https://backend.studyswiftly.com/api/data/admin/course_edit`,
                {
                  name: admin.name,
                  email: admin.email,
                  coursename: coursename,
                  courseprice: amount,
                  coursecatagory: categories,
                  date: newDate,
                }
              )
              .then((res) => console.log(res.data, "Admin edit course action"));
          })();

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });

          setNfts(res.data.result);
          navigate("/admin/courses");
        }
      })
      .catch((err) => {
        // swal({
        //   title: "Attention",
        //   text: `${err.response.data.message}`,
        //   icon: "warning",
        //   button: "OK!",
        //   className: "modal_class_success",
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        });
      });
  };

  const redirectToNftPage = () => {
    navigate("/dashboard/courses");
  };

  const handleCourseDraft = (id) => {
    Swal.fire({
      text: "Are you sure you want to save this course as draft?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`https://backend.studyswiftly.com/api/course/draft/${id}`)
          .then((res) => {
            if (res.status === 200) {
              // alert(res.data.message);
              // swal({
              //   title: "Success",
              //   text: "Saved successfully",
              //   icon: "success",
              //   button: "OK!",
              //   className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>Saved successfully</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              console.log(res.data);
              setRefetch(!refetch);
            }
          })
          .catch((err) => {
            // swal({
            //   title: "Attention",
            //   text: `${err.response.data.message}`,
            //   icon: "warning",
            //   button: "OK!",
            //   className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "warning",
              customClass: "modal_class_success",
            });
          });
      }
    });
  };

  function removeVideoFile(index) {
    var attachments = document.getElementById("videoInput").files;
    var fileBuffer = new DataTransfer();

    for (let i = 0; i < attachments.length; i++) {
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    document.getElementById("videoInput").files = fileBuffer.files;
  }

  const handleVideoSelection = (e) => {
    if (e.target.files[0] !== 0) {
      const newVideos = Object.values(e.target.files);
      const Videos = [...selectedVideos, ...newVideos];

      const formData = new FormData();
      for (const singleVideo of Videos) {
        formData.append("videos", singleVideo);
      }

      setvideoLoader(true);

      axios
        .post(`https://backend.studyswiftly.com/api/mint/videos`, formData)
        .then((res) => {
          setSelectedVideos(res?.data?.videos);
          setvideoLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setvideoLoader(true);
        });
    }
  };

  const handleRemoveVideo = (Video, index) => {
    const filterdVideos = selectedVideos.filter((vdo) => vdo !== Video);

    const filteredData = videoData?.filter((value) => value.video != Video);
    setVideoData(filteredData);
    setSelectedVideos(filterdVideos);
    removeVideoFile(index);
  };

  const handleVideoInputValues = (e) => {
    let mainArr = [];
    let array = videoValueArr;
    array[e.target.name] = e.target.value;
    // console.log(selectedImages, array)

    selectedVideos?.map((video, index1) => {
      array?.map((value, index2) => {
        if (index1 == index2) {
          const dataObj = {
            video: video,
            value: value,
          };

          mainArr.push(dataObj);
          // console.log(dataObj, mainArr)
          setVideoData(mainArr);
          // console.log(mainArr)
        }
      });
    });
  };

  const handleImageSelection = (e) => {
    if (e.target.files[0] !== 0) {
      const newImages = Object.values(e.target.files);
      const images = [...selectedImages, ...newImages];

      const formData = new FormData();
      for (const image of images) {
        formData.append("images", image);
      }

      setimageLoader(true);

      axios
        .post(`https://backend.studyswiftly.com/api/mint/img`, formData)
        .then((res) => {
          // console.log("image form data", res.data)
          // setImg(res?.data?.images);
          setSelectedImages(res?.data?.images);
          setimageLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setimageLoader(false);
        });
    }
  };

  const handleImageInputValues = (e) => {
    let mainArr = [];
    let array = imageArr;
    array[e.target.name] = e.target.value;
    // console.log(selectedImages, array)

    selectedImages?.map((img, index1) => {
      array?.map((value, index2) => {
        if (index1 == index2) {
          const dataObj = {
            image: img,
            value: value,
          };

          mainArr.push(dataObj);
          // console.log(dataObj, mainArr)
          setImgData(mainArr);
        }
      });
    });
  };

  // please dont change any code if you dont understand---->Jahid
  function removeImageFile(index) {
    var attachments = document.getElementById("imgInput").files; // <-- reference of file input here
    var fileBuffer = new DataTransfer();

    // append the file list to an array iteratively
    for (let i = 0; i < attachments.length; i++) {
      // Exclude file in specified index
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    // Assign buffer to file input
    document.getElementById("imgInput").files = fileBuffer.files; // <-- according to your file input reference
  }

  const handleRemoveImage = (image, index) => {
    const filterdImages = selectedImages.filter((img) => img !== image);

    const filteredData = imgData?.filter((value) => value.image != image);
    setImgData(filteredData);
    setSelectedImages(filterdImages);

    removeImageFile(index);
  };

  const handlePDFSelection = (e) => {
    if (e.target.files[0] !== 0) {
      const newPDFs = Object.values(e.target.files);
      const PDFs = [...selectedPDFs, ...newPDFs];

      const formData = new FormData();
      for (const doc of PDFs) {
        formData.append("doc", doc);
      }
      // console.log("file form data", ...formData)

      setpdfLoader(true);
      axios
        .post(`https://backend.studyswiftly.com/api/mint/doc`, formData)
        .then((res) => {
          setSelectedPDFs(res?.data?.document);
          setpdfLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setpdfLoader(false);
        });
    }
  };

  const handlePDFInputValues = (e) => {
    let mainArr = [];
    let array = PdfValueArr;
    array[e.target.name] = e.target.value;
    // console.log(selectedImages, array)

    selectedPDFs?.map((pdf, index1) => {
      array?.map((value, index2) => {
        if (index1 == index2) {
          const dataObj = {
            pdf: pdf,
            value: value,
          };

          mainArr.push(dataObj);
          // console.log(dataObj, mainArr)
          setPdfData(mainArr);
          // console.log(mainArr)
        }
      });
    });
  };

  function removePDFFile(index) {
    var attachments = document.getElementById("pdfInput").files;
    var fileBuffer = new DataTransfer();

    for (let i = 0; i < attachments.length; i++) {
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    document.getElementById("pdfInput").files = fileBuffer.files;
  }

  const handleRemovePDF = (PDF, index) => {
    const filterdPDFs = selectedPDFs.filter((pdf) => pdf !== PDF);

    const filteredData = PdfData?.filter((value) => value.pdf != PDF);

    setSelectedPDFs(filterdPDFs);
    setPdfData(filteredData);
    removePDFFile(index);
  };

  const [categoriess, setCategories] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(
            res.data.Categories?.sort((a, b) => a.name.localeCompare(b.name))
          );
        });
    };
    data();
  }, []);

  return (
    <div style={{ width: "100%", marginLeft: "0" }}>
      <p
        className="cursor-pointer"
        style={{ cursor: "pointer" }}
        title="Back"
        onClick={() => navigate(-1)}>
        <ArrowBack />
      </p>
      <h5 className="text-white text-uppercase">Edit Course</h5>
      <div className="edit-nft-card">
        {/* <h4 className='pt-3 ps- container text-light'>Add Courses</h4> */}
        {/* <h5 className="text-white text-start text-uppercase pt-5 ">
          Course Details
        </h5> */}
        <h5 className="text-warning text-start text-uppercase pt-5 ps-2">
          Course Details
        </h5>
        <form onSubmit={onSubForm}>
          <div className="edit-nft-card-contents">
            <div className="edit-nft-card-content-one text-white">
              <img
                src={Nfts.image?.replace(
                  "https://backend.blockchaincert.sg/",
                  "https://backend.studyswiftly.com/"
                )}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />

              <label className="mb-1 mt-1 text-white">Image of Course</label>
              <input
                type="file"
                className="border w-100 rounded mb-3"
                name="image"
                style={{ backgroundColor: "#272d47", color: "white" }}
              />

              <label className="mb-1">Name of Course</label>
              <div className="course-name-input-container">
                <input
                  onChange={(e) => setCharLimit(e.target.value)}
                  maxLength={55}
                  type="text"
                  className="border w-100 rounded mb-3 p-2"
                  name="coursename"
                  defaultValue={Nfts.coursename}
                  style={{ backgroundColor: "#272d47", color: "white" }}
                />
                {charLimit?.length > 0 && (
                  <span
                    className={`edit-char-limit-position ${
                      charLimit.length == 55 && "text-danger"
                    }`}>
                    {charLimit.length}/55
                  </span>
                )}
              </div>
              <label className="mb-1">Category of Course</label>
              {/* <InputGroup
                className="mb-3"
                style={{ backgroundColor: "#272d47", color: "white" }}>
                <Form.Select
                  aria-label="Default select example"
                  style={{ backgroundColor: "#272d47", color: "white" }}
                  name="categories"
                  onChange={(e) => setCategory(e.target.value)}>
                  <option
                    value="Blockchain"
                    selected={Nfts?.categories === "Blockchain" ? true : false}>
                    Blockchain
                  </option>
                  <option
                    value="Digital_asset"
                    selected={
                      Nfts?.categories === "Digital_asset" ? true : false
                    }>
                    Digital Asset Management
                  </option>
                  <option
                    value="Play_To_Earn"
                    selected={
                      Nfts?.categories === "Play_To_Earn" ? true : false
                    }>
                    Play To Earn
                  </option>
                </Form.Select>
              </InputGroup> */}
              <InputGroup
                className="mb-3"
                style={{ backgroundColor: "#272d47", color: "white" }}>
                <Form.Select
                  aria-label="Default select example"
                  required
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  name="categories"
                  className=""
                  style={{ backgroundColor: "#272d47", color: "white" }}>
                  {/* <option>Type Of NFT</option> */}
                  {categoriess?.map((res) => (
                    <option value={res?.name}>{res?.name}</option>
                  ))}
                  {/* <option value="Blockchain">Blockchain</option>
                <option value="Digital_asset">Digital Asset Management</option>
                <option value="Play_To_Earn">Play To Earn</option> */}
                </Form.Select>
              </InputGroup>

              <label className="mb-1">Price of Course(SGD)</label>
              <input
                className="border w-100 rounded mb-3 p-2"
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                step="0.01"
                min={0.0}
                name="amount"
                defaultValue={Nfts.amount}
                style={{ backgroundColor: "#272d47", color: "white" }}
              />

<label className="mb-1 mt-2">Course Start Date</label>
            <div className="course-name-input-container">
              <input

                name="start_date"
           disabled
                value={new Date(Nfts?.start)?.toLocaleString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
                className="border w-100 rounded mb-3 p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
              />
            </div>
            <label className="mb-1 mt-2">Course End Date</label>
            <div className="course-name-input-container">
              <input
                type="date"
                // defaultValue={Nfts?.end?new Date(Nfts?.end)?.toISOString().split('T')[0]:""}
                name="end_date"
                className="border w-100 rounded mb-3 p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
         
              />
            </div>
            <label className="mb-1 mt-2">Sort Number</label>
            <div className="course-name-input-container">
              <input
                type="number"
                  name="sort"
                  defaultValue={Nfts?.sort}
                  className="border w-100 rounded mb-3 p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                required
              />
            </div>

              <label className="mb-2">Details of Course</label>

              <Editor
                editorState={firstValue}
                onEditorStateChange={setFirstValue}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                toolbarClassName="toolbar-class text-black"
                toolbar={{
                  image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: undefined,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                    fontFamily: {
                      options: [
                        "sans-serif",
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  },
                }}
              />

              <label className="mb-2 mt-3">What You will learn?</label>
              <Editor
                editorState={perkNfts}
                onEditorStateChange={setPerkNfts}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                toolbarClassName="toolbar-class text-black"
                toolbar={{
                  image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: undefined,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                    fontFamily: {
                      options: [
                        "sans-serif",
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  },
                }}
              />
              <label className="mb-2 mt-3">Learning outcomes</label>
              <Editor
                editorState={howLearn}
                onEditorStateChange={setHowLearn}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                toolbarClassName="toolbar-class text-black"
                toolbar={{
                  image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: undefined,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                    fontFamily: {
                      options: [
                        "sans-serif",
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  },
                }}
              />
              <label className="mb-2 mt-3">
                How you can get the certificate?
              </label>
              <Editor
                editorState={howGetCertificate}
                onEditorStateChange={setHowGetCertificate}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                toolbarClassName="toolbar-class text-black"
                toolbar={{
                  image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: undefined,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                    fontFamily: {
                      options: [
                        "sans-serif",
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  },
                }}
              />

              {/* <div className="video_div_admin">
              <iframe className="details_video" src={Nfts.vidLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> */}
            </div>
            <div className="edit-nft-card-content-two">
              <div style={{ backgroundColor: "#272d47", color: "white" }}>
                <h5 className="text-warning text-start text-uppercase pt-5">
                  Course Materials
                </h5>
            

                <label className="mb-1">URLs</label>
                <Editor
                  editorState={urls}
                  required={true}
                  onEditorStateChange={setUrls}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                  toolbarClassName="toolbar-class text-black"
                  toolbar={{
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: undefined,
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "auto",
                      },
                      fontSize: {
                        options: [
                          8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72,
                          96,
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontFamily: {
                        options: [
                          "Arial",
                          "sans-serif",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                    },
                  }}
                />
                {/* <label className="mb-1 mt-3">Website Links</label>
                <Editor
                  editorState={onlineTutorial}
                  required={true}
                  onEditorStateChange={setOnlineTutorial}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                  toolbarClassName="toolbar-class text-black"
                  toolbar={{
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: undefined,
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "auto",
                      },
                      fontSize: {
                        options: [
                          8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72,
                          96,
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontFamily: {
                        options: [
                          "Arial",
                          "sans-serif",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                    },
                  }}
                /> */}

                <label className="mb-1 mt-3 pb-5">Special Instructions</label>
                <Editor
                  editorState={instructions}
                  required={true}
                  onEditorStateChange={setInstructions}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class border mt-2 p-2 bg-white text-black"
                  toolbarClassName="toolbar-class text-black"
                  toolbar={{
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: undefined,
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "auto",
                      },
                      fontSize: {
                        options: [
                          8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72,
                          96,
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontFamily: {
                        options: [
                          "Arial",
                          "sans-serif",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                    },
                  }}
                />
    <>
                  {selectedImages.length > 0 && (
                    <div className="selected-video-container mt-10">
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          className="each-selected-video-for-priview">
                          <div className="each-selected-video-container">
                            <img
                              className="each-selected-image"
                              // src={URL.createObjectURL(image)}
                              src={image}
                              alt=""
                            />
                            <Close
                              className="selected-image-remove-button"
                              fontSize="small"
                              onClick={() => handleRemoveImage(image, index)}
                            />
                          </div>
                          <div className="">
                            <p className="mb-1">Add title</p>
                            <input
                              maxLength={55}
                              type="text"
                              name={index}
                              defaultValue="Course title"
                              className="border  h- rounded  p-"
                              // onChange={(e) => setImgTitle(e.target.value)}
                              onChange={(e) => handleImageInputValues(e)}
                              style={{
                                backgroundColor: "#272d47",
                                color: "white",
                                width: "100%",
                                height: "35px",
                              }}
                              required
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>

                <label className="mb-1 mt-10">
                  {imageLoader ? "Uploading Images..." : "Upload Images"}
                </label>
                <input
                  id="imgInput"
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  multiple
                  name="images"
                  // onChange={onChangePicture}
                  onChange={(e) => handleImageSelection(e)}
                  className="border w-100 rounded mb-3"
                  style={{ backgroundColor: "#272d47", color: "white" }}
                  // required
                />

                <>
                  {selectedVideos.length > 0 && (
                    <div className="selected-video-container">
                      {selectedVideos.map((video, index) => (
                        <div
                          key={index}
                          className="each-selected-video-for-priview">
                          <div className="each-selected-video-container">
                            <video
                              controls
                              // src={URL.createObjectURL(video)}
                              src={video}
                              className="each-selected-vidoe">
                              <source
                                // src={URL.createObjectURL(video)}
                                src={video}
                              />
                            </video>
                            <Close
                              className="selected-image-remove-button"
                              fontSize="small"
                              onClick={() => handleRemoveVideo(video, index)}
                            />
                          </div>
                          <div className="">
                            <p className="mb-1">Add title</p>
                            <input
                              maxLength={55}
                              type="text"
                              name={index}
                              defaultValue="Video title"
                              className="border  h- rounded mb-3 p-"
                              // onChange={(e) => setImgTitle(e.target.value)}
                              onChange={(e) => handleVideoInputValues(e)}
                              style={{
                                backgroundColor: "#272d47",
                                color: "white",
                                width: "100%",
                                height: "35px",
                              }}
                              required
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>

                <label className="mb-1">
                  {videoLoader ? "Uploading Videos..." : "Upload Videos"}
                </label>
                <input
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  multiple
                  id="videoInput"
                  // name="image"
                  // onChange={onChangeVideo}
                  onChange={(e) => handleVideoSelection(e)}
                  className="border w-100 rounded mb-3"
                  style={{ backgroundColor: "#272d47", color: "white" }}
                  // required
                />

                <>
                  {selectedPDFs.length > 0 && (
                    <div className="selected-video-container">
                      {selectedPDFs.map((pdf, index) => (
                        <div
                          key={index}
                          className="each-selected-video-for-priview">
                          <div className="each-selected-video-container">
                            <embed
                              // src={URL.createObjectURL(pdf)}
                              src={pdf}
                              type="application/pdf"
                              frameBorder="0"
                              className="each-selected-image"
                              scrolling="auto"
                              // height="100%"
                              // width="100%"
                            ></embed>

                            <Close
                              className="selected-image-remove-button"
                              fontSize="small"
                              onClick={() => handleRemovePDF(pdf, index)}
                            />
                          </div>
                          <div className="">
                            <p className="mb-1">Add title</p>
                            <input
                              maxLength={55}
                              type="text"
                              name={index}
                              defaultValue="File title"
                              className="border  h- rounded mb-3 p-"
                              // onChange={(e) => setImgTitle(e.target.value)}
                              onChange={(e) => handlePDFInputValues(e)}
                              style={{
                                backgroundColor: "#272d47",
                                color: "white",
                                width: "100%",
                                height: "35px",
                              }}
                              required
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>

                <label className="mb-1">
                  {pdfLoader ? "Uploading Pdf..." : "Upload Pdfs"}
                </label>
                <input
                  id="pdfInput"
                  type="file"
                  // accept="application/pdf,application/vnd.ms-excel"
                  // accept="application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf,image/*"
                  accept="application/pdf,
      application/vnd.ms-powerpoint,
      application/msword,
      application/vnd.openxmlformats-officedocument.presentationml.slideshow,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.openxmlformats-officedocument.presentationml.presentation,
      .pps"
                  multiple
                  // name="image"
                  // onChange={onChangePDF}
                  onChange={(e) => handlePDFSelection(e)}
                  className="border w-100 rounded mb-3"
                  style={{ backgroundColor: "#272d47", color: "white" }}
                  // required
                />
                <h5 className="text-warning text-start text-uppercase pt-30 mb-2 ps-0">
                  Course Certification
                </h5>
                <label className="mb-1 mt-3">MCQs</label>
                <div className="course-name-input-container">
              <textarea
                // maxLength={55}
                type="text"
                id="mcqsQuestion"
                name="mcqsQuestion"
                className="border w-100 rounded p-2"
                onChange={(e) => setQuestion(e.target.value)}
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
              />
              {/* {charLimit?.length > 0 &&
                    < span className={`char-limit-position ${charLimit.length == 55 && "text-danger"}`}>{charLimit.length}/55</span>} */}
            </div>
            <div className="d-flex justify-content-end">
              <p className="text-end mb-0">
                Added : {mcqArr?.length > 0 ? mcqArr?.length : "0"}
              </p>
            </div>
            {/* <label className="mb-1">Response type</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <Form.Select
                aria-label="Default select example"
                required
                name="answer"
                className=""
                style={{ backgroundColor: "#272d47", color: "white" }}>
                <option>Yes / No</option>
                {/* <option value="Yes">Yes</option>
                    <option value="No">No</option> 
              </Form.Select>
            </InputGroup> */}
            <label className="mb-1">Ideal answer 1</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer}
                onChange={(e) => {
                  setAnswer(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>
            <label className="mb-1">Ideal answer 2</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer1}
                onChange={(e) => {
                  setAnswer1(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>{" "}
            <label className="mb-1">Ideal answer 3</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer2}
                onChange={(e) => {
                  setAnswer2(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>{" "}
            <label className="mb-1">Ideal answer 4</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <input
                className="border w-100 rounded p-2"
                style={{
                  backgroundColor: "#272d47",
                  color: "white",
                  position: "relative",
                }}
                value={answer3}
                onChange={(e) => {
                  setAnswer3(e.target.value);
                  console.log(e.target.value);
                }}></input>
            </InputGroup>
            <label className="mb-1">Correct Ans</label>
            <InputGroup
              className="mb-2"
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <Form.Select
                aria-label="Default select example"
                required
                onChange={(e) => setAnswer4(e.target.value)}
                value={answer4}
                name="answer"
                className=""
                style={{ backgroundColor: "#272d47", color: "white" }}>
                <option value={1}>Ideal answer 1</option>
                <option value={2}>Ideal answer 2</option>
                <option value={3}>Ideal answer 3</option>
                <option value={4}>Ideal answer 4</option>
              </Form.Select>
            </InputGroup>
                <button
                  className="btn btn-primary btn-sm mt-2"
                  style={{ padding: "5px 5px", fontSize: "10px" }}
                  type="button"
                  onClick={() => {
                    setShowMCQModal(true);
                  }}>
                  <i class="fas fa-eye me-1"></i>
                  View
                </button>
                <div className="d-flex justify-content-center text-center pt-4">
                  <p
                    className="btn btn-success btn-sm bg-success"
                    style={{ padding: "5px 25px" }}
                    onClick={handleAddMcq}>
                    Add
                  </p>
                </div>

                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}>
                  <Button
                    type="button"
                    onClick={redirectToNftPage}
                    className="btn btn-danger me-2 text-uppercase"
                    style={{ backgroundColor: "#dc3545" }}>
                    CANCEL
                  </Button>
                  <Button
                    onClick={() => handleCourseDraft(Nfts?._id)}
                    style={{ backgroundColor: "blueviolet" }}
                    type="submit"
                    className="edit-nft-update-button me-2 text-uppercase">
                    Draft
                  </Button>
                  <Button
                    type="submit"
                    className="edit-nft-update-button me-2 text-uppercase bg-primary">
                    Publish
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <MCQModal
        show={showMCQModal}
        mcqArr={mcqArr}
        setMcqArr={setMcqArr}
        setShowMCQModal={setShowMCQModal}
        onHide={() => setShowMCQModal(false)}
      />
    </div>
  );
};

export default EditCourse;
