import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { UserContext } from "../../Context/UserContext";

const Scholarship = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [load, setLoad] = useState(false);
  const { data: user } = useContext(UserContext);

  useEffect(() => {
    console.log(user?.email, "user?.email");
    setData((data) => ({ ...data, userEmail: user?.email }));
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(data).length > 24) {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Fill up all the required data</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
      return;
    }
    axios
      .post("https://backend.studyswiftly.com/api/scholarship", data)
      .then((res) => {
        const wrapper = document.createElement("div");
        // ${res.data.message}
        wrapper.innerHTML = `<p class='text-break text-white'>Thanks for Applying for the Scholarship.
        The Admin will notify you once it is reviewed.</p>`;

        Swal.fire({
          html: wrapper,
          icon: "success",
          customClass: "modal_class_success",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Full Scholarship Application Form"
        pageName="Full Scholarship Application Form"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      <div className="container" style={{ padding: "25px 0 40px 0" }}>
        {" "}
        <form onSubmit={handleSubmit}>
          {/* <Card> */}
          <Box sx={{ padding: "20px 5px" }}>
            <Grid container spacing={2}>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Personal Information</h5>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({ ...data, fullName: e.target.value }))
                    }
                    value={data?.fullName}
                    label="Full Name"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    type="date"
                    value={data?.dateOfBirth}
                    focused
                    required
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        dateOfBirth: e.target.value,
                      }))
                    }
                    label="Date of Birth"
                    name="name"
                  />
                </FormControl>
              </Grid>{" "}
              <Grid lg={12} md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    label="Gender"
                    name="Gender"
                    required
                    variant="outlined"
                    onChange={(e) => {
                      setData((data) => ({ ...data, gender: e.target.value }));
                    }}
                    value={data?.gender}>
                    <MenuItem key="male" value="male">
                      Male
                    </MenuItem>
                    <MenuItem key="female" value="female">
                      Female
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({ ...data, phone: e.target.value }))
                    }
                    value={data?.phone}
                    label="Phone"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({ ...data, email: e.target.value }))
                    }
                    value={data?.email}
                    label="Email"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    onChange={(e) =>
                      setData((data) => ({ ...data, mailing: e.target.value }))
                    }
                    value={data?.mailing}
                    required
                    label="Mailing Address"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Educational Background</h5>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        currentSchool: e.target.value,
                      }))
                    }
                    value={data?.currentSchool}
                    label="Current School/College/University"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    onChange={(e) =>
                      setData((data) => ({ ...data, grade: e.target.value }))
                    }
                    value={data?.grade}
                    required
                    label="Grade/Year Level"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    onChange={(e) =>
                      setData((data) => ({ ...data, gpa: e.target.value }))
                    }
                    value={data?.gpa}
                    required
                    label="GPA/Percentage"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        extraCurricular: e.target.value,
                      }))
                    }
                    value={data?.extraCurricular}
                    required
                    label="Extracurricular Activities"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Scholarship Details</h5>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        nameOfScholarship: e.target.value,
                      }))
                    }
                    value={data?.nameOfScholarship}
                    label="Name of Scholarship"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    value={data?.whyApply}
                    multiline
                    rows={5}
                    required
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        whyApply: e.target.value,
                      }))
                    }
                    label="Why are you applying for this scholarship? (Max 200 words)"
                    name="description"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    value={data?.utlilizeThis}
                    multiline
                    rows={5}
                    required
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        utlilizeThis: e.target.value,
                      }))
                    }
                    label="How do you plan to utilize this scholarship to further your academic or career goals? (Max 200 words)"
                    name="description"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Interest and Achievements:</h5>
              </Grid>
              <Grid md={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    value={data?.interest}
                    multiline
                    required
                    rows={5}
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        interest: e.target.value,
                      }))
                    }
                    label="Briefly describe your academic interests and any achievements related to your field of interest. (Max 200 words)"
                    name="description"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Financial Information</h5>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    required
                    variant="outlined"
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        annualIncome: e.target.value,
                      }))
                    }
                    value={data?.annualIncome}
                    label="Annual Household Income"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        numberHouseHold: e.target.value,
                      }))
                    }
                    value={data?.numberHouseHold}
                    label="Number of Dependents in Household"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        financialConsideration: e.target.value,
                      }))
                    }
                    value={data?.financialConsideration}
                    label="Any additional financial considerations"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">References</h5>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    required
                    variant="outlined"
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        referenceAcademic: e.target.value,
                      }))
                    }
                    value={data?.referenceAcademic}
                    label="Name and Contact Information of Academic Reference (Teacher/Professor)"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    required
                    variant="outlined"
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        referencePersonal: e.target.value,
                      }))
                    }
                    value={data?.referencePersonal}
                    label="Name and Contact Information of Personal Reference (Non-family member)"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Essay</h5>
              </Grid>
              <Grid md={12} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    multiline
                    required
                    rows={5}
                    onChange={(e) =>
                      setData((data) => ({ ...data, essay: e.target.value }))
                    }
                    value={data?.essay}
                    label="Please attach an essay (500-700 words) discussing a topic related to your field of interest or a significant personal experience that has influenced your academic journey."
                    name="description"
                  />
                </FormControl>
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Declaration</h5>
              </Grid>{" "}
              <Grid md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiCheckbox-root": {
                          backgroundColor: "#ff5421",
                        },
                      }}
                      checked={data?.dec1}
                      onChange={(e) =>
                        setData((data) => ({ ...data, dec1: e.target.checked }))
                      }
                      name="inActive"
                    />
                  }
                  label="I certify that all information provided in this application is accurate and complete to the best of my knowledge.
                    "
                />
              </Grid>
              <Grid md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.dec2}
                      onChange={(e) =>
                        setData((data) => ({ ...data, dec2: e.target.checked }))
                      }
                      name="inAct"
                    />
                  }
                  label="I understand that any false information may result in disqualification from consideration for the scholarship."
                />
              </Grid>
              <Grid md={12} xs={12}>
                <h5 className="section_heading">Signature</h5>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setData((data) => ({ ...data, sign: e.target.value }))
                    }
                    value={data?.sign}
                    label="Signature"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
              <Grid md={6} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    variant="outlined"
                    type="date"
                    focused
                    required
                    onChange={(e) =>
                      setData((data) => ({ ...data, date: e.target.value }))
                    }
                    value={data?.date}
                    label="Date"
                    name="Alias"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ justifyContent: "flex-end" }} className="container">
            <Button disabled={load} type="submit" variant="contained">
              {load && <CircularProgress></CircularProgress>} Submit Request
            </Button>
          </Box>
          {/* </Card> */}
        </form>
      </div>
      {/* <button onClick={()=>navigate("/my-account",{state:{name:1}})}>button</button> */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default Scholarship;
