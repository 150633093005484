import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import logo from "../../assets/img/dslogo/logo192.png";
import MobileVerifyModal from "./MobileVerifyModal";
import { useTimer } from "react-timer-hook";
import PhoneInput from "react-phone-number-input";
import { UserContext } from "../../Context/UserContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./Shop.css";
import { BlockchainContext } from "../../Context/BlockchainContext";

import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";

import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { Card } from "react-bootstrap";
import {
  Button,
  capitalize,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import EnrolledItems from "./EnrolledItems";
import EmailVerificationModal from "../../pages/EmailVerificationModal";
import Swal from "sweetalert2";

const AccountMain = ({ expiryTimestamp, locations }) => {
  const navigate = useNavigate();
  // const {email} = useParams();
  const [selectedImage, setSelectedImage] = useState();
  const [image, setImage] = useState();
  const [usernameOrigin, setUsernameOrigin] = useState("");
  const [mobileVerify, setMobileVerify] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otpVerify, setOtpVerify] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [EmailOtpVerify, setEmailOtpVerify] = useState();
  const [emailVerify, setEmailVerify] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [isUpdatedEmail, setIsUpdatedEmail] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [nameChange, setNameChange] = useState("");
  const [imageChange, setImageChange] = useState("");

  const [openMobile, setOpenMobile] = useState(false);
  const [isError, setError] = useState(false);
  const [isUpdatedName, setUpdatedName] = useState(false);
  const [isUpdatedImage, setUpdatedImage] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [disableAfterActivation, setDisableAfterActivation] = useState(false);
  const [emailDisableAfterActivation, setEmailDisableAfterActivation] =
    useState(false);
  const [value, setValue] = useState();
  const [refetchUser2, setRefetchUser2] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [enrolled, setEnrolled] = useState([]);
  const [certificateMinted, setMintedCer] = useState([]);
  const [enrolledLength, setEnrolledLength] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const [certificateDetails, setCertificateDetails] = useState({});
  const [serialNumber, setSerialNumber] = useState("");
  const date = new Date();
  const [forEnable, setForEnable] = useState(false);
  const [emailForEnable, setEmailForEnable] = useState(false);
  // const [userData, setUserData] = useState({});
  const { data, refetch, logout, setRefetch } = useContext(UserContext);
  const {
    user,
    connectToMetamask,
    openWalletModal,
    getBalanceTestnet,
    logOut,
    setRefetchUser,
    setRefetchUserName,
    setRefetchUserEmail,
  } = useContext(BlockchainContext);

  // Copy Text
  const [copyTextAffiliateCode, setCopyTextAffiliateCode] = useState("");
  const [copyTextAffiliateLink, setCopyTextAffiliateLink] = useState("");
  const [copyTextWalletAddress, setCopyTextWalletAddress] = useState("");
  const [tabIndex, setTabIndex] = useState(locations?.name || 0);

  let newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  newDate = dd + "/" + mm + "/" + yyyy;
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.index) {
      setTabIndex(location?.state?.index);
    }
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function () {
        console.warn("TATIIIIIIIIIIII");

        connectToMetamask();
      });
    }
  }, []);

  const [checkDevice, setCheckDevice] = useState("");
  // console.log(pathname)
  const [certificates, setCertificates] = useState([]);
  useEffect(() => {
    const data5 = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/certificate/`)
        .then((res) => {
          setCertificates(
            res.data.result?.filter(
              (res) => res.status == "approve" && res?.email == data?.email
            )
          );
        });
    };
    data5();
  }, [data]);

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);

  useEffect(() => {
    getBalanceTestnet();
    setCopyTextWalletAddress(data?.walletAddress ? data?.walletAddress : "");
    setCopyTextAffiliateCode(data?.myReferralCode ?data?.myReferralCode : "");
    // setNameChange(user?.name ? user?.name : "");
    // setCopyTextAffiliateLink(
    //   window.location.origin || user?.myReferralCode
    //     ? window.location.origin + "/" + user?.myReferralCode
    //     : ""
    // );
    setCopyTextAffiliateLink(
      window.location.origin || data?.myReferralCode
        ? window.location.origin + "/" + data?.myReferralCode
        : ""
    );
  }, [user]);

  const splitedFromDate = certificateDetails?.fromDate?.split(" ")?.[0];

  // ********************************************** walletAddress
  const copyToClipboardAffiliateCode = (code) => {
    copy(code);

    if (code !== "") {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>You have copied "${code}"</p>`;

      Swal.fire({
        // title: 'Successfully updated your Full Name.',
        html: wrapper,
        icon: "success",
        customClass: "modal_class_success",
      });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Nothing to Copy</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  // ********************************************** Affiliate Link
  const copyToClipboardAffiliateLink = (code) => {
    copy(code);

    if (code !== "") {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>You have copied "${code}"</p>`;

      Swal.fire({
        // title: 'Successfully updated your Full Name.',
        html: wrapper,
        icon: "success",
        customClass: "modal_class_success",
      });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Nothing to Copy</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  // ********************************************** walletAddress

  const copyToClipboardWalletAddress = () => {
    copy(copyTextWalletAddress);

    if (copyTextWalletAddress !== "") {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>You have copied "${copyTextWalletAddress}"</p>`;

      Swal.fire({
        // title: 'Successfully updated your Full Name.',
        html: wrapper,
        icon: "success",
        customClass: "modal_class_success",
      });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Nothing to Copy</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const LogOut = () => {
    logOut();
    logout();
    navigate("/");
    swal({
      text: `Successfully Signed Out!`,
      icon: "success",
      button: "OK!",
      className: "modal_class_success",
    });
  };
  useEffect(() => {
    axios
      .get("https://restcountries.com/v2/all?fields=alpha2Code,callingCodes")
      .then((res) => {
        setCountryList(res.data);
      });

    console.log("dataa" + data.email);
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://backend.studyswiftly.com/api/mint/data/${user?.walletAddress}`
      )
      .then((res) => {
        setEnrolled(res.data.result);
        setEnrolledLength(res.data.result.length);
      });
  }, []);
  console.log(enrolled);

  useEffect(() => {
    axios.get("https://api.ipregistry.co/?key=nd2chql8jm9f7gxa").then((res) => {
      // setLocatedCountry(res.data);
      setCountryCode(res.data.location.country.calling_code);
    });
  }, []);

  // name update
  const handleNameSubmit = async () => {
    // if (data?._id) {
    if (data?.email) {
      const formDataAddUser = new FormData();
      formDataAddUser.append("name", nameChange);

      await axios
        .put(
          `https://backend.studyswiftly.com/api/v1/user/update`,
          formDataAddUser,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "tokenBlockchian"
              )}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setRefetchUserName(true);
            setUpdatedName(true);
            // document.getElementById("fname").value = "";

            setNameChange("");

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>Successfully updated</p>`;
            setRefetch(!refetch);
            Swal.fire({
              // title: 'Successfully updated your Full Name.',
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });
            // navigate(-1);
          }
        })
        .catch((error) => {
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        });
    } else {
      openWalletModal();
    }
  };
  // name update
  const handleImageSubmit = async () => {
    if (data?.email) {
      const formDataAddUser = new FormData();
      formDataAddUser.append("image", selectedImage);

      console.log(selectedImage);

      await axios
        .put(
          // `https://backend.studyswiftly.com/api/wallet/update/${user?._id}`,
          `https://backend.studyswiftly.com/api/v1/user/update`,
          formDataAddUser,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "tokenBlockchian"
              )}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            setRefetchUser(true);
            setUpdatedImage(true);
            // setUpdatedName(true);
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>Successfully updated!</p>`;

            Swal.fire({
              // title: 'Successfully updated your Full Name.',
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });
            // navigate(-1);
          }
        })
        .catch((error) => {
          // alert(error.response.data.message);
          // console.log(error);
          // setIsLoadingAdmin(false);
          // console.log("not post");
          // swal({
          //     title: "Attention",
          //     text: `${error.response.data.message}`,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        });
    } else {
      openWalletModal();
    }
  };
  // name update
  const handleEmailSubmit = async () => {
    console.log("after verify");

    if (data?.email) {
      const formDataAddUser = new FormData();
      formDataAddUser.append("email", email);

      setEmailVerify(true);

      await axios
        .put(
          // `https://backend.studyswiftly.com/api/wallet/update/${user?._id}`,
          `https://backend.studyswiftly.com/api/v1/user/update`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "tokenBlockchian"
              )}`,
            },
          },
          formDataAddUser
        )
        .then((res) => {
          if (res.status === 200) {
            console.log("verified done");
            setRefetchUserEmail(true);
            setIsUpdatedEmail(true);
            setEmail("");
            setIsVerified(true);
            // setUpdatedName(true);
            // const wrapper = document.createElement("div");
            // wrapper.innerHTML = `<p class='text-break text-white'>Successfully updated!</p>`;

            // Swal.fire(
            //     {
            //         // title: 'Successfully updated your Full Name.',
            //         html: wrapper,
            //         icon: "success",
            //         customClass: "modal_class_success",
            //     }

            // )
            // navigate(-1);
          }
        })
        .catch((error) => {
          setEmailVerify(false);
          // alert(error.response.data.message);
          // console.log(error);
          // setIsLoadingAdmin(false);
          // console.log("not post");
          // swal({
          //     title: "Attention",
          //     text: `${error.response.data.message}`,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        });
    } else {
      openWalletModal();
    }
  };

  useEffect(() => {
    if (!selectedImage) {
      setImage(
        data?.image ? data?.image : "https://i.ibb.co/JtVwzXs/default.jpg"
      );
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const changePhoto = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    console.log("inside image");
    setSelectedImage(e.target.files[0]);
    setUpdatedImage(false);
  };

  // Re-send OTP functionality
  const { seconds, minutes, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };

  let bull = "need edit";
  //verify mobile
  const handleVerifyMobile = async (e) => {
    setDisableAfterActivation(true);

    if (!mobile || mobile.length === 0) {
      // swal({
      //     title: "Attention",
      //     text: "Please enter your mobile number",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Please enter your mobile number</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    } else {
      // setLoading(true);
      setMobileVerify(true);
      axios
        .post("https://backend.studyswiftly.com/api/numberverify", {
          number: countryCode + mobile,
        })
        .then((res) => {
          if (res.status === 200) {
            // alert(res.data.message);
            // setSendMail(res.data.email)
            restarting(180);
            // swal({
            //     text: res.data.message,
            //     icon: "success",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });

            setOtpVerify(res.data.otp);
            console.log(res.data.otp);
            setTimeout(() => {
              setDisableAfterActivation(false);
            }, 180000);
          }
          setOpenMobile(true);
        })
        .catch((err) => {
          // alert(err.response.data.message);
          // setEmailVerify(false);
          // swal({
          //     title: "Attention",
          //     text: err.response.data.message,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_classsuccess",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  };

  const handleVerifyEmail = async (e) => {
    // check if email is valid
    e.preventDefault();
    setEmailDisableAfterActivation(true);
    if (email.length > 0 && email.includes("@" && ".")) {
      // setLoading(true);
      // setEmailVerify(true);
      console.log(email);
      await axios
        // .post("https://backend.studyswiftly.com/api/emailverify", {
        .post("https://backend.studyswiftly.com/api/v1/user/email-verify", {
          email,
        })
        .then((res) => {
          console.log(res, "--data results gotten~");
          if (res.status === 200) {
            console.log("emailres", res);
            setConfirmEmail(res.data.email);
            restarting(180);
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });

            // const wrapper = document.createElement("div");
            // wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

            // Swal.fire(
            //     {
            //         html: wrapper,
            //         icon: "success",
            //         customClass: "modal_class_success",
            //     }
            // )

            console.log(res);
            setEmailOtpVerify(res.data.otp);

            setTimeout(() => {
              setEmailDisableAfterActivation(false);
            }, 120000);
          }
          setOpenEmail(true);
        })
        .catch((err) => {
          // alert(err.response.data.message);
          console.dir(err);
          // setEmailVerify(false);
          // swal({
          //     title: "Attention",
          //     text: err.response.data.message,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      // swal({
      //     title: "Attention",
      //     text: "Please enter a valid email address",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white'>Please enter a valid email address</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const loginWallet = () => {
    // swal({
    //     title: "Attention",
    //     text: "Please login with wallet first.",
    //     icon: "warning",
    //     button: "OK!",
    //     className: "modal_class_success",
    // });

    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p class='text-break text-white'>Please login with wallet first.</p>`;

    Swal.fire({
      html: wrapper,
      icon: "warning",
      customClass: "modal_class_success",
    });

    openWalletModal();
    getBalanceTestnet();
  };

  // get certificate details
  useEffect(() => {
    const data = async () => {
      await axios
        .get(
          `https://backend.studyswiftly.com/api/certificate/email/${user?.email}`
        )
        .then((res) => {
          setCertificateDetails(res.data.result);
        });
    };
    const data3 = async () => {
      await axios
        .get(
          `https://backend.studyswiftly.com/api/v1/mint-certificate/mint-nft`
        )
        .then((res) => {
          setMintedCer(res.data.length);
          console.log(res.data.length);
        });
    };
    data3();
    data();
  }, []);

  useEffect(() => {
    // if (certificateDetails !== {} && certificateMinted) {
    if (certificateMinted) {
      let serialNo;
      console.log("Serial No : " + certificateMinted);
      if (certificateMinted === 0) {
        serialNo = "1000001";
      } else if (certificateMinted < 10) {
        serialNo = "100000" + parseInt(certificateMinted + 1);
      } else if (certificateMinted < 100) {
        serialNo = "10000" + parseInt(certificateMinted + 1);
      } else if (certificateMinted < 1000) {
        serialNo = "1000" + parseInt(certificateMinted + 1);
      } else if (certificateMinted < 10000) {
        serialNo = "100" + parseInt(certificateMinted + 1);
      } else if (certificateMinted < 100000) {
        serialNo = "10" + parseInt(certificateMinted + 1);
      } else {
        serialNo = parseInt(certificateMinted + 1);
      }
      setSerialNumber(serialNo);
    } else {
      setSerialNumber("sample");
    }
  }, [certificateDetails]);

  let tab1 = "PROFILE",
    tab2 = "COURSES ENROLLED",
    tab3 = "CERTIFICATES";
  const tabStyle = "gridFilter text-start mb-50 md-mb-30";
  // const tabStyle = 'intro-tabs tabs-box';

  const handleNameButtons = () => {
    console.log("outside name");
    if (nameChange?.length !== 0) {
      console.log("inside name");
      setUpdatedName(false);
    }
  };
  const handleEmailButtons = () => {
    console.log("outside name");
    if (email?.length !== 0) {
      console.log("inside name");
      setEmailVerify(false);
    }
  };
  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");

    // Parse date part
    const [day, month, year] = datePart.split("/");
    const parsedDate = new Date(`${month}/${day}/${year}`);

    // Format date
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  console.log(emailVerify, email, isUpdatedEmail);
  return (
    <div className="rs-my-account pt-90 pb-100 md-pt-60 md-pb-80">
      <div className="container ">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList className={tabStyle}>
            <Tab>
              <button>{tab1}</button>
            </Tab>
            <Tab>
              <button>
                {tab2}
                {/* <p className='enrolLength'>0{enrolledLength}</p> */}
              </button>
            </Tab>
            <Tab>
              <button className="ms-4">{tab3}</button>
            </Tab>
          </TabList>

          <TabPanel>
            {/* <h2 className="title pb-24 md-pb-14">Profile</h2> */}
            <div id="login">
              <div className="row">
                <div className="col-lg-6 md-mb-50 ">
                  <div className="rs-login walletPartFirst">
                    <div id="login" className="form-group mb-30 ">
                      {console.log(user?.profilePicture)}
                      <div className="form-part">
                        <div className="imageDivNft">
                          <img
                            src={
                              !selectedImage
                                ? data?.image?.replace(
                                    "https://backend.blockchaincert.sg/",
                                    "https://backend.studyswiftly.com/"
                                  )
                                  ? data?.image?.replace(
                                      "https://backend.blockchaincert.sg/",
                                      "https://backend.studyswiftly.com/"
                                    )
                                  : image?.replace(
                                      "https://backend.blockchaincert.sg/",
                                      "https://backend.studyswiftly.com/"
                                    )
                                : image?.replace(
                                    "https://backend.blockchaincert.sg/",
                                    "https://backend.studyswiftly.com/"
                                  )
                            }
                            width={200}
                            height={200}
                            className="d-flex justify-content-start"
                            alt=""
                          />
                        </div>
                        <div className="">
                          <label className="text-white">
                            Update your profile image<span>*</span>
                          </label>
                          <div className="d-flex">
                            <input
                              className="border-1 text-white border-white py-2 cursor-pointer"
                              onChange={changePhoto}
                              type="file"
                              accept="image/*"
                              name="image"
                              id="image"
                            />
                            {/* <button
                                                            // disabled={(nameChange?.length === 0 || isUpdatedName == true) ? true : false}
                                                            onClick={handleImageSubmit}
                                                            className='border-0 px-4 p-2 w-25 d-flex justify-content-center' style={{ color: '#ffffff', backgroundColor: '#FF5421' }}>{isUpdatedName == true ? "Updated" : "Update"}</button> */}

                            {isUpdatedImage == true ? (
                              <button
                                disabled
                                // onClick={handleNameSubmit}
                                className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "rgb(151, 145, 145)",
                                }}>
                                Updated
                              </button>
                            ) : (
                              <button
                                disabled={
                                  !selectedImage || isUpdatedImage == true
                                    ? true
                                    : false
                                }
                                onClick={handleImageSubmit}
                                className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: `${
                                    !selectedImage && data?.image
                                      ? "rgb(151, 145, 145)"
                                      : "#FF5421"
                                  }`,
                                }}>
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="wraper mt-2">
                          <label className="text-white">
                            Update your Full Name<span>*</span>
                          </label>
                          <div className="d-flex">
                            {console.log("user?.name", data?.name)}
                            <input
                              onChange={(e) => {
                                setNameChange(
                                  e.target.value.toLocaleUpperCase()
                                );
                                handleNameButtons();
                              }}
                              id="fname"
                              name="name"
                              className="form-control-mod ps-2 profile-inputs"
                              placeholder="Please update your Full Name."
                              defaultValue={data?.name}
                              type="text"
                            />
                            {console.log(
                              "name changeeee..........",
                              nameChange?.length
                            )}

                            {isUpdatedName == true ? (
                              <button
                                disabled
                                className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "rgb(151, 145, 145)",
                                }}>
                                Updated
                              </button>
                            ) : (
                              <button
                                disabled={
                                  nameChange?.length === 0 ||
                                  isUpdatedName == true
                                    ? true
                                    : false
                                }
                                onClick={handleNameSubmit}
                                className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: `${
                                    data?.name && nameChange?.length === 0
                                      ? "rgb(151, 145, 145)"
                                      : "#FF5421"
                                  }`,
                                }}>
                                Update
                              </button>
                            )}
                          </div>
                          <span
                            style={{
                              color: "yellow",
                              paddingBottom: "5px",
                              fontSize: "12px",
                              fontWeight: "lighter",
                            }}>
                            Please enter your correct name. This name will
                            appear in all your blockchain certificates. There
                            will be an administrative charge of SGD 200 if you
                            need to change your name in your certificates.
                          </span>
                        </div>
                        <div className="wraper mt-1">
                          <label className="text-white">
                            Update your email/username<span>*</span>
                          </label>
                          <div className="d-flex">
                            <input
                              // disabled={emailVerify == true ? true : false}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                handleEmailButtons();
                                setEmailVerify(true);
                              }}
                              id="email"
                              name="email"
                              className="form-control-mod ps-2 profile-inputs "
                              placeholder="Please update your email/username"
                              defaultValue={data?.email}
                              type="email"
                              required
                            />

                            {(data.email && !emailVerify) || isVerified ? (
                              <button
                                disabled
                                // onClick={handleNameSubmit}
                                className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "rgb(151, 145, 145)",
                                }}>
                                Verified
                              </button>
                            ) : (
                              <button
                                disabled={email?.length === 0 ? true : false}
                                onClick={handleVerifyEmail}
                                className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: `${
                                    email?.length === 0 && data?.email
                                      ? "rgb(151, 145, 145)"
                                      : "#FF5421"
                                  }`,
                                }}>
                                Verify
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="wraper mt-1">
                          <label className="text-white">
                            Credit<span>*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            className="form-control-mod ps-2 inputgap"
                            placeholder="Please update your email/username"
                            value={
                              data?.credit > 0
                                ? `SGD ${parseFloat(data?.credit).toFixed(2)}`
                                : "SGD 0.00"
                            }
                          />
                        </div>
                        <span
                          style={{
                            color: "yellow",
                            paddingBottom: "5px",
                            fontSize: "12px",
                            fontWeight: "lighter",
                          }}>
                          The credits can be used only to pay for our courses
                          only
                        </span>
                        {/* <p>
                          The credits can be used only to pay for our course
                          only
                        </p> */}
                        <div className="wraper">
                          <label className="text-white">
                            Earnings<span>*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            className="form-control-mod ps-2 inputgap"
                            placeholder="Earnings"
                            value={`SGD ${parseFloat(data?.earning).toFixed(
                              2
                            )}`}
                          />
                        </div>
                        <span
                          style={{
                            color: "yellow",
                            paddingBottom: "5px",
                            fontSize: "12px",
                            fontWeight: "lighter",
                          }}>
                          The Earnings can be used to pay for our courses or
                          withdrawn upon reaching SGD 100.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 walletPartSecond">
                  <div className="rs-login ">
                    <div id="login" className="form-group mb-30 ">
                      <div className="form-part">
                        {/* <div className="wraper text-white mb-10">
                          <label className="text-white">
                            Your Wallet Address<span>*</span>
                          </label>
                          <div className="d-flex">
                            <input
                              style={{
                                width: data?.walletAddress ? "100%" : "70%",
                              }}
                              type="text"
                              id="walletAddress"
                              name="walletAddress"
                              defaultValue={data?.walletAddress}
                              className="form-control inputgap profileInput text-dark w-51"
                              disabled
                            />
                            {!data?.walletAddress ? (
                              <div style={{
                                width: "30%",
                              }}
                              className="w-51">
                                {checkDevice === "mobileWithoutApp" && (
                                  <a
                                    href="https://metamask.app.link/dapp/http://studyswiftly.com"
                                    target={"_blank"}
                                    className="text-decoration-none">
                                    <button
                                     
                                      className="readon orange-btn transparent meta-btn">
                                      Open in metamask
                                    </button>
                                  </a>
                                )}
                                {checkDevice === "mobileWithApp" && (
                                  <button
                               
                                    className="readon orange-btn transparent meta-btn"
                                    onClick={() => openWalletModal()}>
                                    LOGIN WITH WALLET
                                  </button>
                                )}
                                {checkDevice === "pcWithExtention" && (
                                  <button
                                 
                                    className="readon orange-btn transparent meta-btn"
                                    onClick={() => openWalletModal()}>
                                    LOGIN WITH WALLET
                                  </button>
                                )}
                                {checkDevice === "pcWithoutExtention" && (
                                  <a
                                    href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                                    target={"_blank"}
                                    className="text-decoration-none">
                                    <button
                                 
                                      className="readon orange-btn transparent meta-btn">
                                      INSTALL METAMASK
                                    </button>
                                  </a>
                                )}
                              </div>
                            ) : (
                              <CopyToClipboard>
                                <button
                                  className="copyBtn"
                                  style={{ backgroundColor: "#15407f" }}
                                  type="button"
                                  onClick={copyToClipboardWalletAddress}>
                                  <ContentCopyIcon />
                                </button>
                              </CopyToClipboard>
                            )}
                          </div>
                        </div> */}
                        <div className="wraper mb-10 text-white ">
                          <label className="text-white">
                            Your Student ID<span>*</span>
                          </label>
                          <input
                            className="form-control-mod  ps-2 inputgap"
                            placeholder="Membership ID"
                            defaultValue={data?.memeberId}
                          />
                        </div>
                        <div className="wraper mb-10 text-white">
                          <label className="text-white">
                            Your Affiliate Code<span>*</span>
                          </label>
                          {/* <input className="form-control-mod ps-2" placeholder='Affiliate Code' value={user?.affiliateCode} /> */}
                          <div className="d-flex">
                            <input
                              type="text"
                              name="affiliateCode"
                              // defaultValue={user?.affiliateCode}
                              defaultValue={data?.myReferralCode}
                              className="form-control profileInput inputgap text-dark"
                              disabled
                            />
                            <CopyToClipboard>
                              <button
                                className="copyBtn"
                                style={{ backgroundColor: "#15407f" }}
                                type="button"
                                onClick={()=>copyToClipboardAffiliateCode(data?.myReferralCode)}>
                                <ContentCopyIcon />
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                        <div className="wraper mb-10 text-white">
                          <label className="text-white">
                            Your Affiliate Link<span>*</span>
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              id="AffiliateLink"
                              name="AffiliateLink"
                              value={window.location.origin +
                                "/" +
                                data?.myReferralCode}
                              className="form-control inputgap profileInput text-dark"
                              disabled
                            />
                            <CopyToClipboard>
                              <button
                                className="copyBtn"
                                style={{ backgroundColor: "#15407f" }}
                                type="button"
                                onClick={()=>copyToClipboardAffiliateLink(window.location.origin +"/" +data?.myReferralCode)}>
                                <ContentCopyIcon />
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                        <div>
                          <div className=" social-div">
                            <div>
                              <span className="pt-2 fw-bold affliatteText mb-3 text-white">
                                Share Affiliate Link
                              </span>
                              <div className="d-flex gap-2 mb-3">
                                <TwitterShareButton
                                  url={
                                    window.location.origin +
                                    "/" +
                                    data?.myReferralCode
                                  }
                                  title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                  <TwitterIcon size={40} round={true} />
                                </TwitterShareButton>
                                <LinkedinShareButton
                                  url={
                                    window.location.origin +
                                    "/" +
                                    data?.myReferralCode
                                  }
                                  title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                  <LinkedinIcon size={40} round={true} />
                                </LinkedinShareButton>
                                <WhatsappShareButton
                                  url={
                                    window.location.origin +
                                    "/" +
                                    data?.myReferralCode
                                  }
                                  title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                  <WhatsappIcon size={40} round={true} />
                                </WhatsappShareButton>
                                <FacebookShareButton
                                  url={
                                    window.location.origin +
                                    "/" +
                                    data?.myReferralCode
                                  }
                                  title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                  <FacebookIcon size={40} round={true} />
                                </FacebookShareButton>
                                <PinterestShareButton
                                  url={
                                    window.location.origin +
                                    "/" +
                                    data?.myReferralCode
                                  }
                                  title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                  <PinterestIcon size={40} round={true} />
                                </PinterestShareButton>
                                <TelegramShareButton
                                  url={
                                    window.location.origin +
                                    "/" +
                                    data?.myReferralCode
                                  }
                                  title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                  <TelegramIcon size={40} round={true} />
                                </TelegramShareButton>
                              </div>
                            </div>
                          </div>
                          <p className="affliatteText text-white">
                            Feel free to share your unique affiliate code with
                            your friends. When they use it, they'll receive a 5%
                            discount on all courses, and you'll earn a 5%
                            commission on all payments made by your referred
                            friends.
                          </p>
                          <div className="d-flex justify-content-start">
                            <button
                              onClick={() => navigate("/courses")}
                              className="border-0 px-4 p-2 me-4 profile-btns"
                              type="submit"
                              // style={{ color: '#ffffff', backgroundColor: '#FF5421' }}
                              style={{
                                backgroundColor: "#15407f",
                                color: "#ffffff",
                                width: "28%",
                              }}>
                              Go to courses
                            </button>
                            <button
                              className="border-0 px-4 p-2 ms profile-btns"
                              type="button"
                              style={{
                                color: "#ffffff",
                                backgroundColor: "#FF5421",
                                width: "28%",
                              }}
                              onClick={() => LogOut()}>
                              Sign Out
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          {/*////////////////// Tab 2 /////////////////////// */}

          <TabPanel>
            <div className="container">
              {/* <h3>COURSES ENROLLED</h3> */}
              <EnrolledItems></EnrolledItems>
            </div>
          </TabPanel>

          {/*////////////////// Tab 3 /////////////////////// */}

          <TabPanel>
            <div className="container">
              {/* <h3>CERTIFICATES</h3> */}
              {certificates?.length > 0 ? (
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="table-responsive my-5">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th className="text-nowrap" scope="col">
                              Date
                            </th>
                            <th className="text-nowrap" scope="col">
                              Certificate No
                            </th>
                            <th className="text-nowrap" scope="col">
                              From
                            </th>
                            <th className="text-nowrap" scope="col">
                              To
                            </th>
                            <th className="text-nowrap" scope="col">
                              Serial No
                            </th>
                            <th className="text-nowrap" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {certificates?.map((res) => (
                            <tr>
                              <td className="text-nowrap">{res?.date}</td>
                              <td
                                className="text-nowrap"
                                style={{ textTransform: "capitalize" }}>
                                {res?.CertificateNo || "Sample"}
                              </td>
                              <td
                                className="text-nowrap"
                                style={{ textTransform: "capitalize" }}>
                                {formatDate(res?.fromDate)}
                              </td>
                              <td
                                className="text-nowrap"
                                style={{ textTransform: "capitalize" }}>
                                {res?.date || "Sample"}
                              </td>
                              <td
                                className="text-nowrap"
                                style={{ textTransform: "capitalize" }}>
                                {res?.serialNumber || "000001"}
                              </td>{" "}
                              <td className="text-nowrap">
                                <Link
                                  to={`/certificate/${
                                    res?.coursename || "sample"
                                  }/${formatDate(res?.fromDate)}/${
                                    res?.date || "sample"
                                  }/${res?.serialNumber || "000001"}/${
                                    res?.CertificateNo || "sample"
                                  }`}>
                                  <button className="profileEnrolBtnMint text-uppercase">
                                    {res?.email ? "View Certificate" : "View"}
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="table-responsive my-5">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th className="text-nowrap" scope="col">
                              Date
                            </th>
                            <th className="text-nowrap" scope="col">
                              Certificate No
                            </th>
                            <th className="text-nowrap" scope="col">
                              From
                            </th>
                            <th className="text-nowrap" scope="col">
                              To
                            </th>
                            <th className="text-nowrap" scope="col">
                              Serial No
                            </th>
                            <th className="text-nowrap" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-nowrap">
                              {date?.toLocaleString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </td>
                            <td
                              className="text-nowrap"
                              style={{ textTransform: "capitalize" }}>
                              {certificateDetails?.CertificateNo || "Sample"}
                            </td>
                            <td
                              className="text-nowrap"
                              style={{ textTransform: "capitalize" }}>
                              {certificateDetails?.date || "Sample"}
                            </td>
                            <td
                              className="text-nowrap"
                              style={{ textTransform: "capitalize" }}>
                              {certificateDetails?.date || "Sample"}
                            </td>
                            <td
                              className="text-nowrap"
                              style={{ textTransform: "capitalize" }}>
                              {serialNumber}
                            </td>{" "}
                            <td className="text-nowrap">
                              <Link
                                to={`/certificate/${
                                  certificateDetails?.coursename || "sample"
                                }/${certificateDetails?.date || "sample"}/${
                                  certificateDetails?.date || "sample"
                                }/${serialNumber || "sample"}/${
                                  certificateDetails?.CertificateNo || "sample"
                                }`}>
                                <button className="profileEnrolBtnMint text-uppercase">
                                  {certificateDetails?.email
                                    ? "View Certificate"
                                    : "View"}
                                </button>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="mb-5 mt-5">
                <p>
                  Certificate No:{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {certificateDetails?.CertificateNo || "Sample"}
                  </span>
                </p>
                {/* <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <p>From:</p>
                      <p>{certificateDetails?.date || "Sample"}</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <p>To:</p>
                      <p>{certificateDetails?.date || "Sample"}</p>
                    </div>
                  </div> 
                <p>From: {certificateDetails?.date || "Sample"} </p>
                <p>To: {certificateDetails?.date || "Sample"}</p>
                <p style={{ textTransform: "capitalize" }}>
                  Serial No: {serialNumber}
                </p>
                <Link
                  to={`/certificate/${
                    certificateDetails?.coursename || "sample"
                  }/${certificateDetails?.date || "sample"}/${
                    certificateDetails?.date || "sample"
                  }/${serialNumber || "sample"}/${
                    certificateDetails?.CertificateNo || "sample"
                  }`}>
                  <button className="profileEnrolBtnMint text-uppercase">
                    {certificateDetails?.email
                      ? "View Certificate"
                      : "View Sample Certificate"}
                  </button>
                </Link>
              </div> */}
              <br />
            </div>
          </TabPanel>
        </Tabs>
      </div>

      <MobileVerifyModal
        handleVerifyMobile={handleVerifyMobile}
        minutes={minutes}
        seconds={seconds}
        open={openMobile}
        setOpenMobile={setOpenMobile}
        otpVerify={otpVerify}
        setError={setError}
        setForEnable={setForEnable}
        disableAfterActivation={disableAfterActivation}
      />

      <EmailVerificationModal
        handleEmailSubmit={handleEmailSubmit}
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        seconds={seconds}
        open={openEmail}
        setOpenEmail={setOpenEmail}
        otpVerify={EmailOtpVerify}
        setForEnable={setEmailForEnable}
        setError={setEmailError}
        setEmailVerify={setEmailVerify}
      />
    </div>
  );
};

export default AccountMain;
