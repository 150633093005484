
import Modal from 'react-bootstrap/Modal';
import './AffiliateModal.css'
import dsl from './dsl.jpg'

const AffiliateModal = (props) => {
  const {
    modalData,
    show,
    refetch,
    setRefetch,
    setAffiliateModal,
    onHide } = props;

  console.log('click', modalData)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}
    >
      <Modal.Header closeButton >

      </Modal.Header>
      <Modal.Body>
        {/* <h4>Data For : : </h4> */}
        {/***************************** Design ********************************************* */}
        <div className='d-flex justify-content-center pb-4'>
          <img src={dsl} style={{ width: '80px' }} alt="" />
        </div>
        <div className='d-flex align-items-center '>
          <p className='customB '>1.5%</p>
          <div className='ps-3 '>
            <h5 className='mb-0'>3rd Level</h5>
            <p className='mb-0 text-capitalize'>Membership Id : {modalData?.refMemberId3 || "N/A"} </p>
            <p className='mb-0 '>Email : {modalData?.refEmail3 || "N/A"}</p>
            <p className='text-uppercase'>paid: {modalData?.refAmount3 || "N/A"} BUSD</p>
          </div>
        </div>

        <div className='d-flex align-items-center'>
          <p className='customB '>2.5%</p>
          <div className='ps-3 '>
            <h5 className='mb-0'>2nd Level</h5>
            <p className='mb-0 text-capitalize'>Membership Id : {modalData?.refMemberId2 || "N/A"} </p>
            <p className='mb-0 '>Email : {modalData?.refEmail2 || "N/A"}</p>
            <p className='text-uppercase'>paid: {modalData?.refAmount2 || "N/A"} BUSD</p>
          </div>
        </div>

        <div className='d-flex align-items-center'>
          <p className='custom5 '>5%</p>
          <div className='ps-3 '>
            <h5 className='mb-0'>1st Level</h5>
            <p className='mb-0 text-capitalize'>Membership Id : {modalData?.refMemberId || "N/A"} </p>
            <p className='mb-0 '>Email : {modalData?.refEmail || "N/A"}</p>
            <p className='text-uppercase'>paid: {modalData?.refAmount || "N/A"} BUSD</p>
          </div>
        </div>

        <div className='d-flex align-items-center'>
          <p className='customX '>X</p>
          <div className='ps-3 '>
            <h5 className='mb-0'>Payer</h5>
            <p className='mb-0 text-capitalize'>Membership Id : {modalData?.memberId || "N/A"} </p>
            <p className='mb-0 '>Email : {modalData?.email || "N/A"}</p>
            <p className='text-uppercase'>paid: {modalData?.mainAmount || "N/A"} BUSD</p>
          </div>
        </div>

        <div className='ps-4'>
          {/* <h5>Wallet A : N.A BUSD</h5>
          <h5>Wallet B : N.A BUSD</h5> */}
          <h6>Wallet A : {modalData?.wallet1Amount || "N/A"} BUSD</h6>
          <h6>Wallet B : {modalData?.wallet2Amount || "N/A"} BUSD </h6>
          {/* {modalData?.s39Amount && `& ${modalData?.s39Amount} S39`} */}

        </div>

      </Modal.Body>

    </Modal>
  )
}

export default AffiliateModal