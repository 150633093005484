import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
// import "./Users.css"
import swal from 'sweetalert';
import Swal from 'sweetalert2';


import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';




const AddedCourse = () => {
    const [courseData, setCourseData] = useState([]);
    const [refetchDatas, setrefetchDatas] = useState(1);




    useEffect(() => {
        const data = async () => {
            await axios.get("https://backend.studyswiftly.com/api/learn")
                .then(res => {
                    setCourseData(res?.data?.result)
                })
        }
        data();
    }, [refetchDatas])


    const handleDelete = (id) => {
        // console.log(id);
        // const confirmDelete = window.confirm('Are you sure, you want to delete this student?')

        Swal.fire({
            text: "Are you sure, you want to delete this course?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://backend.studyswiftly.com/api/learn/${id}`)
                    .then(res => {
                        if (res.status === 200) {
                            swal({
                                title: "Success",
                                // text: res.data.message,
                                text: "Course deleted successfully",
                                icon: "success",
                                button: "OK!",
                                className: "modal_class_success",
                            });
                            setCourseData(courseData.filter(data => data._id !== id))
                            setrefetchDatas(refetchDatas + 1);
                        }
                    })
                    .catch(error => {
                        // alert(error.response.data.message);
                        swal({
                            title: "Attention",
                            text: error.response.data.message,
                            icon: "warning",
                            button: "OK!",
                            className: "modal_class_success",
                        });
                    })
            }
        })

        // swal({
        //     title: "Are you sure, you want to delete this course?",
        //     // text: "You will not be able to recover this post again!",
        //     icon: "warning",
        //     buttons: ["No", "Yes"],
        //     dangerMode: true,
        // }).then(function (isConfirm) {
        //     if (isConfirm) {
        //         axios.delete(`https://backend.studyswiftly.com/api/learn/${id}`)
        //             .then(res => {
        //                 if (res.status === 200) {
        //                     swal({
        //                         title: "Success",
        //                         // text: res.data.message,
        //                         text: "Course deleted successfully",
        //                         icon: "success",
        //                         button: "OK!",
        //                         className: "modal_class_success",
        //                     });
        //                     setCourseData(courseData.filter(data => data._id !== id))
        //                     setrefetchDatas(refetchDatas + 1);
        //                 }
        //             })
        //             .catch(error => {
        //                 // alert(error.response.data.message);
        //                 swal({
        //                     title: "Attention",
        //                     text: error.response.data.message,
        //                     icon: "warning",
        //                     button: "OK!",
        //                     className: "modal_class_success",
        //                 });
        //             })

        //     }
        // });
    }




    return (
        // <div className='adminBody' style={{ height: "100vh" }}>
        <div className='adminBody'>
            <h5 className='text-white text-start text-uppercase'>Course Content</h5>
            <h5 className='text-white text-start'></h5>
            <div className='adminCard py-2'>
                <div className="tableNormal pt-5">

                    <Table className='text-white adminDataTable'>


                        <thead>
                            <tr>
                                <th className='text-start '>Course Name</th>
                                <th className='text-start'>Date</th>
                                <th className='text-start'>Price</th>

                                <th className='text-start adminHidden'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                courseData?.map(data => (
                                    <tr className='tableRow' key={data._id}>
                                        <td align='text-start'>{data?.name}</td>
                                        <td className='text-start'>{data?.date}</td>
                                        <td className='text-start'>{data?.amount}</td>
                                        {/* <td className='text-start'>{data?.date}</td> */}
                                        <td className='action adminHidden'>
                                            <div className='d-flex'>

                                                <Link to={`/admin/editcontent/${data?._id}`}>
                                                    <button className='studentBtnAdd'
                                                    // onClick={() => {

                                                    //     handleAddModal(data)
                                                    // }}
                                                    ><Edit /></button>
                                                </Link>

                                                {/* <button className='studentView'
                                                // onClick={() => {
                                                //     setStudentModal(true)
                                                //     handleModal(data)
                                                // }}
                                                ><i class="fas fa-eye"></i></button> */}
                                                <button
                                                    onClick={() => handleDelete(data?._id)}
                                                    className='studentBtnDlt'><DeleteIcon /></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>



            </div>
        </div>
    );
};

export default AddedCourse;