import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

const Degree = () => {
    const navigate=useNavigate()
    return (
        <div className='adminBody'>
        <p className='cursor-pointer' style={{ cursor: "pointer" }} title='Back' onClick={() => navigate(-1)}><ArrowBack /></p>
        <h5 className='text-white text-start text-uppercase'>Degree</h5>
        <h5 className='text-white text-start'></h5>
   
    </div>
    );
};

export default Degree;