import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItems = (props) => {
  const { parentMenu, secondParentMenu } = props;

  const location = useLocation();
  useEffect(() => {
  console.log(location.pathname,"location.pathname",parentMenu);
},[])
  return (
    <React.Fragment>
      <li
        className={
          parentMenu === "home"
            ? "rs-mega-menu menu-item-has-children current-menu-item"
            : "rs-mega-menu menu-item-has-children"
        }>
        <Link to="/">Home</Link>
      </li>
      <li
        className={
          parentMenu === "about"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }>
        <Link
          to="/about"
          className={location.pathname === "/about" ? "active-menu" : ""}>
          About
        </Link>
      </li>
      <li
        className={
          parentMenu === "course"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }>
        <Link
          to="/courses"
          className={location.pathname === "/course" ? "active-menu" : ""}>
          Courses
        </Link>
      </li>
      {/* <li className={parentMenu === 'pages' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/ourdapps">Our Dapps</Link>
               
            </li> */}
      <li
        className={
          parentMenu === "news"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }>
        <Link
          to="/news"
          className={location.pathname === "/news" ? "active-menu" : ""}>
          News
        </Link>
      </li>
      <li
        className={
          parentMenu == "forms"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }>
        <Link
          to="/forms"
          className={location.pathname == "/forms" ? "active-menu" : ""}>
          Forms
        </Link>
      </li>
      <li
        className={
          parentMenu === "contact"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }>
        {/* <Link
          to="/contact"
          className={location.pathname === "/contact" ? "active-menu" : ""}>
          Contact
        </Link> */}
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
