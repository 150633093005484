import { Box, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import AffiliatePayment from "./AffiliatePayment";
import TotalPayment from "./TotalPayment";
import WalletA from "./WalletA";
import WalletB from "./WalletB";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import AffiliatePaymentForCourse from "./AffiliatePaymentForCourse";
import SGDViaCard from "./SGDViaCard";
import FormControl from "@mui/material/FormControl";
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#1976D2",
      height: 2,
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#1976D2",
    },
  },
});

function Payments() {
  const [tabIndex, setTabIndex] = useState(0);

  let newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = String(newDate.getFullYear());
  console.log(yyyy, "This is initial year to filter with");

  // Peremeters for filtering
  const [date, setDate] = useState(dd);
  const [month, setMonth] = useState(mm);
  const [year, setYear] = useState(yyyy);
  const [filterDate, setFilterDate] = useState("All");
  const disableDateSelection = month === "month" || year === "year";
  const disableMonthSelection = year === "year";

  // console.log(date, month, year, "Peremeters");

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const classes = useStyles();

  // HERE GETTING MONTHS
  const Month = (f) =>
    Array.from(Array(12), (e, i) =>
      new Date(25e8 * ++i).toLocaleString("en-US", { month: f })
    );
    const handleChange = (event) => {
      setFilterDate(event.target.value);
    };
  
  return (
    <Box>
      <h5 className="text-white text-start">PAYMENTS</h5>
      <div className="filter-with-date-month-year input-group">
      <FormControl sx={{ minWidth: 150 }}>
          
          <Select
            className=" bg-light "
            value={filterDate}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            
            <MenuItem value="Today">
             Today
            </MenuItem>
            <MenuItem value="This Month">
            This Month
            </MenuItem>
            <MenuItem value="Previous Month">
            Previous Month
            </MenuItem>
            <MenuItem value="All">
              All
            </MenuItem>
          </Select>
          
          
        </FormControl>
        {/* <select
          value={date}
          onChange={(e) => setDate(e.target.value)}
          disabled={disableDateSelection}
          className="form-select">
          <option value="date">Date</option>
          {new Array(31).fill().map((opt, i) => (
            <option value={i + 1 < 10 ? `0${i + 1}` : i + 1}>
              {i + 1 < 10 ? `0${i + 1}` : i + 1}
            </option>
          ))}
        </select>
        <select
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          disabled={disableMonthSelection}
          className="form-select">
          <option value="month">Month</option>
          {new Array(12).fill().map((opt, i) => (
            <option value={i + 1 < 10 ? `0${i + 1}` : i + 1}>
              {Month`short`[i]}
            </option>
          ))}
        </select>
        <select
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="form-select">
          <option value="year">Year</option>
          {Array.from({ length: 100 }, (value, index) => index).map(
            (opt, i) => (
              <option value={i + 2000}>{i + 2000}</option>
            )
          )}
        </select> */}
      </div>
      <Box
        sx={{
          borderBottom: 2,
          borderColor: "#1976D2",
        }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          textColor="white"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
          orientation={window?.innerWidth > 600 ? "horizontal" : "vertical"}>
          {/* <Tab className="text-white" label="Payment to Wallet A (DSL)" /> */}
          <Tab
            className="text-white"
            label="PAYMENTS RECEIVED"
          />
          {/* <Tab className="text-white" label="Payment to Wallet B (S39)" /> */}
          <Tab className="text-white" label="Payment to Affiliates" />
          <Tab className="text-white" label="Total" />
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {tabIndex === 0 && (
          <Box>
            <SGDViaCard date={date} month={month} year={year}></SGDViaCard>
            {/* <WalletA date={date} month={month} year={year} /> */}
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>{/* <WalletB date={date} month={month} year={year} /> */}</Box>
        )}
        {tabIndex === 2 && (
          <Box>
            <AffiliatePaymentForCourse
              date={date}
              month={month}
              year={year}></AffiliatePaymentForCourse>
            {/* <AffiliatePayment date={date} month={month} year={year} /> */}
          </Box>
        )}
        {tabIndex === 3 && (
          <Box>
            <TotalPayment date={date} month={month} year={year} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Payments;
