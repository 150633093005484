import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Pagination from "../../../components/Pagination/Pagination";
import DashboardDraft from "./DashboardDraft";
import DashboardExpired from "./DashboardExpired";
import TemplateModal from "./TemplateModal/TemplateModal";
import { useContext } from "react";
import { AdminContext } from "../../../Context/AdminContext";
import AddCategoryModal from "./AddCategoryModal";
import EditCategoryModal from "./EditCategoryModal";
import ViewMessage from "./ViewMessage";

const AdminContactForm = () => {
  const navigate = useNavigate();
  const { admin, setAdmin } = useContext(AdminContext);
  const [courses, setCourses] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [selectedTab, setSelectedTab] = useState("courses");
  const [id, setId] = useState("");
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  var newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();

  if (min < 10) {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + 0 + min + ":" + ss;
  } else {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  }
  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };
  console.log("courses", courses);

  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/contact/getdata", config)
        .then((res) => {
          setCourses(res.data.data);
        });
    };
    data();
  }, [refetch]);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceCourses, setSliceCourses] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(courses?.length / show);
    setLastPage(lastPage);
  }, [courses, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = courses?.slice((page - 1) * show, page * show);
      setSliceCourses([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = courses?.slice(0, show);
      setSliceCourses([...getSlicingProduct]);
    }
  }, [courses, show, pageNumber]);

  const handleCourseDelete = (courseToDelete) => {
    // const confirmDelete = window.confirm('Are you sure, you want to delete this course?')

    Swal.fire({
      text: "Are you sure, you want to delete this message?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://backend.studyswiftly.com/api/contact/deletedata/${courseToDelete?._id}`
          )
          .then((res) => {
            if (res.status === 200) {
              // alert(res.data.message);
              // swal({
              //     title: "Success",
              //     text: res.data.message,
              //     icon: "success",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              // wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;
              wrapper.innerHTML = `<p class='text-break text-white'>Message Deleted.</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              setCourses(
                courses.filter((course) => course._id !== courseToDelete._id)
              );
            }
          })
          .catch((error) => {
            // alert(error.response.data.message);
            // swal({
            //     title: "Attention",
            //     text: error.response.data.message,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "warning",
              customClass: "modal_class_success",
            });
          });
      }
    });
  };
  const [modalData, setModalData] = useState(null);
  const [studentModal, setStudentModal] = useState(false);
  const handleModal = (data) => {
    // console.log(data)
    setModalData(data);
  };

  const [modalShowNewAdmin, setModalShowNewAdmin] = useState(false);
  const [modalShowNewAdmin1, setModalShowNewAdmin1] = useState(false);

  const [isLoadingAdmin, setIsLoadingAdmin] = useState(false);
  const [isLoadingAdmin1, setIsLoadingAdmin1] = useState(false);

  return (
    // <div className='adminBody' style={{ height: "100vh" }}>
    <div className="adminBody">
      <h5 className="text-white text-start">ENQUIRIES</h5>
      <div className="adminCard py-2">
        {selectedTab === "courses" && (
          <div className="tableNormal ">
            <Table className="text-white adminDataTable ">
              <thead>
                <tr>
                  <th className="text-center">S/No</th>
                  <th className="text-start">Name</th>
                  {/* <th className='text-start adminHidden'>End date</th> */}
                  <th className="text-start">Email</th>
                  <th className="text-start">Message</th>
                  <th className="text-start">Action</th>
                </tr>
              </thead>
              <tbody>
                {sliceCourses?.map((course, index) => (
                  <tr className="tableRow" key={course?._id}>
                    <td align="center"> {index + 1}</td>
                    <td className="text-start">{course?.name}</td>
                    <td className="text-start">{course?.email}</td>
                    <td className="text-start">
                      {course?.message?.slice(0, 80)}
                      {course?.message?.length > 80 && "..."}
                    </td>
                    {/* <td className='text-start adminHidden'>{`${course?.end?.slice(8, 10)}/${course?.end?.slice(5, 7)}/${course?.end?.slice(0, 4)}`}</td> */}
                    <td className="action">
                      <div className="actionDiv text-start">
                        <button
                          className="studentView"
                          onClick={() => {
                            setStudentModal(true);
                            handleModal(course);
                          }}>
                          <i class="fas fa-eye"></i>
                        </button>

                        <button
                          onClick={() => handleCourseDelete(course)}
                          className="deleteBtn">
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {sliceCourses?.length > 0 && (
              <div>
                <Pagination
                  lastPage={lastPage}
                  page={pageNumber}
                  pageHandle={pageHandle}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <ViewMessage
        show={studentModal}
        onHide={() => setStudentModal(false)}
        modalData={modalData}
      />
    </div>
  );
};

export default AdminContactForm;
