import React, { useContext, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";

import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { AddCircleOutlined, ArrowDropUpOutlined } from "@mui/icons-material";

import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PaymentMethodForm from "./PaymentMethodForm";
import CardLists from "./CardLists";
import { Helmet } from "react-helmet";

// Image
import favIcon from "../../../assets/img/fav-orange.png";
import Logo from "../../../assets/img/logo/dark-logo.png";
import footerLogo from "../../../assets/img/logo/lite-logo.png";

// Breadcrumbs Background Image
import bannerbg from "../../../assets/img/breadcrumbs/2.jpg";
import Header from "../../../components/Layout/Header/Header";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import Newsletter from "../../../components/Common/Newsletter";
import Footer from "../../../components/Layout/Footer/Footer";
import ScrollToTop from "../../../components/Common/ScrollTop";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import { UserContext } from "../../../Context/UserContext";
import Swal from "sweetalert2";
import { dummyText } from "./paymentText";

const stripePromise = loadStripe(
  "pk_test_51KiCK6HlIpHzNhGaKYyj2yJBNJNjExR3j2EbDXMAnK2UZjz1e5gJJUO7O8YaykOr1RcTANLewCYHeQqFeDcNanlD00UmPGPKeb"
);

const usePaymentMethodForm = () => {
  //   const { AIuser, setUserRefetch, userRefetch } = useContext(UserAuthContext);

  const { data: AIuser } = useContext(UserContext);

  const stripe = useStripe();

  const elements = useElements();
  const [refetch, setrefetch] = useState(false);
  const [loader, setLoader] = useState(false);
  const [singleCourse, setSingleCourse] = useState([]);

  const [showCardForm, setShowCardForm] = useState(false);
  const [cardError, setCardError] = useState("");
  const [userDetails, setuserDetails] = useState({
    name: AIuser?.name,
    email: AIuser?.email,
    country: "",
    city: "",
  });
  const location = useLocation();
  const { token } = useParams();
  // const [AIuser, setAIuser] = useState(null);
  const [userRefetch, setUserRefetch] = useState(false);

  useEffect(() => {
    const data1 = async () => {
      await axios
        .get(
          `https://backend.studyswiftly.com/api/course/${location?.state?.id}`
        )
        .then((res) => {
          setSingleCourse(res.data.result);
          // console.log(res.data.result)
        });
    };
    data1();
  }, [location?.state?.id]);

  // useEffect(() => {
  //   if (token) {
  //     axios
  //       .get(
  //         "https://backend.studyswiftly.com/api/dashboard/user_signup-signin/current-user",
  //         {
  //           headers: {
  //             authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setAIuser(res.data.result);
  //         }
  //       })
  //       .finally(() => {});
  //     setUserRefetch(false);
  //   }
  // }, [userRefetch, token]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setCardError("");

      setLoader(true);

      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          address: {
            country: userDetails?.country,
            city: userDetails?.city,
          },
          name: AIuser?.name,
          email: AIuser?.email,
        },
      });

      if (error) {
        console.log("[error]", error);
        setCardError(error.message);
        setLoader(false);
      } else {
        console.log(paymentMethod, "paymentMethod", AIuser._id);
        const response = await axios.post(
          "https://backend.studyswiftly.com/api/v1/payment-method/save-card",
          {
            // insert the user id with the payment method object

            userId: AIuser._id,
            paymentMethod,
          }
        );
        if (response) {
          setCardError("");
          setrefetch(!refetch);
          setLoader(false);
          setShowCardForm(false);
        }
      }
    } catch (error) {
      console.log(error, "errors");
      setLoader(false);
      toast.error(error?.response?.data?.error);
    }
  };

  return {
    handleSubmit,
    refetch,
    setrefetch,
    AIuser,
    userDetails,
    setuserDetails,
    loader,
    setLoader,
    showCardForm,
    setShowCardForm,
    cardError,
    setCardError,
    userRefetch,
    setUserRefetch,
  };
};

const PaymentMethodsApps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [savedCards, setSavedCards] = useState([]);
  const [paying, setPaying] = useState(false);
  const {
    handleSubmit,
    refetch,
    setrefetch,
    AIuser,
    userDetails,
    setuserDetails,
    loader,
    setLoader,
    showCardForm,
    setShowCardForm,
    cardError,
    setCardError,
    userRefetch,
    setUserRefetch,
  } = usePaymentMethodForm();

  const location = useLocation();
  const navigate = useNavigate();

  const price = location?.state?.price ? location?.state?.price : 198;
  const paid = location?.state?.paid ? location?.state?.paid : 0;
  const realPrice = location?.state?.realPrice ?? 0;
  const credits = location?.state?.credits ?? 0;
  const refEmail = location?.state?.refEmail ?? "";
  const ownerId = location?.state?.ownerId ?? 1;
  const date = location?.state?.date;

  console.log(location?.state?.price);
  console.log(location?.state?.realPrice);
  console.log(location?.state?.credits);
  console.log(location?.state?.refEmail);
  console.log(location?.state?.ownerId);
  console.log(location?.state?.date);

  const handleAddPaymentMethod = () => {
    setShowCardForm(true);
    setCardError("");
  };

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `https://backend.studyswiftly.com/api/v1/payment-method/${AIuser?._id}`
      );
      // console.log(response);
      setSavedCards(response?.data?.data.reverse());
    };
    getData();
  }, [refetch, AIuser?._id]);

  const handleCardClick = async (card) => {
    try {
      swal({
        text: `Do you want to proceed with payment?`,
        icon: "warning",
        buttons: ["Cancel", "Yes"],
        className: "modal_class_success",
      }).then(async (value) => {
        if (value) {
          if (paid > 0) {
            setPaying(true);
            const response = await axios.post(
              "https://backend.studyswiftly.com/api/v1/payment-method/pay",
              {
                cardData: card,
                amount: paid,
                // amount: price,
                realPrice,
                credits,
                refEmail,
                ownerId,
                date,
              }
            );
            console.log("response", response);

            if (response.data.status === "success") {
              setPaying(false);
              setUserRefetch(!userRefetch);
              // navigate("/dashboard", {
              //   state: { success: true, credits: response.data.credits },
              // });
              const data = JSON.parse(localStorage.getItem("course"));
              console.log(data, "new course");
              await axios
                .post(
                  "https://backend.studyswiftly.com/api/mint/save-nft",
                  data,
                  {}
                )
                .then((res) => {
                  const wrapper = document.createElement("div");
                  wrapper.innerHTML = `<p class='text-break text-white'>Payment Successful</p>`;

                  Swal.fire({
                    html: wrapper,
                    icon: "success",
                    customClass: "modal_class_success",
                  });
                  navigate("/my-account", { state: { index: 1 } });
                });
            }
          } else {
            setPaying(false);
            setUserRefetch(!userRefetch);
            // navigate("/dashboard", {
            //   state: { success: true, credits: response.data.credits },
            // });
            const data = JSON.parse(localStorage.getItem("course"));
            console.log(data, "new course");
            await axios
              .post(
                "https://backend.studyswiftly.com/api/mint/save-nft",
                data,
                {}
              )
              .then((res) => {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-white'>Payment Successful</p>`;

                Swal.fire({
                  html: wrapper,
                  icon: "success",
                  customClass: "modal_class_success",
                });
                navigate("/my-account", { state: { index: 1 } });
              });
          }
        }
      });

      // Process the payment response as needed
    } catch (error) {
      setPaying(false);
      console.error("Error processing payment:", error);
      toast.error(error?.response?.data?.error);
    }
  };
  const handleDeleteCard = async (cardId) => {
    try {
      const response = await axios.delete(
        `https://backend.studyswiftly.com/api/v1/payment-method/${cardId}`
      );

      if (response) {
        toast.success("Card removed successfully!");
        setrefetch(!refetch);
      }

      // Process the payment response as needed
    } catch (error) {
      console.error("Error processing payment:", error);
      toast.error("Something went wrong! Please try again later.");
    }
  };

  return (
    <div className="py-5" style={{ minHeight: "30vh" }}>
         <div className="container">
         <h5
                  style={{
                    color: "#333",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#ff5421",
                  }}
                  className="fs-4 m-0 text-center ">
                  {dummyText[Math.floor(Math.random() * dummyText?.length)]}
                </h5>
         </div>
      <div
        className={`container account-container d-flex align-items-center ${
          paid < 1 && "justify-content-center"
        }`}
        >
       
        {paid > 0 ? (
          <div
            className="mx-auto "
            style={{
              maxWidth: "420px",
            }}>
            <button
              onClick={() =>
                showCardForm == false
                  ? handleAddPaymentMethod()
                  : setShowCardForm(false)
              }
              className="add-payment-btn text-uppercase">
              {showCardForm ? (
                <ArrowDropUpOutlined className="payment-add-icon me-2 fs-2" />
              ) : (
                <AddCircleOutlined className="payment-add-icon me-2" />
              )}
              Add Payment Method
            </button>
            {showCardForm && (
              <PaymentMethodForm
                handleSubmit={handleSubmit}
                setuserDetails={setuserDetails}
                userDetails={userDetails}
                cardError={cardError}
                loader={loader}
              />
            )}

            <CardLists
              savedCards={savedCards}
              price={price ? price : 5}
              handleDeleteCard={handleDeleteCard}
              paying={paying}
              handleCardClick={handleCardClick}
            />
          </div>
        ) : (
          <div className="text-center">
            <button
              onClick={() => handleCardClick("free")}
              className="add-payment-btn text-uppercase">
              Proceed to Pay
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentStripe = () => {
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <link rel="icon" href={favIcon} />
        </Helmet>
        <OffWrap />
        <Header
          parentMenu="pages"
          secondParentMenu="shop"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="support@website.com"
          Location="374 William S Canning Blvd, MA 2721, USA "
        />

        <SiteBreadcrumb
          pageTitle="CheckOut"
          pageName="Payment"
          breadcrumbsImg={bannerbg}
        />

        <Elements stripe={stripePromise}>
          <PaymentMethodsApps />
        </Elements>

        <Newsletter
          sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
          titleClass="title mb-0 white-color"
        />

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}

        <SearchModal />
      </React.Fragment>
    </div>
  );
};

export default PaymentStripe;
