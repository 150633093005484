import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({ color,isLoading, onClick, children }) => {
  return (
    <>
      {isLoading ? (
        <Button
          className="w-100 mt-3"
          color={color}
          variant="contained"
          size="small"
          onClick={onClick}
          disabled={isLoading}
        >
          please wait...
          <span
            class="spinner-border ms-2 spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </Button>
      ) : (
        <Button
          className="w-100 mt-3"
          color={color}
          variant="contained"
          size="small"
          onClick={onClick}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default CustomButton;
