import React from "react";
import { Helmet } from "react-helmet";

// Image
import favIcon from "../../../assets/img/fav-orange.png";
import Logo from "../../../assets/img/logo/dark-logo.png";
import footerLogo from "../../../assets/img/logo/lite-logo.png";

// Breadcrumbs Background Image
import bannerbg from "../../../assets/img/breadcrumbs/2.jpg";
import Header from "../../../components/Layout/Header/Header";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import Newsletter from "../../../components/Common/Newsletter";
import Footer from "../../../components/Layout/Footer/Footer";
import ScrollToTop from "../../../components/Common/ScrollTop";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <link rel="icon" href={favIcon} />
        </Helmet>
        <OffWrap />
        <Header
          parentMenu="pages"
          secondParentMenu="shop"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="support@website.com"
          Location="374 William S Canning Blvd, MA 2721, USA "
        />

        {/* <SiteBreadcrumb
          pageTitle="Payment Failed"
          pageName="Payment"
          breadcrumbsImg={bannerbg}
        /> */}

        <div className="container">
          <div className="row justify-content-center align-items-center my-5">
            <div className="col-md-8">
              <form className="form-signin text-center">
                <div
                  className="alert alert-danger d-flex align-items-center gap-3"
                  role="alert">
                  <i class="fas fa-exclamation fs-1"></i>
                  <div>
                    <div className="h4">Oops! Something went wrong.</div>
                    <p className="m-0">
                      While trying to reserve money from your account
                    </p>
                  </div>
                </div>

                <button
                  className="btn bg-warning text-dark"
                  onClick={() => navigate(-1)}>
                  Try again
                </button>
              </form>
            </div>
          </div>
        </div>

        <Newsletter
          sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
          titleClass="title mb-0 white-color"
        />

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}

        <SearchModal />
      </React.Fragment>
    </div>
  );
};

export default PaymentFailed;
