import axios from "axios";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RiAdminFill } from "react-icons/ri";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
// import Loader from '../../loader/Loader';
import "./DashboardModalNewAdmin.css";
import swal from "sweetalert";

const DashboardModalNewAdmin = (props) => {
  const {
    setModalShowNewAdmin,
    setIsLoadingAdmin,
    refetch,
    setRefetch,
    isLoadingAdmin,
    allAdmin,
    setAllAdmin,
  } = props;
  const [value, setValue] = useState();
  const [invalidPhoneError, setInvalidPhoneNumber] = useState(false);
  // if (isLoadingAdmin) {
  //     return <Loader></Loader>
  // }
  const subNewAdmin = async (event) => {
    event.preventDefault();

    if (value === undefined || value.length <= 6) {
      setInvalidPhoneNumber(true);
      return;
    } else {
      setInvalidPhoneNumber(false);
    }

    const image = event.target.image.files[0];
    const name = event.target.name.value;
    const username = event.target.username.value;
    const phone = value;
    const email = event.target.email.value;
    const password = event.target.password.value;
    const confirmPassword = event.target.confirmPassword.value;

    const formDataAddAdmin = new FormData();
    formDataAddAdmin.append("name", name);
    formDataAddAdmin.append("username", username);
    formDataAddAdmin.append("email", email);
    formDataAddAdmin.append("phone", phone);
    formDataAddAdmin.append("image", image);
    formDataAddAdmin.append("password", password);

    if (password !== confirmPassword) {
      return swal({
        title: "Attention",
        text: "Confirm Password not match!",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    } else {
      await axios
        .post("https://backend.studyswiftly.com/api/admin/", formDataAddAdmin, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("courseSiteAdmin")}`,
          },
        })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            // setAllAdmin(res.data.newAdmin);
            // setIsLoadingAdmin(false);
            setModalShowNewAdmin(false);
            setRefetch(!refetch);
            event.target.reset();
            // alert(res.data.message);
            swal({
              title: "Success",
              text: "Admin created successfully",
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
          }
        })
        .catch((error) => {
          // alert(error.response.data.message);
          swal({
            title: "Attention",
            text: error.response.data.message,
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
          // console.log(error);
          // setIsLoadingAdmin(false);
        });
    }
  };
  const [fileName, setFileName] = useState("Choose a image");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("Choose a image");
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}
    >
      <Modal.Header closeButton className="modelAddAdmin">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-5 text-light"
        >
          <RiAdminFill className="fs-4"></RiAdminFill> Add Admin
        </Modal.Title>
        
      </Modal.Header>
      
      <Modal.Body className="handleModalBody">
        <div>
          <div>
          <p className="mb-0 text-danger">All fields are compulsory</p>
            <form onSubmit={subNewAdmin}>
              <div className="row addAdminDiv">
                <div className="col-lg-12">
                  <p className="mb-1">Profile</p>

                  {/* <div className="border border-light-subtle rounded d-flex">
                    <input
                      type="file"
                      id="fileInput"
                      className="hidden-file-input"
                      onChange={handleFileChange}
                      required
                    />
                    <label
                      htmlFor="fileInput"
                      className="custom-file-input-button"
                    >
                      Choose Profile Image
                    </label>
                    {fileName && <p className="file-name m-2 text-truncate">{fileName}</p>}
                  </div> */}

                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    name="image"
                    required
                  />
                  <p className="mb-1">Full Name</p>
                  <input
                    className="form-control"
                    placeholder="Enter Full Name"
                    type="text"
                    name="name"
                    required
                  />
                  <p className="mb-1">User Name</p>
                  <input
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    name="username"
                    required
                  />
                  <p className="mb-1">Email</p>
                  <input
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                    name="email"
                    required
                  />
                  <p className="mb-1">Phone</p>
                  <PhoneInput
                    international
                    defaultCountry="SG"
                    countryCallingCodeEditable={true}
                    className="form-control handleModalBody add-admin-phone-number-input"
                    type="text"
                    value={value}
                    onChange={setValue}
                    required
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                  />
                  {invalidPhoneError && (
                    <p className="text-danger mb-0 mt-1">
                      *Please enter a valid phone number
                    </p>
                  )}
                  <p className="mb-1">Password</p>
                  <input
                    className="form-control"
                    placeholder="Enter Password"
                    type="password"
                    name="password"
                    required
                  />
                  <p className="mb-1">Confirm Password</p>
                  <input
                    className="form-control"
                    placeholder="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    required
                  />
                </div>
                <Modal.Footer className="mt-3 modalFooter">
                  <button
                    type="button"
                    className="adminBtnAdd11"
                    onClick={props.onHide}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="adminBtnAdd">
                    ADD
                  </button>
                </Modal.Footer>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardModalNewAdmin;
