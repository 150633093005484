// import PersonIcon from '@mui/icons-material/Person';
// import { Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";

import axios from "axios";
import { Link } from "react-router-dom";
import "./News.css";

function News() {
  const [loading, setLoading] = useState(false);
  const [allNews, setAllNews] = useState([]);
  const [news, setNews] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const title = params.get("news");

  useEffect(() => {
    setNews(allNews.find((news) => news.title === title));
  }, [title]);

  console.log(title);

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@dslsingapore"
      )
      .then((res) => {
        const allNewsObj = res.data.items;
        const allNews = [];
        for (const item in allNewsObj) {
          allNews.push(allNewsObj[item]);
        }
        setAllNews(allNews);
        setNews(allNews[0]);
      });
  }, []);

  useEffect(() => {
    if (allNews.length <= 0) {
      setLoading(true);
    }
  }, [allNews.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="news-wrapper">
      <div>
        {/* <h3 className="text-gradient text-center pt-5 text-uppercase" style={{ marginTop: '80px' }}>News</h3> */}
        <div className="small-border bg-color-2"></div>
      </div>
      <div>
        <Container className="section-pading2">
          <div className="row">
            <div className="col-12 col-lg-8">
              <img src={news?.thumbnail} alt="" className=" w-100 mx-auto" />

              <div className="d-flex justify-content-between mt-2">
                <Link className="d-flex footerLink" to="# ">
                  <BsFillPersonFill className="personIcon" />
                  <div>
                    <p className="byAdmin text-dark">By Admin</p>
                  </div>
                </Link>
                {/* {loading && <LoaderTop></LoaderTop>}
                        <p className="local_text">
                           {news?.publication_date}
                        </p> */}
              </div>
              <div className="text-start pb-3">
                <Link
                  to={`/news?news=${news?.title}`}
                  className="news-title py-4"
                >
                  {news?.title}
                </Link>
              </div>
              <div
                className="news-description text-start"
                dangerouslySetInnerHTML={{ __html: news?.description }}
              ></div>
            </div>
            <div className="col-12 col-lg-4 text-start">
              <h6 className="padding-35">RECENT NEWS</h6>
              {allNews.map((news, index) => (
                <Link
                  className="d-flex mt-4 align-items-center text-decoration-none text-dark"
                  to={`/news?news=${news?.title}`}
                  key={index}
                >
                  {/* <div style={{ width: "80px", height: "auto" }}>
                                        <img src={news?.thumbnail} style={{ width: "80px", height: "100%" }} />
                                    </div> */}
                  <div className="text-start ms-2 text-decoration-none">
                    <p className="mb-2 text-dark" style={{ fontSize: 14 }}>
                      {news?.title}
                    </p>
                    <p
                      className="mb-0 text-dark"
                      style={{ fontSize: 12, fontWeight: 500 }}
                    >
                      {news?.publication_date}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default News;
