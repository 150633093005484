import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/Layout/Header/Header";
import Footer from "../../../components/Layout/Footer/Footer";
import OffWrap from "../../../components/Layout/Header/OffWrap";
import SearchModal from "../../../components/Layout/Header/SearchModal";
import Newsletter from "../../../components/Common/Newsletter";
import SiteBreadcrumb from "../../../components/Common/Breadcumb";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { BigNumber, ethers } from "ethers";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { BlockchainContext } from "../../../Context/BlockchainContext";
import { UserContext } from "../../../Context/UserContext";
import { Typography } from "@mui/material";
import Select from "react-select";
import Logo from "../../../assets/img/logo/dark-logo.png";
import footerLogo from "../../../assets/img/logo/lite-logo.png";
import bannerbg from "../../../assets/img/breadcrumbs/2.jpg";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import {
  S39tokenAddressTestnet,
  BUSDtokenAddressTestnet,
} from "../../../utils/constant";
import Swal from "sweetalert2";

const selectOptions = [
  {
    value: "busd",
    label: "BUSD",
    image: "https://i.ibb.co/kGP3D9w/Busd.jpg",
  },

  {
    value: "busd_s39",
    label: "BUSD & S39",
    image: "https://i.ibb.co/Jqw5S0x/s39t.jpg",
  },
];
const BuyCourse = () => {
  const [selectedOption, setSelectedOption] = useState({
    value: "busd",
    label: "BUSD",
    image: "https://i.ibb.co/kGP3D9w/Busd.jpg",
  });

  const { id } = useParams();
  const [singleCourse, setSingleCourse] = useState([]);
  const [USDToken, setUSDToken] = useState("");
  const [token, setToken] = useState("busd");
  const [affiliateWalletAddress, setAffiliateWalletAddress] = useState("");
  const [affiliateEmail, setAffiliateEmail] = useState("");
  const [affiliateMember, setAffiliateMember] = useState("");
  const [affiliateWalletAddress3, setAffiliateWalletAddress3] = useState("");
  const [affiliateEmail3, setAffiliateEmail3] = useState("");
  const [affiliateMember3, setAffiliateMember3] = useState("");
  const [affiliateWalletAddress2, setAffiliateWalletAddress2] = useState("");
  const [affiliateEmail2, setAffiliateEmail2] = useState("");
  const [affiliateMember2, setAffiliateMember2] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [gotRefCode, setGotRefCode] = useState(false);
  const [enrolled, setEnrolled] = useState([]);
  const [checkCourseName, setCheckCourseName] = useState([]);
  const [urls39, setUrls39] = useState("");
  const [earnings, setEarnings] = useState(0);
  const [credit, setCredit] = useState(0);
  const [discount20, setDiscount20] = useState(0);
  const [discount5, setDiscount5] = useState(0);

  const { data } = useContext(UserContext);
  console.log("s39 state", data);
  const {
    user,
    refetchAfterRegister,
    setRefetchAfterRegister,
    openWalletModal,
    getBalanceTestnet,
    payTestnetBUSD,
    payTestnetS39,
    metamaskBalance,
    setRequestLoading,
    signBuyFunction,
    setRefetch,
    refetch,
    buyCourseMainFunction,
    mintCertificateMainFunction,
  } = useContext(BlockchainContext);

  const navigate = useNavigate();
  let newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  newDate = dd + "/" + mm + "/" + yyyy;

  useEffect(() => {
    axios
      .get("https://dslegends.org/api/get-asset-price.php?asset=S39", {
        headers: {
          Tokenkey: `f02063004b60270f693bfefcbd8a37e91273a4290fdcc9e4ea7b0f531a9d9e64`,
        },
      })
      .then((res) => {
        setUSDToken(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const data1 = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/course/${id}`)
        .then((res) => {
          setSingleCourse(res.data.result);
          // console.log(res.data.result)
        });
    };
    data1();
  }, [id]);

  // Referal code discount
  useEffect(() => {
    axios.get("https://backend.studyswiftly.com/api/wallet/all").then((res) => {
      setAllUsers(res.data);
    });
  }, []);

  useEffect(() => {
    const courseName = async () => {
      await axios
        .get(
          `https://backend.studyswiftly.com/api/mint/data/${user?.walletAddress}`
        )
        .then((res) => {
          setCheckCourseName(res.data.result);
        });
    };
    courseName();
    console.log("courseOld", checkCourseName);
  }, []);

  const othersRefCodes = allUsers.filter(
    (i) => i?.affiliateCode !== user?.affiliateCode
  );

  const handleAffiliateCode = (e) => {
    const refCode = othersRefCodes.find(
      (i) => i?.affiliateCode === e.target.value
    );
    setAffiliateWalletAddress(refCode?.walletAddress);
    setAffiliateEmail(refCode?.email);
    setAffiliateMember(refCode?.memberId);

    setAffiliateWalletAddress2(refCode?.refAddress);
    setAffiliateEmail2(refCode?.refEmail);
    setAffiliateMember2(refCode?.refMemberId);

    setAffiliateWalletAddress3(refCode?.refAddress2);
    setAffiliateEmail3(refCode?.refEmail2);
    setAffiliateMember3(refCode?.refMemberId2);

    if (refCode?.affiliateCode === e.target.value) {
      setGotRefCode(true);
    } else {
      setGotRefCode(false);
    }
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: "#fff",
    }),

    singleValue: (provided, state) => {
      return { ...provided };
    },
  };

  ////////////////////////100%of BUSD////////////////////////

  // Referal Code Discount
  const discountReferal = (5 / 100) * (singleCourse?.amount * 0.75);
  const disRefTwoDec = discountReferal.toFixed(2);

  // main balance
  // let totalUSD = singleCourse?.amount * 0.74;;
  let totalUSD = singleCourse?.amount * 0.75;

  //50% main and busd pay
  const Busd50 = (50 * totalUSD) / 100;
  const finalBusd50 = totalUSD - Busd50;
  const finalBusd50TDec = finalBusd50.toFixed(2);

  // 5% of 100% busd
  const maindiscount80Busd = (5 / 100) * finalBusd50TDec;
  const maindisRefBusd80 = maindiscount80Busd.toFixed(2);

  // 2.5% of 100% busd
  const maindiscount80Busd2 = (2.5 / 100) * finalBusd50TDec;
  const maindisRefBusd802 = maindiscount80Busd2.toFixed(2);

  // 1.5% of 100% busd
  const maindiscount80Busd3 = (1.5 / 100) * finalBusd50TDec;
  const maindisRefBusd803 = maindiscount80Busd3.toFixed(2);

  ////////////////////80%part of Busd///////////////////////

  //s39 and busd pay
  const totalS39 = (20 * totalUSD) / 100;
  const finalUSDT = totalUSD - totalS39;

  // 80% Busd......
  const finalUSDTDec = finalUSDT.toFixed(2);

  //20% s39.......
  const s39Need = totalS39 / (USDToken ? USDToken : 0.51);
  const s39Dec = s39Need.toFixed(2);

  // 5% of 80% busd
  const discount80Busd = (5 / 100) * finalUSDTDec;
  const disRefBusd80 = discount80Busd.toFixed(2);

  // 2.5% of 80% busd
  const discount80Busd2 = (2.5 / 100) * finalUSDTDec;
  const disRefBusd802 = discount80Busd2.toFixed(2);

  // 1.5% of 80% busd
  const discount80Busd3 = (1.5 / 100) * finalUSDTDec;
  const disRefBusd803 = discount80Busd3.toFixed(2);

  // total discount
  const totalbusds39dis = discount80Busd + discount80Busd2 + discount80Busd3;
  const totalamountDis = totalbusds39dis.toFixed(2);

  //Final Amount left
  const FinalAmount = finalUSDTDec - totalbusds39dis;
  const FinalamountDis = FinalAmount.toFixed(2);

  //50% of 80% busd amount........
  const dslBusdAmount = (50 * finalUSDTDec) / 100;
  const dslBusdAmountDec = dslBusdAmount.toFixed(2);

  //41% of 80% busd amount........
  const s39busdAmount = (41 * finalUSDTDec) / 100;
  const s39BusdAmountDec = s39busdAmount.toFixed(2);

  // all discount calculation

  ////////////////////100%amount part/////////////////////
  // if pay by only BUSD 5%
  let get5Busd;

  //if pay by only busd 2.5%
  let get2Busd;

  //if pay by only busd 2.5%
  let get1Busd;

  //total payout
  let totalPaymain;

  //50% wallet 1 balance
  let mainwallet01;

  //41% wallet 1 balance
  let mainwallet02;

  //left amount
  let mainleftAmount;

  //wallet2 will get
  let mainwallet2balance;

  //////////////////////80%amount part//////////////////////

  //if pay 80% busd 5%
  let get5Busd80;

  //if pay 80% busd 2.5%
  let get2Busd80;

  //if pay 80% busd 1.5%
  let get1Busd80;

  //total payout
  let totalPay;

  //50% wallet 1 balance
  let wallet01;

  //41% wallet 1 balance
  let wallet02;

  //left amount
  let leftAmount;

  //wallet2 will get
  let wallet2balance;

  // console.log("Emtiazzzzzz", singleCourse?.amount)

  if (!gotRefCode) {
    //100%busd part........
    get5Busd = "0";
    get2Busd = "0";
    get1Busd = "0";
    totalPaymain = get5Busd + get2Busd + get1Busd;
    mainleftAmount = (totalUSD - totalPaymain).toFixed(2);
    mainwallet01 = finalBusd50TDec;
    mainwallet2balance = mainleftAmount - mainwallet01;
    mainwallet02 = mainwallet2balance.toFixed(2);

    //80%busd part.........
    get5Busd80 = "0";
    get2Busd80 = "0";
    get1Busd80 = "0";
    totalPay = get5Busd80 + get2Busd80 + get1Busd80;
    leftAmount = (finalUSDTDec - totalPay).toFixed(2);
    wallet01 = dslBusdAmountDec;
    wallet2balance = leftAmount - wallet01;
    wallet02 = wallet2balance.toFixed(2);
  } else {
    //100%busd part........
    get5Busd =
      affiliateWalletAddress !== "0x0000000000000000000000000000000000000000" &&
      affiliateWalletAddress
        ? maindisRefBusd80
        : "0";
    get2Busd =
      affiliateWalletAddress2 !==
        "0x0000000000000000000000000000000000000000" && affiliateWalletAddress2
        ? maindisRefBusd802
        : "0";
    get1Busd =
      affiliateWalletAddress3 !==
        "0x0000000000000000000000000000000000000000" && affiliateWalletAddress3
        ? maindisRefBusd803
        : "0";
    totalPaymain =
      parseFloat(get5Busd) + parseFloat(get2Busd) + parseFloat(get1Busd);
    mainleftAmount = (totalUSD - totalPaymain).toFixed(2);
    mainwallet01 = finalBusd50TDec;
    mainwallet2balance = mainleftAmount - mainwallet01;
    mainwallet02 = mainwallet2balance.toFixed(2);

    ///80%busd part...........
    get5Busd80 =
      affiliateWalletAddress !== "0x0000000000000000000000000000000000000000" &&
      affiliateWalletAddress
        ? disRefBusd80
        : "0";
    get2Busd80 =
      affiliateWalletAddress2 !==
        "0x0000000000000000000000000000000000000000" && affiliateWalletAddress2
        ? disRefBusd802
        : "0";
    get1Busd80 =
      affiliateWalletAddress3 !==
        "0x0000000000000000000000000000000000000000" && affiliateWalletAddress3
        ? disRefBusd803
        : "0";
    totalPay =
      parseFloat(get5Busd80) + parseFloat(get2Busd80) + parseFloat(get1Busd80);
    leftAmount = (finalUSDTDec - totalPay).toFixed(2);
    wallet01 = dslBusdAmountDec;
    wallet2balance = leftAmount - wallet01;
    wallet02 = wallet2balance.toFixed(2);
  }

  console.log("Total Price (SGD): " + singleCourse?.amount);
  console.log("Total Price in USD: " + totalUSD);
  console.log("5% amount: " + get5Busd);
  console.log("2.5% amount: " + get2Busd);
  console.log("1.5% amount: " + get1Busd);
  console.log("total payout amount: " + totalPaymain);
  console.log("Final Balance: " + mainleftAmount);
  console.log("data.price : " + mainwallet01);
  console.log("refAmount4 : " + mainwallet02);

  console.log("Total Price (SGD): " + singleCourse?.amount);
  console.log("Total Price in USD: " + totalUSD);
  console.log("80% of BUSD: " + finalUSDTDec);
  console.log("20% of S39: " + s39Dec);
  console.log("5% amount: " + get5Busd80);
  console.log("2.5% amount: " + get2Busd80);
  console.log("1.5% amount: " + get1Busd80);
  console.log("total payout amount: " + totalPay);
  console.log("Final Balance: " + leftAmount);
  console.log("data.price : " + wallet01);
  console.log("refAmount4 : " + wallet02);

  const mainTotal = singleCourse?.amount;

  const discountAmount = mainTotal * 0.75 - totalUSD;
  const DiscountDec = discountAmount.toFixed(2);

  const handleChoosePayment = (e) => {
    setSelectedOption(e);
    setToken(e.value);
  };

  const findingTheCoure = checkCourseName.find(
    (name) => name.coursename === singleCourse.coursename
  );

  //Register Free Course...........//.............//
  console.log("singleCourse", singleCourse);
  const registerFreeCourse = async () => {
    // if (!data.email) {
    //   // Need to convert
    //   return swal({
    //     text: "Before registration please update your profile. We will send the details to you.",
    //     icon: "warning",
    //     button: true,
    //     dangerMode: true,
    //     className: "modal_class_success",
    //   }).then((willDelete) => {
    //     if (willDelete) {
    //       navigate(`/my-account`);
    //     } else {
    //       console.log("ok");
    //     }
    //   });
    // }

    const wrapper1 = document.createElement("div");
    wrapper1.innerHTML = `
                    <p className="success">Do you want to enrol for <br/> ${singleCourse?.coursename}?</p>
              `;

    swal({
      content: wrapper1,
      icon: "success",
      className: "modal_class_success",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        const data3 = {
          courseId: singleCourse?._id,
          videoData: singleCourse?.videoData,
          pdfData: singleCourse?.pdfData,
          imageData: singleCourse?.imageData,
          coursename: singleCourse?.coursename,
          image: singleCourse?.image,
          description: singleCourse?.description,
          youlearn: singleCourse?.youlearn,
          categories: singleCourse?.categories,
          date: newDate,
          walletAddress: data?.walletAddress,
          status: false,
          method: "FREE",
          email: data?.email,
          name: data?.name,
          amount: singleCourse?.amount,
          memberId: data?.memberId,
          howlearn: singleCourse?.howlearn,
          duration: singleCourse.start,
        };

        console.log("data3", data3);

        axios
          .post("https://backend.studyswiftly.com/api/mint/save-nft", data3, {})
          .then((res) => {
            if (res.status === 200) {
              console.log("enrolled data", res.data);
              navigate("/my-account", { state: { name: 1 } });

              setRequestLoading(false);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `
                    <p className="success">You have successfully enrolled to <br/> ${singleCourse?.coursename}.</p>
              `;
              swal({
                content: wrapper,
                icon: "success",
                button: "OK",
                className: "modal_class_success",
              });
              setRefetchAfterRegister(refetchAfterRegister + 1);
            }
          })
          .catch((err) => {
            console.log(err);
            setRefetchAfterRegister(false);
            setRequestLoading(false);
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<P>You have successfully registered for this course.</p>`;
            swal({
              title: "Warning",
              content: wrapper,
              icon: "warning",
              button: "OK",
              className: "modal_class_success",
            });
          });
      }
    });
  };

  useEffect(() => {
    axios
      .get(
        `https://backend.studyswiftly.com/api/mint/data/${user?.walletAddress}`
      )
      .then((res) => {
        setEnrolled(res.data.result);
      });
  }, []);

  let tests39;

  useEffect(() => {
    getBalanceTestnet();
  }, [id]);
  // getBalanceTestnet();

  // console.log("balance..testing" + " " + metamaskBalance, metamaskBalance?.busd + " " + finalBusd50TDec)

  //!-----------------

  const mintPaidCourse = async () => {
    const data2 = {
      courseId: singleCourse?._id,
      videoData: singleCourse?.videoData,
      pdfData: singleCourse?.pdfData,
      imageData: singleCourse?.imageData,
      coursename: singleCourse.coursename,
      amount: singleCourse.amount,
      image: singleCourse.image,
      description: singleCourse.description,
      youlearn: singleCourse.youlearn,
      start: singleCourse.start,
      categories: singleCourse.categories,
      end: singleCourse.end,
      duration: singleCourse.start,
      date: new Date(),
      walletAddress: data?.walletAddress,
      status: false,
      method: "BUSD & S39",
      user: data?._id,
      name: data?.name,
      email: data?.email,
      memberId: data?.memberId,
      howlearn: singleCourse?.howlearn,
      payBusd1: singleCourse.amount,
      payBusd2: singleCourse.amount,
      payS39: 0,
      bscUrl1: "Obj.mint_hash",
      bscUrl2: "tests39",
      affiliate1: 1,
      affiliate2: 2,
      affiliate3: 3,
      credit: parseFloat(credit),
      earnings: parseFloat(earnings),
      paid: parseFloat(
        singleCourse?.amount -
          (data?.sponser ? singleCourse?.amount * (5 / 100) : 0) -
          singleCourse?.amount * (20 / 100) -
          earnings -
          credit
      ),
    };
    if(parseFloat(
      singleCourse?.amount -
        (data?.sponser ? singleCourse?.amount * (5 / 100) : 0) -
        singleCourse?.amount * (20 / 100) -
        earnings -
        credit
    )?.toFixed(2) > 0) {
      console.log(data2, "dddddddddddd");
      localStorage.setItem("course", JSON.stringify(data2));
  
      navigate("/shop/payment", {
        state: {
          data: JSON.stringify(data2),
          id: singleCourse?._id,
          price: singleCourse?.amount,
          ownerId: data?.email,
          refEmail: data?.email,
          date: new Date(),
          credits: 0,
          realPrice: singleCourse?.amount,
          credit: parseFloat(credit),
          earnings: parseFloat(earnings),
          paid: parseFloat(
            singleCourse?.amount -
              (data?.sponser ? singleCourse?.amount * (5 / 100) : 0) -
              singleCourse?.amount * (20 / 100) -
              earnings -
              credit
          ),
        },
      });
      // buyCourseMainFunction(1);
      console.log("click");
    }
    else {
   
    
          swal({
            text: `Are you sure you want to enrol?`,
            icon: "warning",
            buttons: ["Cancel", "Yes"],
            className: "modal_class_success",
          }).then(async (value) => {
            if (value) {
                await axios
                  .post(
                    "https://backend.studyswiftly.com/api/mint/save-nft",
                    data2,
                    {}
                  )
                  .then((res) => {
                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-white'>Course Successfully Enrolled.</p>`;
    
                    Swal.fire({
                      html: wrapper,
                      icon: "success",
                      customClass: "modal_class_success",
                    });
                    navigate("/my-account", { state: { index: 1 } });
                  });
          
            }
          });
    
      
    }
    // setTokenId(Obj.ID);
    // await axios
    //   .post(
    //     "https://backend.studyswiftly.com/api/mint/save-nft",
    //     data2,
    //     {}
    //   )
    //   .then((res) => {

    //   })
   
  };

  const mintCertificate = async () => {
    //! api data here, data which contain's uri
    // mintCertificateMainFunction(data)
    console.log("click");
  };
  // const mintPaidCourse = async () => {
  //   // getBalanceTestnet();
  //   console.log(
  //     "balance........................:........." +
  //     " " +
  //     metamaskBalance?.busd +
  //     " " +
  //     finalBusd50TDec
  //   );
  //   // console.log("finalBusd50TDec test", finalBusd50TDec)
  //   if (
  //     (parseFloat(metamaskBalance?.busd) ||
  //       metamaskBalance?.busd == undefined) < parseFloat(finalBusd50TDec)
  //   ) {
  //     // Need to convert
  //     swal({
  //       text: "Please check your BUSD (testnet) balance and try again",
  //       icon: "warning",
  //       button: true,
  //       dangerMode: true,
  //       className: "modal_class_success",
  //     });
  //   } else {
  //     if (token === "busd") {
  //       setRequestLoading(true);
  //       const data = new FormData();
  //       data.append("Id", singleCourse._id);
  //       data.append("amount", finalBusd50TDec);
  //       data.append("tokenAddress", BUSDtokenAddressTestnet);
  //       data.append("refAddress", affiliateWalletAddress);
  //       data.append("refAddress2", affiliateWalletAddress2);
  //       data.append("refAddress3", affiliateWalletAddress3);
  //       data.append(
  //         "refAddress4",
  //         "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d"
  //       );
  //       data.append("refAmount", get5Busd);
  //       data.append("refAmount2", get2Busd);
  //       data.append("refAmount3", get1Busd);
  //       data.append("refAmount4", mainwallet02);
  //       data.append("image", singleCourse?.image);
  //       data.append("email", user?.email);
  //       data.append("nonce", uuidv4());

  //       await axios
  //         .post(
  //           "https://backend.studyswiftly.com/api/mint/uri-json-nft",
  //           data,
  //           {}
  //         )
  //         .then(async (res) => {
  //           let Obj = {};

  //           if (res.status === 200) {
  //             const data1 = await signBuyFunction(
  //               singleCourse._id,
  //               ethers.utils.parseEther(finalBusd50TDec),
  //               BUSDtokenAddressTestnet,
  //               affiliateWalletAddress,
  //               affiliateWalletAddress2,
  //               affiliateWalletAddress3,
  //               "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
  //               ethers.utils.parseEther(get5Busd),
  //               ethers.utils.parseEther(get2Busd),
  //               ethers.utils.parseEther(get1Busd),

  //               ethers.utils.parseEther(mainwallet02),
  //               res.data.uri
  //             );

  //             if (token === "busd") {
  //               Obj = await payTestnetBUSD(data1);
  //             }

  //             const formDataAddUser = new FormData();
  //             formDataAddUser.append("mainAmount", totalUSD);
  //             formDataAddUser.append("date", newDate);
  //             formDataAddUser.append("refAddress", affiliateWalletAddress);
  //             formDataAddUser.append("refAmount", get5Busd);
  //             formDataAddUser.append("refEmail", affiliateEmail);
  //             formDataAddUser.append("refMemberId", affiliateMember);
  //             formDataAddUser.append(
  //               "refAddress2",
  //               affiliateWalletAddress2 ? affiliateWalletAddress2 : " "
  //             );
  //             formDataAddUser.append("refEmail2", affiliateEmail2);
  //             formDataAddUser.append("refAmount2", get2Busd);
  //             formDataAddUser.append("refMemberId2", affiliateMember2);
  //             formDataAddUser.append(
  //               "refAddress3",
  //               affiliateWalletAddress3 ? affiliateWalletAddress3 : " "
  //             );
  //             formDataAddUser.append("refEmail3", affiliateEmail3);
  //             formDataAddUser.append("refAmount3", get1Busd);
  //             formDataAddUser.append("refMemberId3", affiliateMember3);
  //             formDataAddUser.append("wallet1Amount", finalBusd50TDec);
  //             formDataAddUser.append("wallet2Amount", mainwallet02);
  //             formDataAddUser.append(
  //               "wallet1",
  //               "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d"
  //             );
  //             formDataAddUser.append(
  //               "wallet2",
  //               "0x5E8634E377162a86AB61C4b5d4072b558015c7B9"
  //             );

  //             axios
  //               .put(
  //                 `https://backend.studyswiftly.com/api/wallet/update/${user?._id}`,
  //                 formDataAddUser,
  //                 {}
  //               )
  //               .then(async (res) => {
  //                 if (res.status === 200) {
  //                   console.log("success.....................................");
  //                   const data2 = {
  //                     courseId: singleCourse?._id,
  //                     videoData: singleCourse?.videoData,
  //                     pdfData: singleCourse?.pdfData,
  //                     imageData: singleCourse?.imageData,
  //                     coursename: singleCourse.coursename,
  //                     amount: singleCourse.amount,
  //                     image: singleCourse.image,
  //                     description: singleCourse.description,
  //                     youlearn: singleCourse.youlearn,
  //                     start: singleCourse.start,
  //                     categories: singleCourse.categories,
  //                     end: singleCourse.end,
  //                     date: newDate,
  //                     walletAddress: user?.walletAddress,
  //                     status: false,
  //                     method: "BUSD",
  //                     name: user?.name,
  //                     email: user?.email,
  //                     memberId: user?.memberId,
  //                     duration: singleCourse.start,
  //                     howlearn: singleCourse?.howlearn,
  //                     payBusd1: mainwallet01,
  //                     payBusd2: mainwallet02,
  //                     bscUrl1: Obj.mint_hash,
  //                     affiliate1: get5Busd,
  //                     affiliate2: get2Busd,
  //                     affiliate3: get1Busd,
  //                   };

  //                   // setTokenId(Obj.ID);

  //                   await axios
  //                     .post(
  //                       "https://backend.studyswiftly.com/api/mint/save-nft",
  //                       data2,
  //                       {}
  //                     )
  //                     .then((res) => {
  //                       if (res.status === 200) {
  //                         navigate("/my-account", { state: { name: 1 } });

  //                         setRequestLoading(false);
  //                         const wrapper = document.createElement("div");
  //                         wrapper.innerHTML = `
  //                 <a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a>
  //                 <br/>
  //                 <p className="success">You have successfully purchased the course.</p>
  //                  `;
  //                         swal({
  //                           title: "Paid",
  //                           content: wrapper,
  //                           icon: "success",
  //                           button: true,
  //                           className: "modal_class_success",
  //                         });

  //                         // Swal.fire(
  //                         //   {
  //                         //     html: wrapper,
  //                         //     icon: "success",
  //                         //     customClass: "modal_class_success",
  //                         //   }
  //                         // )
  //                         // .then((willDelete) => {
  //                         //   if (willDelete) {
  //                         //     // navigate(`/mintednft/${Obj.ID}/${mintAddressTestnet}`)
  //                         //     swal({
  //                         //       title: "Success",
  //                         //       text: "Please Check your mail for Course details",
  //                         //       icon: "success",
  //                         //       button: "OK!",
  //                         //       className: "modal_class_success",
  //                         //     });
  //                         //   } else {
  //                         //     swal({
  //                         //       title: "Success",
  //                         //       text: "Please Check your mail for Course details",
  //                         //       icon: "success",
  //                         //       button: "OK!",
  //                         //       className: "modal_class_success",
  //                         //     });
  //                         //   }
  //                         // });
  //                         // postDataAfterMint();
  //                         // handleSubmit(Obj.ID, res.data.ImgCelebrity);
  //                       }
  //                     })
  //                     .catch((err) => {
  //                       console.log(err);
  //                       setRequestLoading(false);
  //                       const wrapper = document.createElement("div");
  //                       wrapper.innerHTML = `<a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a> <br/> <p className="success">You have successfully minted but error in while saving data.</p>`;
  //                       // swal({
  //                       //   title: "Warning",
  //                       //   content: wrapper,
  //                       //   icon: "warning",
  //                       //   button: "OK",
  //                       //   className: "modal_class_success",
  //                       // });

  //                       Swal.fire({
  //                         html: wrapper,
  //                         icon: "success",
  //                         customClass: "modal_class_success",
  //                       });
  //                     });
  //                 }
  //               })
  //               .catch((err) => {
  //                 console.log("err........................................");
  //               });
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setRequestLoading(false);
  //           if (err.code === 4001) {
  //             // return swal({
  //             //   title: "Failed",
  //             //   text: "Minting Failed!",
  //             //   icon: "warning",
  //             //   button: "OK",
  //             //   dangerMode: true,
  //             //   className: "modal_class_success",
  //             // });
  //             setRequestLoading(false);

  //             const wrapper = document.createElement("div");
  //             wrapper.innerHTML = `<p class='text-break text-white'>Minting Failed!</p>`;

  //             return Swal.fire({
  //               html: wrapper,
  //               icon: "warning",
  //               customClass: "modal_class_success",
  //             });
  //           }

  //           const wrapper = document.createElement("div");
  //           wrapper.innerHTML = `<p class='text-break text-white'>Something went wrong. Check your balance and try again.</p>`;

  //           Swal.fire({
  //             html: wrapper,
  //             icon: "warning",
  //             customClass: "modal_class_success",
  //           });

  //           // return swal({
  //           //   title: "Attention",
  //           //   text: "Something went wrong. Check your balance and try again.",
  //           //   icon: "warning",
  //           //   button: "OK",
  //           //   dangerMode: true,
  //           //   className: "modal_class_success",
  //           // });
  //         });
  //     } else if (token === "busd_s39") {
  //       if (parseFloat(metamaskBalance?.s39) < parseFloat(s39Dec)) {
  //         // Need to convert
  //         return swal({
  //           text: "Please check your S39 (testnet) balance and try again",
  //           icon: "warning",
  //           button: true,
  //           dangerMode: true,
  //           className: "modal_class_success",
  //         });
  //       }
  //       setRequestLoading(true);
  //       const data = new FormData();
  //       data.append("Id", singleCourse._id);
  //       data.append("amount", s39Dec);
  //       data.append("tokenAddress", S39tokenAddressTestnet);
  //       data.append("refAddress", affiliateWalletAddress);
  //       data.append("refAddress2", affiliateWalletAddress2);
  //       data.append("refAddress3", affiliateWalletAddress3);
  //       data.append("refAmount", "0");
  //       data.append("refAmount2", "0");
  //       data.append("refAmount3", "0");
  //       data.append("image", singleCourse.image);
  //       data.append("email", user?.email);
  //       data.append("nonce", uuidv4());

  //       await axios
  //         .post(
  //           "https://backend.studyswiftly.com/api/mint/uri-json-nft",
  //           data,
  //           {}
  //         )
  //         .then(async (res) => {
  //           let Obj = {};

  //           if (res.status === 200) {
  //             const data1 = await signBuyFunction(
  //               singleCourse._id,
  //               ethers.utils.parseEther(s39Dec),
  //               S39tokenAddressTestnet,
  //               affiliateWalletAddress,
  //               affiliateWalletAddress2,
  //               affiliateWalletAddress3,
  //               "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
  //               "0",
  //               "0",
  //               "0",
  //               "0",
  //               res.data.uri
  //             );

  //             console.log(res.data.uri);

  //             if (token === "busd_s39") {
  //               Obj = await payTestnetS39(data1);
  //             }

  //             setUrls39(Obj.mint_hash);
  //             tests39 = Obj.mint_hash;
  //             console.log("s39 testing", Obj.mint_hash);

  //             const formDataAddUser = new FormData();
  //             formDataAddUser.append("mainAmount", finalUSDTDec);
  //             formDataAddUser.append("s39Amount", s39Dec);
  //             formDataAddUser.append("date", newDate);
  //             formDataAddUser.append("refAddress", affiliateWalletAddress);
  //             formDataAddUser.append("refAmount", get5Busd80);
  //             formDataAddUser.append("refEmail", affiliateEmail);
  //             formDataAddUser.append("refMemberId", affiliateMember);
  //             formDataAddUser.append("refAddress2", affiliateWalletAddress2);
  //             formDataAddUser.append("refEmail2", affiliateEmail2);
  //             formDataAddUser.append("refAmount2", get2Busd80);
  //             formDataAddUser.append("refMemberId2", affiliateMember2);
  //             formDataAddUser.append("refAddress3", affiliateWalletAddress3);
  //             formDataAddUser.append("refEmail3", affiliateEmail3);
  //             formDataAddUser.append("refAmount3", get1Busd80);
  //             formDataAddUser.append("refMemberId3", affiliateMember3);
  //             formDataAddUser.append("wallet1Amount", wallet01);
  //             formDataAddUser.append("wallet2Amount", wallet02);
  //             formDataAddUser.append(
  //               "wallet1",
  //               "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d"
  //             );
  //             formDataAddUser.append(
  //               "wallet2",
  //               "0x5E8634E377162a86AB61C4b5d4072b558015c7B9"
  //             );

  //             axios
  //               .put(
  //                 `https://backend.studyswiftly.com/api/wallet/update/${user?._id}`,
  //                 formDataAddUser,
  //                 {}
  //               )
  //               .then(async (res) => {
  //                 if (res.status === 200) {
  //                   console.log(
  //                     "successfull............................................."
  //                   );
  //                   setRequestLoading(true);
  //                   const data = new FormData();
  //                   data.append("Id", singleCourse._id);
  //                   // data.append('amount', DiscountDecTest);
  //                   // data.append('amount', finalUSDTDec);
  //                   data.append("amount", dslBusdAmountDec);
  //                   data.append("tokenAddress", BUSDtokenAddressTestnet);
  //                   data.append("refAddress", affiliateWalletAddress);
  //                   data.append("refAddress2", affiliateWalletAddress2);
  //                   data.append("refAddress3", affiliateWalletAddress3);
  //                   data.append(
  //                     "refAddress4",
  //                     "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d"
  //                   );
  //                   data.append("refAmount", get5Busd80);
  //                   data.append("refAmount2", get2Busd80);
  //                   data.append("refAmount3", get1Busd80);
  //                   data.append("refAmount4", wallet02);
  //                   data.append("image", singleCourse.image);
  //                   data.append("email", user?.email);
  //                   data.append("nonce", uuidv4());

  //                   await axios
  //                     .post(
  //                       "https://backend.studyswiftly.com/api/mint/uri-json-nft",
  //                       data,
  //                       {}
  //                     )
  //                     .then(async (res) => {
  //                       let Obj = {};

  //                       console.log("objecttt......: " + Obj);

  //                       if (res.status === 200) {
  //                         const data1 = await signBuyFunction(
  //                           singleCourse._id,
  //                           ethers.utils.parseEther(dslBusdAmountDec),
  //                           BUSDtokenAddressTestnet,
  //                           affiliateWalletAddress,
  //                           affiliateWalletAddress2,
  //                           affiliateWalletAddress3,
  //                           "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d",
  //                           ethers.utils.parseEther(get5Busd80),
  //                           ethers.utils.parseEther(get2Busd80),
  //                           ethers.utils.parseEther(get1Busd80),
  //                           ethers.utils.parseEther(wallet02),
  //                           res.data.uri
  //                         );

  //                         if (token === "busd_s39") {
  //                           Obj = await payTestnetBUSD(data1);
  //                         }

  //                         const data2 = {
  //                           courseId: singleCourse?._id,
  //                           videoData: singleCourse?.videoData,
  //                           pdfData: singleCourse?.pdfData,
  //                           imageData: singleCourse?.imageData,
  //                           coursename: singleCourse.coursename,
  //                           amount: singleCourse.amount,
  //                           image: singleCourse.image,
  //                           description: singleCourse.description,
  //                           youlearn: singleCourse.youlearn,
  //                           start: singleCourse.start,
  //                           categories: singleCourse.categories,
  //                           end: singleCourse.end,
  //                           duration: singleCourse.start,
  //                           date: newDate,
  //                           walletAddress: user?.walletAddress,
  //                           status: false,
  //                           method: "BUSD & S39",
  //                           name: user?.name,
  //                           email: user?.email,
  //                           memberId: user?.memberId,
  //                           howlearn: singleCourse?.howlearn,
  //                           payBusd1: wallet01,
  //                           payBusd2: wallet02,
  //                           payS39: s39Dec,
  //                           bscUrl1: Obj.mint_hash,
  //                           bscUrl2: tests39,
  //                           affiliate1: get5Busd80,
  //                           affiliate2: get2Busd80,
  //                           affiliate3: get1Busd80,
  //                         };
  //                         // setTokenId(Obj.ID);
  //                         await axios
  //                           .post(
  //                             "https://backend.studyswiftly.com/api/mint/save-nft",
  //                             data2,
  //                             {}
  //                           )
  //                           .then((res) => {
  //                             if (res.status === 200) {
  //                               navigate("/my-account", { state: { name: 1 } });

  //                               setRequestLoading(false);
  //                               const wrapper = document.createElement("div");
  //                               wrapper.innerHTML = `
  //                             <span>BUSD: <a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a></span>

  //                             <br/>
  //                           <span> S39: <a href=${tests39} target="_any" className="link_hash">${tests39}</a></span>

  //                            <p className="success">You have successfully purchased the course.</p>
  //                      `;
  //                               swal({
  //                                 title: "Paid",
  //                                 content: wrapper,
  //                                 icon: "success",
  //                                 button: true,
  //                                 className: "modal_class_success",
  //                               });

  //                               // Swal.fire(
  //                               //   {
  //                               //     html: wrapper,
  //                               //     icon: "success",
  //                               //     customClass: "modal_class_success",
  //                               //   }
  //                               // )

  //                               // .then((willDelete) => {
  //                               //   if (willDelete) {
  //                               //     // navigate(`/mintednft/${Obj.ID}/${mintAddressTestnet}`)
  //                               //     swal({
  //                               //       title: "Success",
  //                               //       text: "Please Check your mail for Course details",
  //                               //       icon: "success",
  //                               //       button: "OK!",
  //                               //       className: "modal_class_success",
  //                               //     });
  //                               //   } else {
  //                               //     swal({
  //                               //       title: "Success",
  //                               //       text: "Please Check your mail for Course details",
  //                               //       icon: "success",
  //                               //       button: "OK!",
  //                               //       className: "modal_class_success",
  //                               //     });
  //                               //   }
  //                               // });
  //                             }
  //                           })
  //                           .catch((err) => {
  //                             console.log(err);
  //                             setRequestLoading(false);
  //                             const wrapper = document.createElement("div");
  //                             wrapper.innerHTML = `<a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a> <br/> <p className="success">You have successfully minted but error in while saving data.</p>`;
  //                             // swal({
  //                             //   title: "Warning",
  //                             //   content: wrapper,
  //                             //   icon: "warning",
  //                             //   button: "OK",
  //                             //   className: "modal_class_success",
  //                             // });

  //                             Swal.fire({
  //                               html: wrapper,
  //                               icon: "warning",
  //                               customClass: "modal_class_success",
  //                             });
  //                           });
  //                       } //2nd if=200 of s39
  //                     }) //2nd then of s39
  //                     .catch((err) => {
  //                       console.log(err);
  //                       setRequestLoading(false);
  //                       if (err.code === 4001) {
  //                         setRequestLoading(false);

  //                         const wrapper = document.createElement("div");
  //                         wrapper.innerHTML = `<p class='text-break text-white'>Minting Failed!</p>`;

  //                         return Swal.fire({
  //                           html: wrapper,
  //                           icon: "warning",
  //                           customClass: "modal_class_success",
  //                         });

  //                         // return swal({
  //                         //   title: "Failed",
  //                         //   text: "Minting Failed!",
  //                         //   icon: "warning",
  //                         //   button: "OK",
  //                         //   dangerMode: true,
  //                         //   className: "modal_class_success",
  //                         // });
  //                       }

  //                       const wrapper = document.createElement("div");
  //                       wrapper.innerHTML = `<p class='text-break text-white'>Something went wrong. Check your balance and try again.</p>`;

  //                       return Swal.fire({
  //                         html: wrapper,
  //                         icon: "warning",
  //                         customClass: "modal_class_success",
  //                       });

  //                       // return swal({
  //                       //   title: "Attention",
  //                       //   text: "Something went wrong. Check your balance and try again.",
  //                       //   icon: "warning",
  //                       //   button: "OK",
  //                       //   dangerMode: true,
  //                       //   className: "modal_class_success",
  //                       // });
  //                     }); // 2nd catch of busd payment
  //                 }
  //               })
  //               .catch((err) => {
  //                 console.log(err);
  //                 console.log(
  //                   "erron........................................................"
  //                 );
  //               });
  //           } //1st if=200 of busd payment
  //         }) //1st then of busd payment
  //         .catch((err) => {
  //           console.log(err);
  //           setRequestLoading(false);
  //           if (err.code === 4001) {
  //             // return swal({
  //             //   title: "Failed",
  //             //   text: "Minting Failed!",
  //             //   icon: "warning",
  //             //   button: "OK",
  //             //   dangerMode: true,
  //             //   className: "modal_class_success",
  //             // });
  //             setRequestLoading(false);

  //             const wrapper = document.createElement("div");
  //             wrapper.innerHTML = `<p class='text-break text-white'>Minting Failed!</p>`;

  //             return Swal.fire({
  //               html: wrapper,
  //               icon: "warning",
  //               customClass: "modal_class_success",
  //             });
  //           }

  //           const wrapper = document.createElement("div");
  //           wrapper.innerHTML = `<p class='text-break text-white'>Something went wrong. Check your balance and try again.</p>`;

  //           return Swal.fire({
  //             html: wrapper,
  //             icon: "success",
  //             customClass: "modal_class_success",
  //           });

  //           // return swal({
  //           //   title: "Attention",
  //           //   text: "Something went wrong. Check your balance and try again.",
  //           //   icon: "warning",
  //           //   button: "OK",
  //           //   dangerMode: true,
  //           //   className: "modal_class_success",
  //           // });
  //         }); // 1st catch of busd payment
  //     }
  //   }
  // };

  const testAffiliate = (e) => {
    e.preventDefault();
    const formDataAddUser = new FormData();
    formDataAddUser.append("mainAmount", finalUSDTDec);
    formDataAddUser.append("s39Amount", s39Dec);
    formDataAddUser.append("date", newDate);
    formDataAddUser.append("refAddress", affiliateWalletAddress);
    formDataAddUser.append("refAmount", get5Busd80);
    formDataAddUser.append("refEmail", affiliateEmail);
    formDataAddUser.append("refMemberId", affiliateMember);
    formDataAddUser.append(
      "refAddress2",
      "0xb15ccbd86260b5ea9360ada75efc43b8e98c7d24"
    );
    formDataAddUser.append("refEmail2", "webdevshajjad@gmail.com");
    formDataAddUser.append("refAmount2", get2Busd80);
    formDataAddUser.append("refMemberId2", "MPJ3Z0");
    formDataAddUser.append(
      "refAddress3",
      "0xf7cc291b268d28642d8280fe9104ac6b70aac97d"
    );
    formDataAddUser.append("refEmail3", "mohammadjahid0007@gmail.com");
    formDataAddUser.append("refAmount3", get1Busd80);
    formDataAddUser.append("refMemberId3", "DAM4QC");
    // formDataAddUser.append('wallet1Amount', wallet01 )
    // formDataAddUser.append('wallet2Amount', wallet02 )
    formDataAddUser.append(
      "wallet1",
      "0x4c17f3c856d1d059bdd597ad64d0f4375e52944d"
    );
    formDataAddUser.append(
      "wallet2",
      "0x5E8634E377162a86AB61C4b5d4072b558015c7B9"
    );

    axios
      .put(
        `https://backend.studyswiftly.com/api/wallet/update/${user?._id}`,
        formDataAddUser,
        {}
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("successfull");
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("erron");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (data && singleCourse) {
     if(data?.sponser){
      setDiscount5(singleCourse?.amount * (5 / 100))
     }
      setDiscount20(singleCourse?.amount * (20 / 100))
      const xCredit = data?.credit;
      const yEarning = data?.earning;
      const amount =
        singleCourse?.amount -
        (data?.sponser ? singleCourse?.amount * (5 / 100) : 0) -
        singleCourse?.amount * (20 / 100);
      if (amount >= xCredit) {
        setCredit(xCredit);
        const zAmount = amount - xCredit;
        if (zAmount >= yEarning) {
          if (yEarning > 0) {
            setEarnings(yEarning);
          }
        } else {
          if (yEarning > 0) {
            setEarnings(zAmount);
          }
        }
      } else {
        setCredit(amount);
      }
    }
  }, [singleCourse]);
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Enrol"
        pageName="Enrol"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* Course Main */}

      <div className="container my-5 ">
        <h3 className="d-flex align-items-center gap-2">
                <h5
                  style={{
                    color: "#333",
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ff5421",
                  }}
                  className="fs-4 m-0">
                  {singleCourse.coursename}
                </h5>
             </h3>

        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div>
              <img
                src={singleCourse?.image?.replace(
                  "https://backend.blockchaincert.sg/",
                  "https://backend.studyswiftly.com/"
                )}
                alt="Course_image"
                className="video-img w-100"
              />
            </div>
            <div className="imageDiv">
            
              {/* <p>
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                  Course Description:
                </h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: singleCourse.description,
                  }}></div>{" "}
              </p> */}
              {/* {singleCourse?.learnPdf?.length > 0 && (
                <>
                  <h5
                    style={{
                      color: "#333",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                    className="fs-5">
                    What you'll learn:
                  </h5>

                  {singleCourse?.learnPdf?.map((pdf) => (
                    <embed
                      // src={URL.createObjectURL(pdf)}
                      src={pdf}
                      type="application/pdf"
                      frameBorder="0"
                      className="overview-pdf"
                      scrolling="auto"
                      height="100%"
                      width="100%"></embed>
                  ))}
                </>
              )} */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            {/* <p>
              {singleCourse?.learnPdf?.length <= 0 && (
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                  What you'll learn:
                </h5>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: singleCourse.youlearn,
                }}></div>
            </p>

            <h5
              style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
              className="fs-5">
              Learning outcomes:
            </h5>

            <div
              className="pb-1 "
              dangerouslySetInnerHTML={{ __html: singleCourse.howlearn }}></div>

            <h5
              style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
              className="fs-5">
              How you can get the certificate?
            </h5>

            <div
              className="pb-1 "
              dangerouslySetInnerHTML={{
                __html: singleCourse.howGetCertificate,
              }}></div>

            <h5
              style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
              className="fs-5">
              Learning Materials:
            </h5>

            <div className="pb-1 ">
              {singleCourse?.imageData?.length > 0 && (
                <p>
                  <ArrowRightIcon className="text-dark" />{" "}
                  {singleCourse?.imageData?.length} Images
                </p>
              )}
              {singleCourse?.videoData?.length > 0 && (
                <p>
                  <ArrowRightIcon className="text-dark" />{" "}
                  {singleCourse?.videoData?.length} Video
                </p>
              )}
              {singleCourse?.pdfData?.length > 0 && (
                <p>
                  <ArrowRightIcon className="text-dark" />{" "}
                  {singleCourse?.pdfData?.length} PDF files
                </p>
              )}
            </div> */}

            {singleCourse?.amount === "0.00" ? (
              <p className="mt-3">
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                  Course Price:{" "}
                  <span
                    style={{
                      color: "#ff5421",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}>
                    FREE
                  </span>{" "}
                </h5>{" "}
              </p>
            ) : (
              <p className="mt-3">
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                  Course Fees: SGD{" "}
                  {parseFloat(singleCourse?.amount ?? 0)?.toFixed(2)}
                  {/* <span
                    style={{
                      color: "#ff5421",
                      fontSize: "26px",
                      fontWeight: "600",
                    }}>
                    {parseInt(singleCourse?.amount * (80 / 100))}
                  </span>{" "}
                  <sub
                    className="me-2"
                    style={{
                      color: "#ff5421",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}>
                    <del>{singleCourse?.amount}</del>
                  </sub>
                  SGD */}
                </h5>{" "}
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                  20% Discount: <span style={{ color: "red" }}> - SGD{" "}
                  {parseFloat(singleCourse?.amount * (20 / 100))?.toFixed(2)}</span>
                </h5>{" "}
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                  5% Affiliate Discount:{" "}
                  <span style={{ color: data?.sponser?"red":"black" }}> {data?.sponser
                    ? `- SGD ${parseFloat(
                        singleCourse?.amount * (5 / 100)
                      )?.toFixed(2)}`
                    : "SGD 0.00 - Code not entered during sign up."} </span>
                </h5>{" "}
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                  Scholarship Credit: SGD{" "}
                  {data?.credit>0?parseFloat(data?.credit)?.toFixed(2):"0.00"}{" "}
                 { credit>0 &&<span className="mx-2" style={{ color: "red" }}>
                    {`( - SGD ${credit?.toFixed(2)} )`}
                  </span>}
                </h5>{" "}
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className="fs-5">
                    Earnings: SGD {parseFloat(data?.earning)?.toFixed(2)}
                    {earnings>0 && <span className="mx-2" style={{ color: "red" }}>
                    {`( - SGD ${earnings?.toFixed(2)} )`}
                  </span>}
                </h5>{" "}
                <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600",textDecoration:"underline" }}
                  className="fs-5">
                  Balance: SGD{" "}
                  {parseFloat(
                    singleCourse?.amount -
                      discount5 -
                      discount20 -
                      (earnings>0?earnings:0) -
                     ( credit>0?credit:0)
                  )?.toFixed(2)}
                </h5>
              </p>
            )}
            {/****************************** Free Hide ******************************** */}
            {singleCourse?.amount === "0.00" ? (
              <></>
            ) : (
              <>
                {/* <p><h5 style={{ color: "#333", fontSize: '18px', fontWeight: '700' }} className="fs-5">Closing Date: <span style={{ color: "#333", fontSize: '18px', fontWeight: '700' }}>{`${singleCourse.end?.slice(8, 10)}/${singleCourse.end?.slice(5, 7)}/${singleCourse.end?.slice(0, 4)}`}</span></h5>  </p> */}
                {/* <h5
                  style={{ color: "#333", fontSize: "18px", fontWeight: "600" }}
                  className=" mb-1 fs-5"
                >
                  Choose how you want to pay:
                </h5>

                <div className="widthDetailsInput mt-1">
                  <Select
                    value={selectedOption}
                    onChange={handleChoosePayment}
                    options={selectOptions}
                    styles={customStyles}
                    formatOptionLabel={(option) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          src={option.image}
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "100px",
                          }}
                          alt=""
                        />
                        <span>{option.label}</span>
                      </div>
                    )}
                  />
                </div>
                {token === "busd_s39" && (
                  <p>
                    You need{" "}
                    <span style={{ color: "#333" }}>{finalUSDTDec}</span> BUSD
                    and <span style={{ color: "#333" }}>{s39Dec}</span> S39
                    tokens. Please ensure your wallet has both.
                  </p>
                )}
                {token === "busd" && (
                  <p>
                    You have to pay{" "}
                    <span style={{ color: "#333" }}>
                      {totalUSD.toFixed(4)} BUSD{" "}
                    </span>{" "}
                    for buy.
                  </p>
                )} */}

                {/* <h5
                  style={{
                    color: "#333",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  className=" mb-1 fs-5">
                  Affiliate Code:
                </h5>

                <div class="input-group mb-2 widthDetailsInput">
                  <input
                    type="text"
                    name="affiliateCode"
                    disabled
                    value={data?.sponser}
                    onChange={handleAffiliateCode}
                    class="form-control"
                    placeholder="Enter Affiliate Code"
                    aria-label="Enter Affiliate Code"
                    aria-describedby="button-addon2"
                  />

                  <button
                    className={
                      !data?.sponser ? "btn btn-danger" : "btn btn-success"
                    }
                    type="button"
                    id="button-addon2"
                    style={{ zIndex: "0" }}>
                    {!data?.sponser ? <AiOutlineClose /> : <AiOutlineCheck />}
                  </button>
                </div> */}
                {/* {gotRefCode && <div style={{ textAlign: 'start' }}>
                  <Typography className="pt-1 pb-1  text-gradient" variant="subtitle2" gutterBottom component="div">
                  {token === "busd" &&
                     <span className="spanDiscount ">You saved {DiscountDec} BUSD</span>
                     }
                  {token === "busd_s39" &&
                     <span className="spanDiscount ">You saved {disRefBusd80} BUSD</span>
                     }
                  </Typography>
                </div>} */}
                {/* <p>Please enter the affiliate code to get discount.</p> */}
              </>
            )}

            {singleCourse?.amount === "0.00" ? (
              <button
                onClick={() => registerFreeCourse()}
                className="btn text-uppercase readon2 orange-transparent w-100">
                Please click to enrol, It is Free
              </button>
            ) : (
              <button
                onClick={() => mintPaidCourse()}
                className="btn text-uppercase readon2 orange-transparent w-100">
                {parseFloat(
                    singleCourse?.amount -
                      discount5 -
                      discount20 -
                      (earnings>0?earnings:0) -
                     ( credit>0?credit:0)
                  )>0?"Please click to enrol":"Please click to enrol, It is Free"}
              </button>
            )}
            {/* <button
              onClick={() => mintCertificate()}
              className="btn text-uppercase readon2 orange-transparent w-100" style={{marginTop:"8px"}}>
              Mint Certificate
            </button> */}
            {/* <button onClick={()=>navigate("/my-account",{state:{name:1}})}>button</button> */}
            {/* <button type='button' onClick={testAffiliate}>Test</button> */}
          </div>
        </div>
      </div>
      {/* Course Main */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default BuyCourse;
