import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Pagination from "../../../components/Pagination/Pagination";
import DashboardDraft from "./DashboardDraft";
import DashboardExpired from "./DashboardExpired";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import TemplateModal from "./TemplateModal/TemplateModal";
import { useContext } from "react";
import { AdminContext } from "../../../Context/AdminContext";

const Courses = () => {
  const navigate = useNavigate();
  const { admin, setAdmin } = useContext(AdminContext);
  const [courses, setCourses] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [selectedTab, setSelectedTab] = useState("courses");
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  var newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();

  if (min < 10) {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + 0 + min + ":" + ss;
  } else {
    newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  }

  // console.log("courses", courses);
const [categories,setCategories]=useState([])
const [category,setCategory]=useState("all")
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(res.data.Categories);
        });
    };
    data();
  }, [refetch]);

  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/active", config)
        .then((res) => {
          if(category=="all"){
            setCourses(res.data.result);
          } else {
            setCourses(res.data.result?.filter(res=>res?.categories==category)?.sort((a,b)=>a?.sort-b?.sort));
          }
        });
    };
    data();
  }, [refetch,category]);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceCourses, setSliceCourses] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(courses?.length / show);
    setLastPage(lastPage);
  }, [courses, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = courses.slice((page - 1) * show, page * show);
      setSliceCourses([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = courses.slice(0, show);
      setSliceCourses([...getSlicingProduct]);
    }
  }, [courses, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  // , {
  //     headers: {
  //         'authorization': `Bearer ${localStorage.getItem('courseSiteAdmin')}`
  //     }
  // }
  const handleCourseDraft = (id) => {
    Swal.fire({
      text: "Are you sure you want to save this course as draft?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`https://backend.studyswiftly.com/api/course/draft/${id}`)
          .then((res) => {
            if (res.status === 200) {
              // alert(res.data.message);
              // swal({
              //     title: "Success",
              //     text: "Saved successfully",
              //     icon: "success",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>Saved successfully</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              console.log(res.data);
              setRefetch(!refetch);
            }
          })
          .catch((err) => {
            // swal({
            //     title: "Attention",
            //     text: `${err.response.data.message}`,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "warning",
              customClass: "modal_class_success",
            });
          });
      }
    });
  };

  const handleDuplicateCourse = (id) => {
    const theCOURSE = courses?.find((course) => course?._id === id);

    const duplicateCourse = {
      coursename: theCOURSE.coursename,
      youlearn: theCOURSE.youlearn,
      description: theCOURSE.description,
      howlearn: theCOURSE.howlearn,
      start: theCOURSE.start,
      end: theCOURSE.end,
      amount: theCOURSE.amount,
      image: theCOURSE.image,
      categories: theCOURSE.categories,
      learnPdf: theCOURSE.learnPdf,
      date: newDate,
    };

    Swal.fire({
      text: "Do you want to Duplicate this Course?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch("https://backend.studyswiftly.com/api/course/repost", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(duplicateCourse),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result) {
              setRefetch(!refetch);
              swal({
                title: "Success",
                text: `Course successfully duplicated.`,
                icon: "success",
                button: "OK!",
                className: "modal_class_success",
              });
            }
          });
      }
    });
  };

  const handleCourseDelete = (courseToDelete) => {
    // const confirmDelete = window.confirm('Are you sure, you want to delete this course?')

    Swal.fire({
      text: "Are you sure, you want to delete this course?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://backend.studyswiftly.com/api/course/${courseToDelete?._id}`
          )
          .then((res) => {
            if (res.status === 200) {
              // alert(res.data.message);
              // swal({
              //     title: "Success",
              //     text: res.data.message,
              //     icon: "success",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              (async () => {
                await axios
                  .post(
                    `https://backend.studyswiftly.com/api/data/admin/course_delete`,
                    {
                      name: admin.name,
                      email: admin.email,
                      coursename: courseToDelete.coursename,
                      courseprice: courseToDelete.amount,
                      coursecatagory: courseToDelete.categories,
                      date: newDate,
                    }
                  )
                  .then((res) =>
                    console.log(res.data, "Admin delete course action")
                  );
              })();

              const wrapper = document.createElement("div");
              // wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;
              wrapper.innerHTML = `<p class='text-break text-white'>Course Deleted.</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              setCourses(
                courses.filter((course) => course._id !== courseToDelete._id)
              );
            }
          })
          .catch((error) => {
            // alert(error.response.data.message);
            // swal({
            //     title: "Attention",
            //     text: error.response.data.message,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "warning",
              customClass: "modal_class_success",
            });
          });
      }
    });

    // if (confirmDelete) {
    //     axios.delete(`https://backend.studyswiftly.com/api/course/${id}`)
    //         .then(res => {
    //             if (res.status === 200) {
    //                 // alert(res.data.message);
    //                 swal({
    //                     title: "Success",
    //                     text: res.data.message,
    //                     icon: "success",
    //                     button: "OK!",
    //                     className: "modal_class_success",
    //                 });
    //                 setCourses(courses.filter(course => course._id !== id))
    //             }
    //         })
    //         .catch(error => {
    //             // alert(error.response.data.message);
    //             swal({
    //                 title: "Attention",
    //                 text: error.response.data.message,
    //                 icon: "warning",
    //                 button: "OK!",
    //                 className: "modal_class_success",
    //             });
    //         })

    // }
  };

  const handleSort = (id,number) => {
    axios.put(`https://backend.studyswiftly.com/api/course/change-sort/${id}`,{sort:number})
    .then(res => {
    setRefetch(res=>!res)
  })
  }

  return (
    // <div className='adminBody' style={{ height: "100vh" }}>
    <div className="adminBody">
      <h5 className="text-white text-start">COURSES</h5>
      <div className="adminCard py-2">
        <div className="template-icon-button-container">
          {/* <button
            onClick={() => setTemplateModalOpen(true)}
            className="template-icon-button">
            <FactCheckIcon />
          </button> */}
        </div>
        <div className="adminBtnDiv text-center">
          <button
            onClick={() => navigate("/admin/addcourse")}
            className="adminBtn text-uppercase">
            Add new course
          </button>
        </div>
        <div className="mb-3 nftButtonDiv adminHidden">
          <Button
            variant="primary"
            className={` border-0 text-uppercase ${
              selectedTab === "courses" ? "bg-danger" : "bg-success"
            } button_width`}
            style={{ backgroundColor: "blueviolet", color: "white" }}
            onClick={() => setSelectedTab("courses")}>
            Active
          </Button>

          <Button
            variant="primary"
            className={`ms-2 border-0 text-uppercase ${
              selectedTab === "expired" ? "bg-danger" : "bg-success"
            } button_width`}
            style={{ backgroundColor: "blueviolet", color: "white" }}
            onClick={() => setSelectedTab("expired")}>
            Expired
          </Button>
          <Button
            variant="primary"
            className={`ms-2 border-0 text-uppercase ${
              selectedTab === "draft" ? "bg-danger" : ""
            } button_width`}
            style={{ backgroundColor: "blueviolet", color: "white" }}
            onClick={() => setSelectedTab("draft")}>
            Draft
          </Button>
        </div>
      
        {selectedTab === "courses" && (
          <div>
               <div className="d-flex justify-content-center"><InputGroup
            className="mb-3"
            style={{ backgroundColor: "#272d47", color: "white",maxWidth:"300px" }}>
            <Form.Select
              aria-label="Default select example"
              required
              onChange={(e) => setCategory(e.target.value)}
              value={category}
              name="categories"
              className=""
              style={{ backgroundColor: "#272d47", color: "white" }}>
              <option value="all">All</option>
              {categories?.map((res) => (
                <option value={res?.name}>{res?.name}</option>
              ))}
              {/* <option value="Blockchain">Blockchain</option>
            <option value="Digital_asset">Digital Asset Management</option>
            <option value="Play_To_Earn">Play To Earn</option> */}
            </Form.Select>
          </InputGroup></div>
          <div className="tableNormal ">
            <Table className="text-white adminDataTable ">
              <thead>
                <tr>
                 
                  <th className="text-center">Image</th>
                  <th className="text-start">Course</th>
                  <th className="text-start adminHidden">Amount(SGD)</th>
                  {/* <th className="text-start adminHidden">Sort</th> */}
                  {/* <th className='text-start adminHidden'>End date</th> */}
                  <th className="text-start">Action</th>
                </tr>
              </thead>
              <tbody>
                {sliceCourses?.map((course,index) => (
                  <tr className="tableRow" key={course?._id}>
                  
                    <td align="center">
                      {" "}
                      <img
                        className="imgAdmin"
                        src={course?.image?.replace(
                          "https://backend.blockchaincert.sg/",
                          "https://backend.studyswiftly.com/"
                        )}
                        alt=""
                      />
                    </td>
                    <td className="text-start">{course?.coursename}</td>
                    <td className="text-start adminHidden">{course?.amount>0?course?.amount:"Free"}</td>
                    {/* <td className="text-start adminHidden">{!(index==0)&&<i onClick={()=>handleSort(course?._id,-1)} className="fas fa-arrow-up me-3 cursor-pointer"></i>} {!(sliceCourses?.length==index+1)&&<i onClick={()=>handleSort(course?._id,1)} className="fas fa-arrow-down"></i>}</td> */}
                    {/* <td className='text-start adminHidden'>{`${course?.end?.slice(8, 10)}/${course?.end?.slice(5, 7)}/${course?.end?.slice(0, 4)}`}</td> */}
                    <td className="action" style={{width:"110px"}}>
                      <div className="actionDiv text-start">
                        <Link to={`/admin/editcourse/${course?._id}`}>
                          <button className="editBtn">
                            <i className="fas fa-edit"></i>
                          </button>
                        </Link>
                        <button
                          // onClick={() => handleCourseDraft(course?._id)}
                          onClick={() => handleDuplicateCourse(course._id)}
                          className="saveBtn">
                          <i className="fa fa-clone"></i>
                        </button>
                        <button
                          onClick={() => handleCourseDelete(course)}
                          className="deleteBtn">
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {sliceCourses?.length > 0 && (
              <div>
                <Pagination
                  lastPage={lastPage}
                  page={pageNumber}
                  pageHandle={pageHandle}
                />
              </div>
            )}
          </div>
         </div>
        )}
        {selectedTab === "expired" && <DashboardExpired />}
        {selectedTab === "draft" && <DashboardDraft />}
      </div>

      <TemplateModal
        show={templateModalOpen}
        setTemplateModalOpen={setTemplateModalOpen}
        onHide={() => setTemplateModalOpen(false)}
      />
    </div>
  );
};

export default Courses;
