import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Transactions.css";
import Swal from "sweetalert2";
import Pagination from "../../../components/Pagination/Pagination";
import ActionDetailsModal from "./ActionDetailsModal/ActionDetailsModal";

const Transactions = () => {
  const [allActions, setAllActions] = useState([]);
  console.log(allActions, "ALL ACTION");
  const [actionType, setActionType] = useState(
    "https://backend.studyswiftly.com/api/data/admin/login"
  );
  const [actionsLoading, setActionsLoading] = useState(false);

  const [actionDetails, setActionDetails] = useState(null);

  //pagination
  const [slicedActions, setSlicedActions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [show, setShow] = useState(10);

  useEffect(() => {
    const lastPage = Math.ceil(allActions?.length / show);
    setLastPage(lastPage);
  }, [allActions, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = allActions.slice(
        (page - 1) * show,
        page * show
      );
      setSlicedActions([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = allActions.slice(0, show);
      setSlicedActions([...getSlicingProduct]);
    }
  }, [allActions, show, pageNumber]);

  const pageHandle = (jump) => {
    setPageNumber(parseInt(jump));
  };

  //pagination ends

  useEffect(() => {
    setActionsLoading(true);
    axios
      .get(`${actionType}`)
      .then((res) => {
        if (res.status === 200) {
          const reversedActions =
            res?.data?.result?.reverse() || res?.data?.reverse();
          setAllActions(reversedActions);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setActionsLoading(false));
  }, [actionType]);

  // Delete action
  const deleteAction = async (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this action?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${actionType}/${id}`).then((res) => {
          if (res.status === 200) {
            const exceptDeletedAction = allActions.filter(
              (action) => action._id !== id
            );
            setAllActions(exceptDeletedAction);

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });
          }
        });
      }
    });
  };

  return (
    <div className="adminBody">
      <h5 className="text-white text-start">TRANSACTIONS</h5>
      <div className="input-group">
        <select
          style={{ backgroundColor: "rgb(39, 45, 71)", color: "white" }}
          className="action-type-selector form-select"
          onChange={(e) => setActionType(e.target.value)}
        >
          <option value="https://backend.studyswiftly.com/api/data/admin/login">
            Admin Login
          </option>
          <option value="https://backend.studyswiftly.com/api/data/admin/logout">
            Admin Logout
          </option>
          <option value="https://backend.studyswiftly.com/api/data/admin/course_add">
            Course Created
          </option>
          <option value="https://backend.studyswiftly.com/api/data/admin/course_edit">
            Course Edited
          </option>
          <option value="https://backend.studyswiftly.com/api/data/admin/course_delete">
            Course Deleted
          </option>
          <option value="https://backend.studyswiftly.com/api/wallet/all">
            Student Sign up
          </option>
          <option value="https://backend.studyswiftly.com/api/mint">
            Student Registration
          </option>
          <option value="https://backend.studyswiftly.com/api/mint/get/pay-busd">
            Student Payment
          </option>
          <option value="https://backend.studyswiftly.com/api/mint/get/pay-busd">
            Payment Distribution
          </option>
          <option value="https://backend.studyswiftly.com/api/v1/mint-certificate/mint-nft">
            Certificate Minted
          </option>
        </select>
      </div>
      <div className="adminCard py-2">
        <div className="tableNormal pt-5">
          <Table className="text-white adminDataTable">
            <thead>
              <tr>
                <th className="text-start ">Timestamp</th>
                <th className="text-start">Performed by</th>
                <th className="text-start ">Action</th>
              </tr>
            </thead>
            <tbody>
              {slicedActions?.map((data) => (
                <tr className="tableRow" key={data._id}>
                  <td align="text-start">{data.date}</td>
                  <td className="text-start">{data?.name}</td>
                  <td className="">
                    <div className="d-flex">
                      <button
                        onClick={() => setActionDetails(data)}
                        className="editBtn"
                      >
                        <i className="fas fa-eye"></i>
                      </button>

                      <button
                        onClick={() => deleteAction(data?._id)}
                        className="deleteBtn"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* Pagination */}
          {slicedActions?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>

        <ActionDetailsModal
          show={actionDetails}
          onHide={() => setActionDetails(null)}
          actionDetails={actionDetails}
        />
      </div>
    </div>
  );
};

export default Transactions;
