import React from "react";
import { ListGroup } from "react-bootstrap";
import { Pagination } from "@mui/material";
import { useState } from "react";
const CardLists = ({
  savedCards,
  price,
  handleDeleteCard,
  paying,
  handleCardClick,
}) => {
  const itemsPerPage = 5; // Number of items to display per page

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const paginatedData = savedCards?.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    // scroll to half of the page
    window.scrollTo(0, window.innerHeight / 3);
    setCurrentPage(page);
  };

  return (
    <>
      <ListGroup className="mt-2">
        {paginatedData?.map((card) => (
          <>
            <ListGroup.Item
              as="li"
              className="mb-1 d-flex justify-content-between align-items-center gap-3"
              key={card.id}>
              <div className="d-flex align-items-center">
                <p className="text-uppercase mb-0 fw-bold card-font-style me-2 card-fonts">
                  <img
                    src={`https://backend.studyswiftly.com/assets/${card?.card?.brand}.svg`}
                    alt=""
                    className="me-1 card-logo"
                    style={{
                      marginTop: "-2.5px",
                    }}
                  />
                  {card?.card?.brand}
                </p>
                <p
                  className="mb-0 fw-bold card-fonts"
                  style={{
                    marginTop: "-0.3px",
                  }}>
                  {card?.card?.last4}{" "}
                </p>
              </div>

              <div>
                {price && (
                  <button
                    className="btn btn-success btn-sm me-1"
                    style={{
                      fontSize: "12px",
                      // padding left right
                      padding: "10px 14px",
                    }}
                    onClick={() => handleCardClick(card)}
                    disabled={paying}>
                    {paying ? "PAYING" : "PAY"}
                  </button>
                )}
                <button
                  className="btn btn-danger btn-sm"
                  style={{
                    fontSize: "12px",
                    padding: "10px 14px",
                  }}
                  onClick={() => handleDeleteCard(card._id)}>
                  REMOVE
                </button>
              </div>
            </ListGroup.Item>
          </>
        ))}
      </ListGroup>

      {savedCards?.length > 5 && (
        <div className="d-flex justify-content-center text-light mt-3">
          <Pagination
            shape="rounded"
            count={Math.ceil(savedCards?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default CardLists;
