import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";

// Image
import videoImg from "../../../assets/img/about/about-video-bg2.png";
import { BlockchainContext } from "../../../Context/BlockchainContext";
import { UserContext } from "../../../Context/UserContext";
import CoursePopUp from "./CoursePopUp";

const CourseSidebar = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [singleCourse, setSingleCourse] = useState([]);
  const [singleVideo, setSingleVideo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);
  const { data } = useContext(UserContext);
  const {
    user,
    openWalletModal,
    setRefetch,
    refetch,
    refetchForSidebar,
    setPathName,
    pathName,
    setClickedFromSidebar,
    setRefetchAfterRegister,
    setRequestLoading,
    refetchAfterRegister,
  } = useContext(BlockchainContext);

  const [enrolled, setEnrolled] = useState(false);
  const [enrolledLoader, setEnrolledLoader] = useState(false);

  console.log(user?.walletAddress);

  useEffect(() => {
    setEnrolledLoader(true);
    // axios.get(`https://backend.studyswiftly.com/api/mint/data/${user?.walletAddress}`)
    axios
      .get(
        `https://backend.studyswiftly.com/api/mint/data/email/${data?.email}`
      )
      .then((res) => {
        setEnrolled(res.data.result.find(res=>res._id==id)?true:false);
        setEnrolledLoader(false);
      });
  }, []);

  const navigate = useNavigate();
  const [checkDevice, setCheckDevice] = useState("");
  const [alreadyEnrolled, setAlreadyEnrolled] = useState(false);
  console.log(pathname);

  let newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  newDate = dd + "/" + mm + "/" + yyyy;

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);

  // console.log(user.walletAddress)

  const checkIfAlreadyEnrolled = (cid) => {
    if (cid) {
      fetch(
        // `https://backend.studyswiftly.com/api/mint/data/${user?.walletAddress}`
        `https://backend.studyswiftly.com/api/mint/data/email/${data?.email}`
      )
        .then((res) => res.json())
        .then((result) => {
          console.log("all result",result);
          const enrolledCourse = result?.result?.find(
            (eachCourse) => eachCourse.courseId === cid
          );
          console.log(enrolledCourse,"all result",result);
          if (enrolledCourse) {
            setAlreadyEnrolled(true);
          } else {
            setAlreadyEnrolled(false);
          }
        });
    } else {
      setAlreadyEnrolled(false);
    }
  };

  useEffect(() => {
    const data1 = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/course/${id}`)
        .then((res) => {
          setSingleCourse(res.data.result);
          setSingleVideo(res.data.result.image);
          console.log(singleVideo);
          // console.log(res.data.result)
        });
    };
    data1();

   
  }, [id]);

  useEffect(() => {
    checkIfAlreadyEnrolled(id);
  }, [singleCourse,id])
  

  useEffect(() => {
    setPathName(pathname);
    console.log(user?.email, pathName, pathname, refetchForSidebar);
    // navigate(`/enrol/${id}`);

    if (user?.email && refetchForSidebar) {
      navigate(`/enrol/${id}`);
    } else if (user?.walletAddress && refetchForSidebar) {
      navigate(`/my-account`);
    }
  }, [refetchForSidebar]);
  const [popupOpen, setPopupOpen] = useState(false);

  const registerFreeCourse = async () => {
    // if (!user.email) {
    //   // Need to convert
    //   return swal({
    //     text: "Before registration please update your profile. We will send the details to you.",
    //     icon: "warning",
    //     button: true,
    //     dangerMode: true,
    //     className: "modal_class_success",
    //   }).then((willDelete) => {
    //     if (willDelete) {
    //       navigate(`/my-account`);
    //     } else {
    //       console.log("ok");
    //     }
    //   });
    // }

    // else if (singleCourse.coursename === findingTheCoure.coursename) {
    //   return swal({
    //     text: "You have already enrolled!",
    //     icon: "warning",
    //     button: true,
    //     className: "modal_class_success",
    //   });
    // }

    const wrapper1 = document.createElement("div");
    wrapper1.innerHTML = `
                    <p className="success">Do you want to enrol for <br/> ${singleCourse?.coursename}?</p>
              `;

    swal({
      content: wrapper1,
      icon: "success",
      className: "modal_class_success",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        const data3 = {
          courseId: singleCourse?._id,
          videoData: singleCourse?.videoData,
          pdfData: singleCourse?.pdfData,
          imageData: singleCourse?.imageData,
          coursename: singleCourse?.coursename,
          image: singleCourse?.image,
          description: singleCourse?.description,
          youlearn: singleCourse?.youlearn,
          categories: singleCourse?.categories,
          date: newDate,
          walletAddress: user?.walletAddress,
          status: false,
          method: "FREE",
          email: user?.email,
          name: user?.name,
          amount: singleCourse?.amount,
          memberId: user?.memberId,
          howlearn: singleCourse?.howlearn,
          duration: singleCourse.start,
        };

        console.log("data3", data3);

        axios
          .post("https://backend.studyswiftly.com/api/mint/save-nft", data3, {})
          .then((res) => {
            if (res.status === 200) {
              console.log("enrolled data", res.data);
              navigate("/my-account", { state: { name: 1 } });

              setRequestLoading(false);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `
                    <p className="success">You have successfully enrolled to <br/> ${singleCourse?.coursename}.</p>
              `;
              swal({
                content: wrapper,
                icon: "success",
                button: "OK",
                className: "modal_class_success",
              });
              setRefetchAfterRegister(refetchAfterRegister + 1);
            }
          })
          .catch((err) => {
            console.log(err);
            setRefetchAfterRegister(false);
            setRequestLoading(false);
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<P>You have successfully registered for this course.</p>`;
            swal({
              title: "Warning",
              content: wrapper,
              icon: "warning",
              button: "OK",
              className: "modal_class_success",
            });
          });
      }
    });
  };

  return (
    <div className="inner-column">
      <div>
        <div className="btn-part text-center">
          {/* <p className="">Course Duration: <span style={{ color: "#ff5421" }}> {singleCourse.start}</span></p> */}

          {singleCourse.amount === "0.00" ? (
            <p className="text-center text-white">
              Course Price: <span style={{ color: "#ff5421" }}>FREE</span>
            </p>
          ) : (
            <p className="text-center text-white">
              Course Price:{" "}
              <span style={{ color: "#ff5421" }}>
                SGD {singleCourse.amount}
              </span>
            </p>
          )}

          {data?.email ? (
            <>
              {alreadyEnrolled && (
                <>
                  <button
                    onClick={() =>
                      navigate("/my-account", { state: { name: 1 } })
                    }
                    style={{whiteSpace:"normal"}}
                    className="btn readon2 orange-transparent px-0">
                    You have already enrolled to this course. <br /> Please go to Courses Enrolled <br /> Page to Learn.
                  </button>
                </>
              )}

              {singleCourse.amount === "0.00" && !alreadyEnrolled && (
                <>
                  <button
                    onClick={() => registerFreeCourse()}
                    // to={`/enrol/${singleCourse._id}`}
                    className="btn readon2 orange-transparent px-0">
                    Please click to Enrol
                  </button>
                </>
              )}

              {singleCourse.amount !== "0.00" && !alreadyEnrolled && (
                <>
                  {/* <Link
                    to={`/enrol/${singleCourse._id}`}
                    className="btn readon2 orange-transparent px-0">
                    Enrol - Pay with BUSD AND S39 TOKENS
                  </Link> */}
                  <Link
                  style={{whiteSpace:"pre-line"}}
                    to={`/enrol/${singleCourse._id}`}
                    className="btn readon2 btn-info px-0">
                    {data?.credit>singleCourse.amount?`Enrol - Pay with credit.\n you have sgd ${data?.credit?.toFixed(2)}`:"Enrol - Pay with Credit/Debit Card"}
                  </Link>
                </>
              )}
            </>
          ) : (
            <>
              {!data.email ? (
                <button
                  className="header-btn"
                  onClick={() => navigate("/sign-in")}
                  style={{ fontSize: "11px" }}>
                  {/* <img
                    className="me-1"
                    width="22px"
                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                    alt=""
                  /> */}
                  SIGN IN TO ENROL
                </button>
              ) : (
                <span>
                  <Link
                    onClick={() => {
                      navigate("/shop/payment");
                    }}
                    className="btn readon2 orange-transparent text-uppercase">
                    Profile
                  </Link>
                </span>
              )}
            </>
            // <>
            //   {checkDevice === "mobileWithoutApp" && (
            //     <a
            //       href={`https://metamask.app.link/dapp/http://studyswiftly.com/course/${id}`}
            //       target={"_blank"}
            //       className="text-decoration-none"
            //     >
            //       <button className="btn readon2 orange-transparent text-uppercase">
            //         ENROL
            //       </button>
            //     </a>
            //   )}

            //   {checkDevice === "mobileWithApp" && (
            //     <span>
            //       <Link
            //         onClick={() => {
            //           openWalletModal();
            //           setClickedFromSidebar(
            //             singleCourse.amount !== "0.00" && "clicked-from-sidebar"
            //           );
            //         }}
            //         className="btn readon2 orange-transparent text-uppercase"
            //       >
            //         LOGIN WITH WALLET TO ENROL
            //       </Link>
            //     </span>
            //   )}
            //   {checkDevice === "pcWithExtention" && (
            //     <span>
            //       <Link
            //         onClick={() => {
            //           openWalletModal();
            //           setClickedFromSidebar(
            //             singleCourse.amount !== "0.00" && "clicked-from-sidebar"
            //           );
            //         }}
            //         className="btn readon2 orange-transparent text-uppercase"
            //       >
            //         LOGIN WITH WALLET TO ENROL
            //       </Link>
            //     </span>
            //   )}
            //   {checkDevice === "pcWithoutExtention" && (
            //     <a
            //       href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
            //       target={"_blank"}
            //       className="text-decoration-none"
            //     >
            //       <button className="btn readon2 orange-transparent text-uppercase">
            //         INSTALL METAMASK
            //       </button>
            //     </a>
            //   )}
            // </>
          )}
          {/* <a href="https://studyswiftly.com/join-us" target="_blank">
            <p
              // onClick={setPopupOpen}
              // onClick={()=> navigate("/join-us")}
              className="text-center fs-3">
              <a>
                <i class="fas fa-info-circle"></i>
              </a>
            </p>
          </a> */}
        </div>
      </div>
      {popupOpen && (
        <CoursePopUp
          popupOpen={popupOpen}
          setPopupOpen={setPopupOpen}></CoursePopUp>
      )}
    </div>
  );
};

export default CourseSidebar;
