import React, { useState, useEffect } from 'react';

import closeIcon from "../../../assets/img/icon/close.png"
import { IoIosCloseCircle } from 'react-icons/io'
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

const SearchModal = () => {
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    const searchModalRemove = () => {
        document.body.classList.remove('modal-open');
    };


    const handleSearch = () => {
        if (searchValue.length > 0) {
            navigate("/courses")
            document.body.classList.remove('modal-open');
            document.getElementById("input").value = "";
        }
        else {
            // swal({
            //     title: "Attention",
            //     text: `Enter something to search!`,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>Enter something to search!!</p>`;
            
            Swal.fire(
                {
                    html: wrapper,
                    icon: "warning",
                    customClass: "modal_class_success",
                }
        )
    
        }
    }

    return (
        <React.Fragment>
            <div className="search-modal">
                <div onClick={searchModalRemove} className="close" style={{ cursor: 'pointer' }}>

                    <img onClick={searchModalRemove} src={closeIcon} alt="Close" style={{ cursor: 'pointer' }} />
                    {/* <IoIosCloseCircle className='w-5 h-5'/> */}

                </div>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="search-block clearfix">
                            {/* <form> */}
                            <div className="form-group d-flex">
                                <input
                                    id='input'
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    className="search-input position-relative" placeholder="Search" type="text" />
                                <p
                                    onClick={handleSearch}
                                    className="search-input-icon " title='Search'>
                                    <Search style={{ fontSize: "33px" }} />
                                </p>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SearchModal;