import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
// import CategoriesSingle from "../../components/Categories/CategoriesSingle";
// import { FaGraduationCap } from "react-icons/fa";
import Slider from "react-slick";
// Categories Image
// import image1 from "../../assets/img/categories/main-home/1.jpg";
// import image2 from "../../assets/img/categories/main-home/2.jpg";
// import image3 from "../../assets/img/categories/main-home/3.jpg";

// Categories Icon
// import icon1 from "../../assets/img/categories/main-home/icon/1.png";
// import icon2 from "../../assets/img/categories/main-home/icon/2.png";
// import icon3 from "../../assets/img/categories/main-home/icon/3.png";
import { useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import CourseSingleTwo from "../../components/Courses/CourseSingleTwo";
import useMediaQuery from "@mui/material/useMediaQuery";

const Categories = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [blockchainCourses, setBlockchainCourses] = useState([]);
  const [blockLength, setBlockLength] = useState([]);
  const [dappsCourses, setDappsCourses] = useState([]);
  const [DappsLength, setDappsLength] = useState([]);
  const [playCourses, setPlayCourses] = useState([]);
  const [PlayLength, setPlayLength] = useState([]);
  const matches = useMediaQuery("(min-width:768px)");

  const navigate = useNavigate();

  useEffect(() => {
    if (alertOpen) {
      swal({
        title: "Success",
        // text: `${res.data.message}`,
        icon: "success",
        button: "OK!",
        className: "modal_class_success",
      });
    }
  }, []);

  useEffect(() => {
    const data1 = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/category/Blockchain")
        .then((res) => {
          setBlockchainCourses(res.data.result?.[0].length);

          if (res.data.result?.[0].length < 10) {
            console.log(blockchainCourses);
            const data = "0" + blockchainCourses + " " + "Courses";
            setBlockLength(data);
          } else {
            setBlockLength(res.data.result?.[0].length);
          }
        });
    };
    data1();
  }, []);

  useEffect(() => {
    const data2 = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/category/Dapps")
        .then((res) => {
          setDappsCourses(res.data.result.length);

          if (res.data.result.length < 10) {
            console.log(dappsCourses);
            const data = "0" + dappsCourses + " " + "Courses";
            setDappsLength(data);
          } else {
            setDappsLength(res.data.result.length);
          }
        });
    };
    data2();
  }, []);
  useEffect(() => {
    const data3 = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/course/category/Play To Earn"
        )
        .then((res) => {
          setPlayCourses(res.data.result.length);
          console.log(res.data.result.length);
          console.log(res.data.result);
          if (res.data.result.length < 10) {
            const data = "0" + playCourses + " " + "Courses";
            console.log(data);
            setPlayLength(data);
          } else {
            setPlayLength(res.data.result.length);
          }
        });
    };
    data3();
  }, []);

  const handleNavigate = () => {
    console.log("navigated");
    navigate("/courses", { state: { name: 1 } });
  };

  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "30px",
  };
  const sliderSettingsMid = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "30px",
  };

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(
            res.data.Categories?.sort((a, b) => a.name.localeCompare(b.name))
          );
        });
    };
    data();
  }, []);

  const [courses, setCourses] = useState([]);
  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/course/active")
        // await axios.get("courses.json")
        .then((res) => {
          const categoryCounts = res.data.result.reduce((acc, item) => {
            if (!acc[item.categories]) {
              acc[item.categories] = 1; // Initialize count to 1 if category doesn't exist
            } else {
              acc[item.categories]++; // Increment count if category exists
            }
            return acc;
          }, {});
          console.log(categoryCounts, "categoryCounts");
          // setCourses(categoryCounts);
          setCourses(res.data.result);
        });
    };
    data();
  }, []);

  return (
    <div className="rs-categories main-home pt-90 pb-80 md-pt-60 md-pb-40">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title"
          // subtitle="Top Categories"
          titleClass="title black-color"
          title="Popular Courses"
        />

        {/* <div className="rs-slider style1 popular-courses"> */}
        <div className="rs-popular-courses style1 orange-style rs-slider popular-courses">
          {courses && (
            <Slider {...(matches ? sliderSettings : sliderSettingsMid)}>
              {courses
                ?.sort(() => Math.random() - 0.5)
                ?.slice(0, 8)
                ?.map((course) => (
                  <CourseSingleTwo
                    courseClass="courses-item mb-30"
                    courseId={course?._id}
                    courseImg={course?.image?.replace(
                      "https://backend.blockchaincert.sg/",
                      "https://backend.studyswiftly.com/"
                    )}
                    courseTitle={course?.coursename}
                    coursePrice={course?.amount}
                    courseCategory={course?.categories}
                    courseDate={`${course?.end?.slice(
                      8,
                      10
                    )}/${course?.end?.slice(5, 7)}/${course?.end?.slice(0, 4)}`}
                  />
                ))}
              {/* {categories?.map((res, index) => {
              return (
                courses[res?.name] && (
                  <div className="slider-content">
                    <CategoriesSingle
                      onClick={() => setAlertOpen(true)}
                      itemClass="categories-items"
                      image={image1}
                      title={res?.name}
                      quantity={`0${courses[res?.name] ?? 0} Course`}
                      iconImg={icon1}
                    />
                  </div>
                )
              );
            })} */}
            </Slider>
          )}
        </div>

        {/* <div className="slider-content">
                <CategoriesSingle
                  onClick={() => setAlertOpen(true)}
                  itemClass="categories-items "
                  image={image1}
                  title="Blockchain"
                  quantity={`0${blockchainCourses} Course`}
                  iconImg={icon1}
                />
              </div> */}
        {/* <div className="row justify-content-center mb-34">
          <div
            style={{ cursor: "pointer" }}
            className="col-lg-4 col-md-6 mb-30 cursor-pointer"
            onClick={handleNavigate}
          >
            <CategoriesSingle
              onClick={() => setAlertOpen(true)}
              itemClass="categories-items "
              image={image1}
              title="Blockchain"
              quantity={`0${blockchainCourses} Course`}
              iconImg={icon1}
            />
          </div>
          {/* <div className="col-lg-4 col-md-6 mb-30">
                        <CategoriesSingle
                            itemClass="categories-items"
                            image={image2}
                            title="Dapps Development"
                            quantity="00 Courses"
                            iconImg={icon2}
                        />
                    </div> 
          {/* <div
            style={{ cursor: "pointer" }}
            className="col-lg-4 col-md-6 mb-30 cursor-pointer"
            onClick={handleNavigate}
          >
            <CategoriesSingle
              itemClass="categories-items"
              image={image3}
              title="Play To Earn"
              quantity="00 Courses"
              iconImg={icon3}
            />
          </div> 
          {/* <div className="col-lg-4 col-md-6 mb-30">
                        <CategoriesSingle
                            itemClass="categories-items"
                            image={image4}
                            title="Artificial Intelligence"
                            quantity="03 Courses"
                            iconImg={icon4}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <CategoriesSingle
                            itemClass="categories-items"
                            image={image5}
                            title="Business Studies"
                            quantity="12 Courses"
                            iconImg={icon5}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <CategoriesSingle
                            itemClass="categories-items"
                            image={image6}
                            title="Computer Engineering"
                            quantity="05 Courses"
                            iconImg={icon6}
                        />
                    </div> 
        </div> */}
        {/* <div className="text-center">
                    <Link className="readon orange-btn main-home" to="/course-categories">View All categories </Link>
                </div> */}
      </div>
    </div>
  );
};

export default Categories;
