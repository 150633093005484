import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
// import "./Users.css"
import swal from "sweetalert";

import ViewStudent from "../Users/ViewStudent";
import AddCourseModal from "../Users/AddCourse/AddCourse";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../../components/Pagination/Pagination";

const Registered = () => {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [studentModal, setStudentModal] = useState(false);
  const [openAddCourseModal, setAddCourseModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [addCourseData, setAddCourseData] = useState(null);

  useEffect(() => {
    const data = async () => {
      await axios
        .get("https://backend.studyswiftly.com/api/mint")
        .then((res) => {
          setUsers(res.data);
          setAllUsers(res.data);
        });
    };
    data();
  }, []);

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceUsers, setSliceUsers] = useState([]);
  // console.log(sliceProducts)

  useEffect(() => {
    const lastPage = Math.ceil(users?.length / show);
    setLastPage(lastPage);
  }, [users, show]);

  useEffect(() => {
    if (pageNumber) {
      const page = parseInt(pageNumber);
      const getSlicingProduct = users.slice((page - 1) * show, page * show);
      setSliceUsers([...getSlicingProduct]);
      setPageNumber(parseInt(page));
    } else {
      const getSlicingProduct = users.slice(0, show);
      setSliceUsers([...getSlicingProduct]);
    }
  }, [users, show, pageNumber]);

  const pageHandle = (jump) => {
    // navigate(`/admin/products/${jump}`);
    setPageNumber(parseInt(jump));
  };

  const handleDelete = (id) => {
    // console.log(id);
    // const confirmDelete = window.confirm('Are you sure, you want to delete this student?')

    Swal.fire({
      text: "Are you sure, you want to delete this student?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://backend.studyswiftly.com/api/mint/mint-nft/${id}`
          )
          .then((res) => {
            if (res.status === 200) {
              // swal({
              //     title: "Success",
              //     // text: res.data.message,
              //     text: "Student delete success .",
              //     icon: "success",
              //     button: "OK!",
              //     className: "modal_class_success",
              // });

              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<p class='text-break text-white'>Registration Deleted.</p>`;

              Swal.fire({
                html: wrapper,
                icon: "success",
                customClass: "modal_class_success",
              });

              setUsers(users.filter((user) => user._id !== id));
            }
          })
          .catch((error) => {
            // alert(error.response.data.message);
            // swal({
            //     title: "Attention",
            //     text: error.response.data.message,
            //     icon: "warning",
            //     button: "OK!",
            //     className: "modal_class_success",
            // });

            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            });
          });
      }
    });

    // swal({
    //     title: "Are you sure, you want to delete this student?",
    //     // text: "You will not be able to recover this post again!",
    //     icon: "warning",
    //     buttons: ["No", "Yes"],
    //     dangerMode: true,
    // }).then(function (isConfirm) {
    //     if (isConfirm) {
    //         axios.delete(`https://backend.studyswiftly.com/api/mint/mint-nft/${id}`)
    //             .then(res => {
    //                 if (res.status === 200) {
    //                     swal({
    //                         title: "Success",
    //                         // text: res.data.message,
    //                         text: "Student delete success .",
    //                         icon: "success",
    //                         button: "OK!",
    //                         className: "modal_class_success",
    //                     });
    //                     setUsers(users.filter(user => user._id !== id))
    //                 }
    //             })
    //             .catch(error => {
    //                 // alert(error.response.data.message);
    //                 swal({
    //                     title: "Attention",
    //                     text: error.response.data.message,
    //                     icon: "warning",
    //                     button: "OK!",
    //                     className: "modal_class_success",
    //                 });
    //             })

    //     }
    // });
  };

  const handleModal = (data) => {
    // console.log(data)
    setModalData(data);
  };

  const handleAddModal = (data) => {
    setAddCourseData(data);
  };

  const handleSearchUser = (e) => {
    const keyWord = e?.target?.value;

    if (keyWord) {
      const searchedUsers = allUsers.filter(
        (eachUser) =>
          eachUser?.coursename
            ?.toLocaleLowerCase()
            .includes(keyWord?.toLocaleLowerCase()) === true
      );

      setUsers(searchedUsers);
    } else {
      setUsers(allUsers);
    }
  };

  return (
    // <div className='adminBody' style={{ height: "100vh" }}>
    <div className="adminBody">
      <h5 className="text-white text-start">COURSE ENROLMENTS</h5>
      <div className="certificate-search-bar-container">
        <input
          className="form-control"
          placeholder="Search by course name or student name"
          type="text"
          onChange={(e) => handleSearchUser(e)}
        />
      </div>
      <div className="adminCard py-2">
        <div className="tableNormal pt-5">
          <Table className="text-white adminDataTable">
            <thead>
              <tr>
                <th className="text-start ">Name</th>
                <th className="text-start ">Course Name</th>
                <th className="text-start adminHidden">Email</th>
                {/* <th className="text-start adminHidden">Type</th> */}
                {/* <th className='text-start adminHidden'>Username</th> */}
                {/* <th className='text-start adminHidden'>Date</th> */}
                {/* <th className='text-start adminHidden'>Mobile</th> */}
                <th className="text-start">Action</th>
              </tr>
            </thead>
            <tbody>
              {sliceUsers?.reverse()?.map((data) => (
                <tr className="tableRow" key={data._id}>
                  <td align="text-start">{data?.name}</td>
                  <td align="text-start">{data?.coursename}</td>
                  <td className="text-start adminHidden">{data?.email}</td>
                  {/* <td className="text-start adminHidden">{data?.method}</td> */}
                  {/* <td className='text-start'>{data?.date}</td> */}
                  <td className="action">
                    <div className="d-flex">
                      {/* <Link to={`/admin/addcourse/${data?._id}`}>
                                                    <button className='studentBtnAdd' onClick={() => {
                                                        // setAddCourseModal(true)
                                                        handleAddModal(data)
                                                    }}><AddIcon /></button>
                                                </Link> */}
                      <button
                        className="studentView"
                        onClick={() => {
                          setStudentModal(true);
                          handleModal(data);
                        }}
                      >
                        <i class="fas fa-eye"></i>
                      </button>
                      <button
                        onClick={() => handleDelete(data?._id)}
                        className="studentBtnDlt"
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {sliceUsers?.length > 0 && (
            <div>
              <Pagination
                lastPage={lastPage}
                page={pageNumber}
                pageHandle={pageHandle}
              />
            </div>
          )}
        </div>

        {/* <AddCourseModal
                    openAddCourseModal={openAddCourseModal}
                    setAddCourseModal={setAddCourseModal}
                    addCourseData={addCourseData}
                /> */}

        <ViewStudent
          show={studentModal}
          onHide={() => setStudentModal(false)}
          modalData={modalData}
        />
      </div>
    </div>
  );
};

export default Registered;
