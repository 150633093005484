import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CoursePopUp({popupOpen, setPopupOpen}) {
    
  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <div>
    <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={popupOpen}
        sx={{ backgroundColor: 'transparent' }}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} sx={{ backgroundColor: '#171F32', color: '#ffffff', fontSize: '24px' }}>How do I install Metamask?</BootstrapDialogTitle>
        <DialogContent dividers sx={{ backgroundColor: '#171F32' }}>
            <Typography gutterBottom sx={{ color: '#ffffff', paddingLeft: '8px' }}>
                Go to <span style={{ color: '#FF5421' }}><a href='https://metamask.io/' target="_blank">Metamask.io</a></span> and click the “Download” button, which will take you to the relevant store to download the extension or app based on the device and browser you’re using.
                <br/>
            </Typography>
        </DialogContent>
    </BootstrapDialog>
</div>
  );
}