import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import AccountMain from '../../components/Shop/AccountMain';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import { FaRegCopy } from 'react-icons/fa';
import { BlockchainContext } from '../../Context/BlockchainContext';

const Affiliat = () => {

    const { user, logOut, metamaskBalance } = useContext(BlockchainContext);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='shop'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='support@website.com'
                Location='374 William S Canning Blvd, MA 2721, USA '
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Affiliate"
                pageName="Affiliate"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* <AccountMain /> */}
            <div className='px-5 py-5' >
                <div className='container' style={{ background: '#F9F8F8', padding: '8vh 10vw' }}>
                    <div className='register-box'>
                        <form id="login" action="#">
                            <div className="d-flex justify-content-center pt-4">
                                <div className=''>
                                    <h3 className='pb-3'>Affiliate</h3>
                                    <div className="mb-4">
                                        <label className='text-start text-black mb-2' htmlFor='walletAddress'>Wallet Address</label>
                                        <input className='p-3 border-0 bg-white' style={{ width: '100%' }} placeholder='Wallet Address' type="text" name="walletAddress" id="walletAddress" value={user?.walletAddress} />
                                    </div>

                                    <div className="mb-4 form-group">
                                        <label className='text-start text-black mb-2' htmlFor='USDT in wallet'>BUSD in wallet</label>
                                        <div className='d-flex'>
                                            <input className='p-3 border-0 bg-white' style={{ width: '100%' }} type="text" name="USDT in wallet" id="USDT in wallet" value={metamaskBalance?.busd ? metamaskBalance.busd : "0.00"} />
                                        </div>
                                    </div>
                                    <div className="mb-4 form-group">
                                        <label className='text-start text-black mb-2' htmlFor='S39 in wallet'>S39 in wallet</label>
                                        <div className='d-flex'>
                                            <input className='p-3 border-0 bg-white' style={{ width: '100%' }} type="text" name="S39 in wallet" id="S39 in wallet" value={metamaskBalance?.s39 ? metamaskBalance.s39 : "0.00"} />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label className='text-start text-black mb-2' htmlFor='affiliateCode'>Affiliate Code</label>
                                        <div className='d-flex'>
                                            <input className='p-3 border-0 bg-white' style={{ width: '100%' }} type="text" name="affiliateCode" id="affiliateCode" value={user?.affiliateCode} />
                                            <button type="button" className="border bg-success rounded-0 rounded-end text-light px-4">
                                                <FaRegCopy />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className='text-start text-black mb-2' htmlFor='affiliateLink'>Affiliate Link</label>
                                        <div className="d-flex">
                                            <input className='p-3 border-0 bg-white' style={{ width: '100%' }} value={window.location.origin + "/" + user?.affiliateCode} type="text" name="affiliateLink" id="affiliateLink" />
                                            <button type="button" className="border bg-success rounded-0 rounded-end text-light px-4">
                                                <FaRegCopy />
                                            </button>

                                        </div>
                                    </div>
                                    <div className=" social-div">
                                        <div>
                                            <p className='fs-5 fw-bold text-start mb-3'>Share Affiliate Link</p>
                                            <div className='d-flex gap-2 mb-3'>
                                                <TwitterShareButton url={window.location.origin + "/" + user?.myReferralCode} title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                                    <TwitterIcon size={40} round={true} />
                                                </TwitterShareButton>
                                                <LinkedinShareButton url={window.location.origin + "/" + user?.myReferralCode} title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                                    <LinkedinIcon size={40} round={true} />
                                                </LinkedinShareButton>
                                                <WhatsappShareButton url={window.location.origin + "/" + user?.myReferralCode} title={`Get 5% discount at Studyswiftly.com when you use my code.`}>
                                                    <WhatsappIcon size={40} round={true} />
                                                </WhatsappShareButton>

                                            </div>

                                        </div>
                                    </div>
                                    <p className='text-start'>Share your affiliate code to earn 10% of our sales which comes from you. Your friend enjoy another 10% too.</p>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            />

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
};

export default Affiliat;