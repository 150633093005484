import { forwardRef, useContext, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)`
  width: 600px;
  max-width: 1000px !important;
  //   background:linear-gradient(to right bottom, #4e4c4c, #4e4c4c);
  color: white;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
  }
`;

const RegisterModal = ({ open, setOpen }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      PaperComponent={StyledPaper}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="border-dark border-bottom bg-green text-dark d-flex align-items-center justify-content-between">
        <h5 className='m-0' style={{color:"#ff5421",fontSize:"22px"}}>Sign Up Bonus</h5>
        <button
          className="rounded-circle cross-btn"
          onClick={() => {
            setOpen(false);
          }}
        >
          X
        </button>
      </DialogTitle>
      <DialogContent className='text-dark'>
      <h5 style={{margin:"16px 0 10px 0",fontSize:"19px"}}>Upon signing up, all students will receive:</h5>

<p className='mb-2'>1. A SGD 999 Sign-up Scholarship.</p>
<p className='mb-2'>2. Eligibility for a chance to receive a Full Scholarship.</p>
<p className='mb-2'>3. A 20% discount valid until December 31, 2024.</p>
<p className='mb-2'>4. An additional 5% discount when using an affiliate code.</p>
<p className='mb-2'>5. The opportunity to earn 5% every time a friend, who uses the affiliate code, pays for a course.</p>
<p className='mb-2'>6. The chance to secure a fully paid internship in Singapore.</p>
<p className='mb-2'>7. The option to explore employment opportunities with other companies during the internship period.</p>






      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default RegisterModal;
