import axios from "axios";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { MdPendingActions } from "react-icons/md";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  Button,
  ListItemIcon,
  Menu,
  Modal,
  TextField,
} from "@mui/material";
import { FaFilePdf } from "react-icons/fa6";
import { CgFormatText } from "react-icons/cg";
import { FaFileImage } from "react-icons/fa6";
import CustomButton from "../../../components/Common/CustomButton/CustomButton";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Backdrop from "@mui/material/Backdrop";

import Fade from "@mui/material/Fade";

import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const CourseCreatorAI = () => {
  const [courses, setCourses] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [generateTitle, setGenerateTitle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loadingStates, setLoadingStates] = useState({});
  // console.log(generateTitle);

  const handleGetTitle = () => {
    if (categoryName === "Select" || categoryName === "") {
      return setError("Select Category First");
    }
    const titleData = async () => {
      setGenerateTitle([]);
      setError("");
      setLoading(true);
      await axios
        .post("https://backend.studyswiftly.com/api/v1/title/generate-titles", {
          category: categoryName,
        })
        .then((res) => setGenerateTitle(res.data.titles));
      setLoading(false);
    };
    titleData();
  };

  const handleGetOverview = (id, title) => {
    const overviewData = async () => {
      setLoadingStates({ ...loadingStates, [id]: true });

      await axios
        .post(
          "https://backend.studyswiftly.com/api/v1/title/generate-overview",
          {
            category: categoryName,
            courseTitle: title,
            titleId: id,
          }
        )
        .then((res) => setGenerateTitle(res.data.titles));
      setLoadingStates({ ...loadingStates, [id]: false });
    };
    overviewData();
  };
  const handleGetMcq = (id, materials) => {
    const overviewData = async () => {
      setLoadingStates({ ...loadingStates, [id]: true });

      await axios
        .post("https://backend.studyswiftly.com/api/v1/title/generate-mcq", {
          category: categoryName,
          titleId: id,
          materials: materials,
        })
        .then((res) => setGenerateTitle(res.data.titles));
      setLoadingStates({ ...loadingStates, [id]: false });
    };
    overviewData();
  };
  const handleGetMaterials = (id, overview) => {
    const materialData = async () => {
      setLoadingStates({ ...loadingStates, [id]: true });

      await axios
        .post(
          "https://backend.studyswiftly.com/api/v1/title/generate-materials",
          {
            category: categoryName,
            overview: overview,
            titleId: id,
          }
        )
        .then((res) => setGenerateTitle(res.data.titles));
      setLoadingStates({ ...loadingStates, [id]: false });
    };
    materialData();
  };

  const handleGetCourseImage = (id, title) => {
    const courseImageData = async () => {
      setLoadingStates({ ...loadingStates, [id]: true });

      await axios
        .post("https://backend.studyswiftly.com/api/v1/title/generate-image", {
          category: categoryName,
          courseTitle: title,
          titleId: id,
        })
        .then((res) => setGenerateTitle(res.data.titles));
      setLoadingStates({ ...loadingStates, [id]: false });
    };
    courseImageData();
  };

  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCourses(res.data.Categories);
        });
    };
    data();

    if (categoryName == "Select" || categoryName == "") {
      setGenerateTitle([]);
      return setError("Select Category First");
    }

    const titleData = async () => {
      setGenerateTitle([]);
      setError("");
      setLoading(true);
      try {
        const response = await axios.get(
          `https://backend.studyswiftly.com/api/v1/title/get/category-titles/${categoryName}`
        );
        setGenerateTitle(response.data.titles);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError("Category not found. Generate first");
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };
    titleData();
  }, [categoryName]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <h5 className="text-white text-start">COURSE CREATOR AI</h5>

      <div>
        {/* <FormControl className="w-100" sx={{ minWidth: 245 }}>
          <label htmlFor={categoryName} className="text-white p-1 fw-semibold">
            Select Category
          </label>
          <Select
            className="bg-white"
            value={categoryName}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>Select</em>
            </MenuItem>
            {courses.map((course) => (
              <MenuItem key={course._id} value={course.category_name}>
                {course.category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <label htmlFor={categoryName} className="text-white p-1 fw-semibold">
          Select Category
        </label>
        <Autocomplete
          inputValue={categoryName}
          onInputChange={(event, newInputValue) => {
            setCategoryName(newInputValue);
          }}
          className="w-100"
          options={courses}
          autoHighlight
          getOptionLabel={(option) => option.category_name}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option._id}>
              {option.category_name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              className="bg-white rounded"
              {...params}
              label=""
              inputProps={{
                ...params.inputProps,
                "aria-label": "Without label",
              }}
            />
          )}
        />

        {error !== "" ? (
          <p className="pt-2 mb-0 fs-6 text text-danger ">{error}</p>
        ) : (
          ""
        )}
        {loading ? (
          <div style={{ marginTop: "20px", minWidth: "245px" }}>
            <Button className=" w-100 bg-primary-subtle" variant="contained">
              Generating...
              <span
                class="spinner-border ms-2 spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </Button>
            <p
              className="text-wrap rounded-1"
              style={{
                backgroundColor: "#E1F7F5",
                padding: "8px 6px",
                marginTop: "10px",
                color: "#0E46A3",
                fontSize: "14px",
                width: "fit-content",
              }}
            >
              Please do not navigate away from the browser. Doing so will
              stop the process.
            </p>
          </div>
        ) : (
          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            {generateTitle.length > 0 ? (
              <Button
                type="submit"
                onClick={handleGetTitle}
                className=" w-100"
                variant="contained"
              >
                Re-generate 200 Course Titles
              </Button>
            ) : (
              <Button
                type="submit"
                onClick={handleGetTitle}
                className=" w-100"
                variant="contained"
              >
                Generate 200 Course Titles
              </Button>
            )}
          </div>
        )}
      </div>
      {/* {generateTitle.length > 0 && (
        <Button className="w-100" variant="outlined">
          GENERATE COURSE OVERVIEW FOR ALL
        </Button>
      )} */}

      {generateTitle.length > 0 && (
        <TableContainer
          component={Paper}
          className="w-full"
          style={{ marginTop: "20px" }}
        >
          <Table sx={{ minWidth: 100 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#EEF7FF" }}>
              <TableRow>
                <TableCell className="fw-bold">Course Titles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generateTitle.map((data, index) => {
                const title = data.title.replace(/[*]/g, "");
                const title1 = title.replace(/^\d+\.\s*/, "");
                const titleArray = title.split(":");
                const modalTitle = titleArray[0].replace(/^\d+\.\s*/, "");
                console.log(modalTitle);
                return (
                  <TableRow
                    key={data._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <div className="d-flex flex-column">
                        <p
                          className="mt-2 fw-semibold"
                          style={{ marginBottom: "2px" }}
                        >
                          {titleArray[0]}
                        </p>
                        <p style={{ marginBottom: "8px", marginLeft: "15px" }}>
                          {titleArray[1]}
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ columnGap: "8px", rowGap: "8px" }}
                        >
                          {data.overview !== "" && (
                            <>
                              <Chip
                                size="small"
                                style={{ fontSize: "11px", cursor: "pointer" }}
                                label="Text Course Overview"
                                variant="outlined"
                                color="primary"
                                icon={<CgFormatText className=" fs-6 ms-2" />}
                              />
                              <a href={data.pdfOverview} target="_blank">
                                {" "}
                                <Chip
                                  size="small"
                                  style={{
                                    fontSize: "11px",
                                    cursor: "pointer",
                                  }}
                                  label="PDFs Course Overview"
                                  variant="outlined"
                                  color="primary"
                                  icon={<FaFilePdf className=" fs-6 ms-2" />}
                                />
                              </a>
                            </>
                          )}
                          {data.materials.length > 0 && (
                            <>
                              <Chip
                                size="small"
                                style={{ fontSize: "11px", cursor: "pointer" }}
                                label="Text Course Materials"
                                variant="outlined"
                                color="primary"
                                icon={<CgFormatText className=" fs-6 ms-2" />}
                              />

                              <Chip
                                onClick={handleOpen}
                                size="small"
                                style={{
                                  fontSize: "11px",
                                  cursor: "pointer",
                                }}
                                label="⁠PDFs Course Materials"
                                variant="outlined"
                                color="primary"
                                icon={<FaFilePdf className=" fs-6 ms-2" />}
                              />
                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                slots={{ backdrop: Backdrop }}
                                slotProps={{
                                  backdrop: {
                                    timeout: 500,
                                  },
                                }}
                              >
                                <Fade in={open}>
                                  <Box sx={style}>
                                    <Typography
                                      id="transition-modal-title"
                                      variant="h6"
                                      component="h4"
                                      style={{
                                       marginBottom: "10px",
                                       fontSize: "14px",
                                       fontWeight: "600",
                                      }}
                                    >
                                      {modalTitle}
                                    </Typography>
                                    <hr/>
                                    {
                            data.pdfMaterials.map((item, index) => {
                              
                              return (
                                  <a
                                  key={index+1}
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div className="d-flex justify-content-start mb-3">
                                      <PictureAsPdfIcon
                                        className="text-danger fs-4 mt-1"
                                      />
                                      <span
                                        className="text-black  ms-2"
                                        style={{
                                          fontSize: "14px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {title1} 
                                      </span>
                                    </div>
                                  </a>
                              )
                            })
                          }
                                  </Box>
                                </Fade>
                              </Modal>
                            </>
                          )}
                          {data.Mcqs !== "" && (
                            <>
                              <Chip
                                size="small"
                                style={{ fontSize: "11px", cursor: "pointer" }}
                                label="⁠Text MCQ"
                                variant="outlined"
                                color="primary"
                                icon={
                                  <MdPendingActions className=" fs-6 ms-2" />
                                }
                              />
                              <a href={data.pdfMcqs} target="_blank">
                                <Chip
                                  size="small"
                                  style={{
                                    fontSize: "11px",
                                    cursor: "pointer",
                                  }}
                                  label="⁠PDFs MCQ"
                                  variant="outlined"
                                  color="primary"
                                  icon={<FaFilePdf className=" fs-6 ms-2" />}
                                />
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                      {data.CourseImg !== "" && (
                        <a href={data.CourseImg} target="_blank">
                          <Chip
                            size="small"
                            className="mt-3"
                            style={{ fontSize: "11px", cursor: "pointer" }}
                            label="Course Image"
                            variant="outlined"
                            color="primary"
                            icon={<FaFileImage className=" fs-6 ms-2" />}
                          />
                        </a>
                      )}
                      {data.overview === "" &&
                        data.materials.length === 0 &&
                        data.Mcqs === "" &&
                        data.CourseImg === "" && (
                          <CustomButton
                            color="success"
                            isLoading={loadingStates[data._id]}
                            onClick={() => handleGetOverview(data._id, title)}
                          >
                            GENERATE COURSE OVERVIEW
                          </CustomButton>
                        )}
                      {data.overview !== "" &&
                        data.materials.length === 0 &&
                        data.Mcqs === "" &&
                        data.CourseImg === "" && (
                          <CustomButton
                            color="secondary"
                            isLoading={loadingStates[data._id]}
                            onClick={() =>
                              handleGetMaterials(data._id, data.overview)
                            }
                          >
                            GENERATE COURSE MATERIALS
                          </CustomButton>
                        )}
                      {data.overview !== "" &&
                        data.materials.length > 0 &&
                        data.Mcqs === "" &&
                        data.CourseImg === "" && (
                          <CustomButton
                            color="warning"
                            isLoading={loadingStates[data._id]}
                            onClick={() =>
                              handleGetMcq(data._id, data.materials)
                            }
                          >
                            GENERATE COURSE MCQS
                          </CustomButton>
                        )}
                      {data.overview !== "" &&
                        data.materials.length > 0 &&
                        data.Mcqs !== "" &&
                        data.CourseImg === "" && (
                          <CustomButton
                            color="primary"
                            isLoading={loadingStates[data._id]}
                            onClick={() =>
                              handleGetCourseImage(data._id, title1)
                            }
                          >
                            GENERATE COURSE IMAGE
                          </CustomButton>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CourseCreatorAI;
