import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const PdfViewer = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
      setPageNumber(1);
    };
  
    const handlePrevious = () => {
      setPageNumber(prevPage => Math.max(prevPage - 1, 1));
    };
  
    const handleNext = () => {
      setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
    };
  
    return (
      <div className="pdf-viewer">
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div className="navigation">
          <button onClick={handlePrevious} disabled={pageNumber <= 1}>
            Previous
          </button>
          <button onClick={handleNext} disabled={pageNumber >= numPages}>
            Next
          </button>
        </div>
        <p>Page {pageNumber} of {numPages}</p>
      </div>
    );
  };
export default PdfViewer;