import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
// import "./Users.css"
import swal from 'sweetalert';
import Pagination from "../../../components/Pagination/Pagination";
import ViewStudent from '../Users/ViewStudent';
import AddCourseModal from '../Users/AddCourse/AddCourse';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ArrowBack } from '@mui/icons-material';



const RejectedCertificate = () => {
    const [certificates, setCertificates] = useState([]);
    const [studentModal, setStudentModal] = useState(false)
    const [openAddCourseModal, setAddCourseModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [addCourseData, setAddCourseData] = useState(null)

    useEffect(() => {
        const data = async () => {
            await axios.get(`https://backend.studyswiftly.com/api/certificate/`)
                .then(res => {
                    setCertificates(res.data.result?.filter(res=>res.status=="cancel"))
                })
        }
        data();
    }, [])



    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [show, setShow] = useState(2);
    const [users, setUsers] = useState([]);
    const [lastPage, setLastPage] = useState(0);
    const [sliceUsers, setSliceUsers] = useState([]);
  
    // console.log(sliceProducts)
  
    useEffect(() => {
      const lastPage = Math.ceil(certificates?.length / show);
      setLastPage(lastPage);
    }, [certificates, show]);
  
    useEffect(() => {
      if (pageNumber) {
        const page = parseInt(pageNumber);
        const getSlicingProduct = certificates.slice((page - 1) * show, page * show);
        setSliceUsers([...getSlicingProduct]);
        setPageNumber(parseInt(page));
      } else {
        const getSlicingProduct = certificates.slice(0, show);
        setSliceUsers([...getSlicingProduct]);
      }
    }, [certificates, show, pageNumber]);
  
    const pageHandle = (jump) => {
      // navigate(`/admin/products/${jump}`);
      setPageNumber(parseInt(jump));
    };
  
  
  

    const handleDelete = (id) => {

        Swal.fire({
            text: "Are you sure, you want to delete this certificate?",
            showDenyButton: true,
            showCancelButton: false,
            customClass: "modal_class_success",
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://backend.studyswiftly.com/api/certificate/${id}`)
                    .then(res => {
                        if (res.status === 200) {

                            const wrapper = document.createElement("div");
                            wrapper.innerHTML = `<p class='text-break text-white'>Certificate deleted successfully.</p>`;

                            Swal.fire(
                                {
                                    html: wrapper,
                                    icon: "success",
                                    customClass: "modal_class_success",
                                }
                            )

                            setCertificates(certificates.filter(ct => ct._id !== id))
                        }
                    })
                    .catch(error => {


                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

                        Swal.fire(
                            {
                                html: wrapper,
                                icon: "success",
                                customClass: "modal_class_success",
                            }
                        )


                    })

            }
        })



    }
    const navigate = useNavigate();


console.log(sliceUsers)
console.log("sliceUsers")


    return (
        // <div className='adminBody' style={{ height: "100vh" }}>
        <div className='adminBody'>
            <p className='cursor-pointer' style={{ cursor: "pointer" }} title='Back' onClick={() => navigate(-1)}><ArrowBack /></p>
            <h5 className='text-white text-start text-uppercase'>Certificates Rejected</h5>
            <h5 className='text-white text-start'></h5>
            <div className='adminCard py-2'>
                <div className="tableNormal pt-5">

                    <Table className='text-white adminDataTable'>


                        <thead>
                            <tr>
                                <th className='text-start '>Name</th>
                                <th className='text-start adminHidden'>Certificate No</th>
                                <th className='text-start'>Date</th>

                                <th className='text-start'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                certificates?.map(data => (
                                    <tr className='tableRow' key={data._id}>
                                        <td align='text-start'>{data?.name}</td>
                                        <td className='text-start adminHidden'>{data?.CertificateNo}</td>
                                        <td className='text-start'>{data?.date}</td>

                                        <td className='action'>
                                            <div className='d-flex'>
                                                
                                                <Link to={`/admin/edit-certificate/${data?._id}`}><button className="editBtn"><i className="fas fa-edit"></i></button></Link>
                                            
                                                <button onClick={() => handleDelete(data?._id)} className='ms-1 studentBtnDlt'><DeleteIcon /></button>

                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>




                    </Table>


                    {sliceUsers?.length && (
          <div>
            <Pagination
              lastPage={lastPage}
              page={pageNumber}
              pageHandle={pageHandle}
            />
          </div>
        )}



                </div>



            </div>
        </div>
    );
};

export default RejectedCertificate;