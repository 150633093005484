import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { BlockchainContext } from "../../Context/BlockchainContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import "./Shop.css";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}>
    •
  </Box>
);

export default function EnrolledItems() {
  const [enrolled, setEnrolled] = useState([]);
  const [enrolledLoader, setEnrolledLoader] = useState(false);
  const {
    user,
    openWalletModal,
    getBalanceTestnet,
    logOut,
    refetchAfterRegister,
  } = useContext(BlockchainContext);
  const { data } = useContext(UserContext);

  console.log(user?.walletAddress);

  useEffect(() => {
    setEnrolledLoader(true);
    // axios.get(`https://backend.studyswiftly.com/api/mint/data/${user?.walletAddress}`)
    axios
      .get(
        `https://backend.studyswiftly.com/api/mint/data/email/${data?.email}`
      )
      .then((res) => {
        setEnrolled(res.data.result.reverse());
        setEnrolledLoader(false);
      });
  }, [refetchAfterRegister]);

  console.log("enrolled courses", enrolled);

  return (
    <div className="row">
      {enrolledLoader ? (
        <div className="mt-5 text-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          {enrolled.length == 0 ? (
            <div>
              <p>You have not enrolled to any courses</p>
              <Link to="/courses">
                <Button
                  sx={{
                    padding: "10px 20px",
                    borderRadius: "40px",
                    background: "#FF5421",
                  }}
                  variant="contained">
                  ENROL NOW
                </Button>
              </Link>
            </div>
          ) : (
            <>
              <div
                id="rs-popular-course"
                className="rs-popular-courses style1 orange-style pb-100 md-pb-50">
                <div className="row ">
                  {enrolled?.map((course) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={course?._id}>
                      {console.log("course", course)}
                      <div className="courses-item">
                        <Link to={`/enrollDetails/${course?._id}`}>
                          <div className="img-part">
                            <img src={course?.image} alt={course?.coursename} />
                          </div>
                          <div className="content-part">
                            <ul className="meta-part">
                              {course?.amount === "0.00" ? (
                                <li>
                                  <span className="price"> FREE </span>
                                </li>
                              ) : (
                                <li>
                                  <span className="price">
                                    {" "}
                                    {course?.amount} SGD
                                  </span>
                                </li>
                              )}
                            </ul>
                            <h4
                              className="title mb-3"
                              style={{
                                textTransform: "uppercase",
                                height: "70px",
                                margin: "0px",
                                lineHeight: "23px",
                              }}>
                              <Link to={`/enrollDetails/${course._id}`}>
                                {course?.coursename
                                  ? course?.coursename
                                  : "Become a PHP Master and Make Money Fast"}
                              </Link>
                            </h4>
                            {enrolled.length === 0 && (
                              <div className="bottom-part">
                                <div className="info-meta">
                                  <ul>
                                    <li>
                                      <button className="mordetailsBtn">
                                        CLICK HERE FOR MORE DETAILS
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                <div className="btn-part">
                                  <Link to={`/enrollDetails/${course?._id}`}>
                                    <i className="flaticon-right-arrow"></i>
                                  </Link>
                                </div>
                              </div>
                            )}

                            {enrolled.length === 0 ? (
                              <>
                                <div className=" d-flex justify-content-center ">
                                  <Link
                                    className="w-100"
                                    to={`/enrollDetails/${course?._id}`}>
                                    <button className="btns">LEARN</button>
                                  </Link>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="learn_botton d-flex justify-content-center mt-2 pt-3">
                                  <Link
                                    className="w-100"
                                    to={`/enrollDetails/${course?._id}`}>
                                    <button className="btns">LEARN</button>
                                  </Link>
                                </div>
                              </>
                            )}
                          </div>
                        </Link>
                      </div>
                      <p>
                        Number of attempts to get the certificate:{" "}
                        {course?.attempt}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
