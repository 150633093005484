import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuItems from "./MenuItems";
import RSMobileMenu from "./RSMobileMenu";
import CanvasMenu from "./CanvasMenu";
import TopHeader from "./TopBar";

// import normalLogo from "../../../assets/img/logo/lite-logo.png";
import darkLogo from "../../../assets/img/logo/dark-logo.png";

// import productImg1 from "../../../assets/img/shop/1.jpg";
// import productImg2 from "../../../assets/img/shop/2.jpg";
import { UserContext } from "../../../Context/UserContext";
// import { BlockchainContext } from "../../../Context/BlockchainContext";
// import InfoIcon from "@mui/icons-material/Info";
// import { Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
import axios from "axios";

const Header = (props) => {
  const {
    headerClass,
    parentMenu,
    secondParentMenu,
    activeMenu,
    headerNormalLogo,
    headerStickyLogo,
    mobileNormalLogo,
    mobileStickyLogo,
    TopBar,
    TopBarClass,
    emailAddress,
    phoneNumber,
    Location,
    CanvasLogo,
    CanvasClass,
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const { data } = useContext(UserContext);
  const { pathname } = useLocation();

  const [isVisible, setIsVisible] = useState(false);

  // const { user, openWalletModal, setRefetch, refetch, pathName } =
  //   useContext(BlockchainContext);
  const navigate = useNavigate();
  // const [checkDevice, setCheckDevice] = useState("");

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen);
  };

  // useEffect(() => {
  //   const detecting = async () => {
  //     if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
  //       console.log("mobile");
  //       setCheckDevice(() => "mobileWithoutApp");
  //     } else if (
  //       window.innerWidth < 1000 &&
  //       typeof window.ethereum !== "undefined"
  //     ) {
  //       setCheckDevice(() => "mobileWithApp");
  //     } else if (
  //       window.innerWidth > 1000 &&
  //       typeof window.ethereum !== "undefined"
  //     ) {
  //       console.log("pc");
  //       setCheckDevice(() => "pcWithExtention");
  //     } else if (
  //       window.innerWidth > 1000 &&
  //       typeof window.ethereum === "undefined"
  //     ) {
  //       setCheckDevice(() => "pcWithoutExtention");
  //     }
  //   };

  //   detecting();
  // }, []);

  useEffect(() => {
    console.log("dataa" + data.email);
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const searchModalAdd = () => {
    document.body.classList.add("modal-open");
  };

  const canvasMenuAdd = () => {
    document.body.classList.add("nav-expanded");
  };

  // useEffect(() => {
  //   if (user?.walletAddress && refetch && pathname != pathName) {
  //     navigate("/my-account");
  //   }
  // }, [refetch]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 316,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    const data = async () => {
      await axios
        .get(
          "https://backend.studyswiftly.com/api/v1/category/category",
          config
        )
        .then((res) => {
          setCategories(
            res.data.Categories?.sort((a, b) => a.name.localeCompare(b.name))
          );
        });
    };
    data();
  }, []);

  return (
    <React.Fragment>
      <div
        className={
          headerClass ? headerClass : "full-width-header home8-style4 main-home"
        }>
        <header id="rs-header" className="rs-header">
          {TopBar ? (
            <TopHeader
              topBarClass={TopBarClass}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              Location={Location}
            />
          ) : (
            ""
          )}

          <div
            className={
              isVisible
                ? "menu-area menu-sticky sticky"
                : "menu-area menu-sticky"
            }>
            <div className="container">
              <div className="row y-middle">
                <div className="col-xl-2 ">
                  <div className="logo-area hidden-md">
                    <Link to="/">
                      <p
                        className={`logo-text normal-logo ${
                          headerNormalLogo ? "text-black a" : "b"
                        }`}>
                        STUDYSWIFTLY.COM
                      </p>
                      <p
                        className={`logo-text sticky-logo ${
                          headerStickyLogo ? "text-black c" : "text-black d"
                        }`}>
                        STUDYSWIFTLY.COM
                      </p>
                      {/* <img
                        className="normal-logo"
                        src={headerNormalLogo ? headerNormalLogo : normalLogo}
                        alt=""
                      />
                      <img
                        className="sticky-logo"
                        src={headerStickyLogo ? headerStickyLogo : darkLogo}
                        alt=""
                      /> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7  text-end">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <div className="mobile-menu md-display-flex">
                        <Link to="/" className="mobile-normal-logo">
                          <p
                            className={`logo-text normal-logo ${
                              mobileNormalLogo ? "text-black" : ""
                            }`}>
                            STUDYSWIFTLY.COM
                          </p>
                          {/* <img
                            className="normal-logo"
                            src={
                              mobileNormalLogo ? mobileNormalLogo : normalLogo
                            }
                            alt=""
                          /> */}
                        </Link>
                        <Link
                          to="/"
                          className={`mobile-sticky-logo text-black`}>
                          <p className={`logo-text text-dark`}>
                            STUDYSWIFTLY.COM
                          </p>
                          {/* <img
                            src={mobileNormalLogo ? mobileNormalLogo : darkLogo}
                            alt="logo"
                          /> */}
                        </Link>
                        <Link
                          to="#"
                          className="rs-menu-toggle"
                          onClick={() => {
                            setMenuOpen(!menuOpen);
                          }}>
                          <i className="fa fa-bars"></i>
                        </Link>
                      </div>
                      <nav className="rs-menu hidden-md ">
                        <ul className="nav-menu">
                          <MenuItems
                            parentMenu={parentMenu}
                            secondParentMenu={secondParentMenu}
                            activeMenu={activeMenu}
                          />
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items d-flex align-items-center">
                      <li className="sidebarmenu-search">
                        <Link
                          to="#"
                          onClick={searchModalAdd}
                          className="rs-search"
                          href="#">
                          <i className="flaticon-search"></i>
                        </Link>
                      </li>

                      {/* {!data.email && (
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <li className="nav-bar-info-button">
                            <a
                              href="https://studyswiftly.com/join-us"
                              target="_blank">
                              <button>
                                <InfoIcon
                                  sx={{ color: "#cb8c00 !important" }}
                                />
                              </button>
                            </a>
                          </li>
                        </ClickAwayListener>
                      )} */}

                      <li className="login-register">
                        <div className="menuText ">
                          {/* {checkDevice === "mobileWithoutApp" && (
                            <a
                              href="https://metamask.app.link/dapp/http://studyswiftly.com"
                              target={"_blank"}
                              className="text-decoration-none">
                              <button
                                className=" bannerButtonW text-white  "
                                style={{ fontSize: "11px" }}>
                                <img
                                  className="me-1"
                                  width="22px"
                                  src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                  alt=""
                                />
                                GET STARTED
                              </button>
                            </a>
                          )} */}
                          {/* {checkDevice === "mobileWithApp" && ( */}
                          <>
                            {!data.email ? (
                              // <div class="wrap">
                              //   <button class="buttons">
                              //     <img
                              //       className="me-1"
                              //       width="22px"
                              //       src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                              //       alt=""
                              //     />
                              //     GET STARTED
                              //   </button>
                              // </div>
                              <Link className="header-btn" to="/sign-in">
                  <img
                                  className="me-1"
                                  width="22px"
                                  src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                  alt=""
                                />
                                GET STARTED
                </Link>
                              // <button
                              //   className=" bannerButtonW text-white"
                              //   onClick={() => navigate("/login")}
                              //   style={{ fontSize: "11px" }}>
                              //   <img
                              //     className="me-1"
                              //     width="22px"
                              //     src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                              //     alt=""
                              //   />
                              //   GET STARTED
                              // </button>
                            ) : (
                              // <button
                              //   className=" bannerButtonW text-white"
                              //   onClick={() => navigate("/login")}
                              //   style={{ fontSize: "11px" }}>
                              //   <img
                              //     className="me-1"
                              //     width="22px"
                              //     src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                              //     alt=""
                              //   />
                              //   GET STARTED
                              // </button>
                              <Link className="header-btn" to="/my-account">
                  <img
                                  className="me-1"
                                  width="22px"
                                  src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                  alt=""
                                />
                               PROFILE
                </Link>
                            )}
                          </>
                          {/*  )} */}
                          {/* {checkDevice === "pcWithExtention" && (
                            <>
                              {!user.walletAddress ||
                              user.walletAddress === "undefined" ? (
                                <button
                                  className="bannerButtonW text-white"
                                  onClick={() => navigate("/login")}
                                  style={{ fontSize: "11px" }}>
                                  <img
                                    className="me-1"
                                    width="22px"
                                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                    alt=""
                                  />
                                  GET STARTED
                                </button>
                              ) : (
                                <Link
                                  style={{ fontSize: "13px", width: "120px" }}
                                  className=" bannerButtonW text-white "
                                  role="button"
                                  to="/my-account">
                                  Profile
                                </Link>
                                // <button
                                //     className="text-uppercase bannerButtonW text-white "
                                //     onClick={() => navigate("/my-account")} style={{ fontSize: '12px' }}
                                // >
                                //     <img className='me-1' width='22px' src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg" alt="" />
                                //     profile
                                // </button>
                              )}
                            </>
                          )}
                          {checkDevice === "pcWithoutExtention" && (
                            <a
                              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                              target={"_blank"}
                              className="text-decoration-none">
                              <button
                                className="bannerButtonW text-white "
                                style={{ fontSize: "11px" }}>
                                <img
                                  className="me-1"
                                  width="22px"
                                  src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                                  alt=""
                                />
                                GET STARTED
                              </button>
                            </a>
                          )} */}
                        </div>
                      </li>

                      {/* {user?.walletAddress ?
												<li className='login-register'>
													<div className='menuText '>
														<Link style={{ fontSize: '13px', width: "120px" }} className="bannerButtonW text-white" role="button" to="/my-account">Profile</Link>
													</div>
												</li>
												:
												<li className='login-register'>
													<div className='menuText '>
														<button style={{ fontSize: '11px' }} className="bannerButtonW" role="button" onClick={() => openWalletModal()}>
															<img className='me-1' width='22px' src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg" alt="" />
															LOGIN WITH WALLET </button>
													</div>
												</li>
											} */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RSMobileMenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            parentMenu={parentMenu}
            secondParentMenu={secondParentMenu}
          />
          <div
            onClick={() => setMenuOpen(false)}
            className={menuOpen ? "body-overlay show" : "body-overlay"}></div>
        </header>
        <CanvasMenu
          canvasClass={
            CanvasClass
              ? CanvasClass
              : "right_menu_togle orange_color hidden-md"
          }
          canvasLogo={CanvasLogo ? CanvasLogo : darkLogo}
        />
      </div>
    </React.Fragment>
  );
};

export default Header;
