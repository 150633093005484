import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";

export const UserContext = createContext();

export default function UserProvider({ children }) {
  const [email1, setEmail1] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    // axios.get(`https://backend.studyswiftly.com/api/user/currentuser`, {
    axios
      .get(`https://backend.studyswiftly.com/api/v1/user/current-user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenBlockchian")}`,
        },
      })
      .then((res) => {
        setData(res?.data?.result);
        console.log("CallingInFetch", res.data.result);
      });
    console.log("callingTime?", data);
  }, [refetch]);

  const handleRegister = (data) => {
    // axios.post("https://backend.studyswiftly.com/api/user/register", data, {
    axios
      .post("https://backend.studyswiftly.com/api/v1/user/signup", data, {})
      .then((res) => {
        if (res.status === 200) {
          setRefetch(!refetch);
          localStorage.setItem("tokenBlockchian", res.data.token);
          console.log(res.data.token);

          // swal({
          //     title: "Success",
          //     text: `${res.data.message}`,
          //     icon: "success",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });

          // navigate(`/my-account/${email}`);
        }
      })
      .catch((error) => {
        // swal({
        //     title: "Attention",
        //     text: `${error.response.data.message}`,
        //     icon: "warning",
        //     button: "OK!",
        //     className: "modal_class_success",
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        });
      });
  };

  const handleUserLogin = (data) => {
    // axios.post("https://backend.studyswiftly.com/api/user", data, {
    axios
      .post("https://backend.studyswiftly.com/api/v1/user/signin", data, {})
      .then((res) => {
        if (res.status === 200) {
          setRefetch(!refetch);
          localStorage.setItem("tokenBlockchian", res.data.token);
          console.log(res.data.token);
          // swal({
          //     title: "Success",
          //     text: `${res.data.message}`,
          //     icon: "success",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });
        } else if (res.status === 400) {
          // swal({
          //     title: "Attention",
          //     text: `${res.data.message}`,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        }
      })
      .catch((error) => {
        // swal({
        //     title: "Attention",
        //     text: `${error.response.data.message}`,
        //     icon: "warning",
        //     button: "OK!",
        //     className: "modal_class_success",
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        });
      });
  };

  const sendResetLink = (email) => {
    // axios.post("https://backend.studyswiftly.com/api/user/forgotpass", { email })
    axios
      .post(
        "https://backend.studyswiftly.com/api/v1/user/send-reset-password-link",
        { email }
      )
      .then((res) => {
        if (res.status === 200) {
          // swal({
          //     title: "Success",
          //     text: `${res.data.message}`,
          //     icon: "success",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${res.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          });
        }
      })
      .catch((err) => {
        // swal({
        //     title: "Attention",
        //     text: `${err.response.data.message}`,
        //     icon: "warning",
        //     button: "OK!",
        //     className: "modal_class_success",
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${err.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        });
      });
  };

  const logout = () => {
    setData("");
    localStorage.removeItem("tokenBlockchian");
    setRefetch(!refetch);
  };

  return (
    <UserContext.Provider
      value={{
        setEmail1,
        setData,
        data,
        handleRegister,
        handleUserLogin,
        logout,
        setRefetch,
        refetch,
        sendResetLink,
      }}>
      {children}
    </UserContext.Provider>
  );
}
