import React, { useContext, useEffect, useState } from "react";
import "./Mcq.css";
import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios";
import { BlockchainContext } from "../../Context/BlockchainContext";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";

function Mcq({ latestCourseDetails }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    Array(latestCourseDetails?.McqData?.length).fill(null)
  );
  const [showResult, setShowResult] = useState(false);
  const [mcqResult, setMcqResult] = useState();
  const { user } = useContext(BlockchainContext);
  const [refetch, setrefetch] = useState(1);
  const [certificates, setCertificates] = useState([]);
  const [mintedCertificates, setMintedCer] = useState([]);
  const navigate = useNavigate();

  const [answerResult, setAnswerResult] = useState({
    correctMsg: "",
    wrongMsg: null,
  });
  let newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  newDate = dd + "-" + mm + "-" + yyyy;

  useEffect(() => {
    const data1 = async () => {
      await axios
        .get(
          `https://backend.studyswiftly.com/api/certificate/email/${user.email}`
        )
        .then((res) => {
          setMcqResult(res.data.result);
        });
    };
    const data = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/certificate/`)
        .then((res) => {
          setCertificates(res.data.result);
        });
    };
    const data3 = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/v1/mint-certificate/mint-nft`)
        .then((res) => {
          setMintedCer(res.data);
          console.log(res.data.length)
        });
    };
    data();
    data1();
    data3();
  }, []);

 

  const handleAnswer = (answer) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentQuestion] = answer === "YES" ? true : false;
      return newAnswers;
    });
  };
  const handlePrevious = () => {
    setCurrentQuestion((prevQuestion) => prevQuestion - 1);
  };

  const handleNext = () => {
    setCurrentQuestion((prevQuestion) => prevQuestion + 1);
  };

  const handleSubmit = async () => {
    // Check if all questions are answered
    if (userAnswers.includes(null)) {
      swal("Please answer all questions!", "", "warning");
      return;
    }

    // Calculate the score
    let correctAnswers = 0;
    for (let i = 0; i < latestCourseDetails?.McqData?.length; i++) {
      if (userAnswers[i] === latestCourseDetails?.McqData[i].ans) {
        correctAnswers++;
      }
    }
    const percentage =
      (correctAnswers / latestCourseDetails?.McqData?.length) * 100;

    // Show alert if score is less than 60%
    if (Math.round(percentage) < 60) {
      Swal.fire({
        // title: "Sorry, you didn't pass the test",
        text: `You have not attained 60% and more. Please try again.`,
        icon: "error",
        confirmButtonText: "Retake test",
        customClass: "modal_class_success",
      }).then((result) => {
        if (result.isConfirmed) {
          setCurrentQuestion(0);
          setUserAnswers(
            Array(latestCourseDetails?.McqData?.length).fill(null)
          );
          setShowResult(false);
        }
      });
    } else {
      const formData = {
        coursename: latestCourseDetails.coursename,
        name: user.name,
        email: user.email,
        walletAddress: user.walletAddress,
        fromDate: latestCourseDetails.date,
        date: newDate,
        description: latestCourseDetails.description,
        Serial: `${
          mintedCertificates?.length == 0
            ? 1
            : Number(mintedCertificates?.[mintedCertificates?.length - 1]?.Serial) + 1
        }`,
        memberId: user.memberId,
        marks: Math.round(percentage),
      };

      console.log("formData", formData);
      console.log("mcqData", mintedCertificates?.length);

      await axios
        .post(
          "https://backend.studyswiftly.com/api/certificate/",
          formData
        )
        .then((res) => {
          if (res.status === 200) {
            setrefetch(refetch + 1);
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p class='text-break text-white'>Congratulations, you have passed. <br/> Mint your certificate now! <br/> <span class='text-warning'>You have gained ${Math.round(
              percentage
            )}%</span></p>`;

            Swal.fire({
              html: wrapper,
              icon: "success",
              customClass: "modal_class_success",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/my-account", { state: { name: 2 } });
              }
            });
            // setShowResult(true);
          }
        })
        .catch((error) => {
          console.log(error);

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        });

      //   setShowResult(true);
    }
  };

  const renderResult = () => {
    let correctAnswers = 0;
    for (let i = 0; i < latestCourseDetails?.McqData.length; i++) {
      if (userAnswers[i] === latestCourseDetails?.McqData[i].ans) {
        correctAnswers++;
      }
    } 
    const percentage =
      (correctAnswers / latestCourseDetails?.McqData.length) * 100;
    return (
      <div>
        {/* <h3>Results:</h3> */}
        <p className="text-center">
          <TaskAltIcon className="text-success " style={{ fontSize: "80px" }} />
        </p>
        <p className="text-center">
          You have passed successfully with (
          {Math.round(percentage) || Math.round(mcqResult?.marks)}%) marks!
        </p>
      </div>
    );
  };

  const answerAlert = (ans) => {
    const isCorrect = ans === latestCourseDetails?.McqData[currentQuestion].ans;

    if (isCorrect) {
      setAnswerResult({
        correctMsg: "Congratulations!",
        wrongMsg: "",
        isCorrect: true,
      });
    } else {
      setAnswerResult({
        wrongMsg: "Sorry, you got it wrong!",
        correctMsg: "",
        isCorrect: false,
      });
    }
  };

  if (showResult || Math.round(Number(mcqResult?.marks)) > 60) {
    return renderResult();
  }

  const question = latestCourseDetails?.McqData[currentQuestion].question;

  const isLastQuestion =
    currentQuestion === latestCourseDetails?.McqData.length - 1;
  const hasNext = !isLastQuestion;
  const hasPrevious = currentQuestion !== 0;

  return (
    <div className="question-container px-2">
      <h3 className="question text-center">{question}</h3>
      <div className="answer-container">
        <button
          style={{ padding: "5px 20px" }}
          className={`btn btn-primary btn-sm me-2 ${
            userAnswers[currentQuestion] === true
              ? "bg-success"
              : userAnswers[currentQuestion] === null
              ? ""
              : "bg-primary"
          }`}
          onClick={() => {
            handleAnswer("YES");
            answerAlert(true);
          }}
        >
          YES
        </button>
        <button
          style={{ padding: "5px 20px" }}
          className={`btn btn-primary btn-sm ${
            userAnswers[currentQuestion] === false
              ? "bg-success"
              : userAnswers[currentQuestion] === null
              ? ""
              : "bg-primary"
          }`}
          onClick={() => {
            handleAnswer("NO");
            answerAlert(false);
          }}
        >
          NO
        </button>
      </div>
      <div className="count-container">
        <p className="mb-0 text-center mt-2">
          Question {currentQuestion + 1} of{" "}
          {latestCourseDetails?.McqData.length}
        </p>
        {(answerResult.correctMsg || answerResult.wrongMsg) && (
          <>
            {console.log(answerResult.correctMsg, answerResult.wrongMsg)}
            {answerResult.correctMsg ? (
              <p className="text-success">{answerResult.correctMsg}</p>
            ) : (
              <p className="text-danger">{answerResult.wrongMsg}</p>
            )}
          </>
        )}
      </div>
      <div className="button-container">
        {hasPrevious && (
          <button
            style={{ padding: "5px 20px", backgroundColor: "#171f32" }}
            className="btn text-light me-2"
            onClick={handlePrevious}
          >
            Previous
          </button>
        )}
        {hasNext && (
          <button
            className="btn text-light"
            style={{ padding: "5px 20px", backgroundColor: "#ff5421" }}
            disabled={userAnswers[currentQuestion] === null}
            onClick={() => {
              handleNext();
              setAnswerResult("");
            }}
          >
            Next
          </button>
        )}
        {!hasNext && (
          <button
            className="btn text-light"
            style={{ padding: "5px 20px", backgroundColor: "#ff5421" }}
            disabled={userAnswers[currentQuestion] === null}
            onClick={handleSubmit}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default Mcq;
