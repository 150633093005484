import React, { useContext, useEffect, useState } from "react";
import Newsletter from "../../components/Common/Newsletter";
import Footer from "../../components/Layout/Footer/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import OffWrap from "../../components/Layout/Header/OffWrap";
import Header from "../../components/Layout/Header/Header";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

import usePagination from "../../Pagination/UsePagination";

import { makeStyles } from "@mui/styles";

import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import { ReactCountdownClock } from "react-countdown-clock";
import { Pagination } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { UserContext } from "../../Context/UserContext";
import Swal from "sweetalert2";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      backgroundColor: "#a6a6ab",
      color: "#fff",
    },
    "& .MuiPaginationItem-root:hover": {
      backgroundColor: "#a6a6ab",
      color: "#fff",
    },
  },
}));

const MCQ = () => {
  const { id, enrolId } = useParams();
  const navigate = useNavigate();
  const [singleCourse, setSingleCourse] = useState([]);
  const { data } = useContext(UserContext);
  const classes = useStyles();
  let [page, setPage] = useState(1);
  const PER_PAGE = 1;
  const [startMcq, setStartMcq] = useState(false);
  const [finish, setFinish] = useState(false);
  const [restarted, setRestarted] = useState(false);
  const [attempted, setAttempted] = useState(false);
  const [allMcq, setAllMcq] = useState([]);
  const mcqCount = Math.ceil(allMcq?.length / PER_PAGE);
  const mcqPageData = usePagination(allMcq, PER_PAGE);
  const [mintedCertificates, setMintedCer] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOptionsAns, setSelectedOptionsAns] = useState([]);

  const handleMcqPageChange = (e, p) => {
    setPage(p);
    mcqPageData.jump(p);
    console.log(mcqPageData, "mcqPageData");
  };

  function getRandomObjects(array) {
    return array.sort(() => Math.random() - 0.5).slice(0, 10);
  }

  useEffect(() => {
    const data1 = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/course/${id}`)
        .then((res) => {
          setSingleCourse(res.data.result);
          setAllMcq(getRandomObjects(res.data.result?.allMcq));
          // console.log(res.data.result)
        });
    };
    const data3 = async () => {
      await axios
        .get(`https://backend.studyswiftly.com/api/certificate/`)
        .then((res) => {
          setMintedCer(res.data.result);
          console.log(res.data.length);
        });
    };
    data3();
    data1();
  }, [id, restarted]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [finish, startMcq]);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too lale...</div>;
    }

    const minutes = Math.floor(remainingTime / 60); // Calculate minutes
    const seconds = remainingTime % 60; // Calculate seconds

    return (
      <div className="timer">
        <div className="text">Remaining:</div>
        <div className="value">
          {minutes > 0 && `${minutes} min`} {seconds} sec
        </div>
      </div>
    );
  };

  const setAttempt = () => {
    setAttempted(true)
    axios
      .put(
        `https://backend.studyswiftly.com/api/mint/change-attempt/${enrolId}`
      )
      .then((res) => {
        setStartMcq(true);
        setAttempted(false)
      })
      .catch(e => {
        setAttempted(false)
      });
  };

  const handleCompletion = () => {
    // Your custom logic here
    console.log("again tik tok");
    setFinish(true);
    // handleMcqPageChange(1, page + 1)
    // restartTimer()
    // Return object to specify whether to repeat the timer and delay
    return { shouldRepeat: true, delay: 1000 }; // For example, repeat after 1 second
  };

  const [timerKey, setTimerKey] = useState(Date.now());
  const restartTimer = () => {
    axios
      .put(
        `https://backend.studyswiftly.com/api/mint/change-attempt/${enrolId}`
      )
      .then((res) => {
        setTimerKey(Date.now());
        setFinish(false);
        setRestarted((res) => !res);
        setSelectedOptions({});
        setPage(1);
        mcqPageData.jump(1);
        setSelectedOptionsAns([]);
      });

    // Update the key to force rerender and restart the timer
  };

  const handleOptionChange = (event, index, ans) => {
    // const { value } = event.target;
    const val = event == ans;
    selectedOptionsAns[index - 1] = val;
    console.log(`radio${index}`, event);
    setSelectedOptions((prevState) => ({
      ...prevState,

      [`radio${index}`]: event,

      // Update selected option for the specified question index
    }));
    console.log(selectedOptions, selectedOptionsAns);
  };

  const getResult = () => {
    const result = allMcq.map((res) => res.ans);
  };

  const newDate = new Date();
  const sendCertificate = () => {
    const formData = {
      coursename: singleCourse.coursename,
      name: data?.name,
      email: data?.email,
      walletAddress: data?.walletAddress,
      fromDate: singleCourse?.date,
      date: newDate?.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      description: singleCourse.description,
      Serial: `${
        mintedCertificates?.length == 0
          ? 1
          : Number(mintedCertificates?.length) + 1
      }`,
      memberId: data?.memeberId,
      marks: selectedOptionsAns.filter((res) => res == true)?.length,
    };

    axios
      .post("https://backend.studyswiftly.com/api/certificate/", formData)
      .then((res) => {
        if (res.status === 200) {
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white'>Congratulations, you have passed. <br/> You request for certificate has been sent to the admin <br/> <span class='text-warning'>You have gained ${
            selectedOptionsAns.filter((res) => res == true)?.length
          }/10</span></p>`;

          Swal.fire({
            html: wrapper,
            icon: "success",
            customClass: "modal_class_success",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/my-account", { state: { name: 2 } });
            }
          });
          // setShowResult(true);
        }
      })
      .catch((error) => {
        console.log(error);

        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='text-break text-white'>${error.response.data.message}</p>`;

        Swal.fire({
          html: wrapper,
          icon: "warning",
          customClass: "modal_class_success",
        });
      });

    //   setShowResult(true);
  };

  return (
    <div>
      <React.Fragment>
        <OffWrap />
        <Header
          parentMenu="about"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4"
          TopBar="enable"
          TopBarClass="topbar-area home8-topbar"
          emailAddress="support@website.com"
          Location="374 William S Canning Blvd, MA 2721, USA "
        />

        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="Test"
          pageName="Test"
          breadcrumbsImg={bannerbg}
        />
        {/* breadcrumb-area-start */}

        {/* About Main */}
        {finish ? (
          <div class="container mcq-part mt-20 mb-4">
            <div className="p-0 p-md-5 text-center">
              <h4 className="text-white text-center">Your Score is :</h4>
              <p
                className="fw-bold text-center score-circle mx-auto justify-content-center align-items-center"
                style={{
                  border: "1px solid #24fbff",
                  borderRadius: "50%",
                  display: "inline-flex",
                  alignItems: "center",
                }}>
                {selectedOptionsAns.filter((res) => res == true)?.length}/10
              </p>
              {selectedOptionsAns.filter((res) => res == true)?.length < 10 && (
                <div className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={restartTimer}
                    style={{ background: "rgb(220 38 38)" }}>
                    Please try again.
                  </button>
                </div>
              )}
              {selectedOptionsAns.filter((res) => res == true)?.length > 6 && (
                <div className="text-center">
                  <button
                    className="btn success text-white"
                    onClick={sendCertificate}>
                    Please click here to get your certificate.
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div
              className="text-center mx-auto container"
              style={{ marginTop: "30px" }}>
              {startMcq && (
                <div className="d-flex justify-content-center justify-content-md-end">
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <div className="timer-wrapper">
                      <CountdownCircleTimer
                        key={timerKey}
                        isPlaying
                        duration={allMcq?.length * 30}
                        size={150}
                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                        colorsTime={[30, 18, 9, 0]}
                        strokeWidth={8}
                        onComplete={handleCompletion}>
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                    <p className="text-dark mt-3 text-center text-md-end">
                      Total Time: 5 minutes 0 seconds
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div class="container mcq-part mt-5 mb-4">
              {!startMcq && (
                <div className="p-0 p-md-5">
                  <h5 style={{fontSize:"22px"}} className="text-white fw-bold">GET YOUR CERTIFICATE</h5>
                  <div>
                    <p className="mb-2">
                      <span className="fw-bold">Course Name: </span>
                      {singleCourse?.coursename}{" "}
                    </p>
                    <p className="mb-2">
                      <span className="fw-bold"> Number of Questions: </span>{" "}
                      {allMcq?.length}
                    </p>
                    <p className="mb-2">
                      <span className="fw-bold">Time: </span> 5 minutes 0
                      seconds
                    </p>
                  </div>
                  <div
                    className="my-5"
                    style={{
                      background: "#265c77",
                      padding: "12px 18px",
                      borderRadius: "10px",
                    }}>
                    <p className="mb-2">1. Read the instructions carefully.</p>
                    <p className="mb-2">
                      2. Note the time limit and number of questions.
                    </p>
                    <p className="mb-2">
                      {" "}
                      3. Navigating the Test: Use the navigation buttons
                      provided (e.g., "Next," "Previous," "Submit").
                    </p>
                    <p className="mb-2">
                      {" "}
                      4. Read each question thoroughly and answer to the best of
                      your ability.
                    </p>
                    <p className="mb-2">
                      {" "}
                      5. Pace yourself to ensure you complete all questions
                      within the allotted time.
                    </p>
                    <p className="mb-2">
                      {" "}
                      6. Keep an eye on the timer to manage your time
                      effectively.
                    </p>
                    <p className="mb-2">
                      {" "}
                      7. Once you've answered all questions, review your
                      responses.
                    </p>
                    <p className="mb-2">
                      {" "}
                      8. Make any necessary changes before submitting.{" "}
                    </p>
                    <p className="mb-2">
                      9. When you're satisfied with your answers, click the
                      "Submit" button.
                    </p>
                    <p className="mb-2">
                      {" "}
                      10. Do not close the browser until you receive
                      confirmation that the test has been submitted
                      successfully.
                    </p>
                  </div>
                  {/* <p>There will be 10 Question</p>
                  <p>You will get 5 mintues for all the question to answer</p>
                  <p>
                    Once you are ready you can start the test by clicking the
                    below button and your time will start
                  </p> */}
                  <div className="pt-10 d-flex justify-content-between">
                    {" "}
                      <button
                        disabled={attempted}
                      className="btn btn-info text-white mt-5 bg-blue-400"
                      onClick={() => {
                        setAttempt();
                      }}>
                      Please click here to start.
                      </button>
                      <button
                      style={{background:"red"}}
                      className="btn text-white mt-5"
                      onClick={() => {
                        navigate(-1)
                      }}>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {startMcq &&
                mcqPageData?.currentData()?.map((res, index) => (
                  <div class="question ml-sm-5 pl-sm-5 pt-2">
                    <div class="py-2 h5">
                      <b>
                        Q{page}. {res?.question}
                      </b>
                    </div>
                    <div
                      class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3"
                      id="options">
                      {res?.option1 && (
                        <label class="options">
                          {res?.option1}
                          <input
                            type="radio"
                            name={`radio${mcqPageData?.currentPage}`}
                            checked={
                              selectedOptions[
                                `radio${mcqPageData?.currentPage}`
                              ] === 1
                            }
                            onChange={(event) =>
                              handleOptionChange(
                                1,
                                mcqPageData?.currentPage,
                                res?.ans
                              )
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      )}
                      {res?.option2 && (
                        <label class="options">
                          {res?.option2}
                          <input
                            type="radio"
                            name={`radio${mcqPageData?.currentPage}`}
                            checked={
                              selectedOptions[
                                `radio${mcqPageData?.currentPage}`
                              ] === 2
                            }
                            onChange={(event) =>
                              handleOptionChange(
                                2,
                                mcqPageData?.currentPage,
                                res?.ans
                              )
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      )}
                      {res?.option3 && (
                        <label class="options">
                          {res?.option3}
                          <input
                            type="radio"
                            name={`radio${mcqPageData?.currentPage}`}
                            checked={
                              selectedOptions[
                                `radio${mcqPageData?.currentPage}`
                              ] === 3
                            }
                            onChange={(event) =>
                              handleOptionChange(
                                3,
                                mcqPageData?.currentPage,
                                res?.ans
                              )
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      )}
                      {res?.option4 && (
                        <label class="options">
                          {res?.option4}
                          <input
                            type="radio"
                            name={`radio${mcqPageData?.currentPage}`}
                            checked={
                              selectedOptions[
                                `radio${mcqPageData?.currentPage}`
                              ] === 4
                            }
                            onChange={(event) =>
                              handleOptionChange(
                                4,
                                mcqPageData?.currentPage,
                                res?.ans
                              )
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      )}
                    </div>
                  </div>
                ))}
              {startMcq && (
                <div class="d-flex align-items-center pt-3 justify-content-between">
                  {page !== 1 && (
                    <div
                      onClick={(e) => {
                        handleMcqPageChange(e, page - 1);
                      }}
                      id="prev">
                      <button class="btn btn-primary">Previous</button>
                    </div>
                  )}
                  <div></div>
                  <div class="ml-auto mr-sm-5">
                    {mcqPageData?.currentPage == 10 ? (
                      <button
                        class="btn btn-success"
                        onClick={(e) => {
                          setFinish(true);
                        }}>
                        Submit
                      </button>
                    ) : (
                      <button
                        class="btn btn-success"
                        onClick={(e) => {
                          handleMcqPageChange(e, page + 1);
                        }}>
                        Next
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
            {startMcq && (
              <div
                className="d-flex justify-content-center"
                style={{ marginBottom: "30px" }}>
                <Pagination
                  classes={{ ul: classes.ul }}
                  count={mcqCount}
                  page={page}
                  color="primary"
                  onChange={handleMcqPageChange}
                />
              </div>
            )}
          </div>
        )}
        {/* About Main */}

        <Newsletter
          sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
          titleClass="title mb-0 white-color"
        />

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        />
        <SearchModal />
      </React.Fragment>
    </div>
  );
};

export default MCQ;
